import { DataType } from 'modules/logic_builder/types';
import { ParametersState } from '../../types/parameters';
import { Action } from 'redux';

export const ADD_PARAM = 'ADD_PARAM';

export const SET_SELECTED_PARAM = 'SET_SELECTED_PARAM';

export interface AddParamAction extends Action {
  type: 'ADD_PARAM';
  payload: {
    functionUuid: string;
    uuid: string;
    name: string;
    type: DataType;
    desc: string;
    objectUuid?: string;
    enumUuid?: string;
    list: boolean;
    required: boolean;
  };
}

export const addParam = (
  functionUuid: string,
  uuid: string,
  name: string,
  type: DataType,
  desc: string,
  list: boolean,
  required: boolean,
  objectUuid?: string,
  enumUuid?: string
): AddParamAction => ({
  type: ADD_PARAM,
  payload: { functionUuid, uuid, name, type, desc, list, required, objectUuid, enumUuid }
});

export function doAddParam(state: ParametersState, action: AddParamAction): ParametersState {
  const { uuid, name, type, desc, list, required, objectUuid, enumUuid } = action.payload;
  state[uuid] = { uuid, name, type, desc, list, required, objectUuid, enumUuid };
  return state;
}
