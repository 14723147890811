import { CreateElseAction } from '../actions/create_else';
import { FunctionEditorState } from '../../types/function_editor_state';
import { EndpointEditorState } from '../../../../../routes/studio/endpoint_editor/reducers';

export function doCreateElse(
  state: FunctionEditorState | EndpointEditorState,
  action: CreateElseAction
): FunctionEditorState {
  const { functionId, ifBeginAction, elseBeginAction, elseBodyAction, elseEndAction, mode } =
    action.payload;

  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    state.callerInfo.actions.splice(
      elseBeginAction?.order ?? 0,
      0,
      elseBeginAction.uuid,
      elseBodyAction.uuid,
      elseEndAction.uuid
    );
  } else {
    state.functions[functionId].actions.splice(
      elseBeginAction?.order ?? 0,
      0,
      elseBeginAction.uuid,
      elseBodyAction.uuid,
      elseEndAction.uuid
    );
  }

  return state;
}
