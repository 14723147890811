/**
 * Returns 'true' if 'array2' contains every element of 'array1'
 * Returns 'false' otherwise
 */
export function formatCrudType(crudType: string) {
  if (crudType) {
    const words = crudType.split('_').map((word) => word.toLowerCase());
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    const result = capitalizedWords.join(' ');
    return result;
  }
}
