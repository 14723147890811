import React from 'react';
import { FunctionActionManifest } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { t } from 'i18next';
import SessionInfoIcon from './session_info_icon';
import { ACTION_INPUTS_TYPES } from 'web_ui/function_editor/action_inputs';
import { TYPE_PICKER_TYPES } from 'web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';

export const SESSION_INFO_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.SESSION_INFO,
  name: t('SessionInfo'),
  description: t('ActionSession'),
  color: 'success',
  category: FRONTEND_ACTIONS_CATEGORIES.VARIABLE,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.VARIABLE,
  icon: <SessionInfoIcon />,
  inlineInputs: [
    {
      label: t('SetToVariable'),
      placeholder: '',
      key: 'variable',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { argumentTypes: [TYPE_PICKER_TYPES.VAR] }
    },
    {
      label: t('TheSessionValue'),
      placeholder: 'Select Value',
      key: 'value',
      type: ACTION_INPUTS_TYPES.SELECT,
      options: ['idUser', 'name', 'username', 'email']
    }
  ],
  inputs: []
};
