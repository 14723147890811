import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useEndpointEditorDispatch } from '../../../../../../hooks/reduxHooks';
import { updateEndpointParameterType } from '../../../reducers/endpointParameters';
import { DATA_TYPES, DataType, InputType } from '../../../../../../modules/logic_builder/types';
import { EndpointEditorContext } from '../../../index';

export type DataTypeInputProps = {
  parameterId: string;
  endpointId: string;
  className?: string;
  selected: DataType;
  inputType: InputType;
};

export function ParameterDataTypeInput(props: DataTypeInputProps) {
  const { endpointId, selected, parameterId, className, inputType } = props;
  const { t } = useTranslation();
  const dispatch = useEndpointEditorDispatch();
  const { enums, objects } = useContext(EndpointEditorContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let enumId: string | undefined = undefined;
    // 'OBJECT' is automatically selected if input type is 'BODY'.
    // So for now we handle enums only.
    if (e.target.value === 'ENUM') {
      enumId = enums[0]?.uuid;
    }
    dispatch(
      updateEndpointParameterType({
        id: endpointId,
        endpointParameterId: parameterId,
        newType: e.target.value as DataType,
        newEnumId: enumId
      })
    );
  };

  let filteredDataTypes: string[];
  let disabled = false;
  if (inputType === 'BODY') {
    disabled = true;
    filteredDataTypes = Object.keys(DATA_TYPES);
  } else {
    filteredDataTypes = Object.keys(DATA_TYPES).filter((type) => type !== 'OBJECT');
  }

  const disableOption = (type: string): boolean => {
    if (type === 'ENUM' && enums.length === 0) {
      return true;
    } else if (type === 'OBJECT' && objects.length === 0) {
      return true;
    }
    return false;
  };

  return (
    <select
      value={selected}
      onChange={handleChange}
      disabled={disabled}
      onMouseDown={(e) => e.stopPropagation()}
      className={`form-select ${className}`}
    >
      {filteredDataTypes.map((type) => {
        return (
          <option key={type} value={type} disabled={disableOption(type)}>
            {t(DATA_TYPES[type as DataType])}
          </option>
        );
      })}
    </select>
  );
}
