import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import { changeComponentProperty } from '../../store/actions/components';
import { InterfaceStudioState } from '../../store';
import { ComponentUUID } from '../../../types';
import { WorkboardContext } from 'web_ui/workboard';
import { UnitsRatio } from '.';
import { IS_LOCALHOST } from '../../../../../constants';

const ResizeTooltip = ({ width, height, x, y }: any) => {
  return (
    <div className={styles.resizeTooltip} style={{ left: x, top: y }}>
      <p>W: {width}</p>
      <p>H: {height}</p>
    </div>
  );
};

type Tooltip = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type HandleProps = {
  componentUUID: ComponentUUID;
  position: string;
  unitsRatio?: UnitsRatio;
  scrollHeight: number;
  scrollWidth: number;
  width: number;
  height: number;
  offsetWidth: number;
  offsetHeight: number;
};

function ResizeHandle(props: HandleProps) {
  const dispatch = useDispatch();
  const [tooltip, setTooltip] = useState<Tooltip>();
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const workboard = useContext(WorkboardContext);
  const styleRes: string = props.position + 'ResizeHandle';

  const propsRef = useRef<{
    clientWidth: number;
    clientHeight: number;
    scrollWidth: number;
    scrollHeight: number;
    offsetWidth: number;
    offsetHeight: number;
  }>();

  useEffect(() => {
    propsRef.current = {
      clientWidth: props.width,
      clientHeight: props.height,
      scrollWidth: props.scrollWidth,
      scrollHeight: props.scrollHeight,
      offsetWidth: props.offsetWidth,
      offsetHeight: props.offsetHeight
    };
  }, [props]);

  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();

    const widthOption = components[props.componentUUID].data.option;
    const heightOption = components[props.componentUUID].data.option;

    if (widthOption !== 'Fixed' && heightOption !== 'Fixed') {
      dispatch(changeComponentProperty(props.componentUUID, 'optionSizeWidth', 'Fixed'));
      dispatch(changeComponentProperty(props.componentUUID, 'optionSizeHeight', 'Fixed'));
    }

    if (!props.unitsRatio || !IS_LOCALHOST) return;
    const heightUnit = components[props.componentUUID].data.heightUnit
      ? components[props.componentUUID].data.heightUnit
      : 'default';
    const widthUnit = components[props.componentUUID].data.widthUnit
      ? components[props.componentUUID].data.widthUnit
      : 'default';
    if (
      (props.position === 'top' || props.position === 'bottom') &&
      (heightUnit === 'none' || components[props.componentUUID].data.height === 'fit-content')
    ) {
      return;
    }
    if (
      (props.position === 'left' || props.position === 'right') &&
      (widthUnit === 'none' || components[props.componentUUID].data.width === 'fit-content')
    ) {
      return;
    }

    const initialCursorX = e.clientX;
    const initialCursorY = e.clientY;

    const initialWidth = components[props.componentUUID].data.width
      ? components[props.componentUUID].data.width
      : 0;

    const initialHeight = components[props.componentUUID].data.height
      ? components[props.componentUUID].data.height
      : 0;

    let unitsRatioWidth = props.unitsRatio[widthUnit];
    let unitsRatioHeight = props.unitsRatio[heightUnit];
    if (unitsRatioHeight == null) {
      unitsRatioHeight = props.unitsRatio['default'];
    }
    if (unitsRatioWidth == null) {
      unitsRatioWidth = props.unitsRatio['default'];
    }
    const verticalRatio = unitsRatioHeight.vertical;
    const horizontalRatio = unitsRatioWidth.horizontal;

    const handleMouseMove = (ev: MouseEvent) => {
      if (props.position === 'top') {
        const offset = Math.ceil((ev.clientY - initialCursorY) / (workboard.scale * verticalRatio));
        dispatch(changeComponentProperty(props.componentUUID, 'height', initialHeight - offset));
        setTooltip({
          x: ev.offsetX + 15,
          y: ev.offsetY,
          w: initialWidth,
          h: initialHeight - offset
        });
      } else if (props.position === 'left') {
        const offset = Math.ceil(
          (ev.clientX - initialCursorX) / (workboard.scale * horizontalRatio)
        );
        dispatch(changeComponentProperty(props.componentUUID, 'width', initialWidth - offset));
        setTooltip({
          x: ev.offsetX + 15,
          y: ev.offsetY,
          w: initialWidth - offset,
          h: initialHeight
        });
      } else if (props.position === 'right') {
        const offset = Math.ceil(
          (ev.clientX - initialCursorX) / (workboard.scale * horizontalRatio)
        );

        let finalWidth = parseInt(initialWidth) + offset;
        if (widthUnit === 'grid') {
          if (finalWidth < 1) finalWidth = 1;
          else if (finalWidth > 12) finalWidth = 12;
        }

        dispatch(changeComponentProperty(props.componentUUID, 'width', finalWidth));
        setTooltip({
          x: ev.offsetX + 15,
          y: ev.offsetY,
          w: initialWidth + offset,
          h: initialHeight
        });
      } else if (props.position === 'bottom') {
        const offset = Math.ceil((ev.clientY - initialCursorY) / (workboard.scale * verticalRatio));
        dispatch(changeComponentProperty(props.componentUUID, 'height', initialHeight + offset));
        setTooltip({
          x: ev.offsetX + 15,
          y: ev.offsetY,
          w: initialWidth,
          h: initialHeight + offset
        });
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    const handleMouseLeave = () => {
      document.body.style.cursor = 'auto';
      document.removeEventListener('mousemove', handleMouseMove);
      setTooltip(undefined);
      resize();
    };

    const handleMouseUp = () => {
      document.body.style.cursor = 'auto';
      document.removeEventListener('mousemove', handleMouseMove);
      setTooltip(undefined);
      resize();
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mouseleave', handleMouseLeave);
  }

  const resize = () => {
    const componentType = components[props.componentUUID].type;
    if (
      ['ROW', 'CONTAINER', 'COLUMN'].includes(componentType) ||
      !propsRef.current ||
      !IS_LOCALHOST
    ) {
      return;
    }
    if (propsRef.current.scrollHeight > propsRef.current.clientHeight) {
      const verticalBorder = propsRef.current.offsetHeight - propsRef.current.clientHeight;
      dispatch(
        changeComponentProperty(
          props.componentUUID,
          'height',
          propsRef.current.scrollHeight + verticalBorder
        )
      );
      dispatch(changeComponentProperty(props.componentUUID, 'heightUnit', 'px'));
    }
    if (propsRef.current.scrollWidth > propsRef.current.clientWidth) {
      const horizontalBorder = propsRef.current.offsetWidth - propsRef.current.clientWidth;
      dispatch(
        changeComponentProperty(
          props.componentUUID,
          'width',
          propsRef.current.scrollWidth + horizontalBorder
        )
      );
      dispatch(changeComponentProperty(props.componentUUID, 'widthUnit', 'px'));
    }
  };

  return (
    <>
      <div
        className={`${styles.resizeHandle} ${styles[props.position]} ${styles[styleRes]}`}
        onMouseDownCapture={handleMouseDown}
        id={`component-selection-resizehandle-${props.position}`}
      />
      {tooltip && (
        <ResizeTooltip width={tooltip.w} height={tooltip.h} x={tooltip.x} y={tooltip.y} />
      )}
    </>
  );
}

export default ResizeHandle;
