import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CssClasses } from './css_clases';
import { v4 as uuidv4 } from 'uuid';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import styles from './styles.module.css';
import { changeComponentStyle } from 'modules/designer/studio/store/actions/components';
import { changeViewStyle } from 'modules/designer/studio/store/actions/views';
import { AvailableCssClasses } from './add_css_classes';
import { DragNDropItem } from 'web_ui/drag_n_drop';
import { Editor } from 'modules/designer/types';

interface CssClasesControlProps {
  elementId: string;
  elementType: string;
}

export const CssClassesControl = (props: CssClasesControlProps) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const componentClassNames = useSelector((state: InterfaceStudioState) => {
    return state.components ? state.components[props.elementId]?.styles?.classes : [];
  });
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const viewClassNames = useSelector((state: InterfaceStudioState) => {
    if (editor === Editor.CUSTOM_COMPONENT) {
      return state.components ? state.components[props.elementId]?.styles?.classes : [];
    } else {
      return state.views ? state.views[props.elementId]?.styles?.classes : [];
    }
  });
  const globalSelectors = useSelector((state: InterfaceStudioState) => {
    return state.globalCssClasses ?? [];
  });

  const availableCssNames = Array.from(
    new Set(globalSelectors.map((css) => css.cssName.substring(1)))
  );

  const elementClassNames = ((componentClassNames || viewClassNames) ?? []).filter(
    (name: string) => {
      return availableCssNames.includes(name);
    }
  );

  return (
    <>
      <Button
        variant="primary"
        onClick={() => setShowModal(true)}
        className="w-100 mb-3"
        style={{ height: 'fit-content' }}
      >
        {t('designer.cssClass.CssClasses')}
      </Button>
      <CssClassesControlModal
        elementId={props.elementId}
        elementType={props.elementType}
        show={showModal}
        onClose={() => setShowModal(false)}
        globalClasses={availableCssNames}
        elementClassNames={elementClassNames}
      />
    </>
  );
};

interface ControlProps {
  show: boolean;
  onClose: () => void;
  elementId: string;
  elementType: string;
  elementClassNames: string[];
  globalClasses: string[];
}

export type SortableCssClass = {
  className: string;
  id: string;
};

const CssClassesControlModal = (props: ControlProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cssClasses, setCssClasses] = useState<SortableCssClass[]>([]);

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setCssClasses(props.elementClassNames.map((e) => ({ className: e, id: uuidv4() })));
  }, [props.elementClassNames, props.show]);

  const saveClasses = () => {
    const classes: string[] = cssClasses.map((e) => {
      if (e.className[0] === '.') {
        return e.className.slice(1);
      }
      return e.className;
    });
    if (props.elementType === 'component') {
      dispatch(changeComponentStyle(props.elementId, 'classes', classes));
    } else if (props.elementType === 'view') {
      dispatch(changeViewStyle(props.elementId, 'classes', classes));
    }
    props.onClose();
  };

  const updateClasses = (
    newClasses: DragNDropItem[],
    movedId: string,
    oldIndex: number,
    newIndex: number
  ) => {
    setCssClasses(newClasses as SortableCssClass[]);
  };

  const addClass = (newClass: string) => {
    const newClasses = [...cssClasses, { className: newClass, id: uuidv4() }];
    setCssClasses(newClasses);
  };

  const removeClass = (id: string) => {
    const newClasses = cssClasses.filter((e) => e.id !== id);
    setCssClasses(newClasses);
  };

  const availableClassNames = props.globalClasses.filter((name) =>
    cssClasses.every((c) => c.className !== name)
  );

  return (
    <Modal centered show={props.show} onHide={props.onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('designer.cssClass.CssClassesControl')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${styles.CssClassesWrapper}`}>
          <AvailableCssClasses availableClasses={availableClassNames} addClass={addClass} />
        </div>
        <div className={`${styles.CssClassesWrapper}`}>
          <CssClasses
            classes={cssClasses}
            updateClasses={updateClasses}
            removeClass={removeClass}
            globalClasses={props.globalClasses}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button id="saveCssClasses" onClick={saveClasses}>
          {t('Save')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
