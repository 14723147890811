import {
  EnumUUID,
  Index,
  IndexID,
  Relationship,
  externalColumn,
  fkColumnsMapper,
  relationshipID,
  columnID
} from 'modules/modeler/types';
import { Action } from 'redux';
import { FrameUUID } from 'web_ui/workboard/frame';
import { SetSelectedFrameAction } from './studio';
import {
  ChangeColumnDescriptionAction,
  ChangeColumnPropertyAction,
  ChangeColumnNameAction,
  ChangeColumnTypeAction,
  DeleteColumnAction,
  MoveColumnAction
} from './columns';
import { changeTableNameAction, changeTableFolderAction } from './frames';
import { MoveEnumColumnAction } from './enum_column';
import { DatabaseStudioState } from '..';

// Table actions
export const ADD_TABLE = 'ADD_TABLE';
export const DELETE_TABLE = 'DELETE_TABLE';
export const DUPLICATE_TABLE = 'DUPLICATE_TABLE';

// Column actions
export const ADD_COLUMN = 'ADD_COLUMN';
export const ADD_KEY_COLUMN = 'ADD_KEY_COLUMN';
export const ADD_EXTERNAL_RELATIONSHIP_COLUMN = 'ADD_EXTERNAL_RELATIONSHIP_COLUMN';

// Relationships actions
export const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP';
export const DELETE_RELATIONSHIP = 'DELETE_RELATIONSHIP';

// Indexes actions
export const ADD_INDEX = 'ADD_INDEX';
export const DELETE_INDEX = 'DELETE_INDEX';

export const SET_INITIAL_MODELER_STATE = 'SET_INITIAL_MODELER_STATE';

export type RootActions =
  | SetSelectedFrameAction
  | deleteRelationshipAction
  | addRelationshipAction
  | addColumnAction
  | addKeyColumnAction
  | DeleteColumnAction
  | deleteTableAction
  | ChangeColumnNameAction
  | ChangeColumnDescriptionAction
  | ChangeColumnPropertyAction
  | ChangeColumnTypeAction
  | changeTableNameAction
  | changeTableFolderAction
  | addExternalRelationshipColumnAction
  | MoveColumnAction
  | MoveEnumColumnAction
  | SetInitialModelerStateAction;

export type tableInfo = {
  uuid: string;
  type: string;
  name: string;
  description: string;
  posX: number;
  posY: number;
  idPkColumn: string;
  idPkIndex: string;
  idPkIndexColumn: string;
  modificationTime: Date;
  modifiedByUser: string;
  creationTime: Date;
  createdByUser: string;
};

export type columnInfo = { idPkColumn: string };

export type indexInfo = { idPkIndex: string };

export type indexColumnInfo = { idPkIndexColumn: string };

export interface SetInitialModelerStateAction extends Action {
  type: 'SET_INITIAL_MODELER_STATE';
  state: DatabaseStudioState;
}

export interface addTableAction extends Action {
  type: 'ADD_TABLE';
  payload: {
    uuid: string;
    type: string;
    name: string;
    description: string;
    posX: number;
    posY: number;
    idPkColumn: string;
    idPkIndex: string;
    idPkIndexColumn: string;
    modificationTime: Date;
    modifiedByUser: string;
  };
}

export interface deleteTableAction extends Action {
  type: 'DELETE_TABLE';
  payload: {
    uuid: FrameUUID;
    limit: number;
  };
}

export type dupIndexColumn = {
  indexColumnId: string;
  columnId: string;
};

export type dupIndexColumnMap = { [key: string]: dupIndexColumn };

export type dupIndex = {
  indexId: string;
  indexColumns: dupIndexColumnMap;
};

export type dupIndexMap = { [key: IndexID]: dupIndex };

export type dupColumnMap = { [key: columnID]: columnID };

export interface duplicateTableAction extends Action {
  type: 'DUPLICATE_TABLE';
  payload: {
    uuid: string;
    type: string;
    name: string;
    description: string;
    posX: number;
    posY: number;
    indexes: dupIndexMap;
    columns: dupColumnMap;
    modificationTime: Date;
    modifiedByUser: string;
    creationTime: Date;
    createdByUser: string;
  };
}

export interface addRelationshipAction extends Action {
  type: 'ADD_RELATIONSHIP';
  payload: Relationship;
}

export interface deleteRelationshipAction extends Action {
  type: 'DELETE_RELATIONSHIP';
  payload: {
    id: relationshipID;
  };
}

export interface addColumnAction extends Action {
  type: 'ADD_COLUMN';
  payload: {
    UUID: string;
    tableUUID: string;
    name: string;
    type: string;
    columnOrder: number;
    nullable: boolean;
    defaultData: string;
    enumUUID?: EnumUUID;
    description: string;
    properties?: Record<string, string>;
  };
}

export interface addKeyColumnAction extends Action {
  type: 'ADD_KEY_COLUMN';
  payload: {
    column: {
      UUID: string;
      tableUUID: string;
      name: string;
      columnOrder: number;
      type: string;
      nullable: boolean;
      defaultData: string;
      enumUUID?: EnumUUID;
      description: string;
      fkColumns?: fkColumnsMapper;
    };
    pkIndexUUID: string;
    pkIndexColumnUUID: string;
  };
}

export interface addIndexAction extends Action {
  type: 'ADD_INDEX';
  payload: {
    index: Index;
    tableID: FrameUUID;
  };
}

export interface deleteIndexAction extends Action {
  type: 'DELETE_INDEX';
  payload: {
    id: IndexID;
    tableID: FrameUUID;
  };
}

export interface addIndexAction extends Action {
  type: 'ADD_INDEX';
  payload: {
    index: Index;
    tableID: FrameUUID;
  };
}

export interface addExternalRelationshipColumnAction extends Action {
  type: 'ADD_EXTERNAL_RELATIONSHIP_COLUMN';
  payload: {
    tableID: FrameUUID;
    columns: externalColumn[];
  };
}

export const setInitialModelerState = (
  state: DatabaseStudioState
): SetInitialModelerStateAction => ({
  type: SET_INITIAL_MODELER_STATE,
  state
});

export const addTable = (table: tableInfo): addTableAction => ({
  type: ADD_TABLE,
  payload: table
});

export const deleteTable = (uuid: FrameUUID, limit?: number) => ({
  type: DELETE_TABLE,
  payload: { uuid, limit }
});

export const duplicateTable = (payload: {
  uuid: string;
  type: string;
  name: string;
  description: string;
  posX: number;
  posY: number;
  indexes: dupIndexMap;
  columns: dupColumnMap;
  modificationTime: Date;
  modifiedByUser: string;
  creationTime: Date;
  createdByUser: string;
}): duplicateTableAction => ({
  type: DUPLICATE_TABLE,
  payload: payload
});

export const addRelationship = (relationship: Relationship): addRelationshipAction => ({
  type: ADD_RELATIONSHIP,
  payload: relationship
});

export const deleteRelationship = (id: relationshipID): deleteRelationshipAction => ({
  type: DELETE_RELATIONSHIP,
  payload: { id: id }
});

export const addColumn = (
  UUID: string,
  tableUUID: string,
  name: string,
  columnOrder: number,
  type: string,
  nullable: boolean,
  defaultData: string,
  description: string,
  enumUUID?: EnumUUID,
  properties?: Record<string, string>
): addColumnAction => ({
  type: ADD_COLUMN,
  payload: {
    UUID: UUID,
    tableUUID: tableUUID,
    name: name,
    columnOrder: columnOrder,
    type: type,
    nullable: nullable,
    defaultData: defaultData,
    description: description,
    enumUUID: enumUUID,
    properties
  }
});

export const addKeyColumn = (
  column: {
    UUID: string;
    tableUUID: string;
    columnOrder: number;
    name: string;
    type: string;
    nullable: boolean;
    defaultData: string;
    description: string;
    fkColumns?: fkColumnsMapper;
    enumUUID?: EnumUUID;
  },
  pkIndexUUID: string,
  pkIndexColumnUUID: string
): addKeyColumnAction => ({
  type: ADD_KEY_COLUMN,
  payload: {
    column: {
      UUID: column.UUID,
      tableUUID: column.tableUUID,
      name: column.name,
      type: column.type,
      columnOrder: column.columnOrder,
      nullable: column.nullable,
      defaultData: column.defaultData,
      description: column.description,
      enumUUID: column.enumUUID,
      fkColumns: column.fkColumns ? column.fkColumns : undefined
    },
    pkIndexUUID: pkIndexUUID,
    pkIndexColumnUUID: pkIndexColumnUUID
  }
});

export const addIndex = (index: Index, tableID: FrameUUID): addIndexAction => ({
  type: ADD_INDEX,
  payload: {
    index: index,
    tableID: tableID
  }
});

export const deleteIndex = (indexID: IndexID, tableID: FrameUUID): deleteIndexAction => ({
  type: DELETE_INDEX,
  payload: {
    id: indexID,
    tableID: tableID
  }
});

export const addExternalRelationshipColumn = (
  tableID: FrameUUID,
  columns: externalColumn[]
): addExternalRelationshipColumnAction => ({
  type: ADD_EXTERNAL_RELATIONSHIP_COLUMN,
  payload: {
    tableID: tableID,
    columns: columns
  }
});
