import React from 'react';
import styles from './styles.module.css';
import FunctionParameter from './parameter';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../../store/types/function_editor_state';
import { ReturnType } from '../../../../../store/types/functions';

export type ParametersListProps = {
  types: ReturnType[];
};

function ParametersList({ types }: ParametersListProps) {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const paramsIDs = useSelector(
    (state: FunctionEditorState) => state.functions[function_id ?? '']?.parameters
  );
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);

  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return (
    <div className={styles.ParametersList}>
      (
      {paramsIDs &&
        paramsIDs.map((p, index) => (
          <React.Fragment key={index}>
            <FunctionParameter uuid={p} types={types} />
            {index < paramsIDs.length - 1 && <span>, </span>}
          </React.Fragment>
        ))}
      )
    </div>
  );
}

export default ParametersList;
