import { Action } from 'redux';

export const API_URL = process.env.REACT_APP_API_URL;

export class Repo {
  async getInitialState(module_id: string, function_id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/states/modules/${module_id}/functions/${function_id}`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }

  async sendAction(module_id: string, function_id: string, action: Action) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(action)
    };

    const endpoint = `${API_URL}/states/modules/${module_id}/functions/${function_id}/action`;

    return await fetch(endpoint, options).then((response) => response);
  }
}
