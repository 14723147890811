import React, { ReactNode, useContext } from 'react';
import styles from './styles.module.css';
import {
  ActiveSubscriptionPlan,
  getMonthPriceId,
  getPlanMonthPrice,
  getPlanYearPrice,
  LOOKUP_KEYS,
  SUBSCRIPTION_PLANS_FEATURES,
  SubscriptionPlanFeature
} from 'modules/payments/types';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import HelpIcon from '../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';
import { Button } from 'react-bootstrap';
import SessionContext from 'modules/auth/store';

export type PricingTableProps = {
  context: 'SETTINGS_PAGE' | 'WELCOME_PAGE';
  activeSubscription?: ActiveSubscriptionPlan;
  subscriptionPlans: any[];
};

export function PricingTable(props: PricingTableProps) {
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const session = useContext(SessionContext);

  const stripeLookupKey =
    props.context === 'SETTINGS_PAGE' ? props.activeSubscription?.stripeLookupKey : 'basic_plan';

  const getFeatureValue = (featureList: SubscriptionPlanFeature[], idx: number): ReactNode => {
    const feature = featureList[idx];
    if (feature.tooltip) {
      return <HelpIcon />;
    }
    if (feature.available) {
      if (feature.limit) {
        return <>{feature.limit}</>;
      } else {
        return <i className={`fa-solid fa-check ${styles.checkmarkIcon}`}></i>;
      }
    } else {
      return <i className={`fa-solid fa-times ${styles.timesIcon}`} style={{}}></i>;
    }
  };

  const getPopoverText = (featureList: SubscriptionPlanFeature[], idx: number): string => {
    const feature = featureList[idx];
    return feature.tooltip ?? '';
  };

  const shouldHaveOnlyLeftBorder = (plan: string): boolean => {
    if (shouldHaveBothBorders(plan)) {
      return false;
    }
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => stripeLookupKey?.includes(p));
    if (userPlanIdx + 1 === planIdx) {
      return false;
    }
    return true;
  };

  const shouldHaveOnlyRightBorder = (plan: string): boolean => {
    if (shouldHaveBothBorders(plan)) {
      return false;
    }
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => stripeLookupKey?.includes(p));
    if (userPlanIdx - 1 === planIdx) {
      return false;
    }
    return true;
  };

  const shouldHaveBothBorders = (plan: string): boolean => {
    if (stripeLookupKey?.includes(plan) || !props.activeSubscription) {
      return true;
    }
    const plans = Object.keys(LOOKUP_KEYS);
    const planIdx = plans.findIndex((p) => p === plan);
    const userPlanIdx = plans.findIndex((p) => stripeLookupKey?.includes(p));
    if (userPlanIdx - 1 !== planIdx && userPlanIdx + 1 !== planIdx) {
      return true;
    }
    return false;
  };

  const manageAndSelectButtons = (plan: string): ReactNode => {
    if (props.context === 'WELCOME_PAGE') {
      return null;
    }
    if (!props.activeSubscription) {
      return null;
    }
    if (plan === 'enterprise_plan' && !stripeLookupKey?.includes(plan)) {
      return (
        <Button style={{ width: '100%' }} variant="primary" href="mailto:sales@exocoding.com">
          {t('billing.ContactSales')}
        </Button>
      );
    }
    return !stripeLookupKey?.includes(plan) ? (
      <>
        <form action={`${API_URL}/stripe/create-portal-session`} method="POST">
          <input type="hidden" name="userId" value={session.user?.id} />
          <input type="hidden" name="type" value={'payments'} />
          <input
            type="hidden"
            name="planType"
            value={getMonthPriceId(plan, props.subscriptionPlans)}
          />
          <Button
            id={`${plan}SelectButton`}
            type="submit"
            style={{ width: '100%' }}
            // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
            disabled={!!session.user?.deleteByDate}
          >
            {t('Select')}
          </Button>
        </form>
      </>
    ) : (
      <>
        <form action={`${API_URL}/stripe/create-portal-session`} method="POST">
          <input type="hidden" name="userId" value={session.user?.id} />
          <input type="hidden" name="type" value={'payments'} />
          <Button
            id={`${plan}ManageButton`}
            type="submit"
            style={{ width: '100%' }}
            // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
            disabled={!!session.user?.deleteByDate}
          >
            {t('Manage')}
          </Button>
        </form>
      </>
    );
  };

  const pricingCellContent = (plan: string) => {
    return (
      <>
        {plan === 'free_plan' && (
          <>
            <p>
              <span className={`${styles.Pricing}`}>
                <span className={styles.Price}>Free</span>
              </span>
            </p>
          </>
        )}
        {(plan === 'basic_plan' || plan === 'plus_plan' || plan === 'professional_plan') && (
          <>
            <p>
              <span className={`${styles.Pricing}`}>
                <span className={styles.Price}>
                  {getPlanMonthPrice(plan, props.subscriptionPlans)}
                </span>
                <span className={styles.PriceDescription1}>/month</span>
              </span>
            </p>
            <p>
              <span className={`${styles.Pricing}`}>
                <span className={styles.Price}>
                  {getPlanYearPrice(plan, props.subscriptionPlans)}
                </span>
                <span className={styles.PriceDescription1}>/month (Billed annually)</span>
              </span>
            </p>
          </>
        )}
        {plan === 'enterprise_plan' && (
          <div className={`${styles.cell_Emphasis}`}>
            <Button style={{ width: '100%' }} variant="primary" href="mailto:sales@exocoding.com">
              {t('billing.ContactSales')}
            </Button>
          </div>
        )}
      </>
    );
  };

  const headerCellPlan = (plan: string): ReactNode => {
    return (
      <div
        className={`${styles.header_CellContent} ${
          stripeLookupKey?.includes(plan) && styles.cell_Emphasis
        }`}
      >
        <div className={`${styles.header_CellTitle} ${styles[plan + '_title']}`}>
          {LOOKUP_KEYS[plan]}
          {/*manageAndSelectButtons(plan)*/}
        </div>
      </div>
    );
  };

  return (
    <>
      <table className={`${styles.table}`}>
        {/* Invisible row at the top. */}
        <tr>
          <td className={`${styles.header_Cell}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            const rowsSpan = stripeLookupKey?.includes(plan) ? 2 : 1;
            return (
              <td
                key={plan}
                rowSpan={rowsSpan}
                className={`${styles.header_Cell} ${
                  stripeLookupKey?.includes(plan) && styles[plan + '_column_header']
                }`}
              >
                {stripeLookupKey?.includes(plan) && headerCellPlan(plan)}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className={`${styles.header_Cell}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            if (stripeLookupKey?.includes(plan)) return null;
            return (
              <td
                className={`${styles.header_Cell} ${
                  shouldHaveOnlyLeftBorder(plan) && styles[plan + '_column_header_left']
                } ${shouldHaveOnlyRightBorder(plan) && styles[plan + '_column_header_right']} ${
                  shouldHaveBothBorders(plan) && styles[plan + '_column_header']
                }`}
                key={plan}
              >
                {headerCellPlan(plan)}
              </td>
            );
          })}
        </tr>
        {SUBSCRIPTION_PLANS_FEATURES.free_plan.map((_, idx) => {
          const featureName = SUBSCRIPTION_PLANS_FEATURES.free_plan[idx].name;
          const description = SUBSCRIPTION_PLANS_FEATURES.free_plan[idx].description;
          return (
            <tr key={idx}>
              <td className={`${styles.cell_Feature}`}>
                {idx === 5 && <div className={styles['soon']}>{t('Soon') ?? 'Soon'}</div>}
                {featureName}

                {description && (
                  <HelpPopover
                    placement="right"
                    helpBoxProps={{
                      title: description
                    }}
                  >
                    <HelpIcon />
                  </HelpPopover>
                )}
              </td>
              {Object.keys(LOOKUP_KEYS).map((plan) => {
                const popOverText = getPopoverText(SUBSCRIPTION_PLANS_FEATURES[plan], idx);
                return (
                  <td
                    className={`${styles.cell_FeatureValue} ${
                      !stripeLookupKey?.includes(plan) && styles.nonSubscribedColumn
                    } ${shouldHaveOnlyLeftBorder(plan) && styles[plan + '_column_left']} ${
                      shouldHaveOnlyRightBorder(plan) && styles[plan + '_column_right']
                    } ${shouldHaveBothBorders(plan) && styles[plan + '_column']}`}
                    key={plan}
                  >
                    <HelpPopover
                      placement="right"
                      helpBoxProps={{
                        title: popOverText
                      }}
                      disabled={!popOverText}
                    >
                      <div
                        className={`${styles.cell_FeatureValueInner} ${
                          stripeLookupKey?.includes(plan) && styles.cell_Emphasis
                        }`}
                      >
                        {getFeatureValue(SUBSCRIPTION_PLANS_FEATURES[plan], idx)}
                      </div>
                    </HelpPopover>
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <td className={`${styles.cell_Feature}`}>{t('Price')}</td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            const rowsSpan = stripeLookupKey?.includes(plan) ? 2 : 1;
            return (
              <td
                className={`${styles.cell_FooterValue} ${
                  shouldHaveOnlyLeftBorder(plan) && styles[plan + '_column_footer_left']
                } ${shouldHaveOnlyRightBorder(plan) && styles[plan + '_column_footer_right']} ${
                  shouldHaveBothBorders(plan) && styles[plan + '_column_footer']
                }`}
                key={plan}
                rowSpan={rowsSpan}
              >
                {pricingCellContent(plan)}
              </td>
            );
          })}
        </tr>
        {/* Invisible row at the bottom. */}
        <tr>
          <td className={`${styles.cell_Feature}`}></td>
          {Object.keys(LOOKUP_KEYS).map((plan) => {
            if (stripeLookupKey?.includes(plan)) return null;
            return <td key={plan} className={`${styles.cell_Feature}`}></td>;
          })}
        </tr>
      </table>
    </>
  );
}
