import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { PricingTable } from 'modules/payments/components/subscription_plan/pricing_table';
import useTitle from 'hooks/useTitle';
import {
  ActiveSubscriptionPlan,
  getActiveSubscription,
  getSubscriptions
} from 'modules/payments/types';
import SessionContext from 'modules/auth/store';
import { SubscriptionPlan } from 'modules/payments/components/subscription_plan';

function Billing() {
  const { t } = useTranslation();
  useTitle(t('tab.settingsAcc.biling'));
  const session = useContext(SessionContext);
  const [subscriptionPlans, setSubscriptionPlans] = useState<any[]>([]);
  const [userSubscription, setUserSubscription] = useState<ActiveSubscriptionPlan>();

  const fetchStripeSubscriptions = useCallback(async () => {
    if (!session.user || !session.user.id) return;

    getSubscriptions().then((plans) => {
      setSubscriptionPlans(plans);
    });
    getActiveSubscription(session.user.id).then((userSubscription) => {
      setUserSubscription(userSubscription);
    });
  }, [session.user]);

  useEffect(() => {
    fetchStripeSubscriptions();
  }, [fetchStripeSubscriptions]);

  return (
    <>
      <Col className={styles.Section}>
        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('billing.CurrentPlan')}
          </span>
        </div>

        <div className={`${styles.SubscriptionPlanWrapper}`}>
          <SubscriptionPlan activeSubscription={userSubscription} />
        </div>

        <div className={`border-bottom ${styles.SectionTitleDiv}`}>
          <span className={`text-body-emphasis ${styles.SectionTitle}`}>
            {t('billing.AvailableProducts')}
          </span>
        </div>

        <div className={`${styles.PricingCardsWrapper} row`}>
          <PricingTable
            context="SETTINGS_PAGE"
            subscriptionPlans={subscriptionPlans}
            activeSubscription={userSubscription}
          />
        </div>
      </Col>
    </>
  );
}

export default Billing;
