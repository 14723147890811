import React from 'react';

export type ListInputProps = {
  parameterId: string;
  endpointId: string;
  className?: string;
  value: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function ParameterListInput(props: ListInputProps) {
  const { className, value, handleChange } = props;

  return (
    <input
      type="checkbox"
      className={`form-check-input ${className}`}
      onChange={handleChange}
      checked={value}
      onMouseDown={(e) => e.stopPropagation()}
      required
    />
  );
}
