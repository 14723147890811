import React from 'react';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { ParameterInputType } from './inputs/input_type';
import {
  deleteEndpointParameter,
  updateEndpointParameterBooleanProperties,
  updateEndpointParameterInputName,
  updateEndpointParameterName
} from '../../reducers/endpointParameters';
import { ParameterTextInput } from './inputs/name_input';
import { ParameterListInput } from './inputs/list_input';
import { ParameterDataTypeInput } from './inputs/data_type_input';
import styles from './styles.module.css';
import { ParameterEndpoint } from '../../../../../modules/logic_builder/types';
import { RenderItemProps } from '../../../../../web_ui/drag_n_drop';
import { validateParameterNameInput } from '../../actions';
import { DataTypeInputItems } from './inputs/data_type_input_items';

export function ParameterItem(props: RenderItemProps) {
  const currentParameter = props.item as ParameterEndpoint;
  const dispatch = useEndpointEditorDispatch();
  const endpointId = useEndpointEditorSelector((state) => state.editorStatus.endpointId);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>, valid: boolean) => {
    if (!currentParameter?.uuid) return;
    dispatch(
      updateEndpointParameterName({
        id: endpointId,
        endpointParameterId: currentParameter.uuid,
        newName: e.target.value,
        isValid: valid
      })
    );
  };

  const handleChangeInputName = (e: React.ChangeEvent<HTMLInputElement>, valid: boolean) => {
    if (!currentParameter?.uuid) return;
    dispatch(
      updateEndpointParameterInputName({
        id: endpointId,
        endpointParameterId: currentParameter.uuid,
        newInputName: e.target.value,
        isValid: valid
      })
    );
  };

  const handleChangeBooleanProperties = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!currentParameter?.uuid) return;
    dispatch(
      updateEndpointParameterBooleanProperties({
        id: endpointId,
        endpointParameterId: currentParameter.uuid,
        isList: e.target.checked
      })
    );
  };

  const handleDeleteParameter = () => {
    if (!currentParameter?.uuid) return;
    dispatch(
      deleteEndpointParameter({ id: endpointId, endpointParameterId: currentParameter.uuid })
    );
  };

  if (!currentParameter?.uuid) return <></>;

  const disableInputName =
    currentParameter.inputType === 'BODY' || currentParameter.inputType === 'NONE';

  const getDataTypeItemId = (): string => {
    if (currentParameter.type === 'OBJECT') {
      return currentParameter.objectUuid ?? '';
    } else if (currentParameter.type === 'ENUM') {
      return currentParameter.enumId ?? '';
    }
    return '';
  };

  return (
    <div className={`${styles.ParameterItemWrapper} mb-2`}>
      <div className={`${styles.inputWidthSmall} d-flex align-items-center justify-content-center`}>
        <i className="fa-solid fa-grip-vertical"></i>
      </div>
      <div className={`d-flex ${styles.inputWidthLargeLarge}`}>
        <ParameterDataTypeInput
          parameterId={currentParameter.uuid}
          endpointId={props.id}
          selected={currentParameter.type}
          inputType={currentParameter.inputType}
          className={`${styles.halfWidthInput} ${styles.leftRadius}`}
        />
        <DataTypeInputItems
          parameterId={currentParameter.uuid}
          selectedDataType={currentParameter.type}
          value={getDataTypeItemId()}
          className={`${styles.rightRadius} ${styles.halfWidthInput}`}
        />
      </div>
      <ParameterTextInput
        parameterId={currentParameter.uuid}
        endpointId={props.id}
        value={currentParameter.name}
        handleChange={handleChangeName}
        className={`${styles.inputWidthLarge}`}
        validation={(value: string) => validateParameterNameInput(value)}
      />
      <div className={`${styles.inputWidthSmall} d-flex align-items-center justify-content-center`}>
        <ParameterListInput
          parameterId={currentParameter.uuid}
          endpointId={props.id}
          value={currentParameter.list}
          handleChange={handleChangeBooleanProperties}
        />
      </div>
      <ParameterInputType
        parameterId={currentParameter.uuid}
        endpointId={props.id}
        selected={currentParameter.inputType}
        className={`${styles.inputWidthMedium}`}
      />
      <ParameterTextInput
        parameterId={currentParameter.uuid}
        endpointId={props.id}
        value={currentParameter.inputName}
        handleChange={handleChangeInputName}
        className={`${styles.inputWidthLarge} ${styles.rightRadius}`}
        validation={(value) => validateParameterNameInput(value)}
        disabled={disableInputName}
      />
      <div
        className={`${styles.inputWidthSmall} d-flex align-items-center justify-content-center`}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <button
          onClick={handleDeleteParameter}
          className={`btn btn-outline-danger ${styles.deleteButton}`}
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      </div>
    </div>
  );
}
