import React, { useRef } from 'react';
import ActionsSpacerButton from './actions_spacer_button';
import { useDispatch, useSelector } from 'react-redux';
import { addAction } from '../../../../store/events/actions/add_action';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { v4 as uuidv4 } from 'uuid';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';

type ActionsSpacerProps = {
  index: number;
  handleDrop: (e: React.DragEvent, index: number) => void;
};

function ActionsSpacer({ index, handleDrop }: ActionsSpacerProps) {
  const spacerRef = useRef<HTMLDivElement>(null);
  const functionId = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const { mode } = useSelector((state: FunctionEditorState | EndpointEditorState) => state.editor);
  const dispatch = useDispatch();

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '100px';
    spacerRef.current.style.border = '1px dashed rgba(13,110,253)';
  };

  const handleDragLeave = () => {
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '25px';
    spacerRef.current.style.border = 'none';
  };

  const handleOnDrop = (e: React.DragEvent) => {
    if (!spacerRef?.current) return;
    spacerRef.current.style.height = '25px';
    spacerRef.current.style.border = 'none';

    handleDrop(e, index);
  };

  const handleAddEmpty = () => {
    dispatch(addAction(functionId, uuidv4(), index, 'EMPTY', {}, mode));
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleOnDrop}
      style={{ transition: 'height 1s' }}
      className={`d-flex flex-column justify-content-center align-items-center gap-2 border-primary pt-2 pb-2`}
    >
      <div
        id="addActionDrag&Drop"
        ref={spacerRef}
        className={'d-flex justify-content-center align-items-center rounded-2'}
        style={{
          height: '25px',
          width: '100%',
          transition: 'height 0.1s linear'
        }}
      >
        <ActionsSpacerButton handleClick={handleAddEmpty} />
      </div>
    </div>
  );
}

export default ActionsSpacer;
