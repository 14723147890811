import React, { useContext, useEffect } from 'react';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { useTranslation } from 'react-i18next';
import { updateEndpointRoles } from '../../reducers/endpointInfo';
import styles from './styles.module.css';
import { EndpointEditorContext } from '../../index';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export type SecurityRolesProps = {
  className?: string;
};

export const NEW_ACCESS_LEVELS = {
  PUBLIC: 'PUBLIC',
  ALL_ROLES: 'ALL_ROLES',
  CUSTOM: 'CUSTOM'
};
export type NewAccessLevel = keyof typeof NEW_ACCESS_LEVELS;

export function SecurityRoles(props: SecurityRolesProps) {
  const { className } = props;
  const { t } = useTranslation();
  const dispatch = useEndpointEditorDispatch();
  const { roles, uuid } = useEndpointEditorSelector((state) => state.endpoint);
  const [accessLevel, setAccessLevel] = React.useState<NewAccessLevel>();
  const { moduleRoles } = useContext(EndpointEditorContext);

  useEffect(() => {
    if (accessLevel) return;

    if (roles.length === 0) {
      setAccessLevel('PUBLIC');
      return;
    }

    const allRolesIncluded = roles.every((roleId) =>
      moduleRoles.some((role) => role.id === roleId)
    );
    if (allRolesIncluded) {
      setAccessLevel('ALL_ROLES');
      return;
    }

    setAccessLevel('CUSTOM');
  }, [accessLevel, moduleRoles, roles]);

  const handleSelectAccessLevel = (newLvl: NewAccessLevel) => {
    setAccessLevel(newLvl);
    let newRoles: number[] = [];
    if (newLvl === 'PUBLIC') {
      newRoles = [];
    } else if (newLvl === 'ALL_ROLES') {
      moduleRoles.forEach((role) => newRoles.push(role.id ?? 0));
    } else {
      newRoles = roles;
    }

    dispatch(updateEndpointRoles({ id: uuid, newRoles }));
  };

  const handleSelectRole = (roleId: number, checked: boolean) => {
    if (!accessLevel) return;

    let newRoles: number[];
    if (checked) {
      newRoles = roles.includes(roleId) ? roles : [...roles, roleId];
    } else {
      newRoles = roles.filter((role) => role !== roleId);
    }

    dispatch(updateEndpointRoles({ id: uuid, newRoles }));
  };

  if (!accessLevel) return <></>;

  return (
    <div
      className={`mb-3 ${className}`}
      id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-security']}
    >
      <div className="btn-group mb-1 pb-0" role="group" aria-label="Security roles">
        {Object.values(NEW_ACCESS_LEVELS).map((accLvl) => {
          return (
            <button
              key={accLvl}
              type="button"
              className={`btn btn-primary ${accLvl === accessLevel ? 'active' : ''}`}
              onClick={() => handleSelectAccessLevel(accLvl as NewAccessLevel)}
            >
              {t(accLvl)}
            </button>
          );
        })}
      </div>
      <div className={`${styles.RolesWrapper} border rounded p-2`}>
        {moduleRoles.map((role) => {
          return (
            <div key={role.id} className="form-check">
              <input
                id={`endpoint-role-${role.id}`}
                name={`endpoint-role-${role.id}`}
                type="checkbox"
                value={role.id}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleSelectRole(role.id ?? 0, e.target.checked)
                }
                disabled={accessLevel === 'PUBLIC' || accessLevel === 'ALL_ROLES'}
                checked={roles.includes(role.id ?? 0)}
                className={`me-2`}
              />
              <label htmlFor={`endpoint-role-${role.id}`} className="form-check-label">
                {role.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
