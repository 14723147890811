import { ILayoutDefault, Layout } from '../types';
import { LayoutSchema } from './schemas';

export const API_URL = process.env.REACT_APP_API_URL;

export class LayoutRepo {
  private buildSchema(layoutName: string, folderId: string) {
    const layoutSchema: LayoutSchema = {
      name: layoutName,
      description: '',
      folder_id: folderId
    };
    return layoutSchema;
  }

  /**
   * Create a new layout for a specific module.
   */
  async createLayout(moduleId: string, layoutName: string, template: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const body: LayoutSchema = this.buildSchema(layoutName, folderId);
    body.template = template;

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(body)
    };

    const url = `${API_URL}/modules/${moduleId}/layouts`;

    return await fetch(url, options).then((response) => {
      if (response.status === 201) return response.json() as Promise<Layout>;
      throw new Error('Something went wrong while trying to create a new layout.');
    });
  }

  /**
   * Fetch all layouts for a specific module.
   */
  async getLayoutsByModule(moduleId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/modules/${moduleId}/layouts`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Layout[]>;
      throw new Error('Something went wrong while trying to fetch module layouts.');
    });
  }

  async getDefaultLayout(appId: string) {
    const headers = new Headers({ 'Content-Type': 'application/json' });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/layouts/default`;

    const response = await fetch(url, options);

    if (response.ok) {
      return response.json() as Promise<ILayoutDefault>;
    } else {
      throw new Error('Something went wrong while trying to fetch module layouts.');
    }
  }

  /**
   * Fetch all layouts for a specific app.
   */
  async getLayoutsByApp(appId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/apps/${appId}/layouts`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Layout[]>;
      throw new Error('Something went wrong while trying to fetch app layouts.');
    });
  }

  /**
   * Fetch a single layout.
   */
  async getLayout(layoutId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/layouts/${layoutId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Layout>;
      throw new Error('Something went wrong while trying to fetch layout.');
    });
  }

  async updateLayout(layoutId: string, updatedLayout: Layout) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(updatedLayout)
    };

    const url = `${API_URL}/layouts/${layoutId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Layout>;
      throw new Error('Something went wrong while trying to update layout.');
    });
  }

  async moveLayout(layoutId: string, moduleId: string, folderId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const moveData = {
      folder_id: folderId
    };

    const options: RequestInit = {
      method: 'PUT',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(moveData)
    };

    const url = `${API_URL}/modules/${moduleId}/layouts/${layoutId}/move`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Layout>;
      throw new Error('Something went wrong while trying to move layout.');
    });
  }

  async deleteLayout(layoutId: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'DELETE',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const url = `${API_URL}/layouts/${layoutId}`;

    return await fetch(url, options).then(async (response: Response) => {
      if (response.ok) return response;
      const error = await response.json();
      if (error) throw new Error(error.message);
      else throw new Error('Something went wrong while trying to delete layout.');
    });
  }
}
