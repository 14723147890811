import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Page, Modal, Layout, Folder, ILayoutDefault, FolderType } from 'modules/designer/types';
import { useDispatch, useSelector } from 'react-redux';
import CreatePageDialog from './create_page_modal';
import { Form, ListGroup } from 'react-bootstrap';
import CreateModalDialog from './create_modal_modal';
import CreateLayoutDialog from './create_layout_modal';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './styles.module.css';
import {
  DesignerMode,
  InterfaceStudioState,
  RESET_DESIGNER_STUDIO,
  ViewportMode
} from '../../store';
import { FolderService, LayoutService, ModalService, PageService } from 'modules/designer/services';
import CreateFolderDialog from './create_folder_modal';
import { DashboardService } from 'modules/dashboard/services';
import { ModuleInfo } from 'modules/dashboard/types';
import Icon from 'web_ui/icon';
import { useTranslation } from 'react-i18next';
import { LocalStorageManager } from 'utils/localstorage';
import { setDesignerMode, setViewportMode } from '../../store/actions/studio';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import {
  designer_dragleave,
  designer_dragover,
  designer_dragstart,
  designer_drop,
  savefolder,
  designer_saveitem,
  checkFolderNameAlreadyExists,
  updateFoldersOnRename
} from 'web_ui/folders/callbacks';
import { DuplicatePageModal } from './duplicate_page_modal';
import { CreateTemplateModal } from './create_template_modal';
import { validateFolderName, validateViewName } from 'utils/inputValidation';
import { DraggableResource } from 'web_ui/folders/draggable_resource/draggable_resource';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { AppContext } from 'modules/project/store/app_context';
import UpgradePlanModal from '../../../../../web_ui/upgrade_plan_modal';
import { getFeatureLimit } from '../../../../../utils/utils';
import { FoldersContext } from '../../FolderContext';
import { IS_LOCALHOST } from '../../../../../constants';

export type ViewType = {
  uuid: string;
  name: string;
  folders?: ViewType[];
  parent?: string;
  moduleId?: string;
  type?: string;
  uiFolderId?: string;
  homepage?: boolean;
  data?: any;
};

type ViewsToolbarProps = {
  appId: string;
  moduleId: string;
  pages: Page[];
  modals: Modal[];
  layouts: Layout[];
  fetchViews: () => Promise<void>;
  layoutDef: ILayoutDefault;
};

function ViewsToolbar(props: ViewsToolbarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { module_id, app_id, view_id } = useParams();
  const [filterValue, setFilterValue] = useState('');
  const activeView = useSelector((state: InterfaceStudioState) => state.studio.view_id);
  const [showPageDialog, setShowPageDialog] = useState(false);
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [showLayoutDialog, setShowLayoutDialog] = useState(false);
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [newViewParent, setNewViewParent] = useState('');
  const [selectedModule, setSelectedModule] = React.useState<ModuleInfo>();
  const [duplicatePageModal, setDuplicatePageModal] = useState<Page | Layout | Modal>();
  const [createTemplateModal, setCreateTemplateModal] = useState<string>();
  const queryParameters = useQuery();
  const { t } = useTranslation();
  const fetchViews = props.fetchViews;
  const { folders, fetchFolders, setFoldersForce } = useContext(FoldersContext)!;
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const getNestedItems = (): Record<string, boolean> => {
    const getItems = LocalStorageManager.getValueLocalStorageState(props.appId, props.moduleId);
    if (getItems[props.moduleId] && getItems[props.moduleId].interface.collapsedFolders) {
      return getItems[props.moduleId].interface.collapsedFolders;
    }
    return {};
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const ownerLimits = useContext(AppContext).appOwnerLimits;
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<string>('');
  const [codePreviewType, setCodePreviewType] = useState<CodePreviewType>();

  const fetchModules = React.useCallback(async () => {
    if (props.appId) {
      const modules = await DashboardService.getModulesByApp(props.appId);
      if (props.moduleId) {
        const selectedModule = modules.find((x) => x.id === props.moduleId);
        setSelectedModule(selectedModule);
      }
    }
  }, [props.appId, props.moduleId]);

  useEffect(() => {
    fetchFolders();
    fetchModules();
    const copying = LocalStorageManager.getValueLocalStorageState(props.appId, props.moduleId);
    if (copying[props.moduleId] && copying[props.moduleId].interface.lastSelectedViewport)
      dispatch(
        setViewportMode(copying[props.moduleId].interface.lastSelectedViewport as ViewportMode)
      );
  }, [dispatch, fetchFolders, fetchModules, props.appId, props.moduleId]);

  useEffect(() => {
    const copying = LocalStorageManager.getValueLocalStorageState(props.appId, props.moduleId);
    if (copying[props.moduleId] && copying[props.moduleId].interface.lastSelectedView) {
      navigate(
        `/app/${props.appId}/module/${props.moduleId}/ui/${
          copying[props.moduleId].interface.lastSelectedView
        }`
      );
      return;
    }
  }, [props.appId, props.moduleId]);

  useEffect(() => {
    const items: Layout[] = [props.pages, props.layouts, props.modals].flat();
    const newItems: FolderItem[] = [];
    items.forEach((item) => {
      newItems.push({ ...item });
    });
    setFolderItems(newItems);
  }, [props.layouts, props.modals, props.pages]);

  const updateLocalStorageSelectView = useCallback(
    (uuid: string) => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        interface: {
          ...copying[module_id].interface,
          lastSelectedView: uuid
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const isFromVsCodeExt = useCallback((): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  }, [queryParameters]);

  function changeView(uuid: string): void {
    if (uuid === activeView) return;
    props.fetchViews();
    navigate(
      `/app/${props.appId}/module/${props.moduleId}/ui/${uuid}${
        isFromVsCodeExt() ? '?vscode=true' : ''
      }`
    );
    updateLocalStorageSelectView(uuid);
  }

  function handleShowCreateDialog(type: string, folderId?: string): void {
    setNewViewParent(folderId ?? '');
    switch (type) {
      case 'PAGE':
        canCreatePage();
        break;
      case 'MODAL':
        setShowModalDialog(true);
        break;
      case 'LAYOUT':
        setShowLayoutDialog(true);
        break;
      case 'FOLDER':
        if (!folderId) return;
        setShowFolderDialog(true);
        break;
      default:
    }
  }

  function handleCollapseEveryFolder(type: 'EXPAND' | 'COLLAPSE'): void {
    const next: Record<string, boolean> = {};
    Object.keys(showNested).forEach((key) => {
      next[key] = type === 'EXPAND';
    });
    updateShowNestedLocalStorage(next);
    setShowNested(next);
  }

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>) => {
      if (!app_id || !module_id) return;
      const copying = LocalStorageManager.getValueLocalStorageState(props.appId, props.moduleId);
      copying[props.moduleId] = {
        ...copying[props.moduleId],
        interface: {
          ...copying[props.moduleId].interface,
          collapsedFolders: next
        }
      };
      LocalStorageManager.setValueLocalStorageState(props.appId, copying);
    },
    [app_id, module_id, props.appId, props.moduleId]
  );

  const toggleCollapsed = useCallback(
    (folderId: string, collapsed: boolean) => {
      const next = { ...showNested, [folderId]: collapsed };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  const selectView = useCallback(
    (viewId: string) => {
      if (!app_id || !module_id) return;
      if (viewId === activeView) return;
      fetchViews();
      navigate(
        `/app/${app_id}/module/${module_id}/ui/${viewId}${isFromVsCodeExt() ? '?vscode=true' : ''}`
      );
      updateLocalStorageSelectView(viewId);
    },
    [
      activeView,
      app_id,
      fetchViews,
      isFromVsCodeExt,
      module_id,
      navigate,
      updateLocalStorageSelectView
    ]
  );

  const deleteFolder = useCallback(
    async (moduleId: string, folderId: string) => {
      await FolderService.deleteFolder(moduleId, folderId).then(() => {
        fetchFolders();
      });
    },
    [fetchFolders]
  );

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, folders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  const deleteView = useCallback(
    async (viewId: string, type: string) => {
      if (type === 'PAGE') {
        await PageService.deletePage(viewId).then(() => {
          dispatch({ type: RESET_DESIGNER_STUDIO });
          navigate(`/app/${app_id}/module/${module_id}/ui`);
          fetchViews();
        });
      } else if (type === 'LAYOUT') {
        await LayoutService.deleteLayout(viewId).then(() => {
          dispatch({ type: RESET_DESIGNER_STUDIO });
          navigate(`/app/${app_id}/module/${module_id}/ui`);
          fetchViews();
        });
      } else if (type === 'MODAL') {
        await ModalService.deleteModal(viewId).then(() => {
          dispatch({ type: RESET_DESIGNER_STUDIO });
          navigate(`/app/${app_id}/module/${module_id}/ui`);
          fetchViews();
        });
      } else {
        console.error('Missing view delete implementation.');
      }
    },
    [app_id, dispatch, fetchViews, module_id, navigate]
  );

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !folders) return undefined;
    const callbacks: Record<string, Record<string, any>> = {};
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => selectView(folderItem.uuid);
      callbacks[folderItem.uuid].save = (newName: string) => {
        designer_saveitem(folderItem.uuid, props.fetchViews, newName, folderItem.type as string);
      };
      callbacks[folderItem.uuid].drop = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_drop(
          e,
          props.fetchViews,
          fetchFolders,
          'folder',
          el,
          folderItem.folder_id ?? '',
          module_id
        );
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        designer_dragstart(
          e,
          folderItem.uuid,
          folderItem.folder_id ?? '',
          folderItem.type as string,
          'folderItem'
        );
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = async () =>
        await deleteView(folderItem.uuid, folderItem.type as string);
      callbacks[folderItem.uuid].validate = (name: string): string => {
        const v = validateViewName(name);
        if (!v.valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    let checkFolders: Folder[] = [...folders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string) => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(folders, folder.uuid, newName, setFoldersForce);
      };
      callbacks[folder.uuid].drop = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_drop(e, props.fetchViews, fetchFolders, 'folder', el, folder.uuid, module_id);
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        designer_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        designer_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () => await deleteFolder(module_id, folder.uuid);
    }
    return callbacks;
  }, [
    deleteFolder,
    deleteView,
    fetchFolders,
    folderItems,
    folders,
    module_id,
    props.fetchViews,
    selectView
  ]);

  const getRootCallbacks = useCallback((): Record<string, Record<string, any>> => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id) return callbacks;
    callbacks['root'] = {};
    callbacks['root'].drop = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      designer_drop(e, props.fetchViews, fetchFolders, 'root', el, '', module_id);
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      designer_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      designer_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      designer_dragover(e, el, 'root');
    return callbacks;
  }, [fetchFolders, module_id, props.fetchViews]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !folders) {
      return undefined;
    }
    // Folder callbacks.
    let checkFolders: Folder[] = [...folders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
      callbacks[folder.uuid].push({
        label: 'designer.views.NewFolder',
        icon: 'folder',
        onClick: () => handleShowCreateDialog('FOLDER', folder.uuid)
      });
      callbacks[folder.uuid].push({
        label: 'designer.views.NewPage',
        icon: 'window-maximize',
        onClick: () => handleShowCreateDialog('PAGE', folder.uuid)
      });
      IS_LOCALHOST &&
        callbacks[folder.uuid].push({
          label: 'designer.views.NewModal',
          icon: 'window-restore',
          onClick: () => handleShowCreateDialog('MODAL', folder.uuid)
        });
      callbacks[folder.uuid].push({
        label: 'designer.views.NewLayout',
        icon: 'table-columns',
        onClick: () => {
          handleShowCreateDialog('LAYOUT', folder.uuid);
        }
      });
    }
    // Views callbacks.
    for (const viewInfo of folderItems) {
      callbacks[viewInfo.uuid] = [];
      callbacks[viewInfo.uuid].push({
        label: 'designer.views.Duplicate',
        icon: 'copy',
        onClick: () => setDuplicatePageModal(viewInfo as unknown as Layout)
      });
      callbacks[viewInfo.uuid].push({
        label: 'designer.views.ShowBehavior',
        icon: 'code',
        onClick: () => dispatch(setDesignerMode('BEHAVIOR' as DesignerMode))
      });
      callbacks[viewInfo.uuid].push({
        label: 'designer.views.SaveAsTemplate',
        icon: 'puzzle-piece',
        onClick: () => setCreateTemplateModal(viewInfo.uuid)
      });
      callbacks[viewInfo.uuid].push({
        label: 'CodePreview',
        icon: 'code',
        onClick: () => {
          setShowCodePreviewDialog(viewInfo.uuid);
          setCodePreviewType(
            viewInfo.type == 'LAYOUT' ? CodePreviewType.LAYOUT : CodePreviewType.PAGE
          );
        }
      });
    }
    return callbacks;
  }, [dispatch, folderItems, folders, module_id]);

  const getDisabledItems = useCallback((): Record<string, boolean> => {
    const disabled: Record<string, boolean> = {};
    for (const page of props.pages) {
      disabled[page.uuid] = !!page.disabled;
    }
    return disabled;
  }, [props.pages]);

  const canCreatePage = async () => {
    if (ownerLimits == null) {
      console.error("Couldn't access user's resource limits.");
      return;
    }
    const limit = getFeatureLimit(ownerLimits, SystemRoleAuthorityName.ADD_PAGE);
    if (!limit) {
      setShowUpgradePlanModal(true);
      return;
    }
    if (props.pages.length < limit && ownerLimits.totals.numberOfPages < limit) {
      setShowPageDialog(true);
    } else {
      setShowUpgradePlanModal(true);
    }
  };

  return (
    <>
      <div className={`${styles.paneToolbarContent}`}>
        <div className="p-2">
          <Form.Control
            id={'searchField'}
            placeholder={`${t('designer.views.FilterViews')}`}
            onChange={(e) => setFilterValue(e.target.value)}
            value={filterValue}
            autoFocus
          />
        </div>
        <DraggableResource
          handleDrop={getRootCallbacks()['root'].drop}
          handleDragStart={getRootCallbacks()['root'].dragstart}
          handleDragOver={getRootCallbacks()['root'].dragover}
          handleLeave={getRootCallbacks()['root'].dragleave}
        >
          <div className="me-2" style={{ height: '100%' }}>
            <div
              className="font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center"
              style={{ fontWeight: 600 }}
            >
              <label>{selectedModule?.name}</label>
              <ListGroup horizontal>
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('designer.views.FolderCollapse')}`
                  }}
                  placement="top"
                >
                  <ListGroup.Item
                    id="collapseButton"
                    action
                    className="border-0 m-0 p-0 me-2"
                    onClick={(e) => {
                      handleCollapseEveryFolder('COLLAPSE');
                    }}
                  >
                    <Icon iconName="down-left-and-up-right-to-center" />
                  </ListGroup.Item>
                </HelpPopover>
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('designer.views.FolderExpand')}`
                  }}
                  placement="top"
                >
                  <ListGroup.Item
                    id="expandButton"
                    action
                    className="border-0 m-0 p-0 me-2"
                    onClick={(e) => {
                      handleCollapseEveryFolder('EXPAND');
                    }}
                  >
                    <Icon iconName="up-right-and-down-left-from-center" />
                  </ListGroup.Item>
                </HelpPopover>
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('designer.views.NewRootFolder')}`
                  }}
                  placement="top"
                >
                  <ListGroup.Item
                    id="newFolderButton"
                    action
                    className="border-0 m-0 p-0"
                    onClick={() => {
                      setNewViewParent('');
                      setShowFolderDialog(true);
                    }}
                  >
                    <Icon iconName="folder-plus" />
                  </ListGroup.Item>
                </HelpPopover>
              </ListGroup>
            </div>
            <RenderFolders
              folders={folders ?? []}
              collapsedFolders={showNested}
              toggleCollapsedFolder={toggleCollapsed}
              items={folderItems}
              selectedItem={view_id ?? ''}
              callbacks={getCallbacks()}
              itemFilter={filterValue}
              contextMenuItems={getMenuCallbacks()}
              setFolders={setFoldersForce}
              checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                checkFolderNameAlreadyExists(parentId, name, folders ?? [], folderId)
              }
              disabledFolderItems={getDisabledItems()}
            />
          </div>
        </DraggableResource>
      </div>

      {module_id && duplicatePageModal && (
        <DuplicatePageModal
          view={duplicatePageModal}
          show={duplicatePageModal != null}
          onClose={() => {
            setDuplicatePageModal(undefined);
            fetchViews();
          }}
          moduleId={module_id}
        />
      )}

      {app_id && createTemplateModal && (
        <CreateTemplateModal
          show={createTemplateModal != null}
          onClose={() => setCreateTemplateModal(undefined)}
          viewId={createTemplateModal}
          appId={app_id}
        />
      )}

      <CreatePageDialog
        showDialog={showPageDialog}
        dialogOnClose={() => setShowPageDialog(false)}
        onCreate={(uuid: string) => {
          props.fetchViews();
          changeView(uuid);
        }}
        layoutsList={props.layouts}
        parentUuid={newViewParent}
        layoutDefault={props.layoutDef}
      />

      <CreateModalDialog
        show={showModalDialog}
        onClose={() => setShowModalDialog(false)}
        onCreate={(uuid: string) => {
          changeView(uuid);
        }}
        parentUuid={newViewParent}
      />

      <CreateLayoutDialog
        show={showLayoutDialog}
        onClose={() => setShowLayoutDialog(false)}
        onCreate={(uuid: string) => {
          props.fetchViews();
          changeView(uuid);
        }}
        parentUuid={newViewParent}
      />

      {showCodePreviewDialog && (
        <CodeEditorModal
          show={showCodePreviewDialog != ''}
          handleClose={() => setShowCodePreviewDialog('')}
          id={showCodePreviewDialog ?? ''}
          previewType={codePreviewType!}
        />
      )}

      <CreateFolderDialog
        show={showFolderDialog}
        onClose={() => {
          setShowFolderDialog(false);
          setNewViewParent('');
        }}
        parentUuid={newViewParent}
        onCreate={(f: Folder) => {
          fetchFolders();
          setShowFolderDialog(false);
        }}
        validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
        type={FolderType.UI}
      />
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
    </>
  );
}

export default ViewsToolbar;
