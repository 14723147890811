import React, { DragEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'modules/logic_builder/styles.module.css';
import {
  LogicBuilderConcept,
  SchedulerJob,
  getLogicBuilderConceptInfo
} from 'modules/logic_builder/types';
import LogicBuilderContext from 'modules/logic_builder/store';
import SearchBar from 'web_ui/search_bar';
import { SHOW_ALL_URL } from 'modules/logic_builder/types';
import { useParams } from 'react-router-dom';
import { ContextMenuItem, FolderItem, RenderFolders } from 'web_ui/folders';
import { ListGroup } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import {
  checkFolderNameAlreadyExists,
  deletefolder,
  lb_dragleave,
  lb_dragover,
  lb_dragstart,
  lb_drop,
  lb_saveitem,
  savefolder,
  updateFoldersOnCreate,
  updateFoldersOnDelete,
  updateFoldersOnMoveFolder,
  updateFoldersOnRename
} from 'web_ui/folders/callbacks';
import {
  DraggableResource,
  TargetType
} from 'web_ui/folders/draggable_resource/draggable_resource';
import { LocalStorageManager } from 'utils/localstorage';
import { Folder, FolderType } from 'modules/designer/types';
import { validateControllerName, validateFolderName } from 'utils/inputValidation';
import CreateFolderDialog from 'modules/designer/studio/toolbars/views_toolbar/create_folder_modal';
import { ScheduledsService } from 'modules/logic_builder/services';
import {
  ScheduledDialog,
  ScheduledDialog as ScheduledEditorDialog
} from 'modules/logic_builder/components/dialogs/scheduled_dialog';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';

export function SchedulerToolbar() {
  const { t } = useTranslation();
  const { scheduler_id, module_id, app_id } = useParams();
  const CONCEPT = LogicBuilderConcept.SCHEDULER;
  const [searchInput, setSearchInput] = useState('');
  const [showFolderDialog, setShowFolderDialog] = useState<string>();
  const {
    navigateToConcept,
    setLastSelectedConcept,
    schedulerFolders,
    schedulerJobs,
    updateFolders,
    changeSchedulerJob,
    deleteSchedulerJob,
    createSchedulerJob,
    checkRepeatedSchedulerJob
  } = useContext(LogicBuilderContext);
  const getNestedItems = (): Record<string, boolean> => {
    if (!module_id || !app_id) {
      return {};
    }
    const getItems = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
    if (getItems[module_id] && getItems[module_id].logicBuilder.folderState.schedulerJobs) {
      return getItems[module_id].logicBuilder.folderState.schedulerJobs;
    } else {
      return {};
    }
  };
  const [showNested, setShowNested] = useState<Record<string, boolean>>(() => getNestedItems());
  const [folderItems, setFolderItems] = useState<FolderItem[]>([]);
  const [showCreateSchedulerModal, setShowCreateSchedulerModal] = useState(false);
  const [showUpdateSchedulerJobModal, setShowUpdateSchedulerJobModal] = useState<string>();
  const [previewScheduledUuid, setPreviewScheduledUuid] = useState<string>('');

  const handleSelectedScheduler = useCallback(
    (selectedScheduler: string) => {
      navigateToConcept(CONCEPT, selectedScheduler);
      setLastSelectedConcept(CONCEPT, selectedScheduler ?? SHOW_ALL_URL);
    },
    [CONCEPT]
  );

  const handleUpdateFolders = useCallback(
    (f: Folder[]) => {
      updateFolders(f, 'schedulers');
    },
    [updateFolders]
  );

  const updateShowNestedLocalStorage = useCallback(
    (next: Record<string, boolean>) => {
      if (!app_id || !module_id) {
        return;
      }
      const copying = LocalStorageManager.getValueLocalStorageState(app_id, module_id);
      copying[module_id] = {
        ...copying[module_id],
        logicBuilder: {
          ...copying[module_id].logicBuilder,
          folderState: {
            ...copying[module_id].logicBuilder.folderState,
            endpoints: next
          }
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id, copying);
    },
    [app_id, module_id]
  );

  const reloadSchedulerJobs = React.useCallback(async () => {
    if (!module_id) return;
    const items: FolderItem[] = [];
    for (const c of schedulerJobs) {
      items.push({ ...c, uuid: c.uuid ?? '', type: 'SCHEDULERS' });
    }
    setFolderItems(items);
  }, [module_id, schedulerJobs]);

  useEffect(() => {
    reloadSchedulerJobs();
  }, [reloadSchedulerJobs]);

  const openAllFolders = useCallback(() => {
    const next: Record<string, boolean> = {};
    for (const folder of schedulerFolders ?? []) {
      next[folder.uuid] = true;
    }
    setShowNested(next);
    updateShowNestedLocalStorage(next);
  }, [schedulerFolders, updateShowNestedLocalStorage]);

  const closeAllFolders = useCallback(() => {
    setShowNested({});
    updateShowNestedLocalStorage({});
  }, [updateShowNestedLocalStorage]);

  const folderNameIsValid = (nextParent: string, nextName: string) => {
    if (checkFolderNameAlreadyExists(nextParent, nextName, schedulerFolders ?? [])) {
      return false;
    } else if (!validateFolderName(nextName)) {
      return false;
    }
    return true;
  };

  const handleDrop = useCallback(
    (sourceId: string, sourceType: TargetType, newParentFolder: string) => {
      if (!schedulerFolders) {
        return;
      }
      updateFoldersOnMoveFolder(
        schedulerFolders,
        sourceId,
        newParentFolder,
        sourceType,
        handleUpdateFolders
      );
      const findObject = schedulerJobs.find((o) => o.uuid === sourceId);
      if (findObject) {
        changeSchedulerJob({ ...findObject, folder_id: newParentFolder });
      }
    },
    [changeSchedulerJob, handleUpdateFolders, schedulerFolders, schedulerJobs]
  );

  const toSingular = (word: string) => {
    if (word.endsWith('s')) {
      return word.slice(0, -1);
    }
    return word;
  };

  const handleDeleteSchedulerJob = useCallback(
    async (schedulerJob: string): Promise<void> => {
      if (!module_id) return;
      await ScheduledsService.deleteScheduled(schedulerJob, module_id);
    },
    [module_id]
  );

  const getCallbacks = useCallback((): Record<string, Record<string, any>> | undefined => {
    if (!module_id || !schedulerFolders) {
      return undefined;
    }
    const callbacks: Record<string, Record<string, any>> = {};
    // Folder items callbacks.
    for (const folderItem of folderItems) {
      callbacks[folderItem.uuid] = {};
      callbacks[folderItem.uuid].select = () => handleSelectedScheduler(folderItem.uuid);
      callbacks[folderItem.uuid].save = (newName: string) => {
        lb_saveitem(folderItem.uuid, 'SCHEDULER', newName, module_id);
        changeSchedulerJob({ ...folderItem, name: newName } as unknown as SchedulerJob);
      };
      callbacks[folderItem.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folderItem.folder_id ?? '', module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folderItem.folder_id ?? '');
        });
      callbacks[folderItem.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folderItem.uuid, folderItem.folder_id ?? '', 'SCHEDULER', 'folderItem');
      callbacks[folderItem.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folderItem.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folderItem');
      callbacks[folderItem.uuid].delete = async () =>
        await handleDeleteSchedulerJob(folderItem.uuid).then(() =>
          deleteSchedulerJob(folderItem.uuid)
        );
      callbacks[folderItem.uuid].select = async () => handleSelectedScheduler(folderItem.uuid);
      callbacks[folderItem.uuid].validate = (name: string): string => {
        const changed = { ...folderItem, name: name } as unknown as SchedulerJob;
        if (!validateControllerName(name, 64).valid) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        } else if (checkRepeatedSchedulerJob(changed)) {
          return 'inputValidationErrorMessages.GenericErrorMessage';
        }
        return '';
      };
    }
    // Folders callbacks.
    let checkFolders: Folder[] = [...schedulerFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) continue;
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = {};
      callbacks[folder.uuid].save = (newName: string) => {
        savefolder(module_id, folder.uuid, newName);
        updateFoldersOnRename(schedulerFolders, folder.uuid, newName, handleUpdateFolders);
      };
      callbacks[folder.uuid].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        await lb_drop(e, 'folder', el, folder.uuid, module_id).then((s) => {
          if (!s) return;
          handleDrop(s.sourceId, s.sourceType, folder.uuid);
        });
      callbacks[folder.uuid].dragstart = (e: DragEvent<HTMLDivElement>) =>
        lb_dragstart(e, folder.uuid, folder.parent ?? '', '', 'folder');
      callbacks[folder.uuid].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragleave(e, el);
      callbacks[folder.uuid].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
        lb_dragover(e, el, 'folder');
      callbacks[folder.uuid].delete = async () =>
        await deletefolder(module_id, folder.uuid).then(() =>
          updateFoldersOnDelete(schedulerFolders, folder.uuid, handleUpdateFolders)
        );
    }
    return callbacks;
  }, [
    changeSchedulerJob,
    checkRepeatedSchedulerJob,
    deleteSchedulerJob,
    folderItems,
    handleDeleteSchedulerJob,
    handleDrop,
    handleSelectedScheduler,
    handleUpdateFolders,
    module_id,
    schedulerFolders
  ]);

  const getMenuCallbacks = useCallback((): Record<string, ContextMenuItem[]> | undefined => {
    const callbacks: Record<string, ContextMenuItem[]> = {};
    if (!module_id || !schedulerFolders) {
      return undefined;
    }
    // Folder callbacks.
    let checkFolders: Folder[] = [...schedulerFolders];
    while (checkFolders.length) {
      const folder = checkFolders.pop();
      if (!folder) {
        continue;
      }
      checkFolders = checkFolders.concat(folder.folders ? (folder.folders as Folder[]) : []);
      callbacks[folder.uuid] = [];
    }
    // Folder items callbacks.
    for (const item of folderItems) {
      callbacks[item.uuid] = [];
      callbacks[item.uuid].push({
        label: 'Edit',
        icon: 'pencil',
        onClick: () => setShowUpdateSchedulerJobModal(item.uuid)
      });
      callbacks[item.uuid].push({
        label: 'CodePreview',
        icon: 'code',
        onClick: () => setPreviewScheduledUuid(item.uuid)
      });
    }
    return callbacks;
  }, [folderItems, module_id, schedulerFolders]);

  const getRootCallbacks = useCallback(() => {
    const callbacks: Record<string, Record<string, any>> = {};
    if (!module_id) return callbacks;
    callbacks['root'] = {};
    callbacks['root'].drop = async (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      await lb_drop(e, 'root', el, '', module_id).then((s) => {
        if (!s) return;
        handleDrop(s.sourceId, s.sourceType, '');
      });
    callbacks['root'].dragstart = (e: DragEvent<HTMLDivElement>) =>
      lb_dragstart(e, '', '', '', 'root');
    callbacks['root'].dragleave = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragleave(e, el);
    callbacks['root'].dragover = (e: DragEvent<HTMLDivElement>, el: HTMLDivElement) =>
      lb_dragover(e, el, 'root');
    return callbacks;
  }, [handleDrop, module_id]);
  const rootCallbacks = getRootCallbacks()['root'];

  const toggleCollapseFolder = useCallback(
    (folderId: string, collapsed: boolean): void => {
      const next: Record<string, boolean> = {
        ...showNested,
        [folderId]: collapsed
      };
      setShowNested(next);
      updateShowNestedLocalStorage(next);
    },
    [showNested, updateShowNestedLocalStorage]
  );

  return (
    <>
      <div className={'d-flex flex-column h-100 flex-grow-1'} style={{ width: '100%' }}>
        <div className="p-2">
          <SearchBar
            id={'search-bar-schedules'}
            placeholder={t('Search') ?? ''}
            text={searchInput}
            setText={setSearchInput}
          />
        </div>
        <div className={`${styles.layersContainer}`}>
          <div
            className="font-weight-bold ms-3 pb-2 border-bottom border-3 border-body-emphasis me-2 mb-1 d-flex justify-content-between align-items-center"
            style={{ fontWeight: 600 }}
          >
            <label id="label">{'Schedulers'}</label>
            <ListGroup horizontal>
              <HelpPopover
                helpBoxProps={{
                  title: `${t('designer.views.FolderCollapse')}`
                }}
                placement="top"
              >
                <ListGroup.Item
                  action
                  className="border-0 m-0 p-0 me-2"
                  onClick={() => closeAllFolders()}
                >
                  <Icon iconName="down-left-and-up-right-to-center" />
                </ListGroup.Item>
              </HelpPopover>
              <HelpPopover
                helpBoxProps={{
                  title: `${t('designer.views.FolderExpand')}`
                }}
                placement="top"
              >
                <ListGroup.Item
                  action
                  className="border-0 m-0 p-0 me-2"
                  onClick={() => openAllFolders()}
                >
                  <Icon iconName="up-right-and-down-left-from-center" />
                </ListGroup.Item>
              </HelpPopover>
              <HelpPopover
                helpBoxProps={{
                  title: `${t('designer.views.NewRootFolder')}`
                }}
                placement="top"
              >
                <ListGroup.Item
                  id="createFolderButton"
                  action
                  className="border-0 m-0 p-0"
                  onClick={() => {
                    setShowFolderDialog('');
                  }}
                >
                  <Icon iconName="folder-plus" />
                </ListGroup.Item>
              </HelpPopover>
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: `${t('New')} ${toSingular(
                    t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)
                  )}`
                }}
              >
                <div
                  id={`create${t(getLogicBuilderConceptInfo(CONCEPT).parentTitle)}Button`}
                  className={styles.viewHeaderNewView}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    setShowCreateSchedulerModal(true);
                  }}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                >
                  <Icon iconName="plus" extraProps="fa-lg" />
                </div>
              </HelpPopover>
            </ListGroup>
          </div>
          <div style={{ overflowX: 'auto', width: '100%', height: '100%' }}>
            {rootCallbacks && (
              <DraggableResource
                handleDrop={getRootCallbacks()['root'].drop}
                handleDragStart={getRootCallbacks()['root'].dragstart}
                handleDragOver={getRootCallbacks()['root'].dragover}
                handleLeave={getRootCallbacks()['root'].dragleave}
                className={styles.rootDraggableArea}
              >
                <div className={`p-3 pt-0`}>
                  <RenderFolders
                    folders={schedulerFolders ?? []}
                    collapsedFolders={showNested}
                    toggleCollapsedFolder={toggleCollapseFolder}
                    items={folderItems}
                    selectedItem={scheduler_id ?? ''}
                    callbacks={getCallbacks()}
                    itemFilter={searchInput}
                    contextMenuItems={getMenuCallbacks()}
                    setFolders={handleUpdateFolders}
                    checkFolderNameExists={(parentId: string, name: string, folderId: string) =>
                      checkFolderNameAlreadyExists(parentId, name, schedulerFolders ?? [], folderId)
                    }
                  />
                </div>
              </DraggableResource>
            )}
          </div>
        </div>
        {showFolderDialog != null && (
          <CreateFolderDialog
            show={showFolderDialog != null}
            onClose={() => {
              setShowFolderDialog(undefined);
            }}
            parentUuid={showFolderDialog}
            onCreate={(f: Folder) =>
              updateFoldersOnCreate(schedulerFolders ?? [], f, handleUpdateFolders)
            }
            type={FolderType.LG_SCHEDULER}
            validateFolderName={(parent: string, name: string) => folderNameIsValid(parent, name)}
          />
        )}
        <ScheduledDialog
          show={showCreateSchedulerModal}
          onClose={() => setShowCreateSchedulerModal(false)}
          editMode={false}
          dialogTitle={'CreateScheduled'}
          onSuccess={createSchedulerJob}
        />
        {showUpdateSchedulerJobModal && (
          <ScheduledEditorDialog
            show={showUpdateSchedulerJobModal !== null}
            onClose={() => setShowUpdateSchedulerJobModal(undefined)}
            editMode={true}
            dialogTitle={'EditScheduled'}
            schedulerJob={schedulerJobs.find((s) => s.uuid === showUpdateSchedulerJobModal)}
            onSuccess={changeSchedulerJob}
          />
        )}
        {previewScheduledUuid && (
          <CodeEditorModal
            show={previewScheduledUuid !== ''}
            handleClose={() => setPreviewScheduledUuid('')}
            id={previewScheduledUuid}
            previewType={CodePreviewType.SCHEDULER_JOB}
          />
        )}
      </div>
    </>
  );
}
