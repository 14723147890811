import parser from 'cron-parser';
import cronstrue from 'cronstrue';
import { FunctionService } from 'modules/logic_builder/services';
import { FunctionExtended } from 'modules/logic_builder/types';

export const validateCronExpression = (expression: string): boolean => {
  let isCronValid = true;

  try {
    const exp = expression.trim().split(/\s+/);
    if (exp.length < 5 || exp.length > 6) {
      isCronValid = false;
    }

    parser.parseExpression(expression);

    const description = cronstrue.toString(expression);
    if (description.includes('undefined')) {
      isCronValid = false;
    }
  } catch (e) {
    isCronValid = false;
  }

  return isCronValid;
};

export const fetchFunctionsWithParametersIncluded = async (
  moduleId: string
): Promise<FunctionExtended[]> => {
  const functionsWithParameters: FunctionExtended[] = [];
  const fetchFunctionsInfo = await FunctionService.getFunctionsParameters(moduleId);
  for (const fn of fetchFunctionsInfo) {
    if (!fn.parameters.length) {
      functionsWithParameters.push(fn);
    }
  }
  return functionsWithParameters;
};
