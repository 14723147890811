import { FunctionsState } from '../../types/functions';
import { SetPaginationAction } from '../parameters/set_pagination';

export function doSetPagination(
  state: FunctionsState,
  action: SetPaginationAction
): FunctionsState {
  const { functionUuid, value, pageUuid, sizeUuid } = action.payload;

  if (!state[functionUuid]) return state;

  if (value) {
    state[functionUuid].parameters.push(pageUuid, sizeUuid);
    state[functionUuid].options.pagination = true;
    return state;
  }

  const pageIndex = state[functionUuid].parameters.indexOf(pageUuid);
  state[functionUuid].parameters.splice(pageIndex, 1);
  const sizeIndex = state[functionUuid].parameters.indexOf(sizeUuid);
  state[functionUuid].parameters.splice(sizeIndex, 1);
  state[functionUuid].options.pagination = false;
  return state;
}
