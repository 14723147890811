import React, { useEffect, useState } from 'react';
import { modalSettingsProps } from '../types/type';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { IconList, IconPicker } from 'packages/react-fa-icon-picker';
import radio from 'modules/designer/studio/exocode_components/radio';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { FrontendFunction } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';

const ModalSettings = (props: modalSettingsProps) => {
  const functions = useSelector((state: InterfaceStudioState) => state.functions);
  const { t } = useTranslation();

  const showBtn = (val: boolean) => {
    if (val) {
      props.onChange && props.onChange(val, 'showBtnAction');
    } else {
      props.onChange && props.onChange(val, 'showBtnAction');
    }
  };

  const iconUpdate = (v: IconList) => {
    if (v) {
      props.onChange && props.onChange(v, 'BtnActionIcon');
    }
  };

  const updateBtnColor = (value: string) => {
    if (value) {
      props.onChange && props.onChange(value, 'BtnActionColor');
    }
  };

  const updateFunctionToExecute = (functionId: string) => {
    if (functionId) {
      props.onChange && props.onChange(functionId, 'BtnActionFunctionId');
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
    >
      <Modal.Header closeButton>
        {t('designer.right_side.controls.actionBtnModal.header')}
      </Modal.Header>
      <Modal.Body>
        <Form.Group
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}
        >
          <Form.Label>{t('designer.right_side.controls.actionBtnModal.show')}</Form.Label>
          <Form.Check
            type="switch"
            checked={props.value && props.value.showBtnAction ? props.value.showBtnAction : false}
            onChange={(ev) => showBtn(ev.target.checked)}
            style={{ marginLeft: 10 }}
          />
        </Form.Group>

        {props.value && props.value.showBtnAction && (
          <div>
            <hr />
            <div style={{ marginTop: 10 }}>
              <Form.Label>{t('designer.right_side.controls.actionBtnModal.btnIcon')}</Form.Label>
              <div
                style={{
                  width: 'fit-content',
                  padding: '3px 3px 3px 3px',
                  borderRadius: 8
                }}
              >
                <IconPicker
                  value={
                    (props.value && props.value.BtnActionIcon && props.value.BtnActionIcon) ||
                    'FaRegCaretSquareDown'
                  }
                  onChange={(v) => iconUpdate(v)}
                />
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Form.Label>{t('designer.right_side.controls.actionBtnModal.btnColor')}</Form.Label>
              <div style={{ width: '40%' }}>
                <Form.Select
                  value={props.value && props.value.BtnActionColor && props.value.BtnActionColor}
                  onChange={(ev) => updateBtnColor(ev.target.value)}
                >
                  <option defaultChecked>---</option>
                  <option value="primary">primary</option>
                  <option value="secondary">secondary</option>
                  <option value="success">success</option>
                  <option value="danger">danger</option>
                  <option value="warning">warning</option>
                  <option value="info">info</option>
                  <option value="light">light</option>
                  <option value="dark">dark</option>
                </Form.Select>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <Form.Label>
                {t('designer.right_side.controls.actionBtnModal.fnToExecute')}
              </Form.Label>
              <div style={{ width: '40%' }}>
                <Form.Select
                  value={
                    props.value &&
                    props.value.BtnActionFunctionId &&
                    functions[props.value.BtnActionFunctionId].uuid
                  }
                  onChange={(ev) => updateFunctionToExecute(ev.target.value)}
                >
                  <option defaultChecked>---</option>
                  {functions &&
                    Object.values(functions).map((item, index) => (
                      <option value={item.uuid} key={index}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSettings;
