import React, { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState, LinksState } from '../store';
import FloatingTools from '../toolbars/floating_tools';
import { COMPONENT_TYPES } from './index';
import { ComponentUUID, Editor, Message, MessageTypes, ViewUUID } from '../../types';
import { ElementWrapper } from './element_wrapper';
import { Preferences } from '../../../auth/enum';
import SessionContext from '../../../auth/store';
import { GridBox } from '../toolbars/floating_tools/grid_box';
import {
  FORM_TOOLBAR_ACTIONS,
  TOOLBAR_ACTIONS
} from '../toolbars/floating_tools/selection_toolbar';
import { ElementHover } from '../toolbars/floating_tools/element_hover';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import { useParams } from 'react-router-dom';
import { LocalStorageManager } from 'utils/localstorage';
import { setSelectedComponent, setSelectedView } from '../store/actions/studio';
import { useCheckShowGrid } from 'modules/designer/hooks/useCheckIsGridColumn';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import { useHandleClickDesignerAction } from 'web_ui/designer_actions';
import { deleteComponent } from '../store/actions/root';
import { ConfirmationInfo } from '../components/viewport';
import { t } from 'i18next';
import { useDeleteGridColumn } from 'modules/designer/hooks/useDeleteGridColumn';
import { IS_LOCALHOST } from '../../../../constants';

export type ComponentProps = {
  uuid: ComponentUUID;
  type: string;
  data: any;
  styles: any;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  column?: number;
  custom_uuid: string;
  new_custom_uuid: string;
  section?: string;
};

export function ComponentWrapper(Element: ComponentType<any>) {
  const Component = (props: ComponentProps) => {
    const dispatch = useDispatch();
    // This is a work around to update the GridBox.
    // If links changes (e.g. by adding, deleting or moving components) then update GridBox.
    const session = useContext(SessionContext);
    const variables = useSelector((state: InterfaceStudioState) => state.variables);
    const objects = useSelector((state: InterfaceStudioState) => state.objects);
    const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
    const editMode = useSelector((state: InterfaceStudioState) => state.studio.editMode);
    const [componentsLinks, setComponentsLinks] = useState<LinksState | undefined>(undefined);
    const links = useSelector((state: InterfaceStudioState) => state.links);
    const views = useSelector((state: InterfaceStudioState) => state.views);
    const components = useSelector((state: InterfaceStudioState) => state.components);
    const { module_id, app_id } = useParams();
    const checkShowGrid = useCheckShowGrid();
    const isGridColumn = checkShowGrid(props.uuid);
    const handleUpdateGridColumnsOnDelete = useDeleteGridColumn();

    const handleClickAction = useHandleClickDesignerAction(dispatch, props.uuid);

    const settingTheChooseComponent = () => {
      const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

      if (copying[module_id!] && copying[module_id!].interface.lastSelectedComponent) {
        return copying[module_id!].interface.lastSelectedComponent;
      } else {
        return '';
      }
    };
    const [selectedComponentId, setSelectedComponentId] = useState<string | null>(
      settingTheChooseComponent()
    );
    const { view_id, hoveredComponent } = useSelector(
      (state: InterfaceStudioState) => state.studio
    );
    const selectedComponent = useSelector(
      (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
    );
    // Fetch the Id and Type of the component being dragged
    const draggedComponent = useSelector(
      (state: InterfaceStudioState) => state.studio.draggedComponent
    );

    // Used for when a component is being dragged and is hovering this current element.
    const [isBeingHoveredWhenDragged, setIsBeingHoveredWhenDragged] = useState(false);

    const [elementNode, setElementNode] = useState<React.RefObject<HTMLElement>>();

    useEffect(() => {
      const copy = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
      if (copy[module_id!] && copy[module_id!].interface.lastSelectedComponent) {
        // verify if the component really exist
        if (!Object.keys(components).includes(copy[module_id!].interface.lastSelectedComponent))
          return;

        if (editMode) {
          dispatch(setSelectedComponent(copy[module_id!].interface.lastSelectedComponent));
          return;
        }

        dispatch(setSelectedView(view_id));
      }
    }, []);

    useEffect(() => {
      setSelectedComponentId(selectedComponent);
    }, [selectedComponent]);

    useEffect(() => {
      setComponentsLinks(links);
    }, [links]);

    const element = React.useMemo(
      () => (
        <ElementWrapper
          width={elementNode?.current?.clientWidth}
          height={elementNode?.current?.clientHeight}
          offsetLeft={elementNode?.current?.offsetLeft}
          offsetTop={elementNode?.current?.offsetTop}
          setIsBeingHoveredWhenDragged={setIsBeingHoveredWhenDragged}
          setElementNode={setElementNode}
          Element={Element}
          section={props.section}
          elementProps={props}
        />
      ),
      [props]
    );

    const showComponentHover = useCallback((): boolean => {
      if (draggedComponent != null) return false;
      if (selectedComponent === props.uuid) return false;
      if (hoveredComponent !== props.uuid) return false;
      // if (props.type === COMPONENT_TYPES.CUSTOM) return false;
      // if (props.custom_uuid && designerEditor !== 'CUSTOM_COMPONENT') return false;
      return true;
    }, [draggedComponent, hoveredComponent, props.uuid, selectedComponent]);

    /**
     * Show selected component border styles when the selected component is a custom component.
     */
    const showSelectedCustomComponent = useCallback((): boolean => {
      if (!elementNode || !elementNode.current) return false;
      if (selectedComponent !== props.uuid) return false;
      if (props.type !== COMPONENT_TYPES.CUSTOM) return false;
      return true;
    }, [elementNode, props.type, props.uuid, selectedComponent]);

    const waitForResult = useCallback(
      (event: MessageEvent<Message>) => {
        if (event.origin !== window.location.origin) return;
        if (event.data.type === MessageTypes.CONFIRMATION_RESULT) {
          if (event.data.content.result === true && event.data.content.componentId === props.uuid) {
            dispatch(setSelectedComponent(null));
            let isParentEmpty = undefined;
            if (
              props.parentUUID &&
              components[props.parentUUID] &&
              links[props.parentUUID]?.length <= 1 &&
              components[props.parentUUID].data?.autoGenerated
            ) {
              isParentEmpty = true;
            }

            dispatch(
              deleteComponent(
                props.uuid,
                isParentEmpty ? props.parentUUID : undefined,
                undefined,
                isParentEmpty
              )
            );
            handleUpdateGridColumns(props.uuid);
          }
          return;
        }
      },
      [dispatch, links, components, props.parentUUID, props.uuid]
    );

    useEffect(() => {
      window.addEventListener('message', waitForResult);
      return () => {
        window.removeEventListener('message', waitForResult);
      };
    }, [waitForResult]);

    const requestConfirmation = useCallback(
      (componentId, componentType) => {
        if (editor === 'CUSTOM_COMPONENT') {
          dispatch(deleteComponent(componentId));
        } else {
          const content: ConfirmationInfo = {
            message: `${t('deleteSelectedComponent')} (${isGridColumn ? 'COLUMN' : componentType})`,
            confirmationLabel: t('Confirm'),
            cancelLabel: t('Cancel'),
            componentId: componentId
          };
          const navigateMessage: Message = {
            type: MessageTypes.CONFIRMATION,
            content: content
          };
          window.parent.postMessage(navigateMessage);
        }
      },
      [dispatch, editor, isGridColumn]
    );

    const isActionAllowed = (action: any) => {
      const allowedComponents: string[] = action.allowedComponents;
      const allowedTypes: string[] = action.allowedTypes;
      if (props.type === 'CUSTOM') {
        if (allowedComponents.includes('CUSTOM')) return true;
      } else {
        if (allowedComponents.includes('DEFAULT') && (!allowedTypes || allowedTypes.length === 0))
          return true;

        if (allowedTypes.includes(props.type)) return true;
      }
      return false;
    };

    // Simple filter per component type
    const isActionAllowedforComponentType = (action: any, componentType: string) => {
      const allowedComponents: string[] = action.allowedComponents;
      const filterComponentTypes = allowedComponents.filter(
        (componentName) => !['DEFAULT', 'CUSTOM'].includes(componentName)
      );

      if (filterComponentTypes.length === 0) return true;
      else if (filterComponentTypes.includes(componentType)) return true;
      else return false;
    };

    const handleUpdateGridColumns = (deletedComponentUUID: string) => {
      handleUpdateGridColumnsOnDelete(deletedComponentUUID);
    };

    const checkIfIsRootOnBlocksEditor = useMemo(() => {
      if (editor === Editor.CUSTOM_COMPONENT) {
        if (props.viewUUID && links[props.viewUUID]?.includes(props.uuid)) {
          return false;
        }
      }
      return true;
    }, [editor, links, props]);

    return (
      <>
        {((view_id &&
          draggedComponent != null &&
          isBeingHoveredWhenDragged &&
          // Nothing happens when hovering the same component with the same Id
          draggedComponent.componentId !== props.uuid &&
          elementNode &&
          elementNode.current) ||
          (selectedComponentId === props.uuid &&
            // If a component is being dragged then temporarily hide the component selection
            draggedComponent == null &&
            elementNode &&
            elementNode.current)) &&
          (IS_LOCALHOST ? editMode : true) &&
          props.type !== COMPONENT_TYPES.CUSTOM && (
            <FloatingTools
              componentUUID={props.uuid}
              clientWidth={elementNode.current.clientWidth}
              clientHeight={elementNode.current.clientHeight}
              x={elementNode.current.offsetLeft}
              y={elementNode.current.offsetTop}
              scrollHeight={elementNode.current.scrollHeight}
              scrollWidth={elementNode.current.scrollWidth}
              dashed={
                props.type === COMPONENT_TYPES.ROW ||
                props.type === COMPONENT_TYPES.COLUMN ||
                props.type === COMPONENT_TYPES.CONTAINER
              }
              componentType={props.type}
              sourceComponentType={draggedComponent?.componentType}
              showDroppableArea={draggedComponent != null}
              offsetHeight={elementNode.current.offsetHeight}
              offsetWidth={elementNode.current.offsetWidth}
            />
          )}

        {showComponentHover() && elementNode?.current && (IS_LOCALHOST ? editMode : true) && (
          <ElementHover
            width={elementNode.current.clientWidth}
            height={elementNode.current.clientHeight}
            offsetLeft={elementNode.current.offsetLeft}
            offsetTop={elementNode.current.offsetTop}
            componentType={isGridColumn ? 'COLUMN' : props.type}
            offsetHeight={elementNode.current.offsetHeight}
            offsetWidth={elementNode.current.offsetWidth}
            selected={false}
          />
        )}
        {showSelectedCustomComponent() && elementNode?.current && editMode && (
          <ElementHover
            width={elementNode.current.clientWidth}
            height={elementNode.current.clientHeight}
            offsetLeft={elementNode.current.offsetLeft}
            offsetTop={elementNode.current.offsetTop}
            componentType={props.type}
            offsetHeight={elementNode.current.offsetHeight}
            offsetWidth={elementNode.current.offsetWidth}
            selected={true}
          />
        )}
        {view_id &&
          editMode &&
          session.preferences[Preferences.SHOW_GRIDS] &&
          elementNode &&
          elementNode.current &&
          views[props.viewUUID] &&
          (isGridColumn || props.type === COMPONENT_TYPES.CONTAINER) &&
          /* This is a work around to update GridBox after deleting, moving, adding
                                                     a component. */
          componentsLinks !== undefined &&
          /* If any component is being dragged component Selection is hidden.
                                                  When that happens component Grids should not be hidden for the
                                                  selected component. */
          (selectedComponent !== props.uuid || draggedComponent != null) &&
          // Temporarily remove Grids while component is being hovered.
          !isBeingHoveredWhenDragged && (
            <GridBox
              width={elementNode.current.clientWidth}
              height={elementNode.current.clientHeight}
              x={elementNode.current.offsetLeft}
              y={elementNode.current.offsetTop}
            />
          )}
        {/* {view_id && elementNode && elementNode.current && hoveredComponent === props.uuid && (
          <PreSelectBox
            width={elementNode.current.clientWidth}
            height={elementNode.current.clientHeight}
            x={elementNode.current.offsetLeft}
            y={elementNode.current.offsetTop}
            componentUUID={props.uuid}
          />
        )} */}
        {element}
        {editMode && checkIfIsRootOnBlocksEditor && (
          <div key="context-menu-wrapper" style={{ zIndex: 9999 }}>
            <ContextMenu elementRef={elementNode} fromIframe={true}>
              {Object.values(
                props.type === COMPONENT_TYPES.FORM ? FORM_TOOLBAR_ACTIONS : TOOLBAR_ACTIONS
              ).map((action, index) => {
                if (!isActionAllowed(action)) return null;
                if (!isActionAllowedforComponentType(action, props.type)) return null;
                if (action.event === 'click' && action.action === 'DELETE_COMPONENT') {
                  return (
                    <ContextMenuItem
                      key={index}
                      icon={action.icon}
                      label={action.label}
                      onClick={() => requestConfirmation(props.uuid, props.type)}
                    >
                      {action.subMenu &&
                        Object.values(action.subMenu).map((subAction: any, subIndex) => (
                          <ContextMenuItem
                            key={`${index}-${subIndex}`}
                            icon={subAction.icon}
                            label={subAction.label}
                            onClick={() =>
                              handleClickAction(
                                subAction.action,
                                props.uuid,
                                props.viewUUID,
                                props.parentUUID || null,
                                links,
                                components,
                                variables,
                                objects,
                                editor
                              )
                            }
                          />
                        ))}
                    </ContextMenuItem>
                  );
                } else {
                  return (
                    <ContextMenuItem
                      key={index}
                      icon={action.icon}
                      label={action.label}
                      onClick={() =>
                        handleClickAction(
                          action.action,
                          props.uuid,
                          props.viewUUID,
                          props.parentUUID || null,
                          links,
                          components,
                          variables,
                          objects,
                          editor
                        )
                      }
                    >
                      {action.subMenu &&
                        Object.values(action.subMenu).map((subAction: any, subIndex) => (
                          <ContextMenuItem
                            key={`${index}-${subIndex}`}
                            icon={subAction.icon}
                            label={subAction.label}
                            onClick={() =>
                              handleClickAction(
                                subAction.action,
                                props.uuid,
                                props.viewUUID,
                                props.parentUUID || null,
                                links,
                                components,
                                variables,
                                objects,
                                editor
                              )
                            }
                          />
                        ))}
                    </ContextMenuItem>
                  );
                }
              })}
            </ContextMenu>
          </div>
        )}
        <ContextMenu elementRef={elementNode}></ContextMenu>
      </>
    );
  };

  return Component;
}
