import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, Nav, Tab } from 'react-bootstrap';
import { ApiAppInfo, AppFullInfo } from 'modules/project/types';
import { AppProperties as AppPropertiesType } from 'modules/designer/types';
import { ProjectsService } from 'modules/project/services';
import { AppPropertiesService } from 'modules/designer/services';
import Confirmation from 'web_ui/confirmation';
import InformationApp from './components/information_app';
import PropertiesApp from './components/properties_app';
import TechStackApp from './components/tech_stack_app';
import styles from './style.module.css';
import IntegrationsApp from './components/integrations_app';
import { IntegrationsService } from 'modules/integrations/services';
import { ModuleSqlOptions } from 'modules/dashboard/types';
import { validateDescriptionInputs } from 'utils/inputValidation';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { CustomApp, DataSource } from 'modules/project/enum';
import useSession from '../../../../hooks/useSession';
import { mixpanel } from 'exocode';

export type AppSettingsModalProps = {
  appinfo: ApiAppInfo;
  showModal: boolean;
  updateAppInfo: (updatedAppinfo: ApiAppInfo) => void;
  onCloseRequest: () => void;
  onAfterDeleteApp: () => void;
  usingAuth?: boolean;
  reload?: React.Dispatch<React.SetStateAction<boolean>>;
};

function AppSettingsModal({
  appinfo,
  showModal,
  updateAppInfo,
  onCloseRequest,
  onAfterDeleteApp,
  usingAuth,
  reload
}: AppSettingsModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [appInfo, setAppInfo] = useState<ApiAppInfo>(appinfo);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [domain, setDomain] = useState('');
  const [sql, setSql] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [invalid, setInvalidated] = useState(false);
  const [formValidation, setFormValidation] = useState(true);
  const [properties, setProperties] = React.useState<AppPropertiesType[]>([]);
  const [gitRepository, setGitRepository] = useState('');
  const [createPR, setCreatePR] = useState(false);
  const [autoMerge, setAutoMerge] = useState(false);
  const [defaultLayout, setDefaultLayout] = useState('');
  const [urlIcon, setUrlIcon] = useState<string>('');
  const [newIcon, setNewIcon] = useState<File>();
  const formRef = useRef<HTMLFormElement>(null);
  const [descriptionInputErrorMessage, setDescriptionInputErrorMessage] = useState<string>('');
  const [session] = useSession();

  // ? Remove this eventually, the validation should be performed in the change function.
  useEffect(() => {
    setFormValidation(formRef.current !== null && formRef.current.checkValidity());
  }, [name, domain, properties.length, gitRepository, createPR, autoMerge]);

  // const getProperties = React.useCallback(async () => {
  //   if (isLoading) {
  //     return;
  //   }

  //   setIsLoading(true);
  //   try {
  //     if (appInfo !== undefined && appInfo.id !== undefined) {
  //       const propertiesResponse = await AppPropertiesService.getProperties(appInfo.id);
  //       setProperties(propertiesResponse);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }, [appInfo, isLoading]);

  const domainValidation = useCallback((strDomain: string) => {
    const pattern = /^[a-z._0-9]+\.[a-z_0-9]+$/;
    if (pattern.test(strDomain)) {
      setInvalidated(false);
    } else {
      setInvalidated(true);
    }
  }, []);

  useEffect(() => {
    setAppInfo(appinfo);
    if (!showModal) return;
    setInvalidated(false);
    setDescriptionInputErrorMessage('');
    setName(appinfo.name ?? '');
    setDescription(appinfo.description ?? '');
    setDomain(appinfo.domain ?? '');
    setGitRepository(appinfo.gitRepository ?? '');
    setCreatePR(appinfo.createPR ?? false);
    setAutoMerge(appinfo.autoMerge ?? false);
    setSql(appinfo.isSql ?? false);
    setDefaultLayout(appinfo.frontend.defaultLayout ?? '');
    setUrlIcon(appinfo.icon ?? '');
  }, [appinfo, showModal]);

  /**
   * Extracts the appId and omits certain properties from ApiAppInfo
   * that are not needed for updating the Application in the database.
   */
  const buildQuery = (
    appInfo: ApiAppInfo,
    newName: string,
    newDescription: string,
    newDomain: string,
    newSql: boolean,
    defaultLayout: string
  ): [string, AppFullInfo] => {
    const appFullInfoQuery: AppFullInfo = {
      name: newName,
      description: newDescription,
      domain: newDomain,
      isSql: newSql,
      mainLanguage: appInfo.mainLanguage,
      type: appInfo.type,
      template: appInfo.template,
      architecture: appInfo.architecture,
      backend: appInfo.backend,
      frontend: { ...appInfo.frontend, defaultLayout: defaultLayout },
      data_sources: appInfo.data_sources,
      has_authentication: appInfo.has_authentication,
      has_assets: appinfo.has_assets,
      has_frontend: appInfo.has_frontend,
      has_backend: appInfo.has_backend,
      has_database: appInfo.has_database
    };
    const appId = appInfo.id;
    return [appId, appFullInfoQuery];
  };

  const updatingUrlIcon = async (data: ApiAppInfo) => {
    try {
      if ((newIcon === null || newIcon === undefined) && urlIcon.length === 0) {
        if (!data.icon) return; // doesnt have nothing to delete
        await ProjectsService.deleteFavIcon(data.id);
      } else if (
        (newIcon !== null && urlIcon.length > 0) ||
        (newIcon !== null && urlIcon.length === 0)
      ) {
        if (!newIcon) return;
        const formData = new FormData();

        formData.append('file', newIcon);
        await ProjectsService.createFavIcon(formData, data.id);
        formData.delete('file');
      }
    } catch (e) {
      throw new Error('Impossible to update or delete your ico');
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isLoading) {
      return;
    }
    if (validateInputs()) {
      setIsLoading(false);
    }
    try {
      setIsLoading(true);
      if (appInfo) {
        const [appId, appFullInfoQuery] = buildQuery(
          appInfo,
          name,
          description,
          domain,
          sql,
          defaultLayout
        );
        const response = await ProjectsService.updateProjectById(appId, appFullInfoQuery);
        updatingUrlIcon(response);
        await AppPropertiesService.updateProperties(appId, properties);
        updateAppInfo(response);
        if (gitRepository) {
          await IntegrationsService.attatchRepositoryToApp(
            appId,
            gitRepository,
            createPR,
            autoMerge
          );
        } else {
          await IntegrationsService.detatchRepositoryFromApp(appId);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (reload) reload((val) => !val);
      onCloseRequest();
      setIsLoading(false);
    }
  };

  const deleteApp = async () => {
    mixpanel.track('project_deleted', {
      buttonName: t('Delete')
    });
    if (isLoading) {
      return;
    }
    if (appInfo) {
      try {
        setIsLoading(true);
        await ProjectsService.deleteProjectById(appInfo.id);
      } catch (error) {
        console.error(error);
      } finally {
        setShowConfirmationDialog(false);
        await session.reloadUser();
        onAfterDeleteApp();
        setIsLoading(false);
      }
    }
  };

  const onChangeModuleSql = (value: ModuleSqlOptions) => {
    if (value === ModuleSqlOptions.JPA) {
      setSql(false);
    } else if (value === ModuleSqlOptions.JDBC) {
      setSql(true);
    }
  };

  const onChangeDataSourceType = (value: DataSource) => {
    setAppInfo((prevAppInfo) => {
      const existingOptions =
        prevAppInfo.data_sources.length > 0 ? prevAppInfo.data_sources[0].options : {};
      return {
        ...prevAppInfo,
        data_sources: [{ type: value, options: existingOptions }]
      };
    });
  };

  const checkDescriptionIsValid = (input: string) => {
    const { code, valid } = validateDescriptionInputs(input, 255);
    // ? Remove formValidation eventually.
    setFormValidation(valid);
    let errorMessage = '';
    if (code === 'EXCEEDS_MAX_LENGTH') {
      errorMessage = 'inputValidationErrorMessages.ProjectDescriptionExceedsMaxLength';
    }
    setDescriptionInputErrorMessage(errorMessage);
    return valid;
  };

  /**
   * Validate every input that needs validation.
   * Returns false if one them isn't valid.
   */
  const validateInputs = (): boolean => {
    const isValidDescription = checkDescriptionIsValid(description);
    return isValidDescription;
  };

  const handleDescriptionChange = (description: string) => {
    checkDescriptionIsValid(description);
    setDescription(description);
  };

  const onChangeModule = (toggleModule: CustomApp) => {
    ProjectsService.updateProjectById(appInfo.id, { [toggleModule]: true });
    const nextApp = {
      ...appInfo,
      [toggleModule]: true
    };
    setAppInfo(nextApp);
  };

  return (
    <>
      <Modal
        size="lg"
        centered
        show={showModal}
        onHide={() => {
          onCloseRequest();
          if (appInfo) {
            setName(appInfo.name);
            setDescription(appInfo.description);
            setDomain(appinfo.domain);
            setSql(appinfo.isSql);
          }
        }}
        scrollable
      >
        <Form ref={formRef} onSubmit={onSubmit} className={styles.appSettingsDialog}>
          <Modal.Header closeButton>
            <Modal.Title>{t('appResume.manage.Settings')}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'auto' }}>
            <Tab.Container id="tabs-application-settings" defaultActiveKey={'informations'}>
              <Nav className={`nav nav-tabs`}>
                <Nav.Item>
                  <Nav.Link eventKey={'informations'}>
                    {t('appResume.new_module.informations')}
                  </Nav.Link>
                </Nav.Item>
                <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.GITHUB_SYNC]}>
                  <Nav.Item>
                    <Nav.Link eventKey={'integration'}>{t('appResume.Integrations')}</Nav.Link>
                  </Nav.Item>
                </Authorization>
                <Nav.Item>
                  <Nav.Link eventKey={'properties'}>{t('appResume.Properties')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={'techStack'}>{t('appResume.TechStack')}</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={'informations'}>
                  <InformationApp
                    name={name}
                    setName={setName}
                    description={description}
                    handleDescriptionChange={handleDescriptionChange}
                    domain={domain}
                    setDomain={setDomain}
                    domainValidation={domainValidation}
                    invalid={invalid}
                    setInvalidated={setInvalidated}
                    setShowConfirmationDialog={(show: boolean) => setShowConfirmationDialog(show)}
                    usingAuth={usingAuth}
                    appId={appinfo.id}
                    urlIcon={urlIcon}
                    setUrlIcon={setUrlIcon}
                    newIcon={newIcon}
                    setNewIcon={setNewIcon}
                    descriptionInputErrorMessage={descriptionInputErrorMessage}
                  />
                </Tab.Pane>
                <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.GITHUB_SYNC]}>
                  <Tab.Pane eventKey={'integration'}>
                    {appInfo && appInfo.id && (
                      <IntegrationsApp
                        appId={appInfo.id}
                        gitRepository={gitRepository}
                        setGitRepository={setGitRepository}
                        createPR={createPR}
                        setCreatePR={setCreatePR}
                        autoMerge={autoMerge}
                        setAutoMergeR={setAutoMerge}
                      />
                    )}
                  </Tab.Pane>
                </Authorization>
                <Tab.Pane eventKey={'properties'}>
                  <PropertiesApp
                    properties={properties}
                    setProperties={setProperties}
                    isLoading={isLoading}
                    nameApp={name}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey={'techStack'}>
                  <TechStackApp
                    appInfo={appInfo}
                    sql={sql}
                    defaultLayout={defaultLayout}
                    onChangeModuleSql={onChangeModuleSql}
                    onChangeDefaultLayout={setDefaultLayout}
                    onChangeDataSourceType={onChangeDataSourceType}
                    onChangeModule={onChangeModule}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.dialogSaveButton}>
              <Button id={'saveButton'} type="submit" disabled={!formValidation}>
                {t('Save')}
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={t('appResume.ConfirmDelete')}
        showItemNameInput={true}
        itemName={appInfo?.name}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => deleteApp()}
        onClose={() => {
          setShowConfirmationDialog(false);
          onCloseRequest();
        }}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
        isLoading={isLoading}
      />
    </>
  );
}

export default AppSettingsModal;
