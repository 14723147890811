import React, { useEffect, useState } from 'react';
import { Sidebar } from '../../../web_ui/function_editor/components/sidebar';
import ActionsToolbar from '../../../web_ui/function_editor/components/actions_toolbar';
import { Editor } from '../../../web_ui/function_editor/components/editor';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES
} from './function_actions/types/actions';
import { FRONTEND_ACTIONS_MANIFEST } from './function_actions';
import { FRONTEND_VARIABLE_TYPES } from '../types';
import EditActionToolbar from '../../../web_ui/function_editor/components/edit_action_toolbar';
import { SidebarPosition } from '../../../web_ui/workboard/sidebar';
import { useParams } from 'react-router-dom';
import { ProjectsService } from 'modules/project/services';

function FrontendFunctionEditor() {
  const { app_id } = useParams();
  const [hasAuth, setHasAuth] = useState<boolean>(false);

  const getCurrentApp = async () => {
    if (!app_id) return;
    const currApp = await ProjectsService.getProjectById(app_id);
    setHasAuth(currApp.has_authentication);
  };

  useEffect(() => {
    getCurrentApp();
  }, [app_id]);

  const filteringManifest = () => {
    if (hasAuth) {
      return Object.values(FRONTEND_ACTIONS_MANIFEST);
    } else {
      const cpy = Object.values(FRONTEND_ACTIONS_MANIFEST);
      const id = cpy.findIndex((item) => item.type == 'SESSION_INFO');
      cpy.splice(id, 1);
      return cpy;
    }
  };

  return (
    <div
      className={'d-flex bg-body-tertiary'}
      style={{ overflow: 'hidden', marginRight: '0.5rem' }}
    >
      <Sidebar.Root>
        <Sidebar.Content>
          <ActionsToolbar
            actions={filteringManifest()}
            categories={Object.values(FRONTEND_ACTIONS_CATEGORIES)}
            categoriesIcons={FRONTEND_ACTION_CATEGORY_ICONS}
          />
        </Sidebar.Content>
      </Sidebar.Root>
      <Editor.Root>
        <Editor.Header types={Object.values(FRONTEND_VARIABLE_TYPES)} framework={'react'} />
        <Editor.Body manifests={FRONTEND_ACTIONS_MANIFEST} />
      </Editor.Root>
      <Sidebar.Root position={SidebarPosition.RIGHT}>
        <EditActionToolbar manifests={FRONTEND_ACTIONS_MANIFEST} framework={'react'} />
      </Sidebar.Root>
    </div>
  );
}

export default FrontendFunctionEditor;
