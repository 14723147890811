import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

const FontStyleOptions = [
  { value: '', label: 'Default', icon: 'fa-solid fa-xmark' },
  { value: 'italic', label: 'Italic', icon: 'fa-solid fa-italic' }
] as const;

function FontStyleControl(props: ControlProps) {
  const { t } = useTranslation();
  const currentValue = props.value;

  function handleChange(value: string) {
    props.onChange?.(value, props.id);
  }

  const isSelected = (value: string): boolean => {
    return currentValue === value;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      {props.tooltip && (
        <div>
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: `${t(props.tooltip)}}`
            }}
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex'
        }}
      >
        <div className={`form-control form-control-sm`}>
          {FontStyleOptions.map((option) => (
            <button
              className={` ${styles.buttonContent}  btn btn-secondary ${
                isSelected(option.value) ? 'active' : ''
              }`}
              onClick={() => handleChange(option.value)}
              key={option.label}
            >
              <i className={option.icon}></i>
            </button>
          ))}
        </div>
        {props.options?.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </div>
    </div>
  );
}

export default FontStyleControl;
