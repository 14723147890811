import React from 'react';
import Dropdown from 'web_ui/function_editor/action_inputs/components/dropdown';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FunctionParameterType } from 'web_ui/function_editor/store/types/parameters';
import { t } from 'i18next';
import { EndpointEditorState } from '../../../../../../routes/studio/endpoint_editor/reducers';

type ParameterPickerProps = {
  label: string;
  actionUuid: string;
  value: string;
  handleChange: (text: string) => void;
  filterByList: boolean;
  filterByDataType?: (
    state: FunctionEditorState,
    actionUuid: string,
    dataType: string,
    objectUuid?: string
  ) => boolean;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
};

export function ParameterPicker({
  label,
  actionUuid,
  value,
  handleChange,
  filterByList,
  filterByDataType,
  isFromSideBar,
  isFromArgument
}: ParameterPickerProps) {
  const state = useSelector((state: FunctionEditorState | EndpointEditorState) => state);

  const callerId = state.editor.callerId;
  const mode = state.editor.mode;

  // When in the Endpoint editor: the endpoint information is stored in the callerInfo,
  //   not in the state.functions (because an endopoint is not a function).
  //   So we get these parameters in a different way.
  let filtered: FunctionParameterType[] = [];
  if (mode === 'ENDPOINT_EDITOR' && 'endpointParameters' in state) {
    state.endpointParameters.forEach((e) => filtered.push(e as FunctionParameterType));
  } else {
    state.functions[callerId]?.parameters.forEach((e) => {
      const parameterInfo = state.parameters[e];
      if (parameterInfo) filtered.push(parameterInfo);
    });
  }

  if (filterByList) {
    filtered = filtered.filter((p) => p.list);
  }
  if (filterByDataType) {
    filtered = filtered.filter((p) => {
      return filterByDataType(state, actionUuid, p.type, p?.objectUuid);
    });
  }

  function getSelectedValue(): FunctionParameterType | undefined {
    return filtered.find((p) => p.uuid === value);
  }

  return (
    <Dropdown
      items={filtered}
      placeholder={`${t('SelectAParameter')}`}
      label={label}
      value={getSelectedValue()}
      handleChange={handleChange}
      isFromArgument={isFromArgument}
      isFromSideBar={isFromSideBar}
    />
  );
}
