import { CssNode, generate, parse, walk } from 'css-tree';

// Transforms min-height -> minHeight.
const hyphensToCamelCase = (css: string): string => {
  return css.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

const getImportantLabel = (important?: boolean | string): string => {
  return important ? ' !important' : '';
};

/**
 * Get css declarations and values (with added indentation).
 */
export const getCssDeclarations = (css: string, withIndentation = false): string => {
  let declarationValues = '';
  const ast = parse(css);
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      if (withIndentation) {
        declarationValues += '  ';
      }
      declarationValues +=
        node.property + ': ' + generate(node.value) + getImportantLabel(node.important) + ';\n';
    }
  });
  return declarationValues;
};

export const getPlainCssDeclarations = (css: string, forceImportant?: boolean): string => {
  let declarationValues = '';
  const ast = parse(css);
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      const isImportant = forceImportant || node.important;
      declarationValues +=
        node.property + ':' + generate(node.value) + getImportantLabel(isImportant) + ';';
    }
  });
  return declarationValues;
};

export type CssSelector = {
  type: string;
  name: string;
};

export type CssSelectorInfo = {
  cssSelector: CssSelector[];
  firstSection: string;
  secondSection: string;
};

/**
 * Validates:
 * 1. That there is only one selector.
 * 2. That the first selector is a class selector.
 */
export const validateCssValue = (css: string): string => {
  const selectorList: CssSelectorInfo[] = extractSelectors(css);

  if (selectorList.length !== 1) {
    // Make sure there is only one selector.
    return 'designer.cssClass.OnlyOneSelectorErrorMessage';
  } else if (selectorList[0].cssSelector.length >= 1) {
    // Make sure the first thing is a class selector.
    if (selectorList[0].cssSelector[0].type !== 'ClassSelector') {
      return 'designer.cssClass.OnlyClassSelectorErrorMessage';
    }
    // Should we validate what comes after the class selector?
  }

  return '';
};

/**
 * Removes the first thing it encounters in the selector list.
 * And put in the 'firstSection'.
 * Then, get the remaining string after the removal, and put in the 'secondSection'.
 *
 * Make sure the 'cssSelectorNode' parameter is a SelectorList node type.
 *
 * E.g.: .class1 > h2 h4 {}
 *       returns { firstSection: '.class1', secondSection: '>h2 h4' }
 *
 *       .class1 {}
 *       returns { firstSection: '.class1', secondSection: '' }
 */
const getSelectors = (
  cssSelectorNode: CssNode
): {
  firstSection: string;
  secondSection: string;
} => {
  let firstSection = '';
  let secondSection = '';
  let removed = false;

  walk(cssSelectorNode, (node, item, list) => {
    if (removed) return;
    if (!list) return;

    if (node.type !== 'Selector') {
      if (list.size < 2) {
        firstSection = generate(node);
        secondSection = '';
        removed = true;
      } else if (list.first === node) {
        firstSection = generate(node);
        list.remove(item);
        secondSection = generate(cssSelectorNode);
        list.unshift(node);
        removed = true;
      }
    }
  });

  return { firstSection, secondSection };
};

/**
 * Get the list of selectors in the css string.
 * For each css block we create a new list.
 *
 * @param cssString The complete css string.
 *
 * E.g.: .Example {
 *       returns ['.Example']
 *
 * E.g.: .class1 h1 {}
 *       .class2, .class3 {}
 *       returns [['.class1', 'h1'], ['.class2'], ['.class3']]
 */
export const extractSelectors = (cssString: string): CssSelectorInfo[] => {
  const selectors: CssSelectorInfo[] = [];

  const ast = parse(cssString);

  walk(ast, (node) => {
    if (node.type === 'SelectorList') {
      node.children.forEach((selectorNode) => {
        const { firstSection, secondSection } = getSelectors(selectorNode);
        const selectorDetails: CssSelectorInfo = {
          cssSelector: [],
          firstSection,
          secondSection
        };
        walk(selectorNode, (partNode) => {
          switch (partNode.type) {
            case 'TypeSelector':
              selectorDetails.cssSelector.push({
                type: 'TypeSelector',
                name: partNode.name
              });
              break;
            case 'ClassSelector':
              selectorDetails.cssSelector.push({
                type: 'ClassSelector',
                name: partNode.name
              });
              break;
            case 'IdSelector':
              selectorDetails.cssSelector.push({
                type: 'IdSelector',
                name: partNode.name
              });
              break;
            case 'AttributeSelector':
              selectorDetails.cssSelector.push({
                type: 'AttributeSelector',
                name: `[${generate(partNode)}]`
              });
              break;
            case 'PseudoClassSelector':
              selectorDetails.cssSelector.push({
                type: 'PseudoClassSelector',
                name: `:${partNode.name}`
              });
              break;
            case 'PseudoElementSelector':
              selectorDetails.cssSelector.push({
                type: 'PseudoElementSelector',
                name: `::${partNode.name}`
              });
              break;
          }
        });

        selectors.push(selectorDetails);
      });
    }
  });

  return selectors;
};

export const parseCustomCss = (css: string | undefined): Record<string, string> => {
  if (!css) return {};
  const customCss: Record<string, string> = {};
  // Record<'css declaration', 'css value'>
  // E.g.: { color: 'red' }. This is returned by this function and injected directly
  // in the styles attribute.
  // Wrap css with '.cssSelector' so that it is a valid css string and can be parsed
  // correctly.
  const ast = parse('.cssSelector{' + css + '}');
  walk(ast, (node) => {
    if (node.type === 'Declaration') {
      const declaration = hyphensToCamelCase(node.property);
      let value = generate(node.value);
      if (value && node.important) {
        value += ' !important';
      }
      if (value) {
        customCss[declaration] = value;
      }
    }
  });
  return customCss;
};

export const removeDeclarationsWithUndefinedValue = (
  styles: Record<string, string | number | undefined>
) => {
  for (const declaration of Object.keys(styles)) {
    if (styles[declaration] == null) {
      delete styles[declaration];
    }
  }
};
