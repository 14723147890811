import { EndpointsService } from 'modules/logic_builder/services';
import { EndpointExtended, ParameterEndpoint } from 'modules/logic_builder/types';
import { NavigateFunction } from 'react-router-dom';
import { endpointNameRegex, parameterNameRegex } from '../../../utils/regex';
import { v4 } from 'uuid';
import { FunctionsState } from 'web_ui/function_editor/store/types/functions';
import { ParametersState } from 'web_ui/function_editor/store/types/parameters';
import { ActionsState } from 'web_ui/function_editor/store/types/actions';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { EndpointInstanceContext } from 'modules/logic_builder/components/endpoints_list/endpoint_instance';

export const validateParameterNameInput = (name?: string): string => {
  if (name == null) {
    return '';
  }
  if (name.trim().length === 0) {
    return '';
  }
  const valid = parameterNameRegex.test(name);
  if (!valid) {
    return 'inputValidationErrorMessages.EndpointParameterNameInvalid';
  }
  return '';
};

export const validateEndpointNameInput = (name?: string): string => {
  if (name == null) {
    return '';
  }
  const valid = endpointNameRegex.test(name);
  if (!valid) {
    return 'inputValidationErrorMessages.EndpointNameInvalid';
  }
  return '';
};

export const getFunctionState = (stateFunctions: any[]): FunctionsState => {
  const functions: FunctionsState = {};
  stateFunctions.forEach((f) => {
    functions[f.id] = {
      ...f,
      uuid: f.id,
      returnType: {
        type: f.returnType,
        objectUuid: f.returnObjectUuid,
        enumUuid: f.returnEnumUuid,
        list: f.returnList
      }
    };
  });
  return functions;
};

export const getParametersState = (stateParameters: any[]): ParametersState => {
  const parameters = {} as ParametersState;
  stateParameters.forEach((p) => {
    parameters[p.id] = { ...p, uuid: p.id, type: p.dataType, objectUuid: p.object?.id };
  });
  return parameters;
};

export const getActionsState = (stateActions: any[]): ActionsState => {
  const actions = {} as ActionsState;
  stateActions.forEach((a) => {
    actions[a.id] = {
      uuid: a.id,
      type: a.type,
      order: a.order,
      data: a.data,
      returnVariableUuid: a.returnVariableUuid
    };
  });
  return actions;
};

/**
 * Fetch the endpoint actions states, and other useful informaiton.
 */
export const fetchFunctionEditorState = async (moduleId: string, endpointId: string) => {
  const state = await EndpointsService.getInitialEndpointEditorActionsState(moduleId, endpointId);

  const functionsState = getFunctionState(Object.values(state.functions));
  const parametersState = getParametersState(Object.values(state.parameters));
  const actionsState = getActionsState(Object.values(state.actions));

  const newState: FunctionEditorState = {
    ...state,
    actions: actionsState,
    functions: functionsState,
    parameters: parametersState,
    editor: {
      moduleId: moduleId,
      callerId: endpointId,
      selectedAction: '',
      mode: 'LOGIC_BUILDER'
    }
  };

  return newState;
};

/**
 * Fetch endpoint information state.
 */
export const fetchEndpointState = async (endpointId: string): Promise<EndpointExtended> => {
  return await EndpointsService.getEndpoint(endpointId);
};

/**
 * Util function to merge batched payloads into a single payload.
 *
 * @param batchedPayload Array of batched payloads.
 */
export const mergeBatchedPayload = (batchedPayload: any[]) => {
  const mergedPayload: any = {};
  for (const payloadItem of batchedPayload) {
    for (const [key, value] of Object.entries(payloadItem)) {
      mergedPayload[key] = value;
    }
  }
  return mergedPayload;
};

/**
 * Merge batched payloads by id.
 *
 * The result is an array because we can't merge objects with different mergeKey, otherwise
 * use mergeBatchedPayload.
 * Each instance of that array is for a distinct mergeKey.
 */
export const mergeBatchedPayloadById = (batchedPayload: any[], mergeKey: string) => {
  const payloads: Record<string, any> = {};
  for (const payloadItem of batchedPayload) {
    if (!payloads[payloadItem[mergeKey]]) {
      payloads[payloadItem[mergeKey]] = payloadItem;
      continue;
    }
    payloads[payloadItem[mergeKey]] = {
      ...payloads[payloadItem[mergeKey]],
      ...payloadItem
    };
  }
  return Object.values(payloads);
};

/**
 * Send endpoint editor action.
 */
export const sendEndpointEditorAction = async (
  actionType: string,
  payload: any, // The last action payload.
  batchedPayload: any[], // All payloads with the same backend action type that weren't sent.
  moduleId: string,
  endpointId: string
) => {
  let actualPayload = payload;

  if (actionType === 'UPDATE_ENDPOINT_BOOLEAN_PROPERTIES') {
    actualPayload = mergeBatchedPayload(batchedPayload);
  } else if (actionType === 'ADD_ENDPOINT_PARAMETER') {
    actualPayload = batchedPayload;
  } else if (actionType === 'DELETE_ENDPOINT_PARAMETER') {
    actualPayload = batchedPayload;
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_NAME') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_INPUT_NAME') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_TYPE') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_INPUT_TYPE') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_BOOLEAN_PROPERTIES') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  } else if (actionType === 'UPDATE_ENDPOINT_PARAMETER_DATA_TYPE_ITEM') {
    actualPayload = mergeBatchedPayloadById(batchedPayload, 'endpointParameterId');
  }

  await EndpointsService.sendAction(moduleId, endpointId, {
    type: actionType,
    payload: actualPayload
  });
};

export const navigateToEndpointEditor = (
  endpointId: string,
  controllerId: string,
  moduleId: string,
  appId: string,
  navigate: NavigateFunction,
  context: EndpointInstanceContext,
  isFromVsCodeExt: () => boolean
): void => {
  const baseUrl = `/app/${appId}/module/${moduleId}/logic/service/${controllerId}/endpoint-editor/${endpointId}`;
  if (context === 'crud') {
    if (isFromVsCodeExt()) {
      window.open(baseUrl.concat('?vscode=true'), '_blank');
    } else {
      const newWindow = window.open(baseUrl, '_blank');
      //@ts-ignore
      newWindow.fromCrud = true;
    }
  } else {
    navigate(
      `/app/${appId}/module/${moduleId}/logic/service/${controllerId}/endpoint-editor/${endpointId}`
    );
  }
};

export const getNewParameter = (): ParameterEndpoint => {
  return {
    uuid: v4(),
    name: 'parameterName',
    type: 'STRING',
    description: '',
    required: false,
    inputType: 'PATH',
    inputName: 'inputName',
    list: false,
    native: false,
    objectUuid: '',
    order: 0
  };
};
