import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import {
  FRONTEND_ACTION_CATEGORY_ICONS,
  FRONTEND_ACTIONS_CATEGORIES,
  FRONTEND_ACTIONS_TYPES
} from '../types/actions';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import React from 'react';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { FRONTEND_VARIABLE_TYPES } from '../../../types';
import ReturnTypeIcon from './return_type_icon';
import { t } from 'i18next';

function filterByFunctionReturnDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: keyof typeof FRONTEND_VARIABLE_TYPES
): boolean {
  const functionId = state.editor.callerId;
  const mode = state.editor.mode;

  if (mode === 'ENDPOINT_EDITOR') {
    return false;
  } else {
    const func = state.functions[functionId];
    return dataType?.toUpperCase() === func?.returnType?.type?.toUpperCase();
  }
}

export const RETURN_TYPE_MANIFEST: FunctionActionManifest = {
  type: FRONTEND_ACTIONS_TYPES.RETURN_TYPE,
  name: t('Return'),
  description: `${t('ActionReturnVariable')}`,
  color: 'warning',
  category: FRONTEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: FRONTEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <ReturnTypeIcon />,
  inlineInputs: [
    {
      label: t('Return'),
      placeholder: `${t('toReturn')}`,
      key: 'uuid',
      type: ACTION_INPUTS_TYPES.VARIABLES_INPUT,
      options: { filterByDataType: filterByFunctionReturnDataType }
    }
  ],
  inputs: []
};
