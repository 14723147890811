import { combineReducers } from '../../../../packages/redux-utils';
import { applyMiddleware, compose, createStore } from 'redux';
import { rootReducer } from '../../../../web_ui/function_editor/store/reducers/root';
import { initialState } from '../../../../web_ui/function_editor/store/initial_empty_state';
import { saveMiddleware } from './middlewares/save';
import { FunctionEditorReducers } from '../../../logic_builder/function_editor/store';

const appReducers = combineReducers(
  {
    ...FunctionEditorReducers
  },
  rootReducer
);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  appReducers,
  initialState,
  composeEnhancers(applyMiddleware(saveMiddleware))
);
