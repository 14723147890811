import React, { useState } from 'react';
import { ControlProps } from '..';
import { DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import ModalSettings from './modal_settings';

function ActionsButtonControl(props: ControlProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  return (
    <div className={'mb-3 pb-4 pt-1 border-bottom text-body-secondary'}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginBottom: 2
        }}
      >
        <DefaultLabel title={props.label}>{props.label}</DefaultLabel>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`) ?? ''
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <div>
        <Button style={{ width: '100%' }} onClick={() => showModal()}>
          {t('settings.Settings')}
        </Button>
      </div>
      <ModalSettings {...props} show={openModal} onClose={closeModal} />
    </div>
  );
}

export default ActionsButtonControl;
