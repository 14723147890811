import React, { useCallback, useEffect, useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import style from './style.module.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import EmptyMessage from 'web_ui/empty';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { FileMetaData, ModalFile } from './modal';
import { FileInstance } from './file_instance';
import UpgradePlanModal from '../../../../../web_ui/upgrade_plan_modal';

export default function AppAssets() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { app_id } = useParams();
  const { t } = useTranslation();
  const [data, setData] = useState<FileMetaData[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const fetchFiles = useCallback(async () => {
    const response = await fetch(`${API_URL}/apps/${app_id}/assets`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      credentials: 'include',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const currentData: FileMetaData[] = await response.json();
    setData(currentData);
    setLoading(false);
  }, [API_URL, app_id]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return (
    <>
      <ModalFile
        show={openModal}
        onHide={(showUpgradeModal) => {
          setShowUpgradePlanModal(showUpgradeModal);
          setOpenModal(false);
        }}
        updateFiles={fetchFiles}
      />
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
      <div className={style.mainContainer}>
        <div className={style.containerButton}>
          <HelpPopover
            helpBoxProps={{
              title: t('appResume.assets.CreateAsset') ?? 'appResume.assets.CreateAsset'
            }}
            placement="top"
          >
            <Button id={'createButton'} onClick={() => setOpenModal(true)}>
              <Icon iconName="plus"></Icon>
            </Button>
          </HelpPopover>
        </div>
        <div
          className={`border bg-body ${style.containerRow}  ${style.moduleList} ${
            data.length === 0 ? style.alignVertical : ''
          }`}
        >
          {loading === false ? (
            <>
              <ListGroup>
                {data.map((item: FileMetaData, index: number) => {
                  return (
                    <FileInstance
                      key={index}
                      id={item.id}
                      extension={item.extension}
                      title={item.name}
                      updateFiles={fetchFiles}
                    />
                  );
                })}
              </ListGroup>
              {data.length === 0 && (
                <div id="bodyMessage" className="w-100 h-100">
                  <EmptyMessage
                    message={t('appResume.assets.NoAssets')}
                    icon="exclamation"
                    actionMessage={
                      t('appResume.assets.CreateAsset') ?? 'appResume.assets.CreateAsset'
                    }
                    linkAction={() => setOpenModal(true)}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="w-100 h-100">
              <div className={`fa-3x ${style.spinner}`}>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
