import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EndpointEditorState } from '.';
import { fetchEndpointState, fetchFunctionEditorState } from '../actions';
import { ActualEndpointType } from 'modules/logic_builder/types';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';

export type EndpointEditorInitializerPayload = {
  endpointId: string;
  moduleId: string;
};

export const initialState: EndpointEditorState = {
  // Endpoint editor initial states.
  editorStatus: {
    message: null,
    status: 'idle',
    moduleId: '',
    endpointId: '',
    isEndpointEditorOpen: false
  },
  endpoint: {
    controllerUuid: '',
    name: '',
    path: '',
    method: 'GET',
    summary: '',
    uuid: '',
    description: '',
    deprecated: false,
    accessLevel: 'PROTECTED',
    pageable: false,
    sortable: false,
    filterable: false,
    allowAllRoles: false,
    native: false,
    roles: [],
    parameters: [],
    tags: [],
    moduleId: '',
    actions: [],
    creationTime: new Date(),
    modificationTime: new Date(),
    createdByUser: '',
    modifiedByUser: ''
  },
  endpointParameters: [],
  // Function editor (endpoint actions) initial states.
  editor: {
    moduleId: '',
    callerId: '',
    selectedAction: '',
    mode: 'ENDPOINT_EDITOR',
    customCode: false,
    selectedParam: ''
  },
  globals: {},
  functions: {},
  actions: {},
  parameters: {},
  variables: {},
  objects: {},
  objects_items: {},
  enums: {},
  page_params: {},
  callerInfo: {
    uuid: '',
    name: '',
    actions: [],
    creationTime: new Date(),
    modificationTime: new Date(),
    createdByUser: '',
    modifiedByUser: ''
  }
};

/**
 * This fetches everything: endpoint actions states, and endpoint properties states.
 */
export const initializeEndpointEditorState = createAsyncThunk<
  { endpointState: ActualEndpointType; actionsState: FunctionEditorState },
  EndpointEditorInitializerPayload
>('state/initialize', async (payload) => {
  const { endpointId, moduleId } = payload;

  const [endpointStateResponse, endpointActionsStateResponse] = await Promise.all([
    fetchEndpointState(endpointId),
    fetchFunctionEditorState(moduleId, endpointId)
  ]);

  return {
    endpointState: { ...endpointStateResponse, moduleId: payload.moduleId } as ActualEndpointType,
    actionsState: endpointActionsStateResponse
  };
});

/**
 * Used for changing any property in the endpoint state, i.e., setting the initial state.
 */
export const stateReducer = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setInitialEndpointEditorState: (state, action: PayloadAction<EndpointEditorState>) => {
      state.editorStatus = action.payload.editorStatus;
    },
    resetInitialEndpointEditorState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initializeEndpointEditorState.pending, (state) => {
        state.editorStatus.status = 'loading';
        state.editorStatus.message = null;
      })
      .addCase(
        initializeEndpointEditorState.fulfilled,
        (
          state,
          action: PayloadAction<{
            endpointState: ActualEndpointType;
            actionsState: FunctionEditorState;
          }>
        ) => {
          // Endpoint editor.
          state.endpoint = action.payload.endpointState;
          state.editorStatus.status = 'success';
          state.editorStatus.message = null;
          state.editorStatus.moduleId = action.payload.endpointState.moduleId;
          state.editorStatus.endpointId = action.payload.endpointState.uuid;
          state.endpointParameters = action.payload.endpointState.parameters;

          // Caller information.
          action.payload.endpointState.actions.sort((a, b) => {
            const orderA = a.order !== undefined ? a.order : Infinity;
            const orderB = b.order !== undefined ? b.order : Infinity;
            return orderA - orderB;
          });
          state.callerInfo.uuid = action.payload.endpointState.uuid;
          state.callerInfo.name = action.payload.endpointState.name;
          // Contains an array of action ids in the right order.
          state.callerInfo.actions = action.payload.endpointState.actions.map(
            (action) => action.uuid
          );
          state.callerInfo.modificationTime = action.payload.endpointState.modificationTime;
          state.callerInfo.creationTime = action.payload.endpointState.creationTime;
          state.callerInfo.modifiedByUser = action.payload.endpointState.modifiedByUser;
          state.callerInfo.createdByUser = action.payload.endpointState.createdByUser;

          // Actions editor.
          state.parameters = action.payload.actionsState.parameters;
          state.functions = action.payload.actionsState.functions;
          state.actions = action.payload.actionsState.actions;
          state.objects = action.payload.actionsState.objects;
          state.enums = action.payload.actionsState.enums;
          state.objects_items = action.payload.actionsState.objects_items;
          state.variables = action.payload.actionsState.variables;
          state.globals = action.payload.actionsState.globals;
          state.page_params = action.payload.actionsState.page_params;
          state.editor = {
            moduleId: action.payload.endpointState.moduleId,
            callerId: action.payload.endpointState.uuid,
            selectedAction: '',
            mode: 'ENDPOINT_EDITOR',
            customCode: false,
            selectedParam: ''
          };
        }
      )
      .addCase(initializeEndpointEditorState.rejected, (state) => {
        state.editorStatus.status = 'error';
        state.editorStatus.message = 'Failed to load initial endpoint editor state.';
      });
  }
});

export const { setInitialEndpointEditorState, resetInitialEndpointEditorState } =
  stateReducer.actions;
