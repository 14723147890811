import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Service } from 'modules/logic_builder/types';
import { ObjectsService, ServicesService } from 'modules/logic_builder/services';
import { validateName } from '../controller_creator_dialog';
import LogicBuilderContext from 'modules/logic_builder/store';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { Table } from '../../../../modeler/types';

type ServiceEditorDialogProps = {
  serviceUuid: string;
  show: boolean;
  onClose: () => void;
  onCreate?: () => void;
};

const emptyNewService: Service = {
  name: '',
  description: '',
  native: false,
  disabled: false
};

export function ServiceEditorDialog(props: ServiceEditorDialogProps) {
  const [service, setService] = useState<Service>(emptyNewService);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [tables, setTables] = useState<Table[]>([]);
  const { fetchServices, module_id } = useContext(LogicBuilderContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!module_id) return;

    ObjectsService.getObjectsSchema(module_id, true).then((schema) => {
      setTables(schema?.tables ? schema.tables : []);
    });
  }, [module_id]);

  useEffect(() => {
    if (!props.show) return;

    async function fetchService() {
      const fetchedService = await ServicesService.getService(props.serviceUuid);
      setService(fetchedService);
    }

    fetchService();
    setShowConfirmationDialog(false);
    setIsNameInvalid(false);
    setIsPathInvalid(false);
  }, [props.show, props.serviceUuid]);

  async function onDelete() {
    if (!service.uuid || !module_id) return;

    await ServicesService.deleteService(service.uuid).then(() => {
      setShowConfirmationDialog(false);
      fetchServices(module_id);
      props.onClose();
    });
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!service.uuid || !module_id) return;

    setIsLoading(true);
    await ServicesService.updateService(service.uuid, service)
      .then(() => {
        fetchServices(module_id);
        props.onCreate && props.onCreate();
        props.onClose();
      })
      .catch(() => {
        setErrorMessage(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => {
          props.onClose();
          setErrorMessage(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.service.Edit')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={onSubmit}>
          <Modal.Body>
            {errorMessage && (
              <span className={styles.errorMsg}>{`*${t('logicBuilder.service.NameError')}`}</span>
            )}
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{t('logicBuilder.service.Name')}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t('logicBuilder.service.NamePlaceholder') ?? undefined}
                autoFocus
                required
                maxLength={64}
                value={service.name}
                isInvalid={isNameInvalid}
                onChange={(e) => {
                  setService({
                    ...service,
                    name: e.target.value
                  });
                  setIsNameInvalid(validateName(e.target.value));
                }}
              />
              <Form.Text className="text-muted">
                {t('logicBuilder.service.NameRequirements')}
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>{t('logicBuilder.service.Description')}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t('logicBuilder.service.DescriptionPlaceholder') ?? undefined}
                rows={2}
                maxLength={255}
                value={service.description}
                onChange={(e) =>
                  setService({
                    ...service,
                    description: e.target.value
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label style={{ marginTop: 10 }}>{t('modeler.Entity')}</Form.Label>
              <Form.Select
                value={service.entityUuid}
                onChange={(e) =>
                  setService({
                    ...service,
                    entityUuid: e.target.value
                  } as Service)
                }
              >
                <option value="">---</option>
                {tables.map((entity) => (
                  <option key={entity.uuid} value={entity.uuid}>
                    {entity.content.data.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id={'deleteButton'}
              variant="danger"
              onClick={(_) => setShowConfirmationDialog(true)}
            >
              {t('Delete')}
            </Button>
            <Button id={'cancelButton'} variant="secondary" onClick={() => props.onClose()}>
              {t('Cancel')}
            </Button>
            <Button
              id={'saveButton'}
              variant="primary"
              type="submit"
              disabled={isNameInvalid || isPathInvalid}
            >
              {t('Save')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('logicBuilder.DeleteServiceDialog')} (${service.name})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
