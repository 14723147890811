import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import useSession from 'hooks/useSession';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import { AddInvite } from 'modules/invitation/types';
import { RoleType } from 'modules/organization/types';
import Confirmation from 'web_ui/confirmation';
import Icon from 'web_ui/icon';

import styles from './styles.module.css';
import EmptyMessage from 'web_ui/empty';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

export type MembersListProps = {
  members: AddInvite[];
  showUsername: boolean;
  inModal: boolean;
  onDeleteMember: (member: AddInvite) => void;
  onChangeRole?: (role: string, member: AddInvite) => void;
  listRoles: RoleType[];
  isSmaller?: boolean;
  selectedItem?: string;
};

function MembersList({
  members,
  showUsername,
  inModal,
  onDeleteMember,
  onChangeRole,
  listRoles,
  isSmaller,
  selectedItem
}: MembersListProps) {
  const [session] = useSession();
  const { t } = useTranslation();
  const { org_id } = useParams();
  const [confirmationDelete, setConfirmationDelete] = useState<boolean>(false);
  const [memberToDelete, setMemberToDelete] = useState<AddInvite>();
  const deleteUserLabel: string = t('Delete');
  const deleteUserMessage: string = t('invitation.DeleteUser');

  function translatingRole(role: string) {
    switch (role) {
      case 'OWNER':
        return t('OWNER');
      case 'ADMIN':
        return t('ADMIN');
      case 'MANAGER':
        return t('MANAGER');
      case 'DEV':
        return t('DEV');
      case 'BILLING':
        return t('BILLING');
      default:
        return role;
    }
  }

  const canChangeRoles = session.user?.roles?.some((roleO) => {
    return (
      roleO.context === ContextRole.ORGANIZATION &&
      roleO.contextId?.toString() === org_id &&
      roleO?.roleAuthorities.includes(RoleAuthorities.ADD_ADMIN)
    );
  });

  return (
    <>
      {members.length === 0 ? (
        selectedItem === 'Pending' ? (
          <div className="w-100 h-100" id="bodyMessage">
            <EmptyMessage message={t('organizations.organization.noOrders')} icon="exclamation" />
          </div>
        ) : isSmaller ? (
          <div className="w-100 h-100" id="bodyMessage">
            <EmptyMessage message={t('organizations.organization.noMembers')} icon="exclamation" />
          </div>
        ) : (
          <div className="w-100 h-100" id="bodyMessage">
            <EmptyMessage
              message={t('organizations.organization.noMembersLarge')}
              icon="exclamation"
            />
          </div>
        )
      ) : (
        <div
          className={`${isSmaller ? styles.membersListWrapperSmall : styles.membersListWrapper}`}
          id="list-group"
        >
          {members.map((member, index) => {
            return (
              <div key={index}>
                <Row
                  id={member.email.substring(0, 5).toUpperCase()}
                  className={`d-flex p-2 ${styles.container}`}
                >
                  {/* Avatar */}
                  <Col xs={'auto'} className={`d-flex align-items-center`}>
                    <div className={`${styles.avatar} ${showUsername ? 'me-2' : ''}`}>
                      {member.email.substring(0, 1).toUpperCase()}
                    </div>
                  </Col>
                  {/* Username */}
                  <Col className={`${inModal ? styles.infoColModal : styles.infoCol} d-flex ps-0`}>
                    {showUsername && (
                      <Col
                        style={{
                          fontWeight: '500',
                          overflowWrap: 'break-word',
                          paddingLeft: '0.75rem'
                        }}
                      >
                        {member.username}
                      </Col>
                    )}

                    {/* Email */}
                    <Col
                      className={`d-flex `}
                      style={{ overflowWrap: 'anywhere', paddingLeft: '0.75rem' }}
                    >
                      {member.email}
                    </Col>
                    <Col className={'d-flex'}>
                      {/* Roles */}
                      <Col className={'d-flex p-0 align-items-center'}>
                        {showUsername && member.nameRole !== 'OWNER' && canChangeRoles ? (
                          <select
                            id={`selectMember`}
                            className={`form-select ${styles.roleMembers}`}
                            placeholder="Role"
                            value={member.nameRole}
                            defaultValue={member.nameRole}
                            onChange={(e) => {
                              onChangeRole && onChangeRole(e.target.value, member);
                            }}
                          >
                            {listRoles.map((role) => {
                              return (
                                <option key={role.name} id={role.name} value={role.name}>
                                  {translatingRole(role.name)}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <div style={{ paddingLeft: '0.75rem' }}>
                            {translatingRole(member.nameRole)}
                          </div>
                        )}
                      </Col>
                      {/* Operations in Members */}
                      <Col className={`${styles.iconsMember}`}>
                        {member.nameRole !== 'OWNER' && (
                          <HelpPopover
                            placement="top"
                            helpBoxProps={{
                              title: t('Delete') ?? ''
                            }}
                          >
                            <Button
                              id={`deleteButton-${index}`}
                              className={styles.iconButton}
                              variant="body"
                              onClick={() => {
                                setConfirmationDelete(true);
                                setMemberToDelete(member);
                              }}
                            >
                              <Icon iconName="trash-can" padding="0.5rem" />
                            </Button>
                          </HelpPopover>
                        )}
                      </Col>
                    </Col>
                  </Col>
                </Row>
                {/* {members.length !== index + 1 && <hr />} */}
              </div>
            );
          })}
          <Confirmation
            show={confirmationDelete}
            message={deleteUserMessage}
            confirmationLabel={deleteUserLabel}
            onCancel={() => setConfirmationDelete(false)}
            onConfirmation={() => {
              if (memberToDelete) onDeleteMember(memberToDelete);
              setConfirmationDelete(false);
            }}
            onClose={() => setConfirmationDelete(false)}
          />
        </div>
      )}
    </>
  );
}

export default MembersList;
