import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'packages/redux-utils';
import { requestMiddleware } from './save';
import { ActualEndpointType, ParameterEndpoint } from 'modules/logic_builder/types';
import { endpointEditorEndpointReducer } from './endpointInfo';
import { stateReducer } from './stateReducer';
import { editorStatusReducer } from './editorStatus';
import { endpointParametersReducer } from './endpointParameters';
import { FunctionEditorReducers } from '../../../../modules/logic_builder/function_editor/store';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { CallerInfo } from '../../../../web_ui/function_editor/store/types/functions';
import { callerInfoReducer } from './callerInfo';
import { rootReducer } from '../../../../web_ui/function_editor/store/reducers/root';

export type Status = 'idle' | 'loading' | 'success' | 'error';

export type EndpointEditorStatus = {
  message: string | null;
  status: Status;
  moduleId: string;
  endpointId: string;
  isEndpointEditorOpen: boolean;
};

export type Message = {
  status: Status;
  message: string | null;
};
export type ErrorContext =
  | 'GlobalErrorContext' // Not attached to a specific area/input.
  | 'EndpointInputName'
  | 'EndpointInputPath'
  | 'EndpointInputMethod';

export type EndpointEditorState = {
  // todo (extra): add error messages (status + message for each input/area).
  //   messages: Record<ErrorContext, Message>;
  // Endpoint editor states.
  editorStatus: EndpointEditorStatus;
  endpoint: ActualEndpointType;
  endpointParameters: ParameterEndpoint[];
  callerInfo: CallerInfo;

  // Function editor states.
} & FunctionEditorState;

export const reducer = combineReducers(
  {
    // Endpoint reducers.
    editorStatus: editorStatusReducer.reducer,
    endpoint: endpointEditorEndpointReducer.reducer,
    endpointParameters: endpointParametersReducer.reducer,
    callerInfo: callerInfoReducer.reducer,

    // Endpoint actions reducer.
    ...FunctionEditorReducers
  },
  stateReducer.reducer,
  rootReducer
);

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(requestMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
