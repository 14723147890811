import { ActionsCategoriesIcons } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';

export enum BACKEND_ACTIONS_TYPES {
  CREATE_ENTITY = 'CREATE_ENTITY',
  READ_ENTITY = 'READ_ENTITY',
  UPDATE_ENTITY = 'UPDATE_ENTITY',
  DELETE_ENTITY = 'DELETE_ENTITY',
  DECLARE_VARIABLE = 'DECLARE_VARIABLE',
  SET_VARIABLE = 'SET_VARIABLE',
  ADD_LIST = 'ADD_LIST',
  REMOVE_LIST = 'REMOVE_LIST',
  CLEAR_LIST = 'CLEAR_LIST',
  BEGIN_CYCLE = 'BEGIN_CYCLE',
  BEGIN_IF = 'BEGIN_IF',
  END_IF = 'END_IF',
  BEGIN_ELSE = 'BEGIN_ELSE',
  END_ELSE = 'END_ELSE',
  END_CYCLE = 'END_CYCLE',
  RUN_FUNCTION = 'RUN_FUNCTION',
  CUSTOM_CODE = 'CUSTOM_CODE',
  CUSTOM_QUERY = 'CUSTOM_QUERY',
  RETURN_TYPE = 'RETURN_TYPE',
  EMPTY = 'EMPTY',
  COMMENT = 'COMMENT',
  API_RESPONSE = 'API_RESPONSE'
}

export type BackendActions = keyof typeof BACKEND_ACTIONS_TYPES;

export enum BACKEND_ACTIONS_CATEGORIES {
  ENTITY = 'ENTITY',
  VARIABLE = 'VARIABLE',
  LIST = 'LIST',
  CYCLE = 'CYCLE',
  FUNCTION = 'FUNCTION',
  CONDITION = 'CONDITION'
}

export type BackendActionsCategories = keyof typeof BACKEND_ACTIONS_CATEGORIES;

export const BACKEND_ACTION_CATEGORY_ICONS: ActionsCategoriesIcons = {
  ENTITY: 'database',
  VARIABLE: 'file-code',
  LIST: 'list',
  CYCLE: 'arrows-rotate',
  FUNCTION: 'cog'
};
