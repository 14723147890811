import React from 'react';
import { ActionInputProps } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { updateAction } from '../../../store/events/actions/update_action';
import { ComboboxInput } from '../../../../combobox';
import { HttpStatusCodes } from 'routes/studio/endpoint_editor/constants';

export function HttpStatusInput({
  placeholder,
  label,
  actionUuid,
  dataKey,
  // options,
  isFromSideBar
}: ActionInputProps) {
  const dispatch = useDispatch();
  const state = useSelector((state: FunctionEditorState) => state);
  const value = state.actions[actionUuid]?.data[dataKey];

  const handleChange = (newValue: any) => {
    dispatch(updateAction(actionUuid, dataKey, newValue));
  };

  const getHttpStatusCodes = (): number[] => {
    return Object.values(HttpStatusCodes).map((c) => c.code);
  };

  const getDisplayNameFromItem = (item: unknown): string => {
    const code = item as number;
    const message = Object.values(HttpStatusCodes).find((c) => c.code === code)?.message;
    return code + ' - ' + message;
  };

  return (
    <ComboboxInput
      items={getHttpStatusCodes()}
      selectedItem={value}
      handleChangeSelectedItem={handleChange}
      getDisplayNameFromItem={(item) => getDisplayNameFromItem(item)}
      label={label}
      placeholder={placeholder}
      isFromSideBar={isFromSideBar}
    />
  );
}
