import React from 'react';
import { useTranslation } from 'react-i18next';

export type EndpointNameProps = {
  className?: string;
  validation?: (value: string) => string;
  elementId: string;
  value: string;
  handleChange: (newValue: string, errorMessage: string) => void;
  label: string;
};

/**
 * Summary and Description inputs.
 */
export function EndpointTextareaInput(props: EndpointNameProps) {
  const { t } = useTranslation();
  const { className } = props;

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let errorMessage = '';
    if (props.validation) {
      errorMessage = props.validation(e.target.value);
    }
    props.handleChange(e.target.value, errorMessage);
  };

  const errorMessage = props.validation ? props.validation(props.value) : '';

  return (
    <div className={`mb-3 ${className}`} id={props.elementId}>
      <label htmlFor={`${props.elementId}-input`} className="form-label">
        {props.label}
      </label>
      <textarea
        className={`form-control ${!errorMessage ? '' : 'is-invalid'}`}
        value={props.value}
        onChange={handleChange}
        id={`${props.elementId}-input`}
      />
      {errorMessage && <div className={`invalid-feedback`}>{t(errorMessage)}</div>}
    </div>
  );
}
