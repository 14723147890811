import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { ComponentUUID, Message, MessageTypes, ViewUUID } from '../../../types';
import { InterfaceStudioState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Confirmation from 'web_ui/confirmation';
import ConvertCustomModal from '../component_context_menu/convert_component_modal';
import SessionContext from '../../../../auth/store';
import { FONTS } from '../../toolbars/appTheme_toolbar/theme_manager_modal/bootstrap/text_section';
import AnimatedLoading from 'web_ui/animated_loading';

export type ViewportProps = {
  uuid: ViewUUID;
  styles?: Record<string, string | undefined>;
  onLoad?: () => void;
};

export type ConfirmationInfo = {
  message: string;
  confirmationLabel: string;
  cancelLabel: string;
  componentId?: string;
};

export type ConvertCustomComponentInfo = {
  componentUUID: ComponentUUID;
};

function Viewport(props: ViewportProps) {
  const state = useSelector((state: InterfaceStudioState) => state);
  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [confirmationInfo, setConfirmationInfo] = React.useState<ConfirmationInfo>();
  const [showConvertCustomModal, setShowConvertCustomModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [convertCustomComponentInfo, setConvertCustomComponentInfo] =
    React.useState<ConvertCustomComponentInfo>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { app_id, module_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  useEffect(() => {
    const message: Message = {
      type: MessageTypes.PREFERENCES,
      content: session.preferences
    };

    iframeRef.current?.contentWindow?.postMessage(message);
  }, [session.preferences]);

  useEffect(() => {
    const iframe = iframeRef.current;

    iframe?.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });

    if (iframe) {
      const handleLoad = () => {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
        if (iframeDocument) {
          iframeDocument.body.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            e.stopPropagation();
          });
        }
      };

      iframe.addEventListener('load', handleLoad);
      return () => {
        iframe.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  useEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;

    if (!props.styles) return;

    const iframe = iframeRef.current.contentWindow.document;
    const body = iframe.querySelector('body');
    const head = iframe.querySelector('head');

    if (head) {
      // ['Times', 'Times New Roman'] -> Times|Times+New+Roman
      const fonts = FONTS.join('|').replaceAll(' ', '+');
      let link = iframe.getElementById('font-link') as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.id = 'font-link';
        link.href = `https://fonts.googleapis.com/css?family=${fonts}`;
        head.appendChild(link);
      }

      let style = iframe.getElementById('custom-css') as HTMLStyleElement;
      if (!style) {
        style = document.createElement('style');
        style.type = 'text/css';
        style.id = 'custom-css';
        head.appendChild(style);
      }

      if (state && state.globalCssClasses) {
        let fullCss = '';
        for (const cssClass of state.globalCssClasses) {
          fullCss += `${cssClass.cssName}${cssClass.cssSelector} {
          ${cssClass.cssBlock.replaceAll('"', '')}
        }`;
        }
        style.replaceChildren(document.createTextNode(fullCss));
      }
    }

    if (body) {
      body.style.backgroundImage = props.styles.backgroundImage ?? body.style.backgroundImage;
      body.style.backgroundRepeat = props.styles.backgroundRepeat ?? body.style.backgroundRepeat;
      body.style.backgroundSize = props.styles.backgroundSize ?? body.style.backgroundSize;
    }
  }, [props.styles, state.globalCssClasses]);

  const sendInitialState = useCallback(() => {
    const message: Message = {
      type: MessageTypes.LOAD,
      content: state
    };

    iframeRef.current?.contentWindow?.postMessage(message);
  }, [state]);

  const startListening = useCallback(
    (event: MessageEvent<Message>) => {
      if (event.origin !== window.location.origin) return;

      if (event.data.type === MessageTypes.READY) {
        sendInitialState();
        return;
      }

      if (event.data.type === MessageTypes.ACTION) {
        const action = { ...event.data.content, stopReplay: true };
        dispatch(action);
        return;
      }

      if (event.data.type === MessageTypes.NAVIGATE) {
        navigate(event.data.content);
        return;
      }

      if (event.data.type === MessageTypes.CONFIRMATION) {
        setShowConfirmation(true);
        setConfirmationInfo(event.data.content as ConfirmationInfo);
        return;
      }

      if (event.data.type === MessageTypes.CONVERT_CUSTOM_COMPONENT) {
        setShowConvertCustomModal(true);
        setConvertCustomComponentInfo(event.data.content as ConvertCustomComponentInfo);
        return;
      }

      if (event.data.type === MessageTypes.IFRAME_CONTEXT_MENU) {
        const event = new CustomEvent('iframe_contextmenu');
        document.dispatchEvent(event);
      }

      if (event.data.type === MessageTypes.PREVIEW_LOADED) {
        setLoading(false);
        props.onLoad?.();
      }
    },
    [dispatch, navigate, sendInitialState]
  );

  const sendConfirmationResult = useCallback((result: boolean, componentId?: string) => {
    const message: Message = {
      type: MessageTypes.CONFIRMATION_RESULT,
      content: { result, componentId }
    };

    iframeRef.current?.contentWindow?.postMessage(message);
    // Send message to window too so the Layers component can listen to it
    window.parent.postMessage(message);
    setShowConfirmation(false);
  }, []);

  useEffect(() => {
    window.addEventListener('message', startListening);

    return () => {
      window.removeEventListener('message', startListening);
    };
  }, [startListening]);

  // useEffect(() => {
  //   if (!iframeRef.current) return;

  //   iframeRef.current.onload = () => {
  //     if (iframeRef.current)
  //       iframeRef.current.style.height =
  //         iframeRef.current?.contentWindow?.document.body.scrollHeight + 'px';
  //   };
  // }, []);

  const adjustIFrameHeight = useCallback(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;
    const iframeDocument = iframeRef.current.contentWindow.document;
    const body = iframeDocument.body;
    if (body) {
      const newHeight = body.scrollHeight + 'px';
      iframeRef.current.style.height = newHeight;
    }
  }, []);

  // useEffect(() => {
  //   if (!iframeRef.current || !iframeRef.current.contentWindow) return;

  //   const iframeDocument = iframeRef.current.contentWindow.document;
  //   const body = iframeDocument.body;

  //   if (body) {
  //     const observer = new MutationObserver(() => {
  //       adjustIFrameHeight();
  //     });

  //     observer.observe(body, { attributes: true, childList: true, subtree: true });

  //     adjustIFrameHeight();

  //     return () => {
  //       observer.disconnect();
  //     };
  //   }
  // }, [adjustIFrameHeight]);

  const setupMutationObserver = useCallback(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;

    const iframeDocument = iframeRef.current.contentWindow.document;
    const body = iframeDocument.body;

    if (body) {
      const observer = new MutationObserver(() => {
        adjustIFrameHeight();
      });

      observer.observe(body, { childList: true, subtree: true });

      adjustIFrameHeight();

      return () => {
        observer.disconnect();
      };
    }
  }, [adjustIFrameHeight]);

  // useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.onload = () => {
  //       setupMutationObserver();
  //     };
  //   }
  //   return
  // }, [setupMutationObserver]);

  const openedContextMenu = useCallback(() => {
    const message: Message = {
      type: MessageTypes.CONTEXT_MENU_OPEN
    };

    iframeRef.current?.contentWindow?.postMessage(message);
  }, []);

  useEffect(() => {
    window.addEventListener('contextMenuOpen', openedContextMenu);

    return () => {
      window.removeEventListener('contextMenuOpen', openedContextMenu);
    };
  }, []);

  const handleContextMenu = (event: React.MouseEvent<HTMLIFrameElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <>
      {loading ? (
        <div
          className="h-100 w-100 align-center justify-center"
          style={{ position: 'absolute', zIndex: '222' }}
        >
          <AnimatedLoading />
        </div>
      ) : (
        <></>
      )}

      <iframe
        ref={iframeRef}
        title="viewport"
        id="viewport"
        src={`/app/${app_id}/module/${module_id}/preview/${props.uuid}?height=100%&width=100%`}
        style={{ width: '100%', height: loading ? '0%' : '100%' }}
        // onLoad={() => {
        //   setLoading(false);
        // }}
        onContextMenu={handleContextMenu}
      />

      {convertCustomComponentInfo && (
        <ConvertCustomModal
          show={showConvertCustomModal}
          componentUUID={convertCustomComponentInfo.componentUUID}
          onClose={() => setShowConvertCustomModal(false)}
        />
      )}

      {confirmationInfo && (
        <Confirmation
          show={showConfirmation}
          message={confirmationInfo?.message}
          confirmationLabel={confirmationInfo?.confirmationLabel}
          cancelLabel={confirmationInfo?.cancelLabel}
          onCancel={() => sendConfirmationResult(false)}
          onConfirmation={() => sendConfirmationResult(true, confirmationInfo.componentId)}
          onClose={() => sendConfirmationResult(false)}
        />
      )}
    </>
  );
}

export default Viewport;
