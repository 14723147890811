import React from 'react';
import { driver, DriveStep } from 'driver.js';
import 'driver.js/dist/driver.css';
import { WALKTHROUGH_STEPS } from './constants';
import { applyStepActions } from './actions';
import { Trans } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaInfoCircle } from 'react-icons/fa';

export type WalkthroughContext =
  | 'modeler'
  | 'logic'
  | 'designer'
  | 'dashboard'
  | 'assistant'
  | 'modelerAdvandedTableModal'
  | 'functionEditor'
  | 'endpointEditor';

type WalkthroughProps = {
  customSteps?: DriveStep[];
  prevBtnText: string;
  nextBtnText: string;
  doneBtnText: string;
  context: WalkthroughContext;
  onClose?: () => void;
  // Actions map: element id -> action.
  actions?: Record<string, () => void>;
};

const Step1Translated = (step1Description: string): JSX.Element => {
  return (
    <>
      <Trans i18nKey={step1Description} />
      <br />
      <Trans
        i18nKey="walkthrough.step0"
        components={{
          icon: <FaInfoCircle />
        }}
      >
        By the way, you can hit the <FaInfoCircle /> button anytime to bring back the step-by-step
        guide.
      </Trans>
    </>
  );
};

// A map of: context -> new overlay element id -> existing element id.
export const OVERLAY_ELEMENTS: Record<WalkthroughContext, Record<string, string>> = {
  modeler: {},
  logic: {},
  designer: {
    'designer-workboard-overlay': 'designer-main-workboard'
  },
  dashboard: {},
  assistant: {},
  modelerAdvandedTableModal: {},
  functionEditor: {},
  endpointEditor: {}
};

/**
 * Add a temporary overlay element, because the existing one produces a weird shift in the UI.
 */
export const addOverlayElementBeforeDisplayingWalkthrough = (context: WalkthroughContext) => {
  const overlays: Record<string, string> = OVERLAY_ELEMENTS[context];

  if (!overlays) return;

  for (const [newOverlayId, existingElementId] of Object.entries(overlays)) {
    const existingDiv = document.getElementById(existingElementId);

    if (existingDiv) {
      const overlayDiv = document.createElement('div');
      document.body.appendChild(overlayDiv);

      const rect = existingDiv.getBoundingClientRect();

      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      overlayDiv.style.position = 'fixed';
      overlayDiv.style.backgroundColor = 'transparent';
      overlayDiv.style.pointerEvents = 'none';
      overlayDiv.style.zIndex = '1000';

      overlayDiv.style.top = `${Math.max(0, rect.top)}px`;
      overlayDiv.style.left = `${Math.max(0, rect.left)}px`;
      overlayDiv.style.width = `${Math.min(rect.width, viewportWidth - rect.left)}px`;
      overlayDiv.style.height = `${Math.min(rect.height, viewportHeight - rect.top)}px`;
      overlayDiv.id = newOverlayId;
    }
  }
};

export const removeOverlayElementsAfterWalkthrough = (context: WalkthroughContext) => {
  const overlays: Record<string, string> = OVERLAY_ELEMENTS[context];

  if (!overlays) return;

  for (const overlayId of Object.keys(overlays)) {
    const element = document.getElementById(overlayId);
    if (element) {
      document.body.removeChild(element);
    }
  }
};

export function startWalkthrough(props: WalkthroughProps, triggerElementId?: string) {
  let steps = [];

  if (props.customSteps) {
    steps = [...props.customSteps];
  } else {
    steps = [...(WALKTHROUGH_STEPS[props.context] ?? [])];

    const descriptionHtml = renderToStaticMarkup(
      Step1Translated(steps[0].popover?.description ?? '')
    );

    // The first step (for every walkthrough context) has some extra stuff.
    const firstStep: DriveStep = {
      element: steps[0].element,
      popover: {
        title: steps[0].popover?.title ?? '',
        description: descriptionHtml
      }
    };
    steps[0] = firstStep;

    addOverlayElementBeforeDisplayingWalkthrough(props.context);
  }

  const driverInstance = driver({
    onHighlightStarted: (element: Element | undefined) => {
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    stagePadding: 0,
    allowClose: true,
    smoothScroll: true,
    onCloseClick: () => {
      props.onClose && props.onClose();
      driverInstance.destroy();
    },
    onDestroyStarted: () => {
      props.onClose && props.onClose();
      driverInstance.destroy();

      removeOverlayElementsAfterWalkthrough(props.context);
    },
    animate: true,
    showProgress: true,
    prevBtnText: props.prevBtnText,
    nextBtnText: props.nextBtnText,
    doneBtnText: props.doneBtnText,
    progressText: 'Step {{current}} of {{total}}',
    steps: props.actions
      ? applyStepActions(() => driverInstance.moveNext(), props.actions, steps)
      : steps
  });

  driverInstance.drive();
  return driverInstance;
}
