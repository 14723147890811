import React from 'react';
import styles from './styles.module.css';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function UpgradePlanModal(props: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const upgradePlan = () => {
    navigate('/settings/billing');
  };

  const close = () => {
    props.setShow(false);
  };

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} backdrop={'static'} centered>
      <Modal.Header closeButton>{t('upgradePlanModal.title')}</Modal.Header>
      <Modal.Body>
        <div className={styles.containerButtons}>
          <Button className={`${styles.buttons} bg-success`} onClick={() => upgradePlan()}>
            {t('upgradePlanModal.upgradePlan')}
          </Button>
          <Button className={`${styles.buttons} bg-danger`} onClick={() => close()}>
            {t('upgradePlanModal.close')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
