import React, { useContext } from 'react';
import { INPUT_TYPES, InputType } from '../../../../../../modules/logic_builder/types';
import { useTranslation } from 'react-i18next';
import { useEndpointEditorDispatch } from '../../../../../../hooks/reduxHooks';
import { updateEndpointParameterInputType } from '../../../reducers/endpointParameters';
import { EndpointEditorContext } from '../../../index';

export type ParameterInputTypeProps = {
  parameterId: string;
  endpointId: string;
  className?: string;
  selected: InputType;
  disabled?: boolean;
};

export function ParameterInputType(props: ParameterInputTypeProps) {
  const { endpointId, selected, disabled, parameterId, className } = props;
  const { t } = useTranslation();
  const dispatch = useEndpointEditorDispatch();
  const { objects } = useContext(EndpointEditorContext);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // If input type changes to BODY, we also need to provide an object id.
    // Get first available object id.
    let objectId: string | undefined = undefined;
    if (e.target.value === 'BODY') {
      objectId = objects[0]?.uuid;
    }
    dispatch(
      updateEndpointParameterInputType({
        id: endpointId,
        endpointParameterId: parameterId,
        newInputType: e.target.value as InputType,
        objectId
      })
    );
  };

  return (
    <select
      value={selected}
      onChange={handleChange}
      disabled={disabled}
      className={`form-select ${className}`}
      onMouseDown={(e) => e.stopPropagation()}
    >
      {Object.keys(INPUT_TYPES).map((type) => {
        let disabled = false;
        if (type === 'BODY' && objects.length === 0) {
          disabled = true;
        }
        return (
          <option key={type} value={type} disabled={disabled}>
            {t(INPUT_TYPES[type as InputType])}
          </option>
        );
      })}
    </select>
  );
}
