import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@monaco-editor/react';
import { Button, Dropdown, Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { CodePreviewService } from 'modules/logic_builder/services';
import SessionContext from 'modules/auth/store';
import styles from '../styles.module.css';
import { AppContext } from 'modules/project/store/app_context';
import { PopupAlert } from 'web_ui/popup_alert';
import { useTranslation } from 'react-i18next';
import ErrorPane from '../error_pane';

export enum CodePreviewType {
  SERVICE = 'SERVICE',
  CONTROLLER = 'CONTROLLER',
  OBJECT = 'OBJECT',
  TABLE = 'TABLE',
  ENUM = 'ENUM',
  MODULE_TABLE_ENUM = 'MODULE_TABLE_ENUM',
  SCHEDULER_JOB = 'SCHEDULER_JOB',
  ENDPOINT = 'ENDPOINT',
  FUNCTION = 'FUNCTION',
  PAGE = 'PAGE',
  LAYOUT = 'LAYOUT'
}

type File = {
  path?: string;
  fileName: string;
  fileType: string;
  url: string;
  code: string;
};

type CodeEditorProps = {
  id: string;
  previewType: string;
};

type Folder = {
  [folderName: string]: Folder | File[];
};

const CodeEditor: React.FC<CodeEditorProps> = ({ id, previewType }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({});
  const session = React.useContext(SessionContext);
  const appId = React.useContext(AppContext).projectInformation!.id;
  const [errorIntoGeneration, setErrorIntoGeneration] = useState<boolean>(false);
  const { t } = useTranslation();

  const fetchPreview = useCallback(
    async (signal: AbortSignal) => {
      setIsLoading(true);
      try {
        const data = await CodePreviewService.runPreviewJob(appId, id, previewType, signal).catch(
          (err) => setErrorIntoGeneration(true)
        );
        if (signal.aborted) {
          setErrorIntoGeneration(true);
          return;
        }

        const result = await CodePreviewService.getPreviewResult(appId, data.id, signal).catch(
          (err) => setErrorIntoGeneration(true)
        );
        if (signal.aborted) {
          setErrorIntoGeneration(true);
          return;
        }

        const codes: string[] = [];

        if (result.files.length === 0) {
          setErrorMessage(result.message);
          setErrorIntoGeneration(true);
          return;
        }

        for (const file of result.files) {
          const code = await getUrlCode(file.url, signal);
          codes.push(code);

          if (signal.aborted) {
            setErrorIntoGeneration(true);
            return;
          }
        }

        setFiles(
          result.files.map((file: File, index: number) => ({ ...file, code: codes[index] }))
        );
        setSelectedCode(codes[0]);
        setSelectedFile(result.files[0]);
        setErrorMessage(result.message);
      } finally {
        setIsLoading(false);
      }
    },
    [appId, id, previewType]
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchPreview(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [fetchPreview]);

  async function getUrlCode(url: string, signal: AbortSignal): Promise<string> {
    const response = await fetch(url, { signal });
    if (!response.ok) {
      throw new Error('Failed to fetch code');
    }
    return response.text();
  }

  const handleFileClick = (fileName: string) => {
    const file = files.find((f) => f.fileName === fileName) || null;
    if (!file) return;
    setSelectedFile(file);
    setSelectedCode(file.code);
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setAlertMessage('Copied');
  };

  const handleCodeChange = (value: string | undefined) => {
    console.error('Function not implemented.');
  };

  const handleDownload = (file: File | null, code: string | null) => {
    if (!file || !code) return;

    const blob = new Blob([code], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = file.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setAlertMessage('Downloaded');
  };

  const handleDownloadFiles = async (files: File[]) => {
    const zipContent = files.map((file) => `${file.fileName}\n${file.code}`).join('\n\n');
    const blob = new Blob([zipContent], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'code-previews.zip';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setAlertMessage('Downloaded');
  };

  const handleFolderToggle = (folderName: string) => {
    setOpenFolders((prev) => ({ ...prev, [folderName]: !prev[folderName] }));
  };

  const getFolderName = (path: string | undefined): string[] => {
    if (!path) return ['Other'];
    const parts = path.split('/').filter(Boolean);
    return parts.length ? parts : ['Other'];
  };

  const groupedFiles = files.reduce((acc: Folder, file) => {
    const folderPathParts = getFolderName(file.path);
    let currentFolder = acc;

    folderPathParts.forEach((part, index) => {
      if (!currentFolder[part]) {
        currentFolder[part] = index === folderPathParts.length - 1 ? [] : {};
      }
      if (index === folderPathParts.length - 1) {
        (currentFolder[part] as File[]).push(file);
      } else {
        currentFolder = currentFolder[part] as Folder;
      }
    });

    return acc;
  }, {} as Folder);

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const formingBreadcrumb = (): string => {
    if (selectedFile && selectedFile.path) {
      const formatting = selectedFile.path.replaceAll('/', ' > ') + ' > ' + selectedFile.fileName;
      return formatting;
    }
    return '';
  };

  const renderFolder = (folder: Folder, folderName = '') => (
    <div key={folderName} className={styles.folder}>
      <div className={styles.folderName} onClick={() => handleFolderToggle(folderName)}>
        <Icon iconName={openFolders[folderName] ? 'chevron-down' : 'chevron-right'} />
        <Icon iconName={openFolders[folderName] ? 'folder-open' : 'folder'} />
        {folderName}
      </div>
      {openFolders[folderName] && (
        <div className={`border-start ${styles.files}`}>
          {Object.keys(folder).map((name) => {
            const content = folder[name];
            if (Array.isArray(content)) {
              return content.map((file) => (
                <div
                  key={file.fileName}
                  className={styles.file}
                  onClick={() => handleFileClick(file.fileName)}
                >
                  {file.fileName}
                </div>
              ));
            } else {
              return renderFolder(content as Folder, name);
            }
          })}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.codeEditor}>
      {errorIntoGeneration ? (
        <>
          <div
            style={{
              border: '2px solid rgba(217, 83, 79, 0.4)',
              display: 'grid',
              placeItems: 'center',
              height: 400
            }}
          >
            <span style={{ fontSize: 30, color: 'rgba(217, 83, 79, 0.9)' }}>
              {t('errorCodePreview')}
              <i
                className="fa-solid fa-triangle-exclamation"
                style={{ fontSize: 30, marginLeft: 15 }}
              ></i>
            </span>
          </div>
          {errorMessage && <ErrorPane error={errorMessage} />}
        </>
      ) : (
        <>
          {isLoading ? (
            <Spinner animation="border" role="status" className={styles.spinner}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {alertMessage && (
                <Modal
                  show={alertMessage ? true : false}
                  style={{ background: 'transparent', width: 'auto' }}
                  centered={false}
                >
                  <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={'success'} />
                </Modal>
              )}
              {/* <div className={styles.sidebar}>
            {Object.keys(groupedFiles).map((folderName) =>
              renderFolder(groupedFiles[folderName] as Folder, folderName)
            )}
          </div> */}
              <div className={styles.editorContent}>
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <Tabs
                    id="filesTabs"
                    activeKey={selectedFile?.fileName}
                    onSelect={(f) => handleFileClick(f!)}
                    className="border-end mb-3"
                  >
                    {files.map((file) => (
                      <Tab eventKey={file.fileName} title={file.fileName} key={file.fileName} />
                    ))}
                  </Tabs>
                  <div style={{ display: 'flex', paddingLeft: '20px', gap: '10px' }}>
                    <Button id={'copy'} onClick={() => handleCopy(selectedCode!)} className="mb-3">
                      <Icon iconName="copy" />
                    </Button>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id={'download'}
                        className="mb-3"
                        style={{ display: 'flex', alignItems: 'center', height: '40px' }}
                      >
                        <Icon iconName="download" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          id="downloadFile"
                          href="#/action-1"
                          onClick={() => handleDownload(selectedFile, selectedCode)}
                        >
                          {'Download one'}
                        </Dropdown.Item>
                        <Dropdown.Item
                          id="downloadFiles"
                          href="#/action-2"
                          onClick={() => handleDownloadFiles(files)}
                        >
                          {'Download all'}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {selectedFile && (
                  <Editor
                    path={selectedFile.fileName}
                    defaultLanguage={selectedFile.fileType.toLowerCase()}
                    defaultValue={selectedCode || '//Type your code'}
                    theme={session.preferences['exocode-theme'] ? 'vs-dark' : 'light'}
                    height={errorMessage ? '550px' : '600px'}
                    onChange={handleCodeChange}
                    options={{ domReadOnly: true, readOnly: true }}
                  />
                )}
                <div>
                  <span className={styles.breadCrumb}>{formingBreadcrumb().substring(2)}</span>
                  {errorMessage && <ErrorPane error={errorMessage} />}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CodeEditor;
