import { IS_LOCALHOST } from '../../constants';
import { DriveStep } from 'driver.js';
import { t } from 'i18next';

export const WALKTHROUGH_STEPS_ELEMENTS: Record<string, string> = {
  // Modeler module.
  'main-workboard': 'dashboard-main',
  'component-card-table': 'draggable-component-card-table',
  'component-card-enum': 'draggable-component-card-enum',
  'widget-card': 'draggable-component-card-notes',
  'modeler-folder-area': 'modeler-folder-area',
  'code-preview': 'codePreview',
  'entity-generator': 'entityGenerator',
  'import-button': 'importButton',
  'navbar-project-navigation': 'navbar-project-navigation',
  // Designer module.
  'views-button': 'tabButton-0',
  'designer-components-wrapper': IS_LOCALHOST ? 'tabButton-1' : '',
  'blocks-button': 'tabButton-2',
  'theme-button': IS_LOCALHOST ? 'tabButton-3' : 'tabButton-1',
  'designer-main-workboard': 'designer-workboard-overlay',
  'designer-code-preview': 'codePreview',
  'properties-button': 'propertiesButton',
  'styles-button': 'stylesButton',
  'events-button': 'eventsButton',
  'designer-layers': 'designer-layers',
  'designer-grid-mode': 'designer-grid-mode',
  'designer-show-grid': 'showGrid',
  'designer-viewports': 'designer-viewports',
  'designer-action-tabs': 'designer-action-tabs',
  'behavior-tab': 'behavior-tab',
  // Logic builder module.
  'logic-left-sidebar': 'logic-left-sidebar',
  'logic-objects-tab': 'logic-objects-tab',
  'logic-functions-tab': 'logic-functions-tab',
  'logic-schedulers-tab': 'logic-schedulers-tab',
  'logic-endpoints-tab': 'logic-endpoints-tab',
  'logic-assistant-button': 'assistantButton',
  // Dashboard modules tab.
  'dashboard-modules-tab': 'modulesTab',
  'dashboard-languages-tab': 'languagesTab',
  'dashboard-assets-tab': 'assetsTab',
  'dashboard-roles-tab': 'rolesTab',
  'dashboard-add-module': 'dashboard-add-module',
  'dashboard-download-code': 'downloadCodeButton',
  'dashboard-module-settings': 'settingsButton',
  'dashboard-edit-module': 'editButtonMain',
  'dashboard-modules-main': 'modules-main',
  'dashboard-main': 'dashboard-main',
  'dashboard-modeler-button': 'DB-Button',
  'dashboard-logic-button': 'LB-Button',
  'dashboard-designer-button': 'UI-Button',
  // Assistant module.
  'assistant-main': 'assistant-main',
  // Modeler advanced table editor.
  'advanced-editor': 'advanced-editor-content',
  'entity-name': 'form-entity-name',
  'entity-description': 'form-entity-description',
  'column-editor': 'columnEditorWrapper',
  'column-datatype': 'columnType',
  'index-configuration': 'indexesTab',
  'entity-relations': 'relationsTab',
  // Logic builder function editor.
  'function-editor-main': 'function-editor-main',
  'function-editor-header': 'functionHeader',
  'function-editor-body': 'function-body',
  'function-editor-actions': 'logic-left-sidebar',
  'function-editor-properties': 'function-editor-properties',
  'function-editor-action-block': 'function-body',
  'function-editor-custom-code': 'CustomCodeAction',
  'function-editor-code-preview': 'customCodeButton',
  'function-editor-return-button': 'backButton',
  // Endpoint dialog editor.
  'endpoint-editor-main': 'endpoint-editor-main',
  'endpoint-editor-method': 'formEndpointMethod',
  'endpoint-editor-name': 'formEndpointName',
  'endpoint-editor-summary': 'formEndpointSummary',
  'endpoint-editor-path': 'formEndpointPath',
  'endpoint-editor-service-function': 'endpoint-editor-service-function',
  'endpoint-editor-input-parameters': 'endpoint-editor-parameters',
  'endpoint-editor-responses': 'endpoint-editor-responses',
  'endpoint-editor-security': 'endpointsEditorTabs-tab-security'
};

export const RemoveSteps = [
  `#${WALKTHROUGH_STEPS_ELEMENTS['designer-components-wrapper']}`,
  `#${WALKTHROUGH_STEPS_ELEMENTS['blocks-button']}`,
  `#${WALKTHROUGH_STEPS_ELEMENTS['designer-grid-mode']}`,
  `#${WALKTHROUGH_STEPS_ELEMENTS['designer-show-grid']}`
];

// * This const is very unnecessary.
// * Can be removed by constructing this const every time the walkthrough is called, the WALKTHROUGH_STEPS_ELEMENTS
// * is probably the only thing that is important/needed.
export const WALKTHROUGH_STEPS: Record<string, DriveStep[]> = {
  modeler: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['main-workboard']}`,
      popover: {
        title: t('walkthrough.modelerDashboardTitle') ?? 'walkthrough.modelerDashboardTitle',
        description: t('walkthrough.modelerDashboard') ?? 'walkthrough.modelerDashboard'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['component-card-table']}`,
      popover: {
        title: t('walkthrough.modelerCardTableTitle') ?? 'walkthrough.modelerCardTableTitle',
        description: t('walkthrough.modelerCardTable') ?? 'walkthrough.modelerCardTable'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['component-card-enum']}`,
      popover: {
        title: t('walkthrough.modelerCardEnumTitle') ?? 'walkthrough.modelerCardEnumTitle',
        description: t('walkthrough.modelerCardEnum') ?? 'walkthrough.modelerCardEnum'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['widget-card']}`,
      popover: {
        title: t('walkthrough.modelerCardNoteTitle') ?? 'walkthrough.modelerCardNoteTitle',
        description: t('walkthrough.modelerCardNote') ?? 'walkthrough.modelerCardNote'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['modeler-folder-area']}`,
      popover: {
        title: t('walkthrough.modelerFolderTitle') ?? 'walkthrough.modelerFolderTitle',
        description: t('walkthrough.modelerFolder') ?? 'walkthrough.modelerFolder'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['code-preview']}`,
      popover: {
        title: t('walkthrough.modelerCodePreviewTitle') ?? 'walkthrough.modelerCodePreviewTitle',
        description: t('walkthrough.modelerCodePreview') ?? 'walkthrough.modelerCodePreview'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['entity-generator']}`,
      popover: {
        title:
          t('walkthrough.modelerEntityGeneratorTitle') ?? 'walkthrough.modelerEntityGeneratorTitle',
        description: t('walkthrough.modelerEntityGenerator') ?? 'walkthrough.modelerEntityGenerator'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['import-button']}`,
      popover: {
        title: t('walkthrough.modelerImportExcelTitle') ?? 'walkthrough.modelerImportExcelTitle',
        description: t('walkthrough.modelerImportExcel') ?? 'walkthrough.modelerImportExcel'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['main-workboard']}`,
      popover: {
        title:
          t('walkthrough.modelerDeleteElementsTitle') ?? 'walkthrough.modelerDeleteElementsTitle',
        description: t('walkthrough.modelerDeleteElements') ?? 'walkthrough.modelerDeleteElements'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['navbar-project-navigation']}`,
      popover: {
        title:
          t('walkthrough.modelerNavigateModulesTitle') ?? 'walkthrough.modelerNavigateModulesTitle',
        description: t('walkthrough.modelerNavigateModules') ?? 'walkthrough.modelerNavigateModules'
      }
    }
  ],
  designer: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-main-workboard']}`,
      popover: {
        title: t('walkthrough.designerWorkboardTitle') ?? 'walkthrough.designerWorkboardTitle',
        description: t('walkthrough.designerWorkboard') ?? 'walkthrough.designerWorkboard'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['views-button']}`,
      popover: {
        title: t('walkthrough.designerViewsButtonTitle') ?? 'walkthrough.designerViewsButtonTitle',
        description: t('walkthrough.designerViewsButton') ?? 'walkthrough.designerViewsButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-components-wrapper']}`,
      popover: {
        title: t('walkthrough.designerComponentsTitle') ?? 'walkthrough.designerComponentsTitle',
        description: t('walkthrough.designerComponents') ?? 'walkthrough.designerComponents'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['blocks-button']}`,
      popover: {
        title:
          t('walkthrough.designerBlocksButtonTitle') ?? 'walkthrough.designerBlocksButtonTitle',
        description: t('walkthrough.designerBlocksButton') ?? 'walkthrough.designerBlocksButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['theme-button']}`,
      popover: {
        title: t('walkthrough.designerThemeButtonTitle') ?? 'walkthrough.designerThemeButtonTitle',
        description: t('walkthrough.designerThemeButton') ?? 'walkthrough.designerThemeButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['properties-button']}`,
      popover: {
        title:
          t('walkthrough.designerPropertiesButtonTitle') ??
          'walkthrough.designerPropertiesButtonTitle',
        description:
          t('walkthrough.designerPropertiesButton') ?? 'walkthrough.designerPropertiesButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['styles-button']}`,
      popover: {
        title:
          t('walkthrough.designerStylesButtonTitle') ?? 'walkthrough.designerStylesButtonTitle',
        description: t('walkthrough.designerStylesButton') ?? 'walkthrough.designerStylesButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['events-button']}`,
      popover: {
        title:
          t('walkthrough.designerEventsButtonTitle') ?? 'walkthrough.designerEventsButtonTitle',
        description: t('walkthrough.designerEventsButton') ?? 'walkthrough.designerEventsButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-layers']}`,
      popover: {
        title: t('walkthrough.designerLayersTitle') ?? 'walkthrough.designerLayersTitle',
        description: t('walkthrough.designerLayers') ?? 'walkthrough.designerLayers'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-grid-mode']}`,
      popover: {
        title: t('walkthrough.designerGridModeTitle') ?? 'walkthrough.designerGridModeTitle',
        description: t('walkthrough.designerGridMode') ?? 'walkthrough.designerGridMode'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-show-grid']}`,
      popover: {
        title: t('walkthrough.designerShowGridTitle') ?? 'walkthrough.designerShowGridTitle',
        description: t('walkthrough.designerShowGrid') ?? 'walkthrough.designerShowGrid'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-viewports']}`,
      popover: {
        title: t('walkthrough.designerViewportsTitle') ?? 'walkthrough.designerViewportsTitle',
        description: t('walkthrough.designerViewports') ?? 'walkthrough.designerViewports'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-action-tabs']}`,
      popover: {
        title: t('walkthrough.designerActionTabsTitle') ?? 'walkthrough.designerActionTabsTitle',
        description: t('walkthrough.designerActionTabs') ?? 'walkthrough.designerActionTabs'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['designer-code-preview']}`,
      popover: {
        title: t('walkthrough.designerCodePreviewTitle') ?? 'walkthrough.designerCodePreviewTitle',
        description: t('walkthrough.designerCodePreview') ?? 'walkthrough.designerCodePreview'
      }
    }
  ].filter((step) => {
    if (IS_LOCALHOST) return true;
    return !RemoveSteps.includes(step.element);
  }),
  logic: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-objects-tab']}`,
      popover: {
        title: t('walkthrough.logicObjectsTabTitle') ?? 'walkthrough.logicObjectsTabTitle',
        description: t('walkthrough.logicObjectsTab') ?? 'walkthrough.logicObjectsTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-functions-tab']}`,
      popover: {
        title: t('walkthrough.logicFunctionsTabTitle') ?? 'walkthrough.logicFunctionsTabTitle',
        description: t('walkthrough.logicFunctionsTab') ?? 'walkthrough.logicFunctionsTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-endpoints-tab']}`,
      popover: {
        title: t('walkthrough.logicEndpointsTabTitle') ?? 'walkthrough.logicEndpointsTabTitle',
        description: t('walkthrough.logicEndpointsTab') ?? 'walkthrough.logicEndpointsTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-schedulers-tab']}`,
      popover: {
        title: t('walkthrough.logicSchedulersTabTitle') ?? 'walkthrough.logicSchedulersTabTitle',
        description: t('walkthrough.logicSchedulersTab') ?? 'walkthrough.logicSchedulersTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-left-sidebar']}`,
      popover: {
        title: t('walkthrough.logicLeftSidebarTitle') ?? 'walkthrough.logicLeftSidebarTitle',
        description: t('walkthrough.logicLeftSidebar') ?? 'walkthrough.logicLeftSidebar'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['logic-assistant-button']}`,
      popover: {
        title:
          t('walkthrough.logicAssistantButtonTitle') ?? 'walkthrough.logicAssistantButtonTitle',
        description: t('walkthrough.logicAssistantButton') ?? 'walkthrough.logicAssistantButton'
      }
    }
  ],
  assistant: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['assistant-main']}`,
      popover: {
        title: t('walkthrough.assistantMainTitle') ?? 'walkthrough.assistantMainTitle',
        description: t('walkthrough.assistantMain') ?? 'walkthrough.assistantMain'
      }
    }
  ],
  dashboard: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-main']}`,
      popover: {
        title: t('walkthrough.dashboardMainTitle') ?? 'walkthrough.dashboardMainTitle',
        description: t('walkthrough.dashboardMain') ?? 'walkthrough.dashboardMain'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-modules-main']}`,
      popover: {
        title:
          t('walkthrough.dashboardModulesMainTitle') ?? 'walkthrough.dashboardModulesMainTitle',
        description: t('walkthrough.dashboardModulesMain') ?? 'walkthrough.dashboardModulesMain'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-modules-tab']}`,
      popover: {
        title: t('walkthrough.dashboardModulesTabTitle') ?? 'walkthrough.dashboardModulesTabTitle',
        description: t('walkthrough.dashboardModulesTab') ?? 'walkthrough.dashboardModulesTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-modeler-button']}`,
      popover: {
        title:
          t('walkthrough.dashboardModelerButtonTitle') ?? 'walkthrough.dashboardModelerButtonTitle',
        description: t('walkthrough.dashboardModelerButton') ?? 'walkthrough.dashboardModelerButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-logic-button']}`,
      popover: {
        title:
          t('walkthrough.dashboardLogicButtonTitle') ?? 'walkthrough.dashboardLogicButtonTitle',
        description: t('walkthrough.dashboardLogicButton') ?? 'walkthrough.dashboardLogicButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-designer-button']}`,
      popover: {
        title:
          t('walkthrough.dashboardDesignerButtonTitle') ??
          'walkthrough.dashboardDesignerButtonTitle',
        description:
          t('walkthrough.dashboardDesignerButton') ?? 'walkthrough.dashboardDesignerButton'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-edit-module']}`,
      popover: {
        title: t('walkthrough.dashboardEditModuleTitle') ?? 'walkthrough.dashboardEditModuleTitle',
        description: t('walkthrough.dashboardEditModule') ?? 'walkthrough.dashboardEditModule'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-add-module']}`,
      popover: {
        title: t('walkthrough.dashboardAddModuleTitle') ?? 'walkthrough.dashboardAddModuleTitle',
        description: t('walkthrough.dashboardAddModule') ?? 'walkthrough.dashboardAddModule'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-languages-tab']}`,
      popover: {
        title:
          t('walkthrough.dashboardLanguagesTabTitle') ?? 'walkthrough.dashboardLanguagesTabTitle',
        description: t('walkthrough.dashboardLanguagesTab') ?? 'walkthrough.dashboardLanguagesTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-assets-tab']}`,
      popover: {
        title: t('walkthrough.dashboardAssetsTabTitle') ?? 'walkthrough.dashboardAssetsTabTitle',
        description: t('walkthrough.dashboardAssetsTab') ?? 'walkthrough.dashboardAssetsTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-roles-tab']}`,
      popover: {
        title: t('walkthrough.dashboardRolesTabTitle') ?? 'walkthrough.dashboardRolesTabTitle',
        description: t('walkthrough.dashboardRolesTab') ?? 'walkthrough.dashboardRolesTab'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-module-settings']}`,
      popover: {
        title:
          t('walkthrough.dashboardModuleSettingsTitle') ??
          'walkthrough.dashboardModuleSettingsTitle',
        description:
          t('walkthrough.dashboardModuleSettings') ?? 'walkthrough.dashboardModuleSettings'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['dashboard-download-code']}`,
      popover: {
        title:
          t('walkthrough.dashboardDownloadCodeTitle') ?? 'walkthrough.dashboardDownloadCodeTitle',
        description: t('walkthrough.dashboardDownloadCode') ?? 'walkthrough.dashboardDownloadCode'
      }
    }
  ],
  modelerAdvandedTableModal: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['advanced-editor']}`,
      popover: {
        title:
          t('walkthrough.modelerAdvancedEditorTitle') ?? 'walkthrough.modelerAdvancedEditorTitle',
        description: t('walkthrough.modelerAdvancedEditor') ?? 'walkthrough.modelerAdvancedEditor'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['entity-name']}`,
      popover: {
        title: t('walkthrough.modelerEntityNameTitle') ?? 'walkthrough.modelerEntityNameTitle',
        description: t('walkthrough.modelerEntityName') ?? 'walkthrough.modelerEntityName'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['entity-description']}`,
      popover: {
        title:
          t('walkthrough.modelerEntityDescriptionTitle') ??
          'walkthrough.modelerEntityDescriptionTitle',
        description:
          t('walkthrough.modelerEntityDescription') ?? 'walkthrough.modelerEntityDescription'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['column-editor']}`,
      popover: {
        title:
          t('walkthrough.modelerColumnDescriptionTitle') ??
          'walkthrough.modelerColumnDescriptionTitle',
        description:
          t('walkthrough.modelerColumnDescription') ?? 'walkthrough.modelerColumnDescription'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['column-datatype']}`,
      popover: {
        title: t('walkthrough.modelerColumnDataTitle') ?? 'walkthrough.modelerColumnDataTitle',
        description: t('walkthrough.modelerColumnData') ?? 'walkthrough.modelerColumnData'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['index-configuration']}`,
      popover: {
        title:
          t('walkthrough.modelerIndexConfigurationTitle') ??
          'walkthrough.modelerIndexConfigurationTitle',
        description:
          t('walkthrough.modelerIndexConfiguration') ?? 'walkthrough.modelerIndexConfiguration'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['entity-relations']}`,
      popover: {
        title:
          t('walkthrough.modelerEntityRelationsTitle') ?? 'walkthrough.modelerEntityRelationsTitle',
        description: t('walkthrough.modelerEntityRelations') ?? 'walkthrough.modelerEntityRelations'
      }
    }
  ],
  functionEditor: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-main']}`,
      popover: {
        title: t('walkthrough.functionEditorMainTitle') ?? 'walkthrough.functionEditorMainTitle',
        description: t('walkthrough.functionEditorMain') ?? 'walkthrough.functionEditorMain'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-header']}`,
      popover: {
        title:
          t('walkthrough.functionEditorHeaderTitle') ?? 'walkthrough.functionEditorHeaderTitle',
        description: t('walkthrough.functionEditorHeader') ?? 'walkthrough.functionEditorHeader'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-body']}`,
      popover: {
        title: t('walkthrough.functionEditorBodyTitle') ?? 'walkthrough.functionEditorBodyTitle',
        description: t('walkthrough.functionEditorBody') ?? 'walkthrough.functionEditorBody'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-actions']}`,
      popover: {
        title:
          t('walkthrough.functionEditorActionsTitle') ?? 'walkthrough.functionEditorActionsTitle',
        description: t('walkthrough.functionEditorActions') ?? 'walkthrough.functionEditorActions'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-properties']}`,
      popover: {
        title:
          t('walkthrough.functionEditorPropertiesTitle') ??
          'walkthrough.functionEditorPropertiesTitle',
        description:
          t('walkthrough.functionEditorProperties') ?? 'walkthrough.functionEditorProperties'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-action-block']}`,
      popover: {
        title:
          t('walkthrough.functionEditorActionBlockTitle') ??
          'walkthrough.functionEditorActionBlockTitle',
        description:
          t('walkthrough.functionEditorActionBlock') ?? 'walkthrough.functionEditorActionBlock'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-custom-code']}`,
      popover: {
        title:
          t('walkthrough.functionEditorCustomCodeTitle') ??
          'walkthrough.functionEditorCustomCodeTitle',
        description:
          t('walkthrough.functionEditorCustomCode') ?? 'walkthrough.functionEditorCustomCode'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-code-preview']}`,
      popover: {
        title:
          t('walkthrough.functionEditorCodePreviewTitle') ??
          'walkthrough.functionEditorCodePreviewTitle',
        description:
          t('walkthrough.functionEditorCodePreview') ?? 'walkthrough.functionEditorCodePreview'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['function-editor-return-button']}`,
      popover: {
        title:
          t('walkthrough.functionEditorReturnButtonTitle') ??
          'walkthrough.functionEditorReturnButtonTitle',
        description:
          t('walkthrough.functionEditorReturnButton') ?? 'walkthrough.functionEditorReturnButton'
      }
    }
  ],
  endpointEditor: [
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-main']}`,
      popover: {
        title: t('walkthrough.endpointEditorMainTitle') ?? 'walkthrough.endpointEditorMainTitle',
        description: t('walkthrough.endpointEditorMain') ?? 'walkthrough.endpointEditorMain'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-method']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorMethodTitle') ?? 'walkthrough.endpointEditorMethodTitle',
        description: t('walkthrough.endpointEditorMethod') ?? 'walkthrough.endpointEditorMethod'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-name']}`,
      popover: {
        title: t('walkthrough.endpointEditorNameTitle') ?? 'walkthrough.endpointEditorNameTitle',
        description: t('walkthrough.endpointEditorName') ?? 'walkthrough.endpointEditorName'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-summary']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorSummaryTitle') ?? 'walkthrough.endpointEditorSummaryTitle',
        description: t('walkthrough.endpointEditorSummary') ?? 'walkthrough.endpointEditorSummary'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-path']}`,
      popover: {
        title: t('walkthrough.endpointEditorPathTitle') ?? 'walkthrough.endpointEditorPathTitle',
        description: t('walkthrough.endpointEditorPath') ?? 'walkthrough.endpointEditorPath'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-service-function']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorServiceFunctionTitle') ??
          'walkthrough.endpointEditorServiceFunctionTitle',
        description:
          t('walkthrough.endpointEditorServiceFunction') ??
          'walkthrough.endpointEditorServiceFunction'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-input-parameters']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorInputParametersTitle') ??
          'walkthrough.endpointEditorInputParametersTitle',
        description:
          t('walkthrough.endpointEditorInputParameters') ??
          'walkthrough.endpointEditorInputParameters'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-responses']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorResponsesTitle') ??
          'walkthrough.endpointEditorResponsesTitle',
        description:
          t('walkthrough.endpointEditorResponses') ?? 'walkthrough.endpointEditorResponses'
      }
    },
    {
      element: `#${WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-security']}`,
      popover: {
        title:
          t('walkthrough.endpointEditorSecurityTitle') ?? 'walkthrough.endpointEditorSecurityTitle',
        description: t('walkthrough.endpointEditorSecurity') ?? 'walkthrough.endpointEditorSecurity'
      }
    }
  ]
};
