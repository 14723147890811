import { ActionsType } from '../../types/manifestsAndInputs';
import { ActionsState } from '../../types/actions';
import { Action } from 'redux';

export const REPLACE_EMPTY = 'REPLACE_EMPTY';

export interface ReplaceEmptyAction extends Action {
  type: 'REPLACE_EMPTY';
  payload: {
    uuid: string;
    type: ActionsType;
    data: any;
  };
}

export const replaceEmpty = (uuid: string, type: ActionsType, data: any): ReplaceEmptyAction => ({
  type: REPLACE_EMPTY,
  payload: { uuid, type, data }
});

export function doReplaceEmpty(state: ActionsState, action: ReplaceEmptyAction): ActionsState {
  const { uuid, type, data } = action.payload;
  if (!state[uuid]) return state;
  state[uuid] = { ...state[uuid], type, data };

  // if (type === 'DECLARE_VARIABLE') {
  //   const variable: FunctionVariableType = {
  //     uuid: data.uuid,
  //     name: data.name,
  //     desc: '',
  //     type: data.dataType.type,
  //     objectUuid: data.dataType?.objectUuid ?? '',
  //     enumUuid: data.dataType?.enumUuid ?? '',
  //     list: data.list,
  //     required: false
  //   };

  //   state.variables[variable.uuid] = variable;
  // }
  return state;
}
