import { CreateIfAction } from '../actions/create_if';
import { FunctionEditorState } from '../../types/function_editor_state';
import { EndpointEditorState } from '../../../../../routes/studio/endpoint_editor/reducers';

export function doCreateIf(
  state: FunctionEditorState | EndpointEditorState,
  action: CreateIfAction
): FunctionEditorState {
  const {
    functionId,
    ifBeginAction,
    ifBodyAction,
    ifEndAction,
    elseBeginAction,
    elseBodyAction,
    elseEndAction,
    mode
  } = action.payload;

  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    state.callerInfo.actions.splice(
      ifBeginAction?.order ?? 0,
      0,
      ifBeginAction.uuid,
      ifBodyAction.uuid,
      ifEndAction.uuid,
      elseBeginAction.uuid,
      elseBodyAction.uuid,
      elseEndAction.uuid
    );
  } else {
    state.functions[functionId].actions.splice(
      ifBeginAction?.order ?? 0,
      0,
      ifBeginAction.uuid,
      ifBodyAction.uuid,
      ifEndAction.uuid,
      elseBeginAction.uuid,
      elseBodyAction.uuid,
      elseEndAction.uuid
    );
  }
  return state;
}
