import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Editor } from '@monaco-editor/react';
import SessionContext from 'modules/auth/store';
import { useParams } from 'react-router-dom';
import {
  CssSelectorInfo,
  extractSelectors,
  getCssDeclarations,
  getPlainCssDeclarations,
  validateCssValue
} from 'utils/cssUtils';
import { CssStylesService } from 'modules/designer/services';
import { CssClass } from 'web_ui/workboard/sidebar/controls/CssClassesControl/types';
import { useDispatch } from 'react-redux';
import { addCssClass, updateCssClass } from '../../store/actions/cssClasses';
import { v4 as uuidv4 } from 'uuid';

export interface GlobalClassModalProps {
  show: boolean;
  onClose: () => void;
  editMode: boolean;
  classInfo?: CssClass;
}

export function CssClassDialog({ show, onClose, editMode, classInfo }: GlobalClassModalProps) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const { app_id } = useParams();
  const [cssValue, setCssValue] = useState<{
    isDirty: boolean;
    value: string;
    errorMessage: string;
  }>({ isDirty: false, value: '', errorMessage: '' });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!show) {
      return;
    }

    if (editMode && classInfo) {
      const cssBlock = '.css {' + classInfo.cssBlock + '}';
      const block = getCssDeclarations(cssBlock.replaceAll('"', ''), true);

      const fullCss = `${classInfo.cssName}${classInfo.cssSelector ?? ''} {\n${block}}`;
      setCssValue({ isDirty: false, value: fullCss, errorMessage: '' });
    } else if (!editMode) {
      setCssValue({ isDirty: false, value: '', errorMessage: '' });
    }
  }, [classInfo, editMode, show]);

  const dialogTitle = editMode
    ? t('designer.cssClass.EditCssDialogTitle')
    : t('designer.cssClass.CreateCssDialogTitle');

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!app_id) return;
    if (isLoading) return;
    if (editMode && !classInfo) return;

    setIsLoading(true);

    // Validate fields.
    const message = validateCssValue(cssValue.value);
    if (message) {
      setCssValue({ ...cssValue, isDirty: true, errorMessage: message });
      setIsLoading(false);
      return;
    }

    // Send request.
    try {
      const cssSelectors: CssSelectorInfo[] = extractSelectors(cssValue.value);

      // We're sure this is not undefined because we've already validated the css value.
      // And the first item should always exist.
      const firstSection = cssSelectors[0].firstSection;
      const secondSection = cssSelectors[0].secondSection;

      const cssBlock = getPlainCssDeclarations(cssValue.value, true);
      if (editMode) {
        const newClass: CssClass = {
          ...classInfo,
          appId: app_id,
          cssName: firstSection,
          cssSelector: secondSection,
          cssBlock: JSON.stringify(cssBlock)
        };
        await CssStylesService.updateCssClass(app_id, newClass).then(() => {
          dispatch(updateCssClass(newClass));
        });
      } else {
        const newClass: CssClass = {
          id: uuidv4(),
          appId: app_id,
          cssName: firstSection,
          cssSelector: secondSection,
          cssBlock: JSON.stringify(cssBlock)
        };
        await CssStylesService.createCssClass(app_id, newClass).then(() => {
          dispatch(addCssClass(newClass));
        });
      }
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCssValueChange = (value?: string) => {
    const errorMessage = validateCssValue(value ?? '');
    const newCssValue = {
      ...cssValue,
      isDirty: true,
      value: value ?? '',
      errorMessage: errorMessage
    };
    setCssValue(newCssValue);
  };

  return (
    <Modal centered show={show} onHide={onClose} size="lg">
      <Form onSubmit={onSubmit} id="formModal">
        <Modal.Header closeButton>
          <Modal.Title>{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="modalBody">
          <Editor
            height={'40vh'}
            defaultLanguage="css"
            defaultValue={''}
            onChange={handleCssValueChange}
            value={cssValue.value}
            theme={session.preferences['exocode-theme'] === true ? 'vs-dark' : 'light'}
            options={{
              fontSize: 16
            }}
          />
          <Form.Control.Feedback
            type="invalid"
            className="d-block"
            style={{
              display: cssValue.isDirty && cssValue.errorMessage ? 'block' : 'none',
              fontSize: '1rem'
            }}
          >
            {t(cssValue.errorMessage)}
          </Form.Control.Feedback>
        </Modal.Body>
        <Modal.Footer>
          <Button id={'saveGlobaCssClass'} type="submit">
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
