import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EndpointEditorStatus } from '.';

export type SetEditorStatusPayload = EndpointEditorStatus;

const initialState: EndpointEditorStatus = {
  message: null,
  status: 'idle',
  moduleId: '',
  endpointId: '',
  isEndpointEditorOpen: false
};

type SetIsEndpointEditorOpenPayload = {
  isOpen: boolean;
};

/**
 * Used for changing the editor message and status.
 */
export const editorStatusReducer = createSlice({
  name: 'state.editorStatus',
  initialState,
  reducers: {
    setEditorStatus: (state, action: PayloadAction<SetEditorStatusPayload>) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    resetEditorStatus: (state) => {
      state.status = 'idle';
      state.message = null;
    },
    setIsEndpointEditorOpen: (state, action: PayloadAction<SetIsEndpointEditorOpenPayload>) => {
      state.isEndpointEditorOpen = action.payload.isOpen;
    }
  }
});

export const { setEditorStatus, resetEditorStatus, setIsEndpointEditorOpen } =
  editorStatusReducer.actions;
