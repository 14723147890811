import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { DashboardService } from 'modules/dashboard/services';
import { ModuleInfo } from 'modules/dashboard/types';
import Module from '../../../module';
import CreateModuleModal from '../../../../../../modules/project/components/create_module_modal';
import { useTranslation } from 'react-i18next';
import Icon from 'web_ui/icon';
import EmptyMessage from 'web_ui/empty';
import { ListGroup } from 'react-bootstrap';
import { Authorization } from 'modules/auth/session/authorization';
import {
  ContextRole,
  RoleAuthorities,
  SystemRoleAuthorityName
} from 'modules/auth/types/auth_types';
import styles from './styles.module.css';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import UpgradePlanModal from '../../../../../../web_ui/upgrade_plan_modal';
import { AppContext } from 'modules/project/store/app_context';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { useAppInfo } from '../../../app_tabs_navbar';
import { getFeatureLimit } from '../../../../../../utils/utils';

function SearchModules() {
  const appCtx = useContext(AppContext);
  const ownerLimits = appCtx.appOwnerLimits;
  const { reload } = useAppInfo();
  const [modules, setModules] = useState<ModuleInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { app_id } = useParams();
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);

  const moduleListHeaderRef = useRef<HTMLHeadingElement>(null);
  const moduleListRef = useRef<HTMLHeadingElement>(null);

  const fetchModules = useCallback(async () => {
    if (!app_id) return;
    setLoading(true);
    await DashboardService.getModulesByApp(app_id)
      .then((modulesByApp) => {
        setModules(modulesByApp);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [app_id]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  const onRefresh = useCallback(() => {
    fetchModules();
    refreshAppInfo();
  }, [fetchModules]);

  const handleCloseCreateModuleModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const canCreateModule = () => {
    if (ownerLimits == null) {
      console.error("Couldn't access user's resource limits.");
      return;
    }
    const limit = getFeatureLimit(ownerLimits, SystemRoleAuthorityName.ADD_MODULE);
    if (!limit) {
      setShowUpgradePlanModal(true);
      return;
    }
    if (modules.length < limit) {
      setShowModal(true);
    } else {
      setShowUpgradePlanModal(true);
    }
  };

  const refreshAppInfo = () => {
    // Some module changes affect the app info, e.g. creating/deleting an authentication module
    if (!app_id) return;
    // Update in app context
    appCtx?.updateProjectInformation(app_id);
    // Update in DashboardPage state
    reload((val) => !val);
  };

  return (
    <>
      <div className={`${styles.searchListHeader} justify-content-end`} ref={moduleListHeaderRef}>
        <Authorization
          context={ContextRole.APP}
          allowedAuthorities={[RoleAuthorities.MANAGE_MODULE]}
        >
          <HelpPopover
            helpBoxProps={{
              title: ContextRole.APP
                ? t('appResume.module.NewModule') || 'appResume.module.New'
                : t('appResume.module.NewLanguage') || 'appResume.module.New'
            }}
            placement="top"
          >
            <Button
              id={WALKTHROUGH_STEPS_ELEMENTS['dashboard-add-module']}
              variant="primary"
              onClick={() => canCreateModule()}
            >
              <Icon iconName="plus"></Icon>
            </Button>
          </HelpPopover>
        </Authorization>
      </div>

      {loading && (
        <div className={`fa-3x ${styles.spinner}`}>
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      )}

      {!loading && (
        <div
          className={`bg-body border ${styles.moduleList} ${
            modules.length < 1 ? styles.alignVertical : ''
          }`}
          ref={moduleListRef}
        >
          {modules.length > 0 && (
            <ListGroup>
              {modules.map((module, index) => {
                return (
                  <Module
                    key={module.id + '' + index}
                    module={module}
                    onRefresh={onRefresh}
                    modules={modules}
                  />
                );
              })}
            </ListGroup>
          )}

          {modules.length < 1 && (
            <div className="w-100 h-100" id="bodyMessage">
              <EmptyMessage
                message={t('appResume.module.NoModules')}
                icon="exclamation"
                actionMessage={t('appResume.module.CreateModule') ?? ''}
                linkAction={() => setShowModal(true)}
              />
            </div>
          )}
        </div>
      )}
      {app_id && (
        <CreateModuleModal
          modules={modules}
          onCloseRequest={handleCloseCreateModuleModal}
          showModal={showModal}
          fetchModules={fetchModules}
          currentModules={modules}
          loading={loading}
        />
      )}
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
    </>
  );
}

export default SearchModules;
