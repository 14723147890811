import React, { ChangeEvent } from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import { DefaultInput, DefaultLabel } from '../styles';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

function NumberControl(props: ControlProps) {
  const { t } = useTranslation();
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (props.onChange) props.onChange(parseFloat(e.target.value), props.id);
  }

  const insertStyleParentDiv = () => {
    if (props.options?.includes('full')) {
      return { width: '100%', display: 'flex', alignItems: 'center' };
    }
    return { width: 'fit-content', display: 'flex', alignItems: 'center' };
  };

  return (
    <div className="mb-3" style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', marginBottom: '.5rem' }}>
        <DefaultLabel>{props.label}</DefaultLabel>
        {props.tooltip && (
          <HelpPopover
            helpBoxProps={{
              description: t(`${props.tooltip}`)!
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        )}
      </div>
      <div className={`form-control form-control-sm`} style={insertStyleParentDiv()}>
        <DefaultInput
          id={'numberInput'}
          type="number"
          value={props.value ?? 0}
          className={`${styles.inputNumber}`}
          onChange={handleChange}
        />
        {!props.options?.includes('noUnit') && <span style={{ marginLeft: '0px' }}>px</span>}
      </div>
    </div>
  );
}

export default NumberControl;
