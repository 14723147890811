import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { Authorization } from 'modules/auth/session/authorization';
import { ProjectsService } from 'modules/project/services';
import { ApiAppInfo } from 'modules/project/types';
import {
  ContextRole,
  Role,
  RoleAuthorities,
  RoleOptions,
  Teams,
  UserProfile
} from 'modules/auth/types/auth_types';
import InviteAppModal from '../invite_app_modal';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Icon from 'web_ui/icon';

import styles from './style.module.css';

export type AppDetailProps = {
  app: ApiAppInfo;
};

function AppDetail({ app }: AppDetailProps) {
  const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [members, setMembers] = React.useState<UserProfile[]>([]);
  const [teams, setTeams] = React.useState<Teams[]>([]);

  const { app_id } = useParams();

  const { t } = useTranslation();

  React.useEffect(() => {
    findMembers();
  }, []);

  const findMembers = async () => {
    try {
      setLoading(true);
      if (app_id) {
        const data = await ProjectsService.getMembersOfApp(app_id);
        setMembers([...data.users]);
        setTeams([...data.teams]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getRoleApp = (roles: Role[], appId: string): Role => {
    return roles.filter((role) => {
      return role.context === ContextRole.APP && role.contextUuid === appId;
    })[0];
  };

  const isowner = (member: UserProfile) => {
    if (app_id) return getRoleApp(member.roles, app_id).name === RoleOptions.ADMIN;
  };

  return (
    <>
      {!loading && (
        <>
          <ul className={`bg-body-secondary ${styles.legendWrapper} ${styles.separator}`}>
            {/* Teams */}
            <li>
              <div className={`${styles.titleWrapper}`}>
                <div className={styles.title}>{t('CollaboratorsAndTeams')}</div>
                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.MANAGE_TEAM]}
                >
                  <HelpPopover
                    helpBoxProps={{
                      title: t('invitation.Invite') || ''
                    }}
                    placement="top"
                  >
                    <Button
                      id={'addMember'}
                      onClick={() => {
                        setShowInviteModal(true);
                      }}
                    >
                      <Icon iconName="plus"></Icon>
                    </Button>
                  </HelpPopover>
                </Authorization>
              </div>
              <ul className={styles.buttonsWrapper}>
                {members.map((member, index) => {
                  return (
                    <li
                      key={index}
                      className={`${isowner(member) ? 'bg-danger' : 'bg-primary'} ${
                        styles.avatarBackground
                      }`}
                    >
                      <HelpPopover
                        helpBoxProps={{
                          title: member.username
                        }}
                        placement="top"
                      >
                        <Badge pill bg={isowner(member) ? 'danger' : 'primary'}>
                          {member.username?.substring(0, 2).toUpperCase()}
                        </Badge>
                      </HelpPopover>
                    </li>
                  );
                })}
                {teams.map((team, index) => {
                  return (
                    <li key={index} className={`${styles.teamsAvatar}`}>
                      <HelpPopover
                        helpBoxProps={{
                          title: team.name
                        }}
                        placement="top"
                      >
                        <div className={styles.teamsSetting}>
                          {team.name.substring(0, 2).toUpperCase()}
                        </div>
                      </HelpPopover>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
          <InviteAppModal
            showModal={showInviteModal}
            onCloseRequest={() => {
              setShowInviteModal(false);
              findMembers();
            }}
            onCloseModal={() => {
              setShowInviteModal(false);
              findMembers();
            }}
            app={app}
            membersOfApp={members}
          />
        </>
      )}
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}
    </>
  );
}
export default AppDetail;
