import React, { forwardRef, memo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { ComponentUUID } from 'modules/designer/types';
import { TextAlignProperty } from 'csstype';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { convertingCSS } from 'utils/utils';
import styles from '../style.module.css';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';
import * as FontIcon from 'react-icons/fa';
import { IconList } from 'packages/react-fa-icon-picker';

export type InputEmailData = {
  label?: string;
  placeholder?: string;
  tooltip?: string;
  defaultValue?: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number | string;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  translateKey?: string;
  hasTranslation?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
  showInputIcon?: string;
  showBtnAction?: string;
  BtnActionIcon?: IconList;
  BtnActionColor?: string;
};

export type InputEmailStyles = {
  staticColor?: string;
  themeColor?: string;
  fontSize?: number;
  textAlign?: string;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  borderRadius?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  lineHeight?: number | string;
  textDecoration?: string;
  textTransform?: string;
  fontFamily?: string;
  fontStyle?: string;
  iconHeight?: string;
  bgColor?: string;
  borderColor?: string;
  boxshadow?: string;
  placeholderColor?: string;
  classes?: string[];
};

export type InputEmailComponentProps = {
  uuid: ComponentUUID;
  data: InputEmailData;
  styles: InputEmailStyles;
};

function InputEmailComponent(props: InputEmailComponentProps, ref: React.Ref<any>) {
  const [emailVal, setemailVal] = React.useState<string>();
  const [correct, setcorrect] = React.useState<boolean>();
  const [value, setValue] = React.useState<string>('');
  const theme = useSelector((state: InterfaceStudioState) => state.theme);

  let style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    }),
    textAlign: props.styles.textAlign as TextAlignProperty,
    color: props.styles.staticColor
      ? props.styles.staticColor
      : props.styles.themeColor &&
        convertingCSS(
          props.styles.themeColor,
          props.styles.themeColor.includes('#') ? '' : theme.colors[props.styles.themeColor!]
        ),
    fontSize: `${props.styles.fontSize}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    borderRadius: `${props.styles.borderRadius}px`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    lineHeight: `${props.styles.lineHeight}`,
    textDecoration: `${props.styles.textDecoration}`,
    TextTransform: `${props.styles.textTransform}`,
    ...(props.styles.fontFamily && {
      fontFamily: `${props.styles.fontFamily}`
    }),
    fontStyle: `${props.styles.fontStyle}`
  };

  if (props.data.widthCalc && props.data.widthUnit !== 'px') {
    style.width = `calc(${style.width} - ${props.data.widthCalc}px)`;
  }

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  const styleNotValid = {
    borderColor: 'red',
    backgroundImage: 'none',
    boxShadow: 'none',
    backgroundColor:
      props.styles.bgColor &&
      convertingCSS(
        props.styles.bgColor,
        props.styles.bgColor.includes('#') ? '' : theme.colors[props.styles.bgColor]
      )
  };

  function handlingChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const regexMail = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    setemailVal(ev.target.value);
    if (regexMail.test(emailVal || '')) {
      setcorrect(true);
    } else {
      setcorrect(false);
    }
  }

  return (
    <div
      ref={ref}
      style={{ ...style, ...parseCustomCss(props.styles.css) }}
      title={props.data.tooltip}
      className={classes}
    >
      <Form.Group>
        {props.data.label && (
          <Form.Label
            className={`text-${props.styles.textTransform}`}
            style={{ textDecoration: `${props.styles.textDecoration}` }}
          >
            {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.label}
          </Form.Label>
        )}
        <InputGroup
          style={{
            boxShadow: props.styles.boxshadow,
            overflow: props.styles.boxshadow ? 'visible' : undefined
          }}
        >
          {props.data.showInputIcon ? (
            <InputGroup.Text>
              <FaEnvelope style={{ height: `${props.styles.iconHeight}` }} />
            </InputGroup.Text>
          ) : (
            <></>
          )}
          <Form.Control
            type="email"
            placeholder={props.data.placeholder}
            readOnly={props.data.readonly}
            required={props.data.required}
            disabled={props.data.disabled}
            onChange={(ev) => {
              handlingChange(ev);
              if (props.data.defaultValue) return;
              setValue(ev.target.value);
            }}
            style={
              correct === false
                ? styleNotValid
                : {
                    backgroundColor:
                      props.styles.bgColor &&
                      convertingCSS(
                        props.styles.bgColor,
                        props.styles.bgColor.includes('#') ? '' : theme.colors[props.styles.bgColor]
                      ),
                    ...({
                      '--placeholder-color':
                        props.styles.placeholderColor &&
                        convertingCSS(
                          props.styles.placeholderColor,
                          props.styles.placeholderColor.includes('#')
                            ? ''
                            : theme.colors[props.styles.placeholderColor]
                        )
                    } as React.CSSProperties),
                    borderColor:
                      props.styles.borderColor &&
                      convertingCSS(
                        props.styles.borderColor,
                        theme.colors[props.styles.borderColor]
                      )
                  }
            }
            value={!props.data.defaultValue ? value : props.data.defaultValue}
            className={styles.input}
          />
          {props.data.showBtnAction && (
            <Button
              variant={props.data.BtnActionColor}
              style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
            >
              {props.data.BtnActionIcon ? (
                React.createElement(FontIcon[props.data.BtnActionIcon])
              ) : (
                <div />
              )}
            </Button>
          )}
        </InputGroup>
      </Form.Group>
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(InputEmailComponent));
