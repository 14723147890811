import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CrudPage, METHODS } from '../../../../../../../../../modules/logic_builder/types';
import EndpointAction from './action';
import { TemplateEndpoint } from '../../../../../../../../../modules/designer/types';
import { RestDataInterface } from '../../../../../../../../../modules/designer/function_editor/function_actions/types/action_data';
import { TYPE_PICKER_TYPES } from '../../../../../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { useTranslation } from 'react-i18next';

type Props = {
  page: CrudPage;
  masterEndpoints: TemplateEndpoint[];
  detailEndpoints: TemplateEndpoint[];
  onEndpointChange: (
    submitAction: RestDataInterface | undefined,
    fetchAction: RestDataInterface | undefined,
    deleteAction: RestDataInterface | undefined
  ) => void;
};

export default function PageEndpoints(props: Props) {
  function handleFetchActionChange(action: RestDataInterface) {
    props.page.fetchAction = action;
    props.onEndpointChange(
      props.page.submitAction,
      props.page.fetchAction,
      props.page.deleteAction
    );
  }

  function handleDeleteActionChange(action: RestDataInterface) {
    props.page.deleteAction = action;
    props.onEndpointChange(
      props.page.submitAction,
      props.page.fetchAction,
      props.page.deleteAction
    );
  }

  function handleSubmitActionChange(action: RestDataInterface) {
    props.page.submitAction = action;
    const selectedEndpoint = props.detailEndpoints.find(
      (endpoint) => endpoint.uuid === action.endpoint.endpoint
    );

    if (selectedEndpoint?.method === METHODS.POST) {
      const fetchAction: RestDataInterface = {
        endpoint: {
          endpoint: '',
          pathParams: {},
          queryParams: {},
          request: { type: TYPE_PICKER_TYPES.VAR, value: '' },
          response: ''
        }
      };
      props.onEndpointChange(props.page.submitAction, fetchAction, props.page.deleteAction);
    } else {
      props.onEndpointChange(
        props.page.submitAction,
        props.page.fetchAction,
        props.page.deleteAction
      );
    }
  }

  const isSubmitActionUpdate = useCallback(() => {
    const submitEndpoitId = props.page.submitAction?.endpoint.endpoint;
    const selectedEndpoint = props.detailEndpoints.find(
      (endpoint) => endpoint.uuid === submitEndpoitId
    );
    return selectedEndpoint?.method === METHODS.PUT;
  }, [props.detailEndpoints, props.page.submitAction?.endpoint.endpoint]);

  const { t } = useTranslation();

  return (
    <Row className="mt-4">
      <Col sm="1">{t('automation.step4.Endpoints')}:</Col>
      <Col sm="11">
        {props.page.allowReadMulti && (
          <EndpointAction
            page={props.page}
            method={[METHODS.GET]}
            action={props.page.fetchAction}
            endpoints={props.masterEndpoints}
            crudType={['READ_MANY']}
            onChange={handleFetchActionChange}
            isFromPageEdit={true}
          />
        )}
        {(props.page.allowCreateOne || props.page.allowUpdateOne) && (
          <EndpointAction
            page={props.page}
            method={[METHODS.POST, METHODS.PUT]}
            action={props.page.submitAction}
            endpoints={props.detailEndpoints}
            crudType={['CREATE_ONE', 'UPDATE_ONE']}
            onChange={handleSubmitActionChange}
            isFromPageEdit={true}
          />
        )}
        {props.page.allowReadOne && isSubmitActionUpdate() && (
          <EndpointAction
            page={props.page}
            method={[METHODS.GET]}
            action={props.page.fetchAction}
            endpoints={props.masterEndpoints}
            crudType={['READ_ONE']}
            onChange={handleFetchActionChange}
            isFromPageEdit={true}
          />
        )}
        {props.page.allowDeleteOne && (
          <EndpointAction
            page={props.page}
            method={[METHODS.DELETE]}
            action={props.page.deleteAction}
            endpoints={props.masterEndpoints}
            crudType={['DELETE_ONE']}
            onChange={handleDeleteActionChange}
            isFromPageEdit={true}
          />
        )}
      </Col>
    </Row>
  );
}
