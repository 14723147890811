import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

const TextDecorationOptions = [
  { value: '', label: 'Default', icon: 'fa-solid fa-xmark' },
  { value: 'underline', label: 'Underline', icon: 'fa-solid fa-underline' },
  { value: 'line-through', label: 'Line-through', icon: 'fa-solid fa-strikethrough' }
] as const;

function TextDecoration(props: ControlProps) {
  const { t } = useTranslation();
  const currentValue = props.value;

  function handleChange(AlignText: string) {
    if (props.onChange) props.onChange(AlignText, props.id);
  }

  const isSelected = (value: string): boolean => {
    return currentValue === value;
  };

  return (
    <div style={{ display: 'flex' }}>
      {props.tooltip && (
        <div>
          <HelpPopover
            helpBoxProps={{
              description: `${t(props.tooltip)}`
            }}
            placement="top"
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <div className={`form-control form-control-sm`}>
          {TextDecorationOptions.map((option) => (
            <button
              className={` ${styles.buttonContent}  btn btn-secondary ${
                isSelected(option.value) ? 'active' : ''
              }`}
              onClick={() => handleChange(option.value)}
              key={option.label}
            >
              <i className={option.icon}></i>
            </button>
          ))}
        </div>
        {props.options?.map((item, index) => {
          return <option key={index}>{item}</option>;
        })}
      </div>
    </div>
  );
}

export default TextDecoration;
