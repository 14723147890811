import React from 'react';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { RootState } from '../../reducers';
import { updateEndpointBooleanProperties } from '../../reducers/endpointInfo';
import { useTranslation } from 'react-i18next';

export type BooleanPropertiesProps = {
  className?: string;
};

export const BooleanPropertiesInputs = ['deprecated', 'pageable', 'sortable', 'filterable'];

export const i18nBooleanPropertiesInputs: Record<string, string> = {
  deprecated: 'Deprecated',
  pageable: 'Pageable',
  sortable: 'Sortable',
  filterable: 'Filterable'
};

export function BooleanProperties(props: BooleanPropertiesProps) {
  const { className } = props;
  const dispatch = useEndpointEditorDispatch();
  const { uuid, deprecated, pageable, sortable, filterable } = useEndpointEditorSelector(
    (state: RootState) => state.endpoint
  );
  const { t } = useTranslation();

  const handleChange = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.checked);
    dispatch(updateEndpointBooleanProperties({ id: uuid, [key]: e.target.checked }));
  };

  const getValue = (key: string) => {
    if (key === 'deprecated') {
      return deprecated;
    } else if (key === 'pageable') {
      return pageable;
    } else if (key === 'sortable') {
      return sortable;
    } else if (key === 'filterable') {
      return filterable;
    }
  };

  return (
    <>
      {BooleanPropertiesInputs.map((key) => {
        return (
          <div className={`mb-3 ${className}`} key={key}>
            <input
              type="checkbox"
              className={`form-check-input me-2`}
              id={`endpoint-${key}-input`}
              checked={getValue(key)}
              onChange={(e) => handleChange(key, e)}
            />
            <label htmlFor={`endpoint-${key}-input`} className="form-check-label">
              {t(i18nBooleanPropertiesInputs[key])}
            </label>
          </div>
        );
      })}
    </>
  );
}
