import { CallerInfo } from '../../../../web_ui/function_editor/store/types/functions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SetCallerInfo = CallerInfo;

const initialState: CallerInfo = {
  uuid: '',
  name: '',
  actions: [],
  modificationTime: new Date(),
  creationTime: new Date(),
  modifiedByUser: '',
  createdByUser: ''
};

export const callerInfoReducer = createSlice({
  name: 'callerInfo',
  initialState,
  reducers: {
    setCallerInfo: (state, action: PayloadAction<SetCallerInfo>) => {
      state.uuid = action.payload.uuid;
      state.name = action.payload.name;
      state.actions = action.payload.actions;
    }
  }
});

export const { setCallerInfo } = callerInfoReducer.actions;
