import React from 'react';
import { ComboboxInput } from 'web_ui/combobox';

export type ItemType = {
  name: string;
  uuid: string;
  id?: string;
  subName?: string;
  description?: string;
};

type DropdownProps = {
  items: ItemType[];
  // ! This should not be an ItemType, probably unknown.
  value?: ItemType;
  handleChange: (text: string) => void;
  label: string;
  placeholder?: string;
  isFromSideBar?: boolean;
  isFromArgument?: boolean;
  options?: any;
};

export default function Dropdown({
  items,
  value,
  handleChange,
  placeholder,
  label,
  isFromSideBar,
  isFromArgument,
  options
}: DropdownProps) {
  const getDisplayNameFromItem = (item: unknown) => {
    const itemType = item as ItemType | undefined;
    return itemType?.name ?? '';
  };

  const getDisplaySubName = (item: unknown) => {
    const itemType = item as ItemType | undefined;
    return itemType?.subName ?? '';
  };

  const getDisplayDescription = (item: unknown) => {
    const itemType = item as ItemType | undefined;
    return itemType?.description ?? '';
  };

  const handleChangeSelectedItem = (item: unknown) => {
    const itemType = item as ItemType;
    handleChange(itemType.uuid ?? itemType.id);
  };

  return (
    <>
      <ComboboxInput
        items={items}
        selectedItem={value}
        getDisplayNameFromItem={getDisplayNameFromItem}
        getDisplaySubNameFromItem={getDisplaySubName}
        getDisplayDescriptionFromItem={getDisplayDescription}
        handleChangeSelectedItem={handleChangeSelectedItem}
        label={label}
        placeholder={placeholder}
        isFromSideBar={isFromSideBar}
        isFromArgument={isFromArgument}
        options={options}
      />
    </>
  );
}
