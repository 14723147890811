import React, { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { InterfaceStudioState } from '../store';
import { COMPONENTS_LIST } from './index';
import { ComponentUUID, ViewUUID } from '../../types';

export interface ElementProps {
  key: string;
  viewUUID: string;
  parentUUID?: string;
  custom_uuid?: string;
  new_custom_uuid?: string;
  section?: string;
  styles: Record<string, unknown>;
  data: Record<string, unknown>;
  [key: string]: unknown;
}

type ComponentsRendererProps = {
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  // custom_uuid is being used to render the custom component.
  custom_uuid?: ComponentUUID;
  // new_custom_uuid is being used to delete custom components.
  new_custom_uuid?: ComponentUUID;
  section?: string;
  hasChild?: (value: boolean) => void;
};

function ComponentsRenderer(props: ComponentsRendererProps) {
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const key = `${props.parentUUID}${props.section ? '_' + props.section : ''}`;

  const childs = props.parentUUID ? links[key] : links[props.viewUUID];

  return (
    <>
      {childs &&
        childs.map((id) => {
          const element = components[id];
          if (!element) return null;

          const ElementComponent = COMPONENTS_LIST[element.type] as ComponentType<ElementProps>;
          return (
            ElementComponent && (
              <ElementComponent
                key={element.uuid}
                viewUUID={props.viewUUID}
                parentUUID={props.parentUUID}
                custom_uuid={props.custom_uuid}
                new_custom_uuid={props.new_custom_uuid}
                section={props.section}
                styles={element.styles || {}}
                data={element.data || {}}
                {...element}
              />
            )
          );
        })}
    </>
  );
}

export default ComponentsRenderer;
