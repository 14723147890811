import React from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { changeParamType } from '../../../../store/events/parameters/change_param_type';
import DataTypePicker from '../../data_type_picker';
import { ReturnType } from '../../../../store/types/functions';
import ParameterOptions from './parameter_options';
import ParameterNameEditor from './parameter_name_editor';
import ParameterDragIcon from './parameter_drag_icon';
import { useTranslation } from 'react-i18next';
import { selectParam } from 'web_ui/function_editor/store/events/editor/select_param';
import { DataType } from 'modules/logic_builder/types';

type ParameterEditorProps = {
  uuid: string;
  types: ReturnType[];
};

function ParameterEditor({ uuid, types }: ParameterEditorProps) {
  const param = useSelector((state: FunctionEditorState) => state.parameters[uuid]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeType = (newType: ReturnType) => {
    dispatch(
      changeParamType({
        ...param,
        type: newType.type as DataType,
        objectUuid: newType.objectUuid,
        enumUuid: newType.enumUuid
      })
    );
  };

  const handleChangeList = (checked: boolean) => {
    dispatch(
      changeParamType({
        ...param,
        list: checked
      })
    );
  };

  if (!param) return null;

  const handleFocus = () => {
    dispatch(selectParam(uuid));
  };

  return (
    <div className={styles.ParamTypeAndName}>
      <div className={styles.paramGroup}>
        <ParameterDragIcon />
        <DataTypePicker
          handleChange={handleChangeType}
          type={{
            type: param.type,
            objectUuid: param.objectUuid,
            enumUuid: param.enumUuid,
            list: param.list
          }}
          types={types.filter((t) => t.type !== 'VOID')}
          disabled={param?.native}
          onFocus={handleFocus}
        />
        <div className={styles.Check}>
          <Form.Label style={{ marginBottom: '0' }}>{t('automation.step3.List')}</Form.Label>
          <Form.Check
            checked={param.list}
            onChange={(e) => handleChangeList(e.target.checked)}
            onFocus={handleFocus}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ParameterNameEditor uuid={uuid} onFocus={handleFocus} />
        <ParameterOptions uuid={uuid} onFocus={handleFocus} />
      </div>
    </div>
  );
}

export default ParameterEditor;
