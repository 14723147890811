import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { updateEndpointName } from '../../reducers/endpointInfo';
import { validateEndpointNameInput } from '../../actions';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export type EndpointNameProps = {
  className?: string;
};

export function EndpointName(props: EndpointNameProps) {
  const { t } = useTranslation();
  const { className } = props;
  const dispatch = useEndpointEditorDispatch();
  const { uuid, name } = useEndpointEditorSelector((state) => state.endpoint);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validateEndpointNameInput(e.target.value);
    dispatch(updateEndpointName({ id: uuid, newName: e.target.value, isValid: !errorMessage }));
  };

  const errorMessage = validateEndpointNameInput(name);

  return (
    <div className={`mb-3 ${className}`} id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-name']}>
      <label htmlFor="endpoint-name-input" className="form-label">
        {t('logicBuilder.endepoint.Name')}
      </label>
      <input
        type="text"
        className={`form-control ${!errorMessage ? '' : 'is-invalid'}`}
        id={'endpoint-name-input'}
        value={name}
        onChange={handleChange}
        required
      />
      {errorMessage && <div className={`invalid-feedback`}>{t(errorMessage)}</div>}
    </div>
  );
}
