import React, { useContext } from 'react';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import {
  ActiveSubscriptionPlan,
  SUBSCRIPTION_STATUS,
  addDot,
  STRIPE_LOOKUP_KEYS
} from 'modules/payments/types';
import Icon from 'web_ui/icon';
import SessionContext from '../../../auth/store';
import { Button } from 'react-bootstrap';

type ActiveSubscriptionPlanProps = {
  activeSubscription?: ActiveSubscriptionPlan;
};

export function SubscriptionPlan({ activeSubscription }: ActiveSubscriptionPlanProps) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const API_URL = process.env.REACT_APP_API_URL;

  const subscriptionName = (userSubscription: ActiveSubscriptionPlan) => {
    // Stripe enterprise plans MUST all start with 'enterprise_plan'
    const stipePlanName = userSubscription.stripeLookupKey.startsWith('enterprise_plan')
      ? 'enterprise_plan'
      : userSubscription.stripeLookupKey;
    return STRIPE_LOOKUP_KEYS[stipePlanName];
  };

  const billingInfo = () => {
    if (activeSubscription?.status === 'past_due') {
      return <span>{t('billing.PaymentFailed')}</span>;
    } else if (activeSubscription?.status === 'active') {
      return (
        <>
          {activeSubscription?.last4 ? (
            <div className="d-flex ">
              {' '}
              <span className="me-2">{t('Card')} </span>
              <Icon iconName="credit-card" />
              <span className="ms-1">xxxx-xxxx-xxxx-{activeSubscription?.last4}</span>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    }
  };

  const subscriptionEnd = (userSubscription: ActiveSubscriptionPlan) => {
    if (
      userSubscription.currentPeriodEnd == null ||
      userSubscription.stripeLookupKey === 'free_plan'
    ) {
      return <></>;
    }
    if (userSubscription.cancelAtDueDate) {
      return (
        <span>
          {t('billing.SubscriptionEndDate')}{' '}
          {new Date(userSubscription.currentPeriodEnd * 1000).toLocaleDateString()}
        </span>
      );
    } else {
      if (activeSubscription?.status === 'past_due') {
        return (
          <span>
            {t('billing.NextBillingDate')} {SUBSCRIPTION_STATUS[userSubscription.status]}
          </span>
        );
      } else {
        return (
          <span>
            {t('billing.NextBillingDate')}{' '}
            {new Date(userSubscription.currentPeriodEnd * 1000).toLocaleDateString()}
          </span>
        );
      }
    }
  };

  return (
    <>
      {activeSubscription && (
        <div className={styles.CardWrapper}>
          <div className={styles.ContentWrapper}>
            <div className={styles.SubscriptionDescription}>
              {activeSubscription && (
                <>
                  <span style={{ fontWeight: 'bold' }}>{subscriptionName(activeSubscription)}</span>
                  <span>
                    {addDot(activeSubscription.unitAmountDecimal.toString())}
                    {'€'}
                    {activeSubscription.recurrence ? ' / ' + activeSubscription.recurrence : ''}
                  </span>
                  {subscriptionEnd(activeSubscription)}
                </>
              )}
            </div>
            <div className={styles.ManageSubscription}>
              <form action={`${API_URL}/stripe/create-portal-session`} method="POST">
                {activeSubscription.cancelAtDueDate ? (
                  <>
                    <input type="hidden" name="userId" value={session.user?.id} />
                    <input type="hidden" name="type" value={'cancel'} />
                    <Button
                      id="cancelPlanButton"
                      variant="warning"
                      type="submit"
                      style={{ width: '200px', height: '40px' }}
                      // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
                      disabled={!!session.user?.deleteByDate}
                    >
                      {t('billing.RenewPlan')}
                    </Button>
                  </>
                ) : (
                  <>
                    <input type="hidden" name="userId" value={session.user?.id} />
                    <input type="hidden" name="type" value={'cancel'} />
                    <Button
                      id="cancelPlanButton"
                      variant="danger"
                      type="submit"
                      style={{ width: '200px', height: '40px' }}
                      // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
                      disabled={!!session.user?.deleteByDate}
                    >
                      {t('billing.CancelPlan')}
                    </Button>
                  </>
                )}
              </form>
            </div>
          </div>

          <div className={styles.ContentWrapper}>
            <div className={styles.SubscriptionDescription}>
              {t('billing.PaymentMethod')}
              <span style={{ fontWeight: 'bold' }}>{billingInfo()}</span>
            </div>

            <div className={styles.ManageSubscription}>
              <form action={`${API_URL}/stripe/create-portal-session`} method="POST">
                <input type="hidden" name="userId" value={session.user?.id} />
                <input type="hidden" name="type" value={'payments'} />
                <Button
                  id="manageButton"
                  variant="primary"
                  type="submit"
                  style={{ width: '200px', height: '40px' }}
                  // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
                  disabled={!!session.user?.deleteByDate}
                >
                  {t('Manage')}
                </Button>
              </form>

              <form
                className="mt-2"
                action={`${API_URL}/stripe/create-portal-session`}
                method="POST"
              >
                <input type="hidden" name="userId" value={session.user?.id} />
                <input type="hidden" name="type" value={'invoices'} />
                <Button
                  id="billingButton"
                  variant="primary"
                  type="submit"
                  style={{ width: '200px', height: '40px' }}
                  // disabled={disablePortalCreator(userEmail) || !!session.user?.deleteByDate}
                  disabled={!!session.user?.deleteByDate}
                >
                  {t('Billing')}
                </Button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
