import { ViewUUID, ComponentUUID, ManifestLinks } from '../../../types';
import React, { memo, forwardRef, useState, useEffect } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { PageRepository } from 'modules/designer/repos';
import { useDispatch } from 'react-redux';
import { ProjectsRepo } from 'modules/project/repos/index';
import { useParams } from 'react-router-dom';
import { changeComponentProperty } from '../../store/actions/components';
import * as FontIcon from 'react-icons/fa';
import { parseCustomCss, removeDeclarationsWithUndefinedValue } from 'utils/cssUtils';
import useCorrectedUrl from 'modules/designer/hooks/correctUrl';
import useCalculateComponentSize from 'modules/designer/hooks/useCalculateComponentSize';

export type NavbarData = {
  height?: number;
  heightUnit?: number | string;
  width?: number;
  widthUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  placement: string;
  showTitle?: boolean;
  title: string;
  linkTitle?: string;
  titlePosition: string;
  logo?: string;
  logoWidth?: number;
  logoHeight?: number;
  links: ManifestLinks;
  automaticLabels: boolean;
  linksPosition: string;
  translateKey?: string;
  hasTranslation?: string;
  visible?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
};

export type NavbarStyles = {
  staticColor?: string;
  themeColor?: string;
  color?: string;
  fontSize?: number;
  fontWeight?: string;
  borderRadius?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  borderTop?: number;
  borderLeft?: number;
  borderRight?: number;
  borderBottom?: number;
  paddingTop?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingBottom?: number;
  css?: string;
  iconColor?: string;
  classes?: string[];
};

export type NavbarComponentProps = {
  viewUUID: ViewUUID;
  uuid: ComponentUUID;
  data: NavbarData;
  styles: NavbarStyles;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
};

export type AutomaticLabel = {
  name: string;
  route: string;
  uuid: string;
};

function NavbarComponent(props: NavbarComponentProps, ref: React.Ref<any>) {
  const { app_id, module_id } = useParams();
  const [pagesToShow, setPagesToShow] = useState<AutomaticLabel[]>([]);
  const dispatch = useDispatch();
  const [appName, setAppName] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (!module_id || !app_id) return;

      let filteredArr: any[] = [];
      const pages = await PageRepository.getPagesByModule(module_id);

      filteredArr = pages.filter((current) => {
        const found = Object.values(props.data.links.items).find(
          (item) => item.page === current.uuid
        );
        return found ? false : true;
      });

      setPagesToShow(filteredArr);

      const gettingProjectsName = await ProjectsRepo.getProjectById(app_id);
      setAppName(gettingProjectsName.name);
    })();
  }, [app_id, module_id, props.data.links.items]);

  let style = {
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.width && props.data.widthUnit
      ? {
          width: props.data.width + '' + props.data.widthUnit
        }
      : {
          width: 'auto'
        }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    backgroundColor: props.styles.staticColor,
    color: `${props.styles.color}`,
    fontSize: `${props.styles.fontSize}px`,
    fontWeight: `${props.styles.fontWeight}`,
    borderRadius: `${props.styles.borderRadius}px`,
    paddingTop: `${props.styles.paddingTop}px`,
    paddingRight: `${props.styles.paddingRight}px`,
    paddingBottom: `${props.styles.paddingBottom}px`,
    paddingLeft: `${props.styles.paddingLeft}px`,
    borderTop: `${props.styles.borderTop}px solid #333`,
    borderRight: `${props.styles.borderRight}px solid #333`,
    borderBottom: `${props.styles.borderBottom}px solid #333`,
    borderLeft: `${props.styles.borderLeft}px solid #333`,
    marginTop: `${props.styles.marginTop}px`,
    marginRight: `${props.styles.marginRight}px`,
    marginBottom: `${props.styles.marginBottom}px`,
    marginLeft: `${props.styles.marginLeft}px`,
    Position: 'unset'
  };

  removeDeclarationsWithUndefinedValue(style);
  style = useCalculateComponentSize(props.uuid, style) ?? style;

  const classes = props.styles.classes?.join(' ') || '';

  // set the title with the appName
  useEffect(() => {
    // set only if the title does not exist
    if (!props.data.title && appName) {
      dispatch(changeComponentProperty(props.uuid, 'title', appName));
    }
  }, [appName, dispatch, props.data.title, props.uuid]);

  const correctUrl = useCorrectedUrl(props.data.logo);

  const title = (
    <Navbar.Brand
      href="#"
      style={{ color: style.color }}
      className="h-100 d-flex align-items-center"
    >
      {props.data.logo && (
        <img
          src={correctUrl}
          width={props.data.logoWidth}
          height={
            props.data.logoWidth ? props.data.logoWidth : props.data.height && props.data.height - 8
          }
          alt={props.data.title}
          className="d-inline-block align-top"
        />
      )}
      {props.data.hasTranslation ? `{${props.data.translateKey}}` : props.data.title}
    </Navbar.Brand>
  );

  const linksPosition =
    props.data.linksPosition === 'left'
      ? 'me-auto'
      : props.data.linksPosition === 'right'
      ? 'ms-auto'
      : 'mx-auto';

  const customCss = parseCustomCss(props.styles.css);

  return (
    <Navbar
      ref={ref}
      bg={props.styles.themeColor}
      data-bs-theme={props.styles.themeColor}
      style={{ ...style, ...customCss }}
      fixed={
        props.data && props.data.placement && ['top', 'bottom'].includes(props.data.placement)
          ? (props.data.placement as 'top' | 'bottom')
          : undefined
      }
      className={classes}
    >
      <Container>
        {props.data.titlePosition === 'left' && title}

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className={`${linksPosition}`}>
            {props.data.links &&
              props.data.links.children &&
              props.data.links.children[props.uuid] &&
              props.data.links.children[props.uuid].map((key, index) => {
                const item = props.data.links.items[key];
                const iconDiv = item.icon ? React.createElement(FontIcon[item.icon]) : <div />;

                return (
                  <Nav.Link key={index} style={{ color: style.color }}>
                    {iconDiv && (
                      <div
                        className="d-inline-block align-text-top"
                        style={{
                          marginTop: '-4px',
                          marginRight: '4px',
                          color: props.styles.iconColor!
                        }}
                      >
                        {iconDiv}
                      </div>
                    )}
                    {item.title}
                  </Nav.Link>
                );
              })}

            {props.data.automaticLabels &&
              pagesToShow.map((item: any, index: number) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    href={`#`}
                    style={{
                      color: style.color || customCss.color,
                      fontSize: style.fontSize || customCss.fontSize
                    }}
                  >
                    {item.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
          </Nav>
        </Navbar.Collapse>

        {props.data.titlePosition === 'right' && title}
      </Container>
    </Navbar>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(NavbarComponent));
