import React, { useCallback, useEffect } from 'react';
import { Dispatch } from 'redux';
import {
  FRONTEND_EVENT_TYPES,
  LayoutComponent,
  Message,
  MessageTypes
} from 'modules/designer/types';
import { v4 as uuidv4 } from 'uuid';
import {
  getNextComponent,
  getParentIdFromComponent,
  getPreviousComponent
} from 'modules/designer/studio/designer_utils';
import { setSelectedComponent } from 'modules/designer/studio/store/actions/studio';
import { duplicateComponent } from 'modules/designer/studio/store/actions/components';
import { COMPONENT_TYPES, COMPONENTS_TEMPLATE } from 'modules/designer/studio/exocode_components';
import { faker } from '@faker-js/faker';
import { SchemaItem } from 'modules/logic_builder/types';
import produce from 'immer';
import { deleteComponent, extractForm, LinkInfo } from 'modules/designer/studio/store/actions/root';
import { FormContent, FormNestedObjectInfo } from 'modules/designer/studio/exocode_components/form';
import { t } from 'i18next';
import {
  ConfirmationInfo,
  ConvertCustomComponentInfo
} from 'modules/designer/studio/components/viewport';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';

function convertFormNestedObjectsToInput(formContent: FormContent, input: any) {
  const inputContent = {
    uuid: '',
    objectItem: '',
    isProp: false,
    nestedObjects: {}
  };
  let firstLevelObjectItemUuid: string | null = null;

  if (input.parentObject) {
    let currentObjectItemUuid: string = input.objectItemUuid;

    while (formContent.nestedObjectsPath[currentObjectItemUuid]) {
      const formNestedObjectInfo: FormNestedObjectInfo =
        formContent.nestedObjectsPath[currentObjectItemUuid];

      inputContent.nestedObjects = {
        ...inputContent.nestedObjects,
        [formNestedObjectInfo.parentObjectItemUuid]: {
          objectUuid: formNestedObjectInfo.parentObjectUuid,
          objectItemUuid: currentObjectItemUuid
        }
      };

      firstLevelObjectItemUuid = currentObjectItemUuid = formNestedObjectInfo.parentObjectItemUuid;
    }
  } else {
    firstLevelObjectItemUuid = input.objectItemUuid;
  }

  return { nestedObjects: inputContent.nestedObjects, objectItemUuid: firstLevelObjectItemUuid };
}

const requestConvertCustomModal = (componentId: string) => {
  const content: ConvertCustomComponentInfo = {
    componentUUID: componentId
  };
  const navigateMessage: Message = {
    type: MessageTypes.CONVERT_CUSTOM_COMPONENT,
    content: content
  };
  window.parent.postMessage(navigateMessage);
};

export const useHandleClickDesignerAction = (dispatch: Dispatch, componentId: string) => {
  // useConfirmationListener(dispatch, componentId);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const handleClickAction = useCallback(
    (action, componentId, viewId, parentId, links, components, variables, objects, editor) => {
      switch (action) {
        case 'SELECT_PARENT_COMPONENT':
          handleSelectParentComponent(dispatch, componentId, viewId, links);
          break;
        case 'SELECT_PREVIOUS_COMPONENT':
          handleSelectPreviousComponent(dispatch, componentId, viewId, links);
          break;
        case 'SELECT_NEXT_COMPONENT':
          handleSelectNextComponent(dispatch, componentId, viewId, links);
          break;
        case 'COPY_COMPONENT': {
          // Map between old ids and new ids. We send this to the backend because the ids from
          // both the backend and the frontend has to be the same.
          const oldIdsNewIds: Record<string, string> = {};
          oldIdsNewIds[componentId] = uuidv4();
          const check = [componentId];
          while (check.length) {
            const checkThis = check.pop();
            if (!checkThis) break;
            const children = links[checkThis];
            if (!children) continue;
            for (const child of children) {
              check.push(child);
              oldIdsNewIds[child] = uuidv4();
            }
          }
          dispatch(duplicateComponent(parentId || viewId, componentId, oldIdsNewIds));
          break;
        }
        case 'EXTRACT_FORM_COMPONENT':
          handleExtractFormComponent(
            dispatch,
            componentId,
            viewId,
            parentId,
            components,
            variables,
            objects
          );
          break;
        case 'EDIT_TEXT_COMPONENT':
          handleEditTextComponent(dispatch, componentId, components);
          break;
        case 'CONVERT_COMPONENT_TO_CUSTOM_COMPONENT':
          requestConvertCustomModal(componentId);
          break;
        default:
          throw new Error('Missing implementation for toolbar action handler.');
      }
    },
    [dispatch, componentId]
  );

  return handleClickAction;
};

function handleSelectParentComponent(
  dispatch: Dispatch,
  componentId: string,
  viewId: string,
  links: any
) {
  const parentId = getParentIdFromComponent(componentId, links);
  if (parentId && parentId !== viewId) {
    dispatch(setSelectedComponent(parentId));
  }
}

function handleSelectPreviousComponent(
  dispatch: Dispatch,
  componentId: string,
  viewId: string,
  links: any
) {
  const previousComponent = getPreviousComponent(componentId, links, viewId);
  if (previousComponent && previousComponent !== viewId) {
    dispatch(setSelectedComponent(previousComponent));
  }
}

function handleSelectNextComponent(
  dispatch: Dispatch,
  componentId: string,
  viewId: string,
  links: any
) {
  const nextComponent = getNextComponent(componentId, links, viewId);
  if (nextComponent && nextComponent !== viewId) {
    dispatch(setSelectedComponent(nextComponent));
  }
}

function handleCopyComponent(
  dispatch: Dispatch,
  componentId: string,
  parentId: string | null,
  viewId: string,
  links: any
) {
  const oldIdsNewIds: Record<string, string> = {};
  oldIdsNewIds[componentId] = uuidv4();
  const check = [componentId];
  while (check.length) {
    const checkThis = check.pop();
    if (!checkThis) break;
    const children = links[checkThis];
    if (!children) continue;
    for (const child of children) {
      check.push(child);
      oldIdsNewIds[child] = uuidv4();
    }
  }
  dispatch(duplicateComponent(parentId || viewId, componentId, oldIdsNewIds));
}

function handleExtractFormComponent(
  dispatch: Dispatch,
  componentId: string,
  viewId: string,
  parentId: string | null,
  components: any,
  variables: any,
  objects: any
) {
  const form = components[componentId];
  if (!form.data.content.variable) return;

  const inputs: LayoutComponent[] = [];
  for (const formInput of form.data.content.inputs) {
    let newComponent: LayoutComponent | null = null;
    switch (formInput.type) {
      case 'checkbox':
        newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.CHECK], name: '' };
        break;
      case 'number':
        newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTNUMBER], name: '' };
        break;
      case 'date':
        newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTDATE], name: '' };
        break;
      case 'text':
        newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUT], name: '' };
        break;
      case 'select':
        newComponent = { ...COMPONENTS_TEMPLATE[COMPONENT_TYPES.SELECT], name: '' };
        break;
      default:
        throw new Error('Missing implementation for input type.');
    }
    if (newComponent) {
      const { nestedObjects, objectItemUuid } = convertFormNestedObjectsToInput(
        form.data.content,
        formInput
      );

      newComponent.uuid = uuidv4();
      newComponent.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
      newComponent.data.label = formInput.label;
      newComponent.data.variable = {
        isProp: false,
        uuid: form.data.content.variable,
        objectItem: objectItemUuid,
        nestedObjects: nestedObjects
      };

      if (formInput.type === 'select') {
        const objectUuid = variables[form.data.content.variable]?.objectUuid?.toString();

        if (!objectUuid) return;

        const varObject = objects[objectUuid];

        if (!varObject || !varObject.objectItems) return;

        const objectItems = varObject.objectItems.filter(
          (objectItem: SchemaItem) => objectItem.uuid === formInput.objectItemUuid
        );

        if (objectItems.length === 0) return;

        newComponent.data.optionSourceType = 'ENUM';
        newComponent.data.optionRef = { uuid: (objectItems[0] as any).enumId };
      }
      newComponent.data.options = [];
      inputs.push(JSON.parse(JSON.stringify(newComponent)));
    }
  }
  if (form.data.extraButton) {
    const extraButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
      draft.uuid = uuidv4();
      draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
      draft.data.text = form.data.extraButton;
      draft.styles.themeColor = 'primary';
      if (form.events && form.events[FRONTEND_EVENT_TYPES.CLICK]) {
        draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.CLICK];
      }
    });
    inputs.push(extraButton);
  }

  const submitButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
    draft.uuid = uuidv4();
    draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
    draft.data.text = form.data.submitText;
    draft.styles.themeColor = 'primary';
    if (form.events && form.events[FRONTEND_EVENT_TYPES.SUBMIT]) {
      draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.SUBMIT];
    }
  });
  inputs.push(submitButton);

  const link: LinkInfo = {
    viewUUID: viewId,
    parentUUID: parentId && parentId !== '' ? parentId : viewId
  };
  dispatch(extractForm(componentId, link, inputs));
}

function handleEditTextComponent(dispatch: Dispatch, componentId: string, components: any) {
  if (!components || !componentId || !components[componentId]) return;
  dispatch(setSelectedComponent(componentId));
  const inputRef = window.parent.document.getElementById('formText');
  if (inputRef) inputRef.focus();
}
