import { Reducer, combineReducers, ReducersMapObject, CombinedState } from 'redux';

// export default function combine<T, S>(
//   reducers: ReducersMapObject<S, any>,
//   rootReducer: Reducer<CombinedState<S>, any> | null = null,
//   anotherRootReducer: Reducer<CombinedState<T>, any> | null = null
// ): Reducer<CombinedState<S>> {
//   const originalReducers = combineReducers(reducers);
//
//   return function combination(state: any, action: any) {
//     let nextState = originalReducers(state, action);
//
//     if (rootReducer != null) {
//       nextState = rootReducer(nextState, action);
//       if (anotherRootReducer != null) {
//         nextState = anotherRootReducer(nextState, action);
//       }
//
//       if (typeof nextState === 'undefined') {
//         const actionType = action && action.type;
//         throw new Error(
//           'When called with an action of type ' +
//             (actionType ? '"' + String(actionType) + '"' : '(unknown type)') +
//             ', the root reducer returned undefined. ' +
//             'To ignore an action, you must explicitly return the previous state.'
//         );
//       }
//     }
//
//     return nextState !== state ? nextState : state;
//   };
// }

export default function combine<T, S>(
  reducers: ReducersMapObject<S, any>,
  rootReducer: Reducer<CombinedState<S>, any> | null = null,
  anotherRootReducer: Reducer<CombinedState<T>, any> | null = null
): Reducer<CombinedState<S>> {
  const originalReducers = combineReducers(reducers);

  return function combination(state: CombinedState<S> | undefined, action: any): CombinedState<S> {
    let nextState = originalReducers(state, action);

    if (rootReducer) {
      nextState = rootReducer(nextState, action);
    }

    if (anotherRootReducer) {
      nextState = anotherRootReducer(
        nextState as unknown as CombinedState<T>,
        action
      ) as unknown as CombinedState<S>;
    }

    if (typeof nextState === 'undefined') {
      const actionType = action && action.type;
      throw new Error(
        `When called with an action of type ${
          actionType ? `"${String(actionType)}"` : '(unknown type)'
        }, the root reducer returned undefined. ` +
          'To ignore an action, you must explicitly return the previous state.'
      );
    }

    return nextState !== state ? nextState : state;
  };
}

export function combineHistory(reducer: Reducer, historyReducer: Reducer): Reducer {
  return function combination(state: any, action: any) {
    let nextState = historyReducer(state, action);
    nextState = reducer(nextState, action);

    return nextState !== state ? nextState : state;
  };
}
