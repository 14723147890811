import {
  Action,
  Endpoint,
  EndpointExtended,
  EndpointRequest,
  EndpointResponse,
  EndpointResponseSimple,
  EndpointSimple,
  ParameterEndpoint
} from '../types';
import { StructToSaveEndpointCrud } from 'web_ui/function_editor/store/types/endpoint';
import { ApiError } from '../../project/types';

export const API_URL = process.env.REACT_APP_API_URL;

const baseOptions: RequestInit = {
  headers: new Headers({
    'Content-Type': 'application/json'
  }),
  mode: 'cors',
  credentials: 'include'
};

export class EndpointsRepo {
  /**
   * create endpoints using automation crud
   */
  async createEnpointAutomationCrud(
    dataDTO: StructToSaveEndpointCrud,
    moduleId: string,
    entityId: string
  ): Promise<StructToSaveEndpointCrud | undefined> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(dataDTO)
    };

    const url = `${API_URL}/modules/${moduleId}/automations/crud/${entityId}/endpoints`;
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    } else if (response.status === 409) {
      const apiError: ApiError = await response.json();
      throw new Error(apiError.message);
    }
    throw new Error('Something went wrong while trying to fetch Endpoint.');
  }

  /**
   * Get the information of a specific endpoint.
   */
  async getEndpoint(endpointId: string): Promise<EndpointExtended> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointExtended>;

      throw new Error('Something went wrong while trying to fetch Endpoint.');
    });
  }

  /**
   * Get a list of endpoints from a module.
   */
  async getEndpointsByModule(moduleId: string, method?: string): Promise<Endpoint[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    let url = `${API_URL}/modules/${moduleId}/endpoints`;
    if (method) url = url + `?methodType=${method}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint[]>;

      throw new Error('Something went wrong while trying to get endpoints.');
    });
  }

  /**
   * Get a list of endpoints from a controller.
   */
  async getEndpointsByControllerId(controllerId: string, moduleId: string): Promise<Endpoint[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/modules/${moduleId}/controller/${controllerId}/endpoints`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint[]>;

      throw new Error('Something went wrong while trying to get endpoints.');
    });
  }

  /**
   * Get the information of the endpoint action.
   */
  async getEndpointAction(endpointId: string): Promise<Action<any>> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}/action`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>>;

      throw new Error('Something went wrong while trying to get the call.');
    });
  }

  /**
   * Returns the information of the endpoint parameters.
   */
  async getParameters(endpointId: string): Promise<ParameterEndpoint[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}/parameters`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ParameterEndpoint[]>;

      throw new Error('Something went wrong while trying to fetch list of Parameters.');
    });
  }

  /**
   * Delete a specific endpoint.
   */
  async deleteEndpoint(endpointId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/endpoints/${endpointId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete endpoint.');
    });
  }

  /**
   * Deletes a specific endpoint parameter and related information.
   */
  async deleteParameter(endpointId: string, parameterId: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/endpoints/${endpointId}/parameters/${parameterId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete parameter.');
    });
  }

  /**
   * Creates a new endpoint (attached to a module).
   */
  async createEndpoint(moduleId: string, endpoint: EndpointSimple): Promise<Endpoint> {
    const endpointSchema = endpoint as EndpointSimple;

    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(endpointSchema)
    };

    const url = `${API_URL}/modules/${moduleId}/endpoints`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint>;

      throw new Error('Something went wrong while trying to create a new endpoint.');
    });
  }

  /**
   * Creates a new parameter (attached to an endpoint).
   */
  async createParameter(
    endpointId: string,
    parameter: ParameterEndpoint
  ): Promise<ParameterEndpoint> {
    const parameterSchema = parameter as ParameterEndpoint;

    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(parameterSchema)
    };

    const url = `${API_URL}/endpoints/${endpointId}/parameters`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ParameterEndpoint>;

      throw new Error('Something went wrong while trying to create a new parameter.');
    });
  }

  /**
   * Updates an endpoint.
   */
  async updateEndpoint(endpointId: string, endpoint: Endpoint): Promise<Endpoint> {
    const endpointSchema = endpoint as Endpoint;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(endpointSchema)
    };

    const url = `${API_URL}/endpoints/${endpointId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Endpoint>;

      throw new Error('Something went wrong while trying to update endpoint.');
    });
  }

  /**
   * Changes the action executed by the endpoint.
   */
  async updateEndpointAction(endpointId: string, action: Action<any>): Promise<Action<any>> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(action)
    };

    const url = `${API_URL}/endpoints/${endpointId}/action`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<Action<any>>;

      throw new Error('Something went wrong while trying to update the endpoint Action.');
    });
  }

  /**
   * Updates the information of an endpoint parameter.
   */
  async updateParameter(
    endpointId: string,
    parameterId: string,
    parameter: ParameterEndpoint
  ): Promise<ParameterEndpoint> {
    const parameterSchema = parameter as ParameterEndpoint;

    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(parameterSchema)
    };

    const url = `${API_URL}/endpoints/${endpointId}/parameters/${parameterId}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<ParameterEndpoint>;

      throw new Error('Something went wrong while trying to update parameter.');
    });
  }

  // -------------------------- Endpoints Responses and Requests --------------------------

  /**
   * Create a new endpoint response.
   */
  async createEndpointResponse(
    endpointId: string,
    response: EndpointResponse
  ): Promise<EndpointResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'POST',
      body: JSON.stringify(response)
    };

    const url = `${API_URL}/endpoints/${endpointId}/responses`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointResponse>;

      throw new Error('Something went wrong while trying to create a new endpoint response.');
    });
  }

  /**
   * Fetch all the endpoint responses.
   */
  async getEndpointResponses(endpointId: string): Promise<EndpointResponseSimple[]> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}/responses`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointResponseSimple[]>;

      throw new Error('Something went wrong while trying to fetch endpoint response.');
    });
  }

  /**
   * Fetch a specific endpoint response.
   */
  async getEndpointResponse(endpointId: string, status: string): Promise<EndpointResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}/responses/${status}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointResponse>;

      throw new Error('Something went wrong while trying to fetch endpoint response.');
    });
  }

  /**
   * Update an endpoint response.
   */
  async updateEndpointResponse(
    endpointId: string,
    status: string,
    response: EndpointResponse
  ): Promise<EndpointResponse> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'PUT',
      body: JSON.stringify(response)
    };

    const url = `${API_URL}/endpoints/${endpointId}/responses/${status}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointResponse>;

      throw new Error('Something went wrong while trying to update an endpoint response.');
    });
  }

  /**
   * Delete a specific endpoint response.
   */
  async deleteEndpointResponse(endpointId: string, status: string) {
    const options: RequestInit = {
      ...baseOptions,
      method: 'DELETE'
    };

    const url = `${API_URL}/endpoints/${endpointId}/responses/${status}`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return;

      throw new Error('Something went wrong while trying to delete an endpoint response.');
    });
  }

  /**
   * Returns the information of the endpoint parameter with a Body input type.
   */
  async getEndpointRequest(endpointId: string): Promise<EndpointRequest> {
    const options: RequestInit = {
      ...baseOptions,
      method: 'GET'
    };

    const url = `${API_URL}/endpoints/${endpointId}/request`;

    return await fetch(url, options).then((response) => {
      if (response.ok) return response.json() as Promise<EndpointRequest>;

      throw new Error('Something went wrong while trying to fetch endpoint request.');
    });
  }

  async sendAction(moduleId: string, endpointId: string, action: any) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'POST',
      headers: headers,
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify(action)
    };

    const endpoint = `${API_URL}/modules/${moduleId}/endpoints/${endpointId}/action`;

    return await fetch(endpoint, options).then((response) => response);
  }

  async getInitialEndpointEditorActionsState(module_id: string, endpoint_id: string) {
    const headers = new Headers({
      'Content-Type': 'application/json'
    });

    const options: RequestInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
      credentials: 'include'
    };

    const endpoint = `${API_URL}/states/modules/${module_id}/endpoints/${endpoint_id}`;

    const response = await fetch(endpoint, options);
    const body = await response.json();

    if (response.status !== 200) {
      throw new Error(body.message);
    }

    return body;
  }
}
