import { t } from 'i18next';
import { Teams } from 'modules/auth/types/auth_types';
import React, { useState } from 'react';
import { Row, Col, OverlayTrigger, Button, ListGroup } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import styles from './styles.module.css';
import ManageTeamModal from '../manage_team_modal';
import { TeamServ } from 'modules/organization/services';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Confirmation from 'web_ui/confirmation';
import HelpIcon from '../../../../../../../web_ui/workboard/sidebar/controls/components/HelpIcon';

type Props = {
  teams: Teams[];
  orgId: number | null;
  updateList: () => void;
};

const TeamList = ({ teams, orgId, updateList }: Props) => {
  const [openManageTeam, setOpenManageTeam] = useState<boolean>(false);
  const [currentTeamId, setCurrentTeamId] = useState<number>(-1);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState<number | null>(null);
  const [teamNameToDelete, setTeamNameToDelete] = useState<string | null>(null);

  const closingManageTeam = () => {
    setOpenManageTeam(false);
  };

  const openingManageTeam = (team: Teams) => {
    if (team.disabled) {
      return;
    }
    setCurrentTeamId(team.id);
    setOpenManageTeam(true);
  };

  const deletingTeam = async (teamId: number) => {
    if (!orgId) return;
    const dataRetrieved = await TeamServ.deleteTeam(orgId, teamId);
    console.log(dataRetrieved);
    if (dataRetrieved) {
      updateList();
      setShowConfirmationDialog(false);
    }
  };

  const handleDeleteFunction = (teamId: number, teamName: string) => {
    setTeamToDelete(teamId);
    setTeamNameToDelete(teamName);
    setShowConfirmationDialog(true);
  };

  return (
    <div>
      {teams.map((item, index) => (
        <ListGroup.Item
          id={item.name.substring(0, 5).toUpperCase()}
          className={`d-flex p-2 ${item.disabled ? styles.disabledContainer : styles.container}`}
          key={item.id}
          variant="body"
          onClick={() => {
            openingManageTeam(item);
          }}
        >
          {/* Avatar */}
          <Col xs={'auto'} className={`d-flex align-items-center`}>
            <div className={`${styles.avatar} me-2}`}>
              {item.name.substring(0, 1).toUpperCase()}
            </div>
          </Col>
          {/* Username */}
          <Col className={`${styles.infoCol} d-flex ps-0`}>
            <Col
              style={{
                fontWeight: '500',
                overflowWrap: 'break-word',
                paddingLeft: '0.75rem'
              }}
            >
              {item.name}
            </Col>

            <Col className={'d-flex'}>
              {/* Operations in Members */}
              <Col className={`${styles.iconsMember}`}>
                {!item.disabled && (
                  <HelpPopover
                    placement="top"
                    helpBoxProps={{
                      title: t('Edit') ?? ''
                    }}
                  >
                    <Button
                      id={`deleteButton-${index}`}
                      className={styles.iconButton}
                      variant="body"
                      onClick={() => {
                        openingManageTeam(item);
                      }}
                    >
                      <Icon iconName="pen-to-square" padding="0.5rem" />
                    </Button>
                  </HelpPopover>
                )}

                <HelpPopover
                  placement="top"
                  helpBoxProps={{
                    title: t('Delete') ?? ''
                  }}
                >
                  <Button
                    id={`deleteButton-${index}`}
                    variant="body"
                    className={styles.iconButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteFunction(item.id, item.name);
                    }}
                  >
                    <Icon iconName="trash-can" padding="0.5rem" />
                  </Button>
                </HelpPopover>
              </Col>
            </Col>
          </Col>
          {item.disabled && (
            <div style={{ display: 'inline-block', marginTop: '0px' }}>
              <HelpPopover
                helpBoxProps={{
                  title: t('dashboard.projectRow.PlanIssue') || 'dashboard.projectRow.PlanIssue',
                  description:
                    t('dashboard.projectRow.cantSelect') || 'dashboard.projectRow.cantSelect',
                  note: [t('dashboard.projectRow.currentPlans')]
                }}
                placement="top"
              >
                <HelpIcon />
              </HelpPopover>
            </div>
          )}
        </ListGroup.Item>
      ))}
      <ManageTeamModal
        show={openManageTeam}
        close={closingManageTeam}
        orgId={orgId}
        updateList={updateList}
        teamId={currentTeamId}
      />
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('organizations.organization.deleteTeam')} ${
          teamNameToDelete ? `(${teamNameToDelete})` : ''
        }`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => {
          if (teamToDelete !== null) {
            deletingTeam(teamToDelete);
          }
        }}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

export default TeamList;
