import { Action } from 'redux';
import { FunctionParameterType, ParametersState } from '../../types/parameters';

export const SET_SORT = 'SET_SORT';

export interface SetSortAction extends Action {
  type: 'SET_SORT';
  payload: {
    functionUuid: string;
    value: boolean;
    sortUuid: string;
  };
}

export const setSort = (functionUuid: string, value: boolean, sortUuid: string): SetSortAction => ({
  type: SET_SORT,
  payload: { functionUuid, value, sortUuid }
});

export function doSetSort(state: ParametersState, action: SetSortAction): ParametersState {
  const { value, sortUuid } = action.payload;
  if (value) {
    state[sortUuid] = createSortParam(sortUuid);
    return state;
  }

  if (!state[sortUuid]) return state;
  delete state[sortUuid];
  return state;
}

const createSortParam = (sortUuid: string): FunctionParameterType => {
  return {
    uuid: sortUuid,
    name: 'sort',
    type: 'STRING',
    desc: '',
    list: false,
    required: true,
    native: true
  };
};
