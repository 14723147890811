import { DataType, ParameterEndpoint } from 'modules/logic_builder/types';
import { MakeOptional } from 'utils/types';

export interface ParametersState {
  [key: string]: FunctionParameterType;
}

export type FunctionParameterType = {
  uuid: string;
  name: string;
  type: DataType;
  desc: string;
  objectUuid?: string;
  enumUuid?: string;
  list: boolean;
  required: boolean;
  native?: boolean;
  relationshipId?: string;
} & MakeOptional<ParameterEndpoint, 'description' | 'inputName' | 'inputType'>;

export interface ChangeFunctionParameterPayload {
  uuid: string;
  name: string;
  type: string;
}

export const FORBIDDEN_PARAMS_NAMES = ['sort', 'filter', 'page', 'size'];
