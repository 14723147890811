import { Middleware } from 'redux';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { FrontendFunctionsRepo } from '../../repo';
import { storeAPIEvents } from '../../../../../web_ui/function_editor/store/events';

const actions = Object.values(storeAPIEvents);

let lastAction: string | null = null;
let timerRef: NodeJS.Timeout | null = null;

export const saveMiddleware: Middleware<Record<string, unknown>, FunctionEditorState> = function (
  store
) {
  return (next) => (action) => {
    if (!actions.includes(action.type)) return next(action);

    if (lastAction === action.type && timerRef) {
      clearTimeout(timerRef);
    }

    lastAction = action.type;
    const nextAction = next(action);

    timerRef = setTimeout(() => {
      const { moduleId, callerId } = store.getState().editor;
      FrontendFunctionsRepo.sendAction(moduleId, callerId, action);
      //   .then(async (response) => {
      //   if (!response.ok) {
      //     store.dispatch({ type: RESTORE_LAST_VALID_STATE });
      //     const body: any = await response.json();
      //
      //     const i18nKeyErrorMessage =
      //       BACKEND_FUNCTIONS_EDITOR_ERROR_MESSAGES.ERROR_PROCESSING_ACTION;
      //     store.dispatch(setErrorMessage(body?.message ?? i18nKeyErrorMessage));
      //   } else {
      //     store.dispatch({ type: SAVE_LAST_VALID_STATE });
      //   }
      // }
      // );
    }, 350);

    return nextAction;
  };
};
