import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RootState, store } from './reducers';
import { useEndpointEditorDispatch, useEndpointEditorSelector } from 'hooks/reduxHooks';
import { initializeEndpointEditorState } from './reducers/stateReducer';
import AnimatedLoading from 'web_ui/animated_loading';
import { Sidebar } from 'web_ui/function_editor/components/sidebar';
import ActionsToolbar from 'web_ui/function_editor/components/actions_toolbar';
import { ENDPOINT_ACTIONS_MANIFEST } from 'modules/logic_builder/function_editor/function_actions';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES
} from 'modules/logic_builder/function_editor/function_actions/types/actions';
import { EditorHeader } from './components/editor_header';
import { EditorActions } from './components/editor_actions';
import {
  AppRoleService,
  FunctionService,
  ObjectsService,
  TagsService
} from '../../../modules/logic_builder/services';
import { ExoRole, FunctionType, ObjectSimple, Tag } from '../../../modules/logic_builder/types';
import { DataService } from '../../../modules/modeler/services';
import { EnumFrame } from '../../../modules/modeler/types';
import EditActionToolbar from 'web_ui/function_editor/components/edit_action_toolbar';
import { SidebarPosition } from 'web_ui/workboard/sidebar';
import { AppContext } from '../../../modules/project/store/app_context';
import { ApiAppInfo } from '../../../modules/project/types';
import { useViews } from 'modules/designer/hooks/outletContext';
import { getEndpointEditorActions } from 'web_ui/walkthrough/actions';
import { setIsEndpointEditorOpen } from './reducers/editorStatus';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

// Provide some useful information.
// Do not change these after the initial render (this thing is at the root of this component).
export const EndpointEditorContext = createContext<{
  appId: string;
  moduleId: string;
  endpointId: string;
  functionId: string;
  moduleRoles: ExoRole[];
  functions: Record<string, FunctionType[]>;
  enums: EnumFrame[];
  objects: ObjectSimple[];
  tags: Tag[];
  appInfo?: ApiAppInfo;
}>({
  appId: '',
  moduleId: '',
  endpointId: '',
  functionId: '',
  moduleRoles: [],
  functions: {},
  enums: [],
  objects: [],
  tags: []
});

function EndpointEditorInner() {
  const { app_id, endpoint_id, module_id } = useParams();
  const dispatch = useEndpointEditorDispatch();
  const status = useEndpointEditorSelector((state) => state.editorStatus.status);
  const callerInfo = useEndpointEditorSelector((state: RootState) => state.callerInfo);
  const [moduleRoles, setModuleRoles] = React.useState<ExoRole[]>([]);
  const [functions, setFunctions] = React.useState<Record<string, FunctionType[]>>({});
  const [enums, setEnums] = React.useState<EnumFrame[]>([]);
  const [objects, setObjects] = React.useState<ObjectSimple[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const appInfo = useContext(AppContext).projectInformation;
  const [isLoading, setIsLoading] = useState(true);
  const { setWalkthroughStepsActions } = useViews();
  const [done, setDone] = useState(false);

  const fetchFunctions = useCallback(async (moduleId: string) => {
    const fetchedFunctions = await FunctionService.getFunctions(moduleId);
    const formattedFunctions: { [key: string]: FunctionType[] } = {};
    fetchedFunctions.forEach((func) => {
      if (func.serviceUuid == null) return;
      if (formattedFunctions[func.serviceUuid] == null) {
        formattedFunctions[func.serviceUuid] = [];
      }
      formattedFunctions[func.serviceUuid].push(func);
    });
    setFunctions(formattedFunctions);
  }, []);

  const getObjects = useCallback(async (module_id) => {
    await ObjectsService.getObjectsByModule(module_id).then((objects) => {
      setObjects(objects);
    });
  }, []);

  const getEnums = useCallback(async (module_id) => {
    await DataService.getEnums(module_id).then((enums) => {
      setEnums(enums);
    });
  }, []);

  const getRoles = React.useCallback(async (app_id: string) => {
    await AppRoleService.getRoles(app_id).then((roles: ExoRole[]) => {
      setModuleRoles(roles);
    });
  }, []);

  const fetchTags = useCallback(async (appId: string) => {
    const fetchedTags = await TagsService.getTags(appId);
    setTags(fetchedTags);
  }, []);

  useEffect(() => {
    if (!endpoint_id || !app_id || !module_id) return;
    if (status === 'loading') return;

    if (status === 'idle' || callerInfo.uuid !== endpoint_id) {
      dispatch(initializeEndpointEditorState({ endpointId: endpoint_id, moduleId: module_id }));
    } else if (status === 'error') {
      console.error('Error fetching endpoint state.');
    }
  }, [
    app_id,
    callerInfo.uuid,
    dispatch,
    endpoint_id,
    fetchFunctions,
    fetchTags,
    getEnums,
    getObjects,
    getRoles,
    module_id,
    status
  ]);

  useEffect(() => {
    if (done) return;
    setWalkthroughStepsActions(
      'logic_endpoint-editor',
      getEndpointEditorActions(() => {
        dispatch(setIsEndpointEditorOpen({ isOpen: true }));
      })
    );
    setDone(true);
  }, [dispatch, done, setWalkthroughStepsActions]);

  useEffect(() => {
    if (!app_id || !module_id || !endpoint_id) return;

    Promise.all([
      getRoles(app_id),
      fetchFunctions(module_id),
      getEnums(module_id),
      getObjects(module_id),
      fetchTags(app_id)
    ]).finally(() => setIsLoading(false));
  }, [app_id, endpoint_id, fetchFunctions, fetchTags, getEnums, getObjects, getRoles, module_id]);

  if (isLoading) {
    return <AnimatedLoading />;
  }
  if (!endpoint_id || !app_id || !module_id) {
    return <></>;
  }
  if (status === 'loading') {
    return <AnimatedLoading />;
  }
  if (status === 'error') {
    return <></>;
  }
  if (status === 'idle') {
    return <></>;
  }

  return (
    <EndpointEditorContext.Provider
      value={{
        appId: app_id,
        moduleId: module_id,
        endpointId: endpoint_id,
        functionId: '',
        moduleRoles: moduleRoles,
        functions: functions,
        enums: enums,
        objects: objects,
        tags: tags,
        appInfo: appInfo
      }}
    >
      <div
        className={'d-flex bg-body-tertiary'}
        style={{ overflow: 'hidden' }}
        id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-main']}
      >
        {/*Left sidebar.*/}
        <Sidebar.Root>
          <Sidebar.Content>
            <ActionsToolbar
              actions={Object.values(ENDPOINT_ACTIONS_MANIFEST)}
              categories={Object.values(BACKEND_ACTIONS_CATEGORIES)}
              categoriesIcons={BACKEND_ACTION_CATEGORY_ICONS}
            />
          </Sidebar.Content>
        </Sidebar.Root>
        {/*Middle part.*/}
        <div
          className={`container`}
          style={{
            width: 'calc(100% - 480px)',
            height: 'calc(100vh - 60px)'
          }}
        >
          <EditorHeader />
          <EditorActions />
        </div>
        {/*Right sidebar.*/}
        <Sidebar.Root position={SidebarPosition.RIGHT}>
          <EditActionToolbar manifests={ENDPOINT_ACTIONS_MANIFEST} />
        </Sidebar.Root>
      </div>
    </EndpointEditorContext.Provider>
  );
}

export function EndpointEditor() {
  return (
    <Provider store={store}>
      <EndpointEditorInner />
    </Provider>
  );
}
