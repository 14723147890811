import React from 'react';
import styles from '../editor_panel/styles.module.css';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { setPagination } from '../../../../store/events/parameters/set_pagination';
import { v4 as uuidv4 } from 'uuid';
import { setSort } from '../../../../store/events/parameters/set_sort';
import { setFilter } from '../../../../store/events/parameters/set_filter';
import { useTranslation } from 'react-i18next';

function OptionsEditor() {
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const callerId = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const func = useSelector((state: FunctionEditorState) => state.functions[callerId]);
  const parameters = useSelector((state: FunctionEditorState) => state.parameters);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!func) {
    return <p>Loading</p>;
  }

  // If the callerId is an endpoint, these boolean properties are being edited
  // elsewhere. Check for the file endpoint_editor/index.tsx.
  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  const handleSetPagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return dispatch(setPagination(callerId, e.target.checked, uuidv4(), uuidv4()));
    }
    const functionParams = func.parameters.map((paramId) => parameters[paramId]);
    const pageUuid = functionParams.filter((param) => param.name === 'page')[0].uuid;
    const sizeUuid = functionParams.filter((param) => param.name === 'size')[0].uuid;
    return dispatch(setPagination(callerId, e.target.checked, pageUuid, sizeUuid));
  };

  const handleSetSort = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return dispatch(setSort(callerId, e.target.checked, uuidv4()));
    }
    const functionParams = func.parameters.map((paramId) => parameters[paramId]);
    const sortUuid = functionParams.filter((param) => param.name === 'sort')[0].uuid;
    return dispatch(setSort(callerId, e.target.checked, sortUuid));
  };

  const handleSetFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      return dispatch(setFilter(callerId, e.target.checked, uuidv4()));
    }
    const functionParams = func.parameters.map((paramId) => parameters[paramId]);
    const filterUuid = functionParams.filter((param) => param.name === 'filter')[0].uuid;
    return dispatch(setFilter(callerId, e.target.checked, filterUuid));
  };

  if (func.options === undefined) return null;

  return (
    <div className={styles.FixedOptions}>
      <Form.Check
        id="paginationCheck"
        label={t('automation.step3.Pagination')}
        checked={func.options.pagination}
        onChange={handleSetPagination}
        className={styles.check}
      />
      <Form.Check
        label={t('automation.step3.Sort')}
        checked={func.options.sortable}
        onChange={handleSetSort}
        id="sortCheck"
        className={styles.check}
      />
      <Form.Check
        label={t('automation.step3.Filter')}
        checked={func.options.filterable}
        onChange={handleSetFilter}
        id="filterCheck"
        className={styles.check}
      />
    </div>
  );
}

export default OptionsEditor;
