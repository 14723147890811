import React from 'react';

function SessionInfoIcon() {
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      <span style={{ fontStyle: 'italic', fontFamily: 'serif', fontWeight: 'bolder' }}>
        <i className="fa-solid fa-user"></i>
      </span>
    </span>
  );
}

export default SessionInfoIcon;
