import { Dispatch, SetStateAction } from 'react';
import { IconList } from '../../../packages/react-fa-icon-picker';
import { ComponentTypes } from '../studio/exocode_components';
import { ViewData, ViewStyles } from '../studio/frames/page_view';
import { ComponentsState, LinksState, WidgetsState } from '../studio/store';
import {
  DATA_TYPES,
  EndpointSimple,
  LogicBuilderConcept,
  ParameterEndpoint
} from 'modules/logic_builder/types';
import { InputValidationOutput } from 'utils/inputValidation';
import { EditorMode } from 'web_ui/function_editor/store/types/function_editor_state';
import { MoveSource, MoveTarget } from '../studio/store/actions/links';

export type ComponentUUID = string;

export type SavedState = {
  logicBuilder: {
    lastSelectedTab: LogicBuilderConcept;
    lastSelectedService: string;
    lastSelectedController: string;
    folderState: {
      objects: { [key: string]: boolean };
      functions: { [key: string]: boolean };
      endpoints: { [key: string]: boolean };
      schedulerJobs: { [key: string]: boolean };
    };
  };
  interface: {
    lastSelectedTab: string;
    lastSelectedTabBehavior: string;
    lastSelectedView: string;
    lastSelectedComponent: string;
    lastSelectedViewport: string;
    lastSelectedLeftSidebarTab: number;
    lastSelectedRightSidebarTab: number;
    collapsedFolders: { [key: string]: boolean };
    collapsedGroups: string[];
  };
  dbmodeler: {
    lastSelectedLeftSidebarTab: number;
    lastSelectedFrame: string;
    enumFolders: {
      collapsedFolders: { [key: string]: boolean };
    };
    tableFolders: {
      collapsedFolders: { [key: string]: boolean };
    };
  };
};

export type Template = {
  uuid: string;
  type: string;
  name: string;
  description: string;
  thumbnail: string;
  isNative: boolean;
  createdByUser: string;
  owner: string;
  isOwnerOrg: boolean;
  appId: string;
  isPublic: boolean;
};

export type CrudTemplate = {
  templates: Template[];
  masterEndpoints: TemplateEndpoint[];
  detailEndpoints: TemplateEndpoint[];
};

export type TemplateEndpoint = EndpointSimple & {
  uuid: string;
  pathParams: ParameterEndpoint[];
  crudType: string;
};

export interface LayoutComponent<D = any, S = any, E = any> {
  uuid: ComponentUUID;
  type: ComponentTypes;
  data?: D;
  styles?: S;
  custom_uuid?: string;
  events?: E;
  // Name and description are filled when type === CUSTOM.
  name?: string;
  description?: string;
  classes?: string[];
}

export const Editor = {
  PAGE: 'PAGE',
  MODAL: 'MODAL',
  LAYOUT: 'LAYOUT',
  CUSTOM_COMPONENT: 'CUSTOM_COMPONENT'
} as const;

export type CustomComponentsUUID = string;
export interface CustomComponent {
  uuid: CustomComponentsUUID;
  // appId: string;
  name: string;
  description?: string;
  components?: ComponentsState;
  links?: LinksState;
  widgets?: WidgetsState;
  isHierarchy?: boolean;
  event: { [key: string]: FrontendFunctionUUID };
  // properties?: FrontentPropertyUUID[];
}

export interface Modal {
  uuid: string;
  type: string;
  name: string;
  description: string;
  template?: string;
  folder_id?: string;
  properties?: FrontendProperty[];
  disabled?: boolean;
}

export interface Page {
  uuid: string;
  type: string;
  name: string;
  description: string;
  route: string;
  layout: string;
  modals: string[]; // List of modal uuids
  template?: string;
  params: PageParam[];
  data: any;
  folder_id?: string;
  editorMode?: string;
  disabled?: boolean;
}

export interface Layout {
  uuid: string;
  type: string;
  name: string;
  description: string;
  template?: string;
  folder_id?: string;
  disabled?: boolean;
}

export interface ILayoutDefault {
  defaultLayout: string;
}

export interface Folder {
  uuid: string;
  name: string;
  folders?: Folder[];
  parent?: string;
  moduleId?: string;
}

export type ViewUUID = string;

export interface View {
  uuid: ViewUUID;
  type: string;
  posX?: number;
  posY?: number;
  data: ViewData;
  styles: ViewStyles;
  layout_uuid?: ViewUUID;
  functions: FrontendFunctionUUID[];
  params: string[];
  variables: VariableUUID[];
  properties: FrontendPropertyUUID[];
  events: { [key: string]: FrontendFunctionUUID };
  roles: number[];
  uiFolderId?: string;
  editorMode?: EditorModeOptions;
  classes?: string[];
  modificationTime: Date;
  modifiedByUser: string;
  creationTime: Date;
  createdByUser: string;
}

export type FrontendFunctionUUID = string;
export type FrontendVariableUUID = string;
export type FrontendPropertyUUID = string;

export const FRONTEND_FUNCTION_TYPES = {
  REST: 'REST',
  NAVIGATE: 'NAVIGATE',
  TOGGLE_MODAL: 'TOGGLE_MODAL'
};

export type FunctionTypes = keyof typeof FRONTEND_FUNCTION_TYPES;

export interface FrontendFunction {
  uuid: FrontendFunctionUUID;
  name: string;
  description: string;
  customComponent?: string;
  view?: string;
}

export const FRONTEND_VARIABLE_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  ANY: 'ANY',
  OBJECT: 'OBJECT'
};

export const FRONTEND_TO_BACKEND_TYPES: { [key: string]: string[] } = {
  [FRONTEND_VARIABLE_TYPES.STRING]: [DATA_TYPES.STRING, DATA_TYPES.VARCHAR, DATA_TYPES.CHAR],
  [FRONTEND_VARIABLE_TYPES.NUMBER]: [
    DATA_TYPES.INTEGER,
    DATA_TYPES.DOUBLE,
    DATA_TYPES.DECIMAL,
    DATA_TYPES.SMALLINT,
    DATA_TYPES.FLOAT,
    DATA_TYPES.BIGINT
  ],
  [FRONTEND_VARIABLE_TYPES.BOOLEAN]: [DATA_TYPES.BOOLEAN],
  [FRONTEND_VARIABLE_TYPES.OBJECT]: [DATA_TYPES.OBJECT],
  [FRONTEND_VARIABLE_TYPES.ANY]: [FRONTEND_VARIABLE_TYPES.ANY]
};

// Used for frontend variables and properties.
export type VariableTypes = keyof typeof FRONTEND_VARIABLE_TYPES;

export type VarComponent = {
  variable: {
    uuid: string;
    objectItem: string | null | undefined;
  };
};

export interface FrontendVariable {
  uuid: FrontendVariableUUID;
  name: string;
  type: VariableTypes;
  native: boolean;
  list: boolean;
  objectUuid?: string;
  view?: string;
  customComponent?: string;
  // --- Only one of these should be set.
  initialValueFixed?: string;
  initialValuePropId?: string;
  initialValueParamId?: string;
  // ---.
  frontendFunctionId?: string | null;
}

export interface FrontendProperty {
  uuid: string;
  view?: string;
  customComponent?: string;
  name: string;
  type: VariableTypes;
  native: boolean;
  list: boolean;
  object?: string;
  required: boolean;
}

export type VariableUUID = string;

export type ParamsUUID = string;

export interface PageParam {
  uuid: ParamsUUID;
  name: string;
  native: boolean;
  viewid: ViewUUID;
  // Page parameter order
  order: number;
  route: string;
}

export const FRONTEND_EVENT_TYPES = {
  LOAD: 'LOAD',
  LEAVE: 'LEAVE',
  CLICK: 'CLICK',
  HOVER: 'HOVER',
  MOUNT: 'MOUNT',
  UNMOUNT: 'UNMOUNT',
  SUBMIT: 'SUBMIT',
  SEARCH: 'SEARCH',
  SELECT: 'SELECT',
  CHANGE: 'CHANGE'
};

export type EventTypes = keyof typeof FRONTEND_EVENT_TYPES;
export type ThemeUUID = string;
export type ThemeValueUUID = string;

export interface ThemeValues {
  id?: ThemeValueUUID;
  section: string;
  key: string;
  value: string;
}

export interface Theme {
  id?: ThemeUUID;
  name: string;
  values?: ThemeValues[];
  selected?: boolean;
}

export type PreferenceId = string;

export interface Preference {
  key: string;
  value: boolean;
}

export interface AppLanguages {
  id: string;
  locale: string;
  name: string;
}

export interface AppTranslation {
  id: string;
  key: string;
  value: string;
}

export interface AppProperties {
  key: string;
  value: string;
  description: string | null;
  comment: boolean;
}

export type AssetURI = string;

export interface ComponentManifest {
  type: string;
  name: string;
  descriptionId: string;
  description: string;
  previewHeight: number;
  previewWidth: number;
  icon?: string;
  group?: string;
  preview?: AssetURI;
  properties: ComponentPropField[];
  styles: ComponentStyleField[];
  events?: EventTypes[];
  validParents?: ComponentTypes[];
  validChildren?: ComponentTypes[];
  allowDrop?: boolean;
  maxChildren?: number;
  hasSections?: boolean;
}

export interface ComponentPropField {
  controlType: string;
  controlLabel: string;
  descriptionId?: string;
  controlOptions?: string[];
  key?: string;
  // Used for validating the input.
  validation?: (input: any) => InputValidationOutput;
  // Used for input text.
  maxLength?: number;
  tooltip?: string;
  // Record<INPUT_ERROR_CODES -> error message translation>. Used in conjunction with the property
  // 'validation'.
  errorMessages?: Record<string, string>;
}

export interface ViewManifest {
  type: string;
  name: string;
  description: string;
  icon?: string;
  preview?: AssetURI;
  properties: ViewPropField[];
  styles: ViewStyleField[];
}

export interface ComponentStyleField {
  controlType: string;
  controlLabel: string;
  controlOptions?: string[];
  key?: string;
  tooltip?: string;
}

export interface ViewPropField {
  controlType: string;
  controlLabel: string;
  controlOptions?: string[];
  key: string;
  // Used for input validation.
  validation?: (input: any) => InputValidationOutput;
  // Used for inputs of type text or textarea.
  maxLength?: number;
  tooltip?: string;
  // Record<INPUT_ERROR_CODES -> error message translation>. Used in conjunction with the property
  // 'validation'.
  errorMessages?: Record<string, string>;
}

export interface ViewStyleField {
  controlType: string;
  controlLabel: string;
  controlOptions?: string[];
  key: string;
  tooltip?: string;
}

export enum LinkTarget {
  BLANK = '_blank',
  SELF = '_self'
}

export type LinkUUID = string;

export enum fixPostion {
  TOP = 'Top',
  BOTTOM = 'Bottom',
  DEFAULT = ''
}

export type Link = {
  id: string;
  // head?: boolean;
  title?: string;
  description?: string;
  url?: string;
  page?: string; // UUID da page
  icon?: IconList;
  disableIcon?: boolean;
  target?: LinkTarget;
  hide?: boolean;
  isDropdown?: boolean;
  openNewTab?: boolean;
  readOnly?: boolean;
  fixed?: fixPostion;
  isDivider?: boolean;
};

export type ManifestLinks = {
  items: { [key: LinkUUID]: Link };
  children: { [key: LinkUUID]: LinkUUID[] };
};

export type LinksToManifest = { [key: LinkUUID]: Link };
export type ChildrenToManifest = { [key: LinkUUID]: LinkUUID[] };

export type TreeProp = {
  items: LinksToManifest;
  children: ChildrenToManifest;
  onSelected: Dispatch<SetStateAction<string>>;
  idNav: string;
  onCreate: (id: string) => void;
  onRemove: (id: string) => void;
  setChildrenState: Dispatch<SetStateAction<ChildrenToManifest>>;
  marginLeft: number;
};

export enum MessageTypes {
  READY = 'ready',
  LOAD = 'load',
  ACTION = 'action',
  NAVIGATE = 'navigate',
  CONFIRMATION = 'confirmation',
  CONFIRMATION_RESULT = 'confirmation_result',
  CONVERT_CUSTOM_COMPONENT = 'convert_custom_component',
  PREFERENCES = 'preferences',
  CONTEXT_MENU_OPEN = 'context_menu_open',
  IFRAME_CONTEXT_MENU = 'iframe_context_menu',
  PREVIEW_LOADED = 'preview_loaded'
}

export interface Message {
  type: MessageTypes;
  content?: any;
}

export enum ComponentGroups {
  LAYOUT = 'LAYOUT',
  DATA = 'DATA',
  BUTTON = 'BUTTON',
  INPUT = 'INPUT',
  MEDIA = 'MEDIA',
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER',
  ALERT = 'ALERT',
  STATUS = 'STATUS',
  MENU = 'MENU'
}

export interface GroupManifest {
  type: string;
  name: string;
}

export const LAYOUT_GROUP_MANIFEST: GroupManifest = {
  type: 'LAYOUT',
  name: 'Layouts'
};

export const BUTTON_GROUP_MANIFEST: GroupManifest = {
  type: 'BUTTON',
  name: 'Buttons'
};

export const INPUT_GROUP_MANIFEST: GroupManifest = {
  type: 'INPUT',
  name: 'Inputs'
};

export const MEDIA_GROUP_MANIFEST: GroupManifest = {
  type: 'MEDIA',
  name: 'Medias'
};

export const TEXT_GROUP_MANIFEST: GroupManifest = {
  type: 'TEXT',
  name: 'Texts'
};

export const CONTAINER_GROUP_MANIFEST: GroupManifest = {
  type: 'CONTAINER',
  name: 'Containers'
};

export const ALERT_GROUP_MANIFEST: GroupManifest = {
  type: 'ALERT',
  name: 'Alerts'
};

export const STATUS_GROUP_MANIFEST: GroupManifest = {
  type: 'STATUS',
  name: 'Status'
};

export const MENU_GROUP_MANIFEST: GroupManifest = {
  type: 'MENU',
  name: 'Menus'
};

export const CUSTOM_GROUP_MANIFEST: GroupManifest = {
  type: 'CUSTOM',
  name: 'Customs'
};

export const DATA_GROUP_MANIFEST: GroupManifest = {
  type: 'DATA',
  name: 'Data'
};

export const GROUPS_MANIFEST: { [key: string]: GroupManifest } = {
  [ComponentGroups.LAYOUT]: LAYOUT_GROUP_MANIFEST,
  [ComponentGroups.DATA]: DATA_GROUP_MANIFEST,
  [ComponentGroups.BUTTON]: BUTTON_GROUP_MANIFEST,
  [ComponentGroups.INPUT]: INPUT_GROUP_MANIFEST,
  [ComponentGroups.MEDIA]: MEDIA_GROUP_MANIFEST,
  [ComponentGroups.TEXT]: TEXT_GROUP_MANIFEST,
  [ComponentGroups.CONTAINER]: CONTAINER_GROUP_MANIFEST,
  [ComponentGroups.ALERT]: ALERT_GROUP_MANIFEST,
  [ComponentGroups.STATUS]: STATUS_GROUP_MANIFEST,
  [ComponentGroups.MENU]: MENU_GROUP_MANIFEST
};

export enum ReservedNames {
  'types',
  'api',
  'components'
}

export enum FolderType {
  UNKNOWN = 'unknown',
  DB = 'db',
  LOGIC = 'logic',
  LG_OBJ = 'lgobject',
  LG_SRV = 'lgservice',
  LG_CONTROLLER = 'lgcontroller',
  LG_SCHEDULER = 'lgscheduler',
  UI = 'ui'
}

export interface FolderCreateDTO {
  id: string;
  name: string;
  type: FolderType;
  parent?: string; // The parent folder.
}

export enum EditorModeOptions {
  NORMAL = 'NORMAL',
  ADVANCED = 'ADVANCED',
  PIXEL_PERFECT = 'PIXEL_PERFECT'
}

export interface MoveComponentPayload {
  source: MoveSource;
  target: MoveTarget;
}

export const viewPortRatioMapper: Record<string, string> = {
  mobile: '9/16',
  tablet: '3/4',
  desktop: '16/9'
};
