import { SystemRole, SystemRoleAuthorityName, UserLimits } from '../modules/auth/types/auth_types';

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function byteConverter(bytes: number, decimals: number, only?: string) {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  if (bytes === 0) return '0 Byte';

  if (only === 'MB') return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i];

  return resp;
}

export function validateRegex(pattern: RegExp, str: string): boolean {
  return pattern.test(str);
}

export function generateUniqueName(desiredName: string, existingNames: string[]): string {
  const existingNamesSet = new Set(existingNames);

  if (!existingNamesSet.has(desiredName)) {
    return desiredName;
  }

  let counter = 1;
  let uniqueName = `${desiredName}${counter}`;
  while (existingNamesSet.has(uniqueName)) {
    counter++;
    uniqueName = `${desiredName}${counter}`;
  }

  return uniqueName;
}

export function toCamelCase(str: string, capitalize?: boolean) {
  const camelCase = str.replace(/^\w|[A-Z]|\b\w|\s+/g, (match, index) => {
    if (/\s+/.test(match)) return ''; // for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
  return capitalize ? camelCase.charAt(0).toUpperCase() + camelCase.slice(1) : camelCase;
}

export function toSnakeCase(str: string) {
  if (!str) return '';
  const matchedString = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
  );
  if (!matchedString) return '';
  return matchedString.map((s) => s.toLowerCase()).join('_');
}

export function convertingCSS(val: string, toReturn: string) {
  if (val && val.includes('#')) {
    return val;
  }
  switch (val) {
    case 'primary':
      return toReturn;
    case 'info':
      return toReturn;
    case 'danger':
      return toReturn;
    case 'warning':
      return toReturn;
    case 'dark':
      return toReturn;
    case 'success':
      return toReturn;
    case 'light':
      return toReturn;
    case 'secondary':
      return toReturn;
    default:
      return val;
  }
}

/**
 * Get the feature limit.
 * @param user user from which to retrieve the limits.
 * @param feature the feature/authority from which the limit will be extracted.
 * @return a positive integer defining the limit of the feature or zero if the user doesn't have access to the feature.
 */
export function getFeatureLimit(user: UserLimits, feature: SystemRoleAuthorityName): number {
  // CUSTOM limits
  if (user.customLimits) {
    const limit = user.customLimits.find((l) => l.name === feature);
    if (limit) {
      // Authority with a custom limit
      return limit.featureLimit;
    }
  }
  // Subscription limits
  if (user.systemRoles) {
    const sa = user.systemRoles
      .flatMap((role: SystemRole) => {
        return role.systemRoleAuthorities;
      })
      .find((l) => l.name === feature);
    if (!sa) {
      // No Authority -> set the limit to zero
      return 0;
    }
    if (!sa.subscriptionPlanLimits) {
      // Authority without limit
      return Number.MAX_SAFE_INTEGER;
    } else {
      // Authority with a configured limit
      return sa.subscriptionPlanLimits.featureLimit;
    }
  }
  return 0;
}
