import * as React from 'react';
import { ControlProps } from '../..';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.css';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import EmptyMessage from 'web_ui/empty';
import { useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';
import { sleep } from 'utils/utils';
import { FileMetaData } from 'routes/dashboard/components/tabs/app_assets/modal';
import { setErrorMessage } from 'modules/designer/studio/store/actions/studio';
import { useDispatch } from 'react-redux';
import { PopupAlert } from 'web_ui/popup_alert';

interface Props {
  controlPropsFromRoot: ControlProps;
  show: boolean;
  onHide: () => void;
}

const formData = new FormData();

export default function ModalPictures(props: Props) {
  const [option, setOption] = React.useState<string>('photos');
  const API_URL = process.env.REACT_APP_API_URL;
  const refFileInput = React.useRef<HTMLInputElement | null>(null);
  const { app_id } = useParams();
  const [imagePreview, setImagePreview] = React.useState<string | ArrayBuffer | null | undefined>(
    ''
  );
  const [linkValue, setLinkValue] = React.useState<string>('');
  const [dataFromApi, setDataFromApi] = React.useState<FileMetaData[]>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const [alertMessage, setAlertMessage] = React.useState<string>('');

  const handleDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  const getPreviewUrl = (url: string): string => {
    const API_URL = process.env.REACT_APP_API_URL;
    return API_URL + url;
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  const showAlertPopup = (msg: string) => {
    setAlertMessage(msg);
  };

  const handleDrop = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer && ev.dataTransfer.files && ev.dataTransfer.files[0]) {
      const reader = new FileReader();

      // Read data.
      reader.readAsDataURL(ev.dataTransfer.files[0]);

      // After reading data.
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        setImagePreview(e.target.result);
      };

      // Save image into formData - it needs to be in the final.
      formData.append('file', ev.dataTransfer.files[0]);
    }
  };

  const savingImage = React.useCallback(async () => {
    if (!formData.get('file')) {
      dispatch(setErrorMessage('insert some file!'));
      throw new Error('Failed to upload file!');
    }

    fetch(`${API_URL}/apps/${app_id}/assets`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    })
      .then((dataRaw) => {
        if (dataRaw.status === 413) {
          setImagePreview('');
          formData.delete('file');
          dispatch(setErrorMessage('appResume.assets.tooLarge'));
          return;
        }
        return dataRaw.json();
      })
      .then((res) => {
        // Save the file, retrieve it from the response, and set its properties.
        if (res) {
          // Retrieve the current file from formData and compare it with the data received in the
          // POST request.
          const myCurrFile = formData.get('file');
          if (myCurrFile instanceof File && res.name === myCurrFile.name) {
            onChangeChosenPhoto(res.url);
            props.onHide();
          }
          formData.delete('file');
          setImagePreview('');
          showAlertPopup('uploadImage.successUpload');
        }
      })
      .catch((err) => {
        throw new Error('Failed to upload file!');
      });
  }, []);

  React.useEffect(() => {
    fetch(`${API_URL}/apps/${app_id}/assets`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      credentials: 'include',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((rawData) => rawData.json())
      .then((data) => {
        setDataFromApi(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throw new Error(err);
      });
  }, [option]);

  const onChangeChosenPhoto = async (urlPhoto: string) => {
    if (props.controlPropsFromRoot.onChange) {
      const urlValue = urlPhoto === props.controlPropsFromRoot.value ? '' : urlPhoto;
      props.controlPropsFromRoot.onChange(urlValue, props.controlPropsFromRoot.id);
      await sleep(100);
      urlValue && props.controlPropsFromRoot.onChange('', 'variable');
    }
  };

  const openFileSelector = () => {
    refFileInput.current?.click();
  };

  const savingFormDataAndShowTheImage = (ev: any) => {
    const reader = new FileReader();

    if (ev.target.files && ev.target.files.length > 0) {
      // reading data
      reader.readAsDataURL(ev.target.files[0]);

      // after Read
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        setImagePreview(e.target.result);
      };

      // saving img into formdata - it needs to be in the final
      formData.append('file', ev.target.files[0]);
    }
  };

  const rederingPhotosContainer = () => {
    return (
      <div className={styles.containerPhotos}>
        {!loading ? (
          <>
            {dataFromApi && dataFromApi.length === 0 ? (
              <div className={styles.containerWithoutPhotos}>
                <EmptyMessage message={t('appResume.assets.NoAssets')} icon="exclamation" />
              </div>
            ) : (
              <>
                {dataFromApi &&
                  dataFromApi.map((data, index) => (
                    <>
                      <Image
                        key={data.id}
                        src={getPreviewUrl(data.url)}
                        rounded
                        className={`${styles.imageStyle} ${
                          data.url === props.controlPropsFromRoot.value
                            ? 'border border-5 border-primary'
                            : ''
                        }`}
                        onClick={() => {
                          onChangeChosenPhoto(data.url);
                          props.onHide();
                        }}
                      />
                    </>
                  ))}
              </>
            )}
          </>
        ) : (
          <div className={`w-100 h-100 d-flex justify-content-center align-items-center`}>
            <div className={`fa-3x ${styles.spinner}`}>
              <i className="fas fa-spinner fa-spin"></i>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderingUploadContainer = () => {
    return (
      <div
        onDragOver={(e) => handleDragOver(e)}
        onDrop={(e) => handleDrop(e)}
        className={styles.containerUpload}
        onClick={imagePreview ? () => {} : () => openFileSelector()}
        style={imagePreview ? { border: 'none' } : {}}
      >
        {imagePreview ? (
          <div>
            <Image src={imagePreview.toString()} roundedCircle width={250} height={250} />
            <div className={styles.imageUploadButton}>
              <Button
                variant="success"
                className={styles.butonSaveDelete}
                onClick={() => {
                  savingImage();
                }}
              >
                {t('appResume.assets.Save')}
              </Button>
              <Button
                variant="danger"
                className={styles.butonSaveDelete}
                onClick={() => {
                  setImagePreview('');
                  if (formData.get('file')) {
                    formData.delete('file');
                  }
                }}
              >
                {t('appResume.assets.Delete')}
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p className={styles.textUpload}>
              {t('uploadImage.uploadPhrase')}
              <br />
              {t('uploadImage.or')}
              <br />
            </p>
            <button className={styles.buttonForUpload}>{t('uploadImage.button')}</button>
          </div>
        )}

        <input
          type="file"
          style={{ display: 'none' }}
          ref={refFileInput}
          onChange={savingFormDataAndShowTheImage}
          required
        />
      </div>
    );
  };

  const renderingSetLink = () => {
    return (
      <div className={styles.containerExternalLink}>
        <Form.Group style={{ flex: 1, marginTop: '1rem' }}>
          <Form.Label>{t('uploadImage.link.linkQuote')}</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FaLink size={25} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              value={linkValue}
              onChange={(ev) => setLinkValue(ev.target.value)}
            />
          </InputGroup>
          <div className={styles.containerButtons}>
            <Button
              variant="success"
              className={styles.buttonsLinkContainer}
              style={{ marginRight: '1rem' }}
              onClick={() => onChangeChosenPhoto(linkValue)}
            >
              {t('uploadImage.link.save')}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setLinkValue('');
              }}
              className={styles.buttonsLinkContainer}
            >
              {t('uploadImage.link.clear')}
            </Button>
          </div>
        </Form.Group>
      </div>
    );
  };

  const renderingBanner = (currentOption: string) => {
    switch (currentOption) {
      case 'photos':
        return rederingPhotosContainer();
      case 'upload':
        return renderingUploadContainer();
      case 'link':
        return renderingSetLink();
      default:
        return <></>;
    }
  };

  return (
    <>
      {alertMessage && (
        <Modal
          show={!!alertMessage}
          style={{ background: 'transparent', width: 'auto' }}
          centered={false}
          backdrop={false}
        >
          <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant="success" />
        </Modal>
      )}
      <Modal
        size="lg"
        centered
        show={props.show}
        onHide={() => {
          setOption('photos');
          props.onHide();
        }}
      >
        <Modal.Header closeButton>{t('uploadImage.choosePicture')}</Modal.Header>
        <Modal.Body>
          <Nav variant="tabs" defaultActiveKey={option}>
            <Nav.Item onClick={() => setOption('photos')}>
              <Nav.Link eventKey="photos">{t('uploadImage.photo')}</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setOption('upload')}>
              <Nav.Link eventKey="upload">Upload</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setOption('link')}>
              <Nav.Link eventKey="link">Link</Nav.Link>
            </Nav.Item>
          </Nav>
        </Modal.Body>
        {renderingBanner(option)}
      </Modal>
    </>
  );
}
