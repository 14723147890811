import { ComponentType, ReactNode } from 'react';
import TextControl from './TextControl';
import NumberControl from './NumberControl';
import TextareaControl from './TextareaControl';
import CheckboxControl from './CheckboxControl';
import ColorPickerControl from './ColorPickerControl';
import SelectControl from './SelectControl';
import ListControl from './ListControl';
import ListLinksControl from './ListLinksControl';
import SelectPicsControl from './SelectPicsControl';
import DataTableSource from './DataTableSource';
import FormContentControl from './FormContentControl';
import PositionControl from './PositionControl';
import SpacingControl from './Spacing';
import VariableControl from './VariableControl';
import SelectTranslation from './SelectTranslation';
import SizeControl from './SizeControl';
import ListParams from './ListParams';
import OverflowControl from './OverflowControl';
import IconControl from './IconControl';
import ModalSizeControl from './ModalSizeControl';
import { CustomCssControl } from './CssControl';
import MenuTypeControl from './MenuTypeControl';
import IconTextControl from './IconTextControl';
import IconStylesControl from './IconStylesControl';
import PaginationControl from './PaginationControl';
import TableSelectorControl from './TableSelectorControl';
import ActionControl from './ActionsControl';
import SearchControl from './SearchControl';
import ContextMenuControl from './ContextMenuControl';
import AlignTextControl from './AlignTextControl';
import TextFormatControl from './TextControl/text';
import TextDecoration from './TextDecorationControl';
import TextTransform from './TextTransform';
import FontStyleControl from './fontStyleControl';
import VisibleVariableControl from './VariableControl/visibleVariable';
import ReadOnlyControl from './ReadOnlyControl';
import ListSelectorControl from './ListSelectorControl';
import GapControl from './GapControl';
import ListSortControl from './ListSortControl';
import FontControl from './FontControl';
import BoxShadowControl from './BoxShadowControl';
import { InputValidationOutput } from 'utils/inputValidation';
import IconPickerControl from './IconPickerControl';
import GridControl from './GridControl';
import BorderRadiusControl from './BorderRadiusControl';
import CarouselSectionsControl from './CarouselSectionsControl';
import ActionsButtonControl from './ActionsButtonControl';

export enum ControlsTypes {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
  LIST = 'LIST',
  COLOR_PICKER = 'COLOR_PICKER',
  ICON_PICKER = 'ICON_PICKER',
  LIST_LINKS = 'LIST_LINKS',
  SELECT_PICS = 'SELECT_PICS',
  DATA_TABLE_SOURCE = 'DATA_TABLE_SOURCE',
  FORM_CONTENT = 'FORM_CONTENT',
  POSITION = 'POSITION',
  SPACING = 'SPACING',
  VISIBLEVARIABLE = 'VISIBLEVARIABLE',
  VARIABLE = 'VARIABLE',
  SELECT_TRANSLATION = 'SELECT_TRANSLATION',
  SIZE = 'SIZE',
  LIST_PARAMS = 'LIST_PARAMS',
  OVERFLOW = 'OVERFLOW',
  GAP = 'GAP',
  ICONS = 'ICONS',
  MODAL_SIZE = 'MODAL_SIZE',
  CUSTOM_CSS = 'CUSTOM_CSS',
  MENU_TYPE = 'MENU_TYPE',
  ICON_TEXT = 'ICON_TEXT',
  ICON_STYLES = 'ICON_STYLES',
  PAGINATION = 'PAGINATION',
  LIST_SELECTOR = 'LIST_SELECTOR',
  LIST_SORTER = 'LIST_SORTER',
  TABLE_SELECTOR = 'TABLE_SELECTOR',
  ACTION = 'ACTION',
  SEARCH = 'SEARCH',
  CONTEXT_MENU = 'CONTEXT_MENU',
  TEXTALIGN = 'TEXTALIGN',
  TEXTDECORATION = 'TEXTDECORATION',
  TEXTTRANSFORM = 'TEXTTRANSFORM',
  FONTSTYLE = 'FONTSTYLE',
  TEXTFORMAT = 'TEXTFORMAT',
  VARIABLEREADONLY = 'VARIABLEREADONLY',
  FONT = 'FONT',
  BOXSHADOW = 'BOXSHADOW',
  GRID = 'GRID',
  BORDER_RADIUS = 'BORDER_RADIUS',
  CAROUSEL_SECTIONS = 'CAROUSEL_SECTIONS',
  ACTION_BUTTON = 'ACTION_BUTTON'
}

export type ControlProps = {
  id: any;
  label: string;
  value?: any;
  options?: string[];
  onChange?: (value: any, key: any) => any;
  validation?: (input: any) => InputValidationOutput;
  maxLength?: number;
  tooltip?: string;
  errorMessages?: Record<string, string>;
};

// value -> valor da key no manifest

export const ControlsList: { [key: string]: ReactNode } = {
  [ControlsTypes.TEXT]: TextControl as ComponentType<ControlProps>,
  [ControlsTypes.TEXTFORMAT]: TextFormatControl as ComponentType<ControlProps>,
  [ControlsTypes.TEXTAREA]: TextareaControl as ComponentType<ControlProps>,
  [ControlsTypes.NUMBER]: NumberControl as ComponentType<ControlProps>,
  [ControlsTypes.SELECT]: SelectControl as ComponentType<ControlProps>,
  [ControlsTypes.CHECKBOX]: CheckboxControl as ComponentType<ControlProps>,
  [ControlsTypes.LIST]: ListControl as ComponentType<ControlProps>,
  [ControlsTypes.COLOR_PICKER]: ColorPickerControl as ComponentType<ControlProps>,
  [ControlsTypes.ICON_PICKER]: IconPickerControl as ComponentType<ControlProps>,
  [ControlsTypes.LIST_LINKS]: ListLinksControl as ComponentType<ControlProps>,
  [ControlsTypes.SELECT_PICS]: SelectPicsControl as ComponentType<ControlProps>,
  [ControlsTypes.DATA_TABLE_SOURCE]: DataTableSource as ComponentType<ControlProps>,
  [ControlsTypes.FORM_CONTENT]: FormContentControl as ComponentType<ControlProps>,
  [ControlsTypes.POSITION]: PositionControl as ComponentType<ControlProps>,
  [ControlsTypes.SPACING]: SpacingControl as ComponentType<ControlProps>,
  [ControlsTypes.VISIBLEVARIABLE]: VisibleVariableControl as ComponentType<ControlProps>,
  [ControlsTypes.VARIABLE]: VariableControl as ComponentType<ControlProps>,
  [ControlsTypes.SELECT_TRANSLATION]: SelectTranslation as ComponentType<ControlProps>,
  [ControlsTypes.SIZE]: SizeControl as ComponentType<ControlProps>,
  [ControlsTypes.LIST_PARAMS]: ListParams as ComponentType<ControlProps>,
  [ControlsTypes.OVERFLOW]: OverflowControl as ComponentType<ControlProps>,
  [ControlsTypes.GAP]: GapControl as ComponentType<ControlProps>,
  [ControlsTypes.ICONS]: IconControl as ComponentType<ControlProps>,
  [ControlsTypes.MODAL_SIZE]: ModalSizeControl as ComponentType<ControlProps>,
  [ControlsTypes.CUSTOM_CSS]: CustomCssControl as ComponentType<ControlProps>,
  [ControlsTypes.MENU_TYPE]: MenuTypeControl as ComponentType<ControlProps>,
  [ControlsTypes.ICON_TEXT]: IconTextControl as ComponentType<ControlProps>,
  [ControlsTypes.ICON_STYLES]: IconStylesControl as ComponentType<ControlProps>,
  [ControlsTypes.PAGINATION]: PaginationControl as ComponentType<ControlProps>,
  [ControlsTypes.LIST_SELECTOR]: ListSelectorControl as ComponentType<ControlProps>,
  [ControlsTypes.LIST_SORTER]: ListSortControl as ComponentType<ControlProps>,
  [ControlsTypes.TABLE_SELECTOR]: TableSelectorControl as ComponentType<ControlProps>,
  [ControlsTypes.ACTION]: ActionControl as ComponentType<ControlProps>,
  [ControlsTypes.SEARCH]: SearchControl as ComponentType<ControlProps>,
  [ControlsTypes.CONTEXT_MENU]: ContextMenuControl as ComponentType<ControlProps>,
  [ControlsTypes.TEXTALIGN]: AlignTextControl as ComponentType<ControlProps>,
  [ControlsTypes.TEXTDECORATION]: TextDecoration as ComponentType<ControlProps>,
  [ControlsTypes.TEXTTRANSFORM]: TextTransform as ComponentType<ControlProps>,
  [ControlsTypes.FONTSTYLE]: FontStyleControl as ComponentType<ControlProps>,
  [ControlsTypes.VARIABLEREADONLY]: ReadOnlyControl as ComponentType<ControlProps>,
  [ControlsTypes.FONT]: FontControl as ComponentType<ControlProps>,
  [ControlsTypes.BOXSHADOW]: BoxShadowControl as ComponentType<ControlProps>,
  [ControlsTypes.GRID]: GridControl as ComponentType<ControlProps>,
  [ControlsTypes.BORDER_RADIUS]: BorderRadiusControl as ComponentType<ControlProps>,
  [ControlsTypes.CAROUSEL_SECTIONS]: CarouselSectionsControl as ComponentType<ControlProps>,
  [ControlsTypes.ACTION_BUTTON]: ActionsButtonControl as ComponentType<ControlProps>
};

export const LocalhostControlsList = [
  ControlsTypes.SIZE,
  ControlsTypes.SPACING,
  ControlsTypes.POSITION,
  ControlsTypes.MENU_TYPE,
  ControlsTypes.GRID
];
