import React, { useContext, useState } from 'react';
import FunctionDescription from './FunctionDescription';
import ActionEditor from './ActionEditor';
import { ActionsManifests } from '../../store/types/manifestsAndInputs';
import ParameterRelationshipEditor from '../editor/function_header/parameter_editor/parameter_relationship_editor';
import CrudTypeEditor from '../editor/function_header/crudtype_editor/crudtype_editor';
import Icon from 'web_ui/icon';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useTranslation } from 'react-i18next';
import { AvailableFrameworks } from '../editor/function_header/function_signatures';
import { AppContext } from '../../../../modules/project/store/app_context';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { EndpointEditorState } from '../../../../routes/studio/endpoint_editor/reducers';
import { FunctionParameterType } from 'web_ui/function_editor/store/types/parameters';

type EditActionToolbarProps = {
  manifests: ActionsManifests;
  framework?: AvailableFrameworks;
};

function EditActionToolbar({ manifests, framework = 'spring' }: EditActionToolbarProps) {
  const { t } = useTranslation();
  const [showInputs, setShowInputs] = useState(false);
  const params = useSelector((state: FunctionEditorState) => state.parameters);
  const callerId = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const state = useSelector((state: FunctionEditorState | EndpointEditorState) => state);

  let parameters: string[];
  const allParams: Record<string, FunctionParameterType> = { ...params };
  if (mode === 'ENDPOINT_EDITOR' && 'endpointParameters' in state) {
    parameters = state.endpointParameters.map((e) => e.uuid ?? '');

    // Update params.
    state.endpointParameters.forEach((p) => {
      if (!p.uuid) return;
      allParams[p.uuid] = p as FunctionParameterType;
    });
  } else {
    parameters = state.functions[callerId]?.parameters;
  }

  const functions = useSelector((state: FunctionEditorState) => state.functions);
  const appInfo = useContext(AppContext).projectInformation;

  const metadata: {
    modificationTime: Date;
    creationTime: Date;
    modifiedByUser: string;
    createdByUser: string;
  } = {
    modificationTime: new Date(),
    creationTime: new Date(),
    modifiedByUser: '',
    createdByUser: ''
  };
  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    metadata.modificationTime = state.callerInfo.modificationTime;
    metadata.creationTime = state.callerInfo.creationTime;
    metadata.modifiedByUser = state.callerInfo.modifiedByUser;
    metadata.createdByUser = state.callerInfo.createdByUser;
  } else {
    metadata.modificationTime = functions[callerId]?.modificationTime;
    metadata.creationTime = functions[callerId]?.creationTime;
    metadata.modifiedByUser = functions[callerId]?.modifiedByUser;
    metadata.createdByUser = functions[callerId]?.createdByUser;
  }

  const toggleShowInputs = () => {
    setShowInputs(!showInputs);
  };

  const shouldShowCrudAssistantType = mode !== 'ENDPOINT_EDITOR';

  return (
    <div
      id={WALKTHROUGH_STEPS_ELEMENTS['function-editor-properties']}
      style={{
        padding: '0.5rem',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}
    >
      <FunctionDescription />
      {framework === 'spring' && (
        <>
          {appInfo?.has_backend && shouldShowCrudAssistantType && (
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '1rem',
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                justifyContent: 'space-between'
              }}
              onClick={toggleShowInputs}
            >
              CrudAssistant
              <Icon iconName={showInputs ? 'angle-up' : 'angle-down'} />
            </button>
          )}
          {showInputs && (
            <>
              <div style={{ marginTop: '10px' }}>
                <div style={{ marginBottom: '5px' }}>
                  CRUD Type{' '}
                  <HelpPopover
                    helpBoxProps={{
                      description: t('logicBuilder.CrudEditorTooltip') ?? ''
                    }}
                    placement="top"
                  >
                    <span>
                      <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
                    </span>
                  </HelpPopover>
                </div>
                <CrudTypeEditor />
              </div>
              {parameters.map((id) => (
                <div key={id} style={{ marginTop: '10px' }}>
                  <div style={{ marginBottom: '5px' }}>
                    {allParams[id].name}
                    <HelpPopover
                      helpBoxProps={{
                        description: t('logicBuilder.RelationshipEditorTooltip') ?? ''
                      }}
                      placement="top"
                    >
                      <span>
                        <Icon padding={'10px'} brands={'regular'} iconName={'circle-question'} />
                      </span>
                    </HelpPopover>
                  </div>
                  <ParameterRelationshipEditor uuid={id} />
                </div>
              ))}
            </>
          )}
          <hr />
          {appInfo?.is_owner_org && (
            <>
              Group info
              <p style={{ fontSize: 'small' }}>{`Last edited by ${
                metadata.modifiedByUser
              } at ${new Date(metadata.modificationTime).toLocaleDateString()}`}</p>
              <p style={{ fontSize: 'small' }}>{`Created by ${metadata.createdByUser} at ${new Date(
                metadata.creationTime
              ).toLocaleDateString()}`}</p>
            </>
          )}
        </>
      )}
      <ActionEditor manifests={manifests} />
    </div>
  );
}

export default EditActionToolbar;
