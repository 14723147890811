import React from 'react';
import styles from './styles.module.css';
import TagItemRadio from './tag_item_radio';
import { Tag } from 'modules/logic_builder/types';

type TagEditorProps = {
  search: string;
  updateTags: (newTags: number[]) => Promise<void>;
  allTags: Tag[];
  selectedTags: number[];
};

function TagSelector(props: TagEditorProps) {
  const updateTag = async (tagId: number, checked: boolean) => {
    const nextTags: number[] = [...props.selectedTags];
    if (checked) {
      nextTags.push(tagId);
    } else {
      nextTags.splice(nextTags.indexOf(tagId), 1);
    }
    await props.updateTags(nextTags);
  };

  const sortTags = (a: Tag, b: Tag) => {
    if (!a.id || !b.id) return 0;
    const A = props.selectedTags.includes(a.id);
    const B = props.selectedTags.includes(b.id);
    return A === B ? 0 : A ? -1 : 1;
  };

  return (
    <div className={`card p-2 ${styles.TagsWrapper}`}>
      {props.allTags
        .filter((tag) => tag.name.toLowerCase().includes(props.search.toLowerCase()))
        .sort((a, b) => sortTags(a, b))
        .map((tag) => {
          return (
            <TagItemRadio
              key={tag.id}
              tag={tag}
              updateTag={updateTag}
              selectedTags={props.selectedTags}
            />
          );
        })}
    </div>
  );
}

export default TagSelector;
