import TextInput from './inputs/text';
import BackendDataInput from './inputs/backend_data_types';
import FrontendDataInput from './inputs/frontend_data_types';
import ObjectInput from './inputs/object';
import VariableInput from './inputs/variable';
import Select from './inputs/select';
import Checkbox from './inputs/checkbox';
import CodeInput from './inputs/code';
import FunctionInput from './inputs/function';
import EnumInput from './inputs/enum';
import ArgumentPicker from './inputs/argument_picker';
import EndpointPicker from './inputs/endpoint_picker';
import ViewPicker from './inputs/view_picker';
import QueryInput from './inputs/query';
import EntityInput from './inputs/entity';
import RelationshipInput from './inputs/relationship';
import ReturnInput from './inputs/return';
import { InputValidationOutput, validateVariableName } from 'utils/inputValidation';
import TextAreaInput from './inputs/text_area';
import { HttpStatusInput } from './inputs/http_status_input';
import { DataTypeInput } from './inputs/data_type';

export enum ACTION_INPUTS_TYPES {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  CHECKBOX = 'CHECKBOX',
  CODE = 'CODE',
  DBQUERY = 'DBQUERY',
  BACKEND_DATA_TYPES = 'BACKEND_DATA_TYPES',
  FRONTEND_VARIABLE_TYPES = 'FRONTEND_VARIABLE_TYPES',
  OBJECTS_INPUT = 'OBJECTS_INPUT',
  VARIABLES_INPUT = 'VARIABLES_INPUT',
  FUNCTIONS_INPUT = 'FUNCTIONS_INPUT',
  ENUMS_INPUT = 'ENUMS_INPUT',
  ARGUMENT_PICKER = 'ARGUMENT_PICKER',
  ENDPOINT_PICKER = 'ENDPOINT_PICKER',
  VIEW_PICKER = 'VIEW_PICKER',
  DATA_TYPE_INPUT = 'DATA_TYPE_INPUT',
  ENTITY_INPUT = 'ENTITY_INPUT',
  RELATIONSHIP_INPUT = 'RELATIONSHIP_INPUT',
  RETURN_INPUT = 'RETURN_INPUT',
  TEXT_AREA = 'TEXT_AREA',
  HTTP_STATUS_INPUT = 'HTTP_STATUS_INPUT'
}

export const ACTION_INPUTS = {
  [ACTION_INPUTS_TYPES.TEXT]: TextInput,
  [ACTION_INPUTS_TYPES.SELECT]: Select,
  [ACTION_INPUTS_TYPES.CHECKBOX]: Checkbox,
  [ACTION_INPUTS_TYPES.CODE]: CodeInput,
  [ACTION_INPUTS_TYPES.DBQUERY]: QueryInput,
  [ACTION_INPUTS_TYPES.BACKEND_DATA_TYPES]: BackendDataInput,
  [ACTION_INPUTS_TYPES.FRONTEND_VARIABLE_TYPES]: FrontendDataInput,
  [ACTION_INPUTS_TYPES.OBJECTS_INPUT]: ObjectInput,
  [ACTION_INPUTS_TYPES.VARIABLES_INPUT]: VariableInput,
  [ACTION_INPUTS_TYPES.FUNCTIONS_INPUT]: FunctionInput,
  [ACTION_INPUTS_TYPES.ENUMS_INPUT]: EnumInput,
  [ACTION_INPUTS_TYPES.ARGUMENT_PICKER]: ArgumentPicker,
  [ACTION_INPUTS_TYPES.ENDPOINT_PICKER]: EndpointPicker,
  [ACTION_INPUTS_TYPES.VIEW_PICKER]: ViewPicker,
  [ACTION_INPUTS_TYPES.DATA_TYPE_INPUT]: DataTypeInput,
  [ACTION_INPUTS_TYPES.ENTITY_INPUT]: EntityInput,
  [ACTION_INPUTS_TYPES.RELATIONSHIP_INPUT]: RelationshipInput,
  [ACTION_INPUTS_TYPES.RETURN_INPUT]: ReturnInput,
  [ACTION_INPUTS_TYPES.TEXT_AREA]: TextAreaInput,
  [ACTION_INPUTS_TYPES.HTTP_STATUS_INPUT]: HttpStatusInput
};

export const INPUTS_VALIDATION: Record<string, (input: string) => InputValidationOutput> = {
  [ACTION_INPUTS_TYPES.TEXT]: (input: string) => validateVariableName(input, 64)
};
