import React, { useContext } from 'react';
import { EndpointEditorContext } from '../../../index';
import { DataType } from '../../../../../../modules/logic_builder/types';
import { useEndpointEditorDispatch } from '../../../../../../hooks/reduxHooks';
import { updateEndpointParameterDataTypeItem } from '../../../reducers/endpointParameters';

export type DataTypeObjectsProps = {
  parameterId: string;
  className?: string;
  selectedDataType: DataType;
  value: string;
};

export function DataTypeInputItems(props: DataTypeObjectsProps) {
  const { className, selectedDataType, value } = props;
  const { objects, enums, endpointId } = useContext(EndpointEditorContext);
  const dispatch = useEndpointEditorDispatch();

  const handleChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(
      updateEndpointParameterDataTypeItem({
        id: endpointId,
        dataType: selectedDataType,
        endpointParameterId: props.parameterId,
        value: e.target.value
      })
    );
  };

  if (selectedDataType !== 'OBJECT' && selectedDataType !== 'ENUM') {
    return <></>;
  }

  return (
    <>
      {selectedDataType === 'OBJECT' && (
        <select
          value={value}
          onChange={handleChangeSelect}
          className={`form-select ${className}`}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {objects.map((object) => {
            return (
              <option key={object.uuid} value={object.uuid}>
                {object.name}
              </option>
            );
          })}
        </select>
      )}
      {selectedDataType === 'ENUM' && (
        <select
          value={value}
          onChange={handleChangeSelect}
          className={`form-select ${className}`}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {enums.map((object) => {
            return (
              <option key={object.uuid} value={object.uuid}>
                {object.content.data.name}
              </option>
            );
          })}
        </select>
      )}
    </>
  );
}
