import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import { RenderItemProps } from '.';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  RenderItem: React.ComponentType<RenderItemProps>;
  item?: unknown;
};

const inlineFixedStyles: CSSProperties = {
  position: 'relative',
  transformOrigin: '50% 50%',
  height: 'auto',
  width: '100%',
  display: 'inline-block'
};

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, withOpacity, isDragging, style, RenderItem, item, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      ...inlineFixedStyles,
      opacity: withOpacity ? '0.5' : '1',
      cursor: isDragging ? 'grabbing' : 'grab',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <RenderItem id={id} isDragging={isDragging} item={item} />
      </div>
    );
  }
);
