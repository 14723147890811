import React from 'react';
import styles from './styles.module.css';
import { EndpointInstance } from 'modules/logic_builder/components/endpoints_list/endpoint_instance';
import { useEndpointEditorDispatch, useEndpointEditorSelector } from 'hooks/reduxHooks';
import { RootState } from '../reducers';
import { Endpoint } from 'modules/logic_builder/types';
import { ActionButton } from './action_button';
import useReturnToStudio from 'web_ui/function_editor/hooks/useReturnToStudio';
import { EndpointInformation } from './endpoint_information';
import { setIsEndpointEditorOpen } from '../reducers/editorStatus';

export function EditorHeader() {
  const endpoint = useEndpointEditorSelector((state: RootState) => state.endpoint);
  const isEndpointEditorOpen = useEndpointEditorSelector(
    (state) => state.editorStatus.isEndpointEditorOpen
  );
  const returnAction = useReturnToStudio();
  const dispatch = useEndpointEditorDispatch();

  const handleOpenEditor = () => {
    dispatch(setIsEndpointEditorOpen({ isOpen: !isEndpointEditorOpen }));
  };

  return (
    <div>
      <div className={`${styles.HeaderWrapper}`}>
        <div className={styles.ActionButtonWrapper}>
          <ActionButton
            onClick={() => returnAction()}
            className={styles.ReturnActionButton}
            icon={<span className={'fa fa-arrow-left'} />}
          />
        </div>
        <div className={styles.EndpointInstanceWrapper}>
          <EndpointInstance
            context="endpoint-editor"
            endpoint={endpoint as unknown as Endpoint}
            showEditBtn={true}
            className={styles.EndpointInstance}
            editButtonOnClick={handleOpenEditor}
          />
        </div>
      </div>
      {isEndpointEditorOpen && <EndpointInformation />}
    </div>
  );
}
