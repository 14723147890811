import React from 'react';
import styles from './styles.module.css';
import { SchedulerJob } from 'modules/logic_builder/types';
import cronstrue from 'cronstrue';
import Icon from '../../../../web_ui/icon';

type ScheduledInstanceProps = {
  scheduledInstance: SchedulerJob;
  cursor?: string;
  onEdit: (e: SchedulerJob) => void;
  onPreviewScheduler: (schedulerUuid: string) => void;
};

export function ScheduledInstance(props: ScheduledInstanceProps) {
  function getExpression(cronExpression: string): string {
    let expression = '';
    try {
      expression = cronstrue.toString(cronExpression);
    } catch (error) {
      return '-';
    }
    return expression;
  }

  return (
    <>
      {props.scheduledInstance.uuid && (
        <div
          id={props.scheduledInstance.name}
          className={`bg-body-tertiary border ${styles.ScheduledWrapper}`}
          style={{
            cursor: props.cursor ?? 'default'
          }}
          onClick={() => props.onEdit(props.scheduledInstance)}
        >
          <div className={`w-100 ${styles.ScheduledDescription}`}>
            <div className={styles.Details}>{props.scheduledInstance.name}</div>
            <div className={styles.AlignRight}>
              <label>{getExpression(props.scheduledInstance.cronExpression)}</label>
            </div>
            <div
              id="codePreview"
              className={`bg-body-tertiary shadow-sm ${styles.itemIcons} text-secondary `}
              onClick={(event) => {
                event.stopPropagation();
                props.onPreviewScheduler(props.scheduledInstance.uuid ?? '');
              }}
            >
              <Icon iconName="fa-solid fa-code" extraProps={`h7`} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
