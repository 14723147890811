import { ObjectsRepository } from '../repos';
import { ObjectSimple, ObjectType } from '../types';

export class ObjectsServ {
  async createObject(moduleId: string, object: ObjectType): Promise<ObjectType> {
    return await ObjectsRepository.createObject(moduleId, object);
  }

  async createBaseObject(moduleId: string, entityId: string): Promise<ObjectSimple> {
    return await ObjectsRepository.createBaseObject(moduleId, entityId);
  }

  async batchCreateObjects(moduleId: string, objects: ObjectType[]): Promise<ObjectType[]> {
    return await ObjectsRepository.batchCreateObjects(moduleId, objects);
  }

  async getObjectsByModule(moduleId: string): Promise<ObjectSimple[]> {
    return await ObjectsRepository.getObjectsByModule(moduleId, undefined, undefined, false);
  }

  async getObjectsByModuleBackendFrontend(
    moduleId: string,
    backend?: boolean,
    frontend?: boolean
  ): Promise<ObjectSimple[]> {
    return await ObjectsRepository.getObjectsByModule(moduleId, backend, frontend, false);
  }

  async getObjectsByModuleWithDependencies(moduleId: string): Promise<ObjectSimple[]> {
    return await ObjectsRepository.getObjectsByModule(moduleId, undefined, undefined, true);
  }

  async getObjectsByEntity(moduleId: string, entityId: string): Promise<ObjectSimple[]> {
    return await ObjectsRepository.getObjectsByEntity(moduleId, entityId);
  }

  async getObjectsSchema(moduleId: string, includeDependentModules?: boolean) {
    return await ObjectsRepository.getObjectsSchema(moduleId, includeDependentModules);
  }

  async getObject(objectId: string): Promise<ObjectType> {
    return await ObjectsRepository.getObject(objectId);
  }

  async updateObject(objectId: string, object: ObjectType): Promise<ObjectType> {
    return await ObjectsRepository.updateObject(objectId, object);
  }

  async deleteObject(objectId: string, force = false) {
    return await ObjectsRepository.deleteObject(objectId, force);
  }

  async changeFolder(objectId: string, folderId: string) {
    return await ObjectsRepository.changeFolder(objectId, folderId);
  }
}
