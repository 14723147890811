import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { FloatingLabel, Form } from 'react-bootstrap';
import {
  ComponentManifest,
  Editor,
  EventTypes,
  FrontendFunctionUUID,
  LayoutComponent,
  View,
  ViewManifest
} from '../../../types';
import { addEvent, deleteEvent } from '../../store/actions/views';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import { addComponentEvent, deleteComponentEvent } from '../../store/actions/components';
import styles from './styles.module.css';
import { VIEWS_MANIFEST, VIEWS_TYPES } from '../../frames';

function EventsTool() {
  const dispatch = useDispatch();
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const functions = useSelector((state: InterfaceStudioState) => state.functions);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);

  // Only one of these should be null at a time (or maybe both, if nothing is selected).
  let componentInfo: LayoutComponent | null = null;
  let viewInfo: View | null = null;
  // Manifest.
  let manifest: ComponentManifest | ViewManifest | null = null;
  // Custom component editor.
  if (editor === Editor.CUSTOM_COMPONENT) {
    if (selectedView) {
      componentInfo = components[selectedView];
      manifest = VIEWS_MANIFEST[VIEWS_TYPES.CUSTOMCOMPONENTS];
    } else if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    }
  }
  // Regular editor.
  else {
    if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    } else if (selectedView) {
      viewInfo = views[selectedView];
      manifest = VIEWS_MANIFEST[viewInfo?.type];
    }
  }

  let events: EventTypes[] | undefined = [];
  if (componentInfo && manifest && 'events' in manifest) {
    events = manifest.events;
  }

  function handleEventChange(type: EventTypes, functionUuid: FrontendFunctionUUID) {
    if (!selectedView && !selectedComponent) return;

    const view = selectedView as NonNullable<typeof selectedView>;
    const component = selectedComponent as NonNullable<typeof selectedComponent>;

    if (functionUuid !== '---') {
      view
        ? dispatch(addEvent(view, type, functionUuid))
        : dispatch(
            addComponentEvent(
              component,
              type,
              functionUuid,
              editor === Editor.CUSTOM_COMPONENT ? component : undefined
            )
          );
    } else {
      view ? dispatch(deleteEvent(view, type)) : dispatch(deleteComponentEvent(component, type));
    }
  }

  if (!viewInfo && !componentInfo) {
    return <></>;
  }

  if (!componentInfo || !COMPONENTS_MANIFEST[componentInfo.type].events) {
    return <></>;
  }

  return (
    <div className={`p-3 pt-0 ${styles.controlList}`}>
      <h6 className="mt-2">
        <span>Events</span>
      </h6>
      <hr />

      {viewInfo && (
        <div>
          <FloatingLabel className="mb-2" label="On Load">
            <Form.Select
              id="selectLoad"
              value={viewInfo.events.LOAD}
              onChange={(e) => handleEventChange('LOAD', e.target.value)}
            >
              <option>---</option>
              {Object.values(functions).map((func, index) => (
                <option key={index} value={func.uuid}>
                  {func.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel className="mb-2" label="On Leave">
            <Form.Select
              id="selectLeave"
              value={viewInfo.events.UNLOAD}
              onChange={(e) => handleEventChange('LEAVE', e.target.value)}
            >
              <option>---</option>
              {Object.values(functions).map((func, index) => (
                <option key={index} value={func.uuid}>
                  {func.name}
                </option>
              ))}
            </Form.Select>
          </FloatingLabel>
        </div>
      )}

      {componentInfo && (
        <div>
          {events?.map((eventType, index) => (
            <div key={index}>
              {selectedComponent && (
                <FloatingLabel className="mb-2" label={eventType}>
                  <Form.Select
                    id={`select${eventType}`}
                    value={
                      componentInfo?.events && componentInfo?.events[eventType]
                        ? componentInfo?.events[eventType]
                        : ''
                    }
                    onChange={(e) => handleEventChange(eventType, e.target.value)}
                  >
                    <option>---</option>
                    {Object.values(functions).map((func, index) => (
                      <option key={index} value={func.uuid}>
                        {func.name}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default EventsTool;
