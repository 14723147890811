import React, { useEffect, useState } from 'react';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { ExoRole } from 'modules/logic_builder/types';
import { View } from 'modules/designer/types';
import { setRoleToView } from 'modules/designer/studio/store/actions/roles';
import { useDispatch } from 'react-redux';

type RoleType = 'All Roles' | 'Custom' | 'Public';

type RoleSelectorProps = {
  roles: ExoRole[];
  viewInfo: View;
};

const RoleSelector: React.FC<RoleSelectorProps> = ({ roles, viewInfo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedRoleType, setSelectedRoleType] = useState<RoleType>();

  useEffect(() => {
    if (!viewInfo.roles) {
      return;
    }
    if (selectedRoleType) {
      return;
    }

    let roleType: RoleType;
    if (viewInfo.roles.length === 0) {
      roleType = 'Public';
    } else if (viewInfo.roles.length === roles.length) {
      roleType = 'All Roles';
    } else {
      roleType = 'Custom';
    }

    setSelectedRoleType(roleType);
  }, [roles.length, selectedRoleType, viewInfo.roles]);

  const checkSelected = (roleId?: number): boolean => {
    if (!roleId) return false;
    return viewInfo.roles.includes(roleId);
  };

  const handleRoleSelect = (roleId: number) => {
    let nextRoles: number[];

    if (viewInfo.roles.includes(roleId)) {
      nextRoles = viewInfo.roles.filter((id) => id !== roleId);
    } else {
      nextRoles = [...viewInfo.roles, roleId];
    }

    dispatch(setRoleToView(nextRoles, viewInfo.uuid));
  };

  return (
    <div>
      <ToggleButtonGroup
        type="radio"
        name="roles-options"
        value={selectedRoleType}
        size="sm"
        className="mb-3"
      >
        <ToggleButton
          id="publicButton"
          style={{ cursor: 'default' }}
          value={'Public'}
          onClick={() => {}}
        >
          {t('automation.step4.public')}
        </ToggleButton>
        {/* add tooltip when project have no roles: "Add roles to project" */}
        <ToggleButton
          id="allButton"
          style={{ cursor: 'default' }}
          value={'All Roles'}
          onClick={() => {}}
        >
          {t('automation.step4.allRoles')}
        </ToggleButton>
        <ToggleButton
          id="customButton"
          style={{ cursor: 'default' }}
          value={'Custom'}
          onClick={() => {}}
        >
          Custom
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={styles.ViewRoles}>
        {roles &&
          roles.map((role) => {
            return (
              <Form.Check
                key={role.id}
                label={role.name}
                name="view-roles"
                type="checkbox"
                id={role.name}
                checked={checkSelected(role.id)}
                value={role.id}
                onChange={() => handleRoleSelect(role.id ?? 0)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default RoleSelector;
