import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { Step0 } from './components/wizard_steps/step0';
import Crud from './components/wizard_steps/crud';
import { AUTOMATION_TYPES, AUTOMATIONS_STEPS, Entity, SelectedAutomation } from './types';
import ModelGeneratorAutomation from './components/wizard_steps/model_generator';
import { PopupAlert } from 'web_ui/popup_alert';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchEntities } from './helpers/fetchEntities';
import { ModuleInfo } from 'modules/dashboard/types';
import { ProjectsService } from 'modules/project/services';
import { ApiAppInfo } from 'modules/project/types';
import useTitle from 'hooks/useTitle';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import SessionContext from 'modules/auth/store';
import { useClickOutsideEvent } from 'hooks/useClickOutside';
import { useViews } from 'modules/designer/hooks/outletContext';

export function AutomationWizard() {
  const location = useLocation();
  const { module_id, app_id } = useParams();
  const [searchParams] = useSearchParams();
  const formRef = useRef<HTMLFormElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [formValidity, setFormValidity] = useState(true);
  const [selectedAutomation, setSelectedAutomation] = useState<SelectedAutomation>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [currEntities, setCurrEntities] = useState<{ [p: string]: Entity }>();
  const [mod, setMod] = useState<ModuleInfo>();
  const [app, setApp] = useState<ApiAppInfo>();
  const mainRef = useRef<HTMLDivElement>(null);
  const { updatePageVisits, pageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const { setCurrentLocationSubContext } = useViews();
  const currentContext = 'assistant';

  useEffect(() => {
    if (currentStep === 0) {
      setCurrentLocationSubContext('');
    } else {
      setCurrentLocationSubContext('wizard-steps');
    }
  }, [currentStep, setCurrentLocationSubContext]);

  const setCorrectTabName = React.useCallback(async () => {
    if (!app_id || !module_id) return;
    const projectData = await ProjectsService.getProjectById(app_id);
    const moduleData = await ProjectsService.getModuleById(module_id);
    setApp(projectData);
    setMod(moduleData);
  }, [app_id, module_id]);

  useEffect(() => {
    setCorrectTabName();
  }, []);

  useTitle(
    app?.name && mod?.name ? `Automation Wizard · ${mod.name} - ${app.name}` : 'Exocoding',
    mod
  );

  const locationState = location.state;
  useEffect(() => {
    if (locationState) {
      setCurrentStep(locationState.step);
    }
  }, [locationState]);

  const getSelectedEntity = useCallback((): string => {
    return searchParams.get('entity') ?? '';
  }, [searchParams]);

  useEffect(() => {
    if (getSelectedEntity() !== '') {
      setCurrentStep(2);
      setSelectedAutomation(AUTOMATIONS_STEPS.CRUD);
    }
  }, [getSelectedEntity]);

  useEffect(() => {
    const gettingVal = searchParams.get('entityGenerator') ?? '';
    if (gettingVal) {
      setSelectedAutomation(AUTOMATIONS_STEPS.ENTITIES);
      setCurrentStep(1);
      setFormValidity(false);
    }
  }, []);

  useEffect(() => {
    if (!module_id) return;
    (async () => {
      fetchEntities(module_id, true).then((entities) => {
        setCurrEntities(entities);
      });
    })();
  }, [module_id]);

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    if (!mainRef.current) return;

    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      }
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <>
      <div
        ref={mainRef}
        className={styles.wizardContainer}
        id={WALKTHROUGH_STEPS_ELEMENTS['assistant-main']}
      >
        <Form ref={formRef} id="wizard-form" style={{ flexGrow: 2 }}>
          {currentStep === 0 && (
            <Step0
              selectedAutomation={selectedAutomation}
              setSelectedAutomation={(s: SelectedAutomation) => {
                setSelectedAutomation(s);
                setCurrentStep(1);
                setFormValidity(false);
              }}
            />
          )}
          {selectedAutomation &&
            selectedAutomation.name === AUTOMATION_TYPES.CRUD &&
            currentStep > 0 && (
              <Crud
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                formValidity={formValidity}
                setFormValidity={setFormValidity}
                selectedEntityId={getSelectedEntity()}
                setIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}
                isLoading={isLoading}
                entities={currEntities}
              />
            )}
          {selectedAutomation &&
            selectedAutomation.name === AUTOMATION_TYPES.ENTITIES &&
            currentStep > 0 && (
              <ModelGeneratorAutomation
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                formValidity={formValidity}
                setFormValidity={setFormValidity}
                setErrorMessage={(message: string) => setErrorMessage(message)}
                setIsLoading={(isLoading: boolean) => setIsLoading(isLoading)}
                isLoading={isLoading}
                selectedAutomation={selectedAutomation}
              />
            )}
        </Form>
        {errorMessage && <PopupAlert i18nKey={errorMessage} onClose={() => setErrorMessage('')} />}
      </div>
    </>
  );
}
