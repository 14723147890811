import SessionContext from 'modules/auth/store';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { useContext } from 'react';

export const useCanCreateApp = (): boolean => {
  const session = useContext(SessionContext);

  if (!session.user || !session.user.totals) {
    return false;
  }
  if (
    session.user.totals.numberOfCreatedApplications <
    session.getSystemAuthorityLimit(SystemRoleAuthorityName.ADD_APP)
  ) {
    return true;
  }
  return false;
};
