import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FunctionEditorState } from '../../../web_ui/function_editor/store/types/function_editor_state';
import LogicBuilderFunctionEditor from '../../../modules/logic_builder/function_editor';
import { BackendFunctionsRepo } from '../../../modules/logic_builder/function_editor/repo';
import { FunctionsState } from '../../../web_ui/function_editor/store/types/functions';
import { ParametersState } from '../../../web_ui/function_editor/store/types/parameters';
import { ActionsState } from '../../../web_ui/function_editor/store/types/actions';
import { ModuleInfo } from 'modules/dashboard/types';
import { ApiAppInfo } from 'modules/project/types';
import { useTranslation } from 'react-i18next';
import { ProjectsService } from 'modules/project/services';
import useTitle from 'hooks/useTitle';

type LogicBuilderFunctionEditorPageProps = {
  toggleTheme: () => void;
};

function LogicBuilderFunctionEditorPage({ toggleTheme }: LogicBuilderFunctionEditorPageProps) {
  const { app_id, module_id, function_id } = useParams();
  const [state, setState] = useState<FunctionEditorState>();
  const [loading, setLoading] = useState(true);
  const [mod, setMod] = useState<ModuleInfo>();
  const [app, setApp] = useState<ApiAppInfo>();
  const { t } = useTranslation();

  const setCorrectTabName = React.useCallback(async () => {
    if (!app_id || !module_id) return;
    const projectData = await ProjectsService.getProjectById(app_id);
    const moduleData = await ProjectsService.getModuleById(module_id);
    setApp(projectData);
    setMod(moduleData);
  }, [app_id, module_id]);

  useEffect(() => {
    setCorrectTabName();
  }, []);

  useTitle(
    app?.name && mod?.name ? `${t('tab.functionEditor')}${mod.name} - ${app.name}` : 'Exocoding',
    mod
  );

  useEffect(() => {
    const fetchFunctionEditorState = async () => {
      if (!module_id || !function_id) return;
      const rawState = await BackendFunctionsRepo.getInitialState(module_id, function_id);
      const functions = {} as FunctionsState;
      Object.values(rawState?.functions).forEach((f: any) => {
        functions[f.id] = {
          ...f,
          uuid: f.id,
          returnType: {
            type: f.returnType,
            objectUuid: f.returnObjectUuid,
            enumUuid: f.returnEnumUuid,
            list: f.returnList
          }
        };
      });
      const parameters = {} as ParametersState;
      Object.values(rawState?.parameters)?.forEach((p: any) => {
        parameters[p.id] = { ...p, uuid: p.id, type: p.dataType, objectUuid: p?.object?.id };
      });
      const actions = {} as ActionsState;
      Object.values(rawState?.actions)?.forEach((a: any) => {
        actions[a.id] = {
          uuid: a.id,
          type: a.type,
          order: a.order,
          data: a.data,
          returnVariableUuid: a.returnVariableUuid
        };
      });
      const newState: FunctionEditorState = {
        ...rawState,
        actions,
        functions,
        parameters,
        editor: {
          moduleId: module_id,
          callerId: function_id,
          selectedAction: '',
          mode: 'LOGIC_BUILDER'
        }
      };
      setState(newState);
      setLoading(false);
    };
    fetchFunctionEditorState().then(() => {});
  }, [app_id, module_id, function_id]);

  return (
    <>
      {app_id && module_id && function_id && state && !loading && (
        <LogicBuilderFunctionEditor moduleId={module_id} functionId={function_id} state={state} />
      )}
    </>
  );
}

export default LogicBuilderFunctionEditorPage;
