import React from 'react';
import TypePicker, { TYPE_PICKER_TYPES } from '../argument_picker/type_picker';
import ValuePicker from '../argument_picker/value_picker';
import ObjectNodePicker from '../../components/object_node_picker';
import useCheckValueIsObject from '../argument_picker/useCheckValueIsObject';
import { Argument } from '../../../store/types/manifestsAndInputs';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';
import { FunctionParameterType } from 'web_ui/function_editor/store/types/parameters';

type ParameterEditorProps = {
  paramId: string;
  param: Argument;
  label: string;
  actionUuid: string;
  handleChange: (paramId: string, value: Argument) => void;
  options?: any;
  isFromSideBar?: boolean;
};

function ParameterEditor({
  paramId,
  param,
  label,
  actionUuid,
  handleChange,
  options,
  isFromSideBar
}: ParameterEditorProps) {
  const parameters = useSelector((state: FunctionEditorState) => state.parameters);
  const { isObject, objectUuid } = useCheckValueIsObject(param.type, param.value);
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const endpointParameters = useSelector((state: EndpointEditorState) => state.endpointParameters);

  const allParams: Record<string, FunctionParameterType> = { ...parameters };
  if (mode === 'ENDPOINT_EDITOR' && endpointParameters) {
    // Just make sure we're using values that aren't out of date.
    endpointParameters.forEach((p) => {
      if (!p.uuid) return;
      allParams[p.uuid] = p as FunctionParameterType;
    });
  }

  const handleChangeType = (t: TYPE_PICKER_TYPES) => {
    handleChange(paramId, { type: t, value: '', objectNode: '' });
  };

  const handleChangeValue = (v: string) => {
    handleChange(paramId, { ...param, value: v, objectNode: '' });
  };

  const handleChangeObjectNode = (o: string) => {
    handleChange(paramId, { ...param, objectNode: o });
  };

  const filterByDataType = (state: FunctionEditorState, actionUuid: string, dataType: string) => {
    if (!dataType) return false;
    if (param.type === 'PAGE_PARAM') {
      return (
        allParams[paramId]?.type.toUpperCase() !== 'OBJECT' &&
        allParams[paramId]?.type.toUpperCase() !== 'VOID'
      );
    }
    return (
      dataType.toUpperCase() === allParams[paramId]?.type.toUpperCase() ||
      dataType.toUpperCase() === 'OBJECT'
    );
  };

  return (
    <div className={'ps-3 d-flex align-items-end flex-wrap'}>
      <p className={'text-secondary-emphasis mb-0 me-1'}>{allParams[paramId]?.name} = </p>
      <TypePicker
        placeholder={'Select argument type'}
        label={label}
        value={param.type}
        handleChange={handleChangeType}
        argumentTypes={
          allParams[paramId]?.list
            ? [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM]
            : options.argumentTypes
            ? options.argumentTypes
            : []
        }
        isFromSideBar={isFromSideBar}
      />
      <ValuePicker
        type={param.type}
        label={label}
        actionUuid={actionUuid}
        filterByList={allParams[paramId] && allParams[paramId].list}
        filterByDataType={filterByDataType}
        filterByGlobalVars={options?.onlyGlobals}
        value={param.value}
        handleChange={handleChangeValue}
        isFromSideBar={isFromSideBar}
      />
      {isObject && (
        <ObjectNodePicker
          objectUuid={objectUuid}
          filterByList={allParams[paramId]?.list}
          value={param.objectNode ?? ''}
          handleChange={handleChangeObjectNode}
          isFromSideBar={isFromSideBar}
        />
      )}
    </div>
  );
}

export default ParameterEditor;
