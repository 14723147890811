import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ModuleInfo } from 'modules/dashboard/types';
import styles from './styles.module.css';
import { DropdownMenu } from './DropdownMenu';
import { DropdownToggle } from './DropdownToggle';
import CreateModuleModal from '../../../../../project/components/create_module_modal';
import { useParams } from 'react-router-dom';
import {
  ContextRole,
  RoleAuthorities,
  SystemRoleAuthorityName
} from 'modules/auth/types/auth_types';
import { useTranslation } from 'react-i18next';
import { getFeatureLimit } from '../../../../../../utils/utils';
import { AppContext } from '../../../../../project/store/app_context';
import UpgradePlanModal from '../../../../../../web_ui/upgrade_plan_modal';

type BreadcrumbModuleDropdownProps = {
  modulesList: ModuleInfo[] | undefined;
  selectedModule: ModuleInfo | undefined;
  handleSelectModule: (module: ModuleInfo) => void;
  fetchModules: () => void;
};

export function BreadcrumbModuleDropdown(props: BreadcrumbModuleDropdownProps) {
  const [showCreateModuleModal, setShowCreateModuleModal] = React.useState(false);
  const { app_id } = useParams();
  const { t } = useTranslation();

  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState(false);
  const ownerLimits = useContext(AppContext).appOwnerLimits;

  const modalOnCloseRequest = React.useCallback(() => {
    setShowCreateModuleModal(false);
  }, []);

  const canCreateModule = () => {
    if (props.modulesList === undefined || ownerLimits == null) {
      console.error("Couldn't access user's resource limits.");
      return;
    }
    const limit = getFeatureLimit(ownerLimits, SystemRoleAuthorityName.ADD_MODULE);
    if (!limit) {
      setShowUpgradePlanModal(true);
      return;
    }
    if (props.modulesList.length < limit) {
      setShowCreateModuleModal(true);
    } else {
      setShowUpgradePlanModal(true);
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        as={DropdownToggle}
        id="dropdown-toggle"
        handleUpdateList={props.fetchModules}
      >
        <p
          id={props.selectedModule?.name}
          className={styles.hidingName}
          style={{ padding: 0, margin: 0 }}
        >
          {props.selectedModule?.name}
        </p>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={DropdownMenu}
        openModal={canCreateModule}
        buttonLabel={t('designer.NewModule')}
        filterPlaceholder={t('designer.FilterModules')}
        authorityContext={ContextRole.APP}
        allowedAuthorities={[RoleAuthorities.MANAGE_MODULE]}
      >
        {props.modulesList
          ?.filter((module) => !module.disabled)
          .map((module) => (
            <Dropdown.Item
              key={module.id}
              eventKey={module.id}
              onClick={() => props.handleSelectModule(module)}
              className={`${styles.DropdownItem} ${
                props.selectedModule?.id === module.id && styles.DropdownItemSelected
              }`}
            >
              {/* This is used to filter pages,
                if you want filter to keep working this hidden <span>
                should stay as the first children of <Dropdown.Item> */}
              <span id={module.name} className={styles.HiddenFilterParameter}>
                {module.name}
              </span>
              {module.name}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>

      {app_id && (
        <CreateModuleModal
          modules={props.modulesList}
          onCloseRequest={modalOnCloseRequest}
          showModal={showCreateModuleModal}
          fetchModules={props.fetchModules}
        />
      )}

      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
    </Dropdown>
  );
}
