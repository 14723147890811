import { FunctionEditorState } from '../../types/function_editor_state';
import { AddActionAction } from '../actions/add_action';
import { FunctionVariableType } from '../../types/variables';
import { ReplaceEmptyAction } from '../actions/replace_empty';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';

export function doAddAction(
  state: FunctionEditorState | EndpointEditorState,
  action: AddActionAction | ReplaceEmptyAction
): FunctionEditorState {
  if (action.type !== 'REPLACE_EMPTY') {
    const { functionUuid, uuid, order, mode } = action.payload;
    if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
      state.callerInfo.actions.splice(order, 0, uuid);
    } else {
      state.functions[functionUuid].actions.splice(order, 0, uuid);
    }
  }

  state.editor.selectedAction = action.payload.uuid;
  if (action.payload.type !== 'DECLARE_VARIABLE') return state;

  const data = action.payload.data;

  const variable: FunctionVariableType = {
    uuid: data.uuid,
    name: data.name,
    desc: '',
    type: data.dataType.type,
    objectUuid: data.dataType?.objectUuid ?? '',
    enumUuid: data.dataType?.enumUuid ?? '',
    list: data.list,
    required: false
  };

  state.variables[variable.uuid] = variable;
  return state;
}
