import React, { useContext } from 'react';
import { MethodsInput } from './endpoint_inputs/method_input';
import { BooleanProperties } from './endpoint_inputs/boolean_properties';
import { PathInput } from './endpoint_inputs/path_input';
import { SecurityRoles } from './endpoint_inputs/security_roles';
import { EndpointName } from './endpoint_inputs/endpoint_name';
import styles from './styles.module.css';
import { EndpointParameters } from './endpoint_parameters';
import { EndpointTags } from './endpoint_inputs/endpoint_tags';
import { useTranslation } from 'react-i18next';
import { useEndpointEditorDispatch, useEndpointEditorSelector } from 'hooks/reduxHooks';
import { EndpointTextareaInput } from './endpoint_inputs/endpoint_textarea_input';
import { updateEndpointDescription, updateEndpointSummary } from '../reducers/endpointInfo';
import { EndpointEditorContext } from '../index';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export function EndpointInformation() {
  const { t } = useTranslation();
  const { summary, description, uuid } = useEndpointEditorSelector((state) => state.endpoint);
  const dispatch = useEndpointEditorDispatch();
  const hasAuth = useContext(EndpointEditorContext).appInfo?.has_authentication;

  const handleDescriptionChange = (newValue: string, errorMessage: string) => {
    dispatch(
      updateEndpointDescription({ id: uuid, newDescription: newValue, isValid: !errorMessage })
    );
  };

  const handleSummaryChange = (newValue: string, errorMessage: string) => {
    dispatch(updateEndpointSummary({ id: uuid, newSummary: newValue, isValid: !errorMessage }));
  };

  return (
    <>
      <div className={`d-flex flex-column w-100 mt-3 ${styles.container}`}>
        <div className={`${styles.InformationWrapper}`}>
          <div className={`${styles.LeftSide}`}>
            <EndpointName />
            <MethodsInput />
            <PathInput />
            <EndpointTextareaInput
              value={summary}
              label={t('logicBuilder.Summary')}
              handleChange={handleSummaryChange}
              elementId={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-summary']}
            />
            <EndpointTextareaInput
              value={description}
              label={t('logicBuilder.endepoint.Description')}
              handleChange={handleDescriptionChange}
              elementId={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-description']}
            />
          </div>
          <div className={`${styles.RightSide}`}>
            <div className={`d-flex flex-row flex-wrap gap-2`}>
              <BooleanProperties />
            </div>
            {hasAuth && <SecurityRoles />}
            <EndpointTags />
          </div>
        </div>
        <div
          id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-input-parameters']}
          style={{ paddingBottom: 20 }}
        >
          <EndpointParameters />
        </div>
      </div>
      <hr className={styles.lineSeparator} />
    </>
  );
}
