import { parameterNameRegex } from '../../../utils/regex';
import { FORBIDDEN_PARAMS_NAMES, FunctionParameterType } from '../store/types/parameters';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../store/types/function_editor_state';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';

function useValidateParamName() {
  const { callerId } = useSelector((state: FunctionEditorState) => state.editor);
  const func = useSelector((state: FunctionEditorState) => state.functions[callerId]);
  const endpointParameters = useSelector((state: EndpointEditorState) => state.endpointParameters);
  const params = useSelector((state: FunctionEditorState) => state.parameters);
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);

  const allParams: Record<string, FunctionParameterType> = { ...params };
  if (mode === 'ENDPOINT_EDITOR' && endpointParameters) {
    // Just make sure we're using values that aren't out of date.
    endpointParameters.forEach((p) => {
      if (!p.uuid) return;
      allParams[p.uuid] = p as FunctionParameterType;
    });
  }

  const callerParameters: string[] =
    mode === 'ENDPOINT_EDITOR' ? endpointParameters.map((p) => p.uuid ?? '') : func.parameters;

  return (name: string) => {
    if (!name) return false;
    if (!parameterNameRegex.test(name)) return false;
    if (FORBIDDEN_PARAMS_NAMES.includes(name.toLowerCase())) return false;
    for (const p of callerParameters) {
      if (allParams[p].name === name) {
        return false;
      }
    }
    return true;
  };
}

export default useValidateParamName;
