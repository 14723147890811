import React from 'react';

export type NameInputProps = {
  parameterId: string;
  endpointId: string;
  className?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, valid: boolean) => void;
  validation?: (value: string) => string; // Return the i18n key error message.
  disabled?: boolean;
};

export const ParameterTextInput = React.memo((props: NameInputProps) => {
  const { className, handleChange, validation, value } = props;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e, validation ? !validation(e.target.value) : true);
  };

  const errorMessage = validation ? validation(value) : '';

  return (
    <input
      type="text"
      className={`form-control ${className} ${errorMessage ? 'is-invalid' : ''}`}
      onChange={handleInputChange}
      value={value}
      onMouseDown={(e) => e.stopPropagation()}
      disabled={props.disabled}
      required
    />
  );
});
