import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';
import { EditorMode, FunctionEditorState } from '../../types/function_editor_state';
import { Action } from 'redux';

export const DELETE_ACTION = 'DELETE_ACTION';

export interface DeleteActionAction extends Action {
  type: 'DELETE_ACTION';
  payload: {
    functionUuid: string;
    uuid: string;
    mode: string;
  };
}

export const deleteAction = (
  functionUuid: string,
  uuid: string,
  mode: EditorMode
): DeleteActionAction => ({
  type: DELETE_ACTION,
  payload: { functionUuid, uuid, mode }
});

export function doDeleteAction(
  state: FunctionEditorState | EndpointEditorState,
  action: DeleteActionAction
): FunctionEditorState {
  const { functionUuid, uuid, mode } = action.payload;

  let stateActions;
  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    stateActions = state.callerInfo.actions;
  } else {
    stateActions = state.functions[functionUuid].actions;
  }

  const a = state.actions[uuid];
  if (a?.type === 'DECLARE_VARIABLE') delete state.variables[a.data.uuid];

  if (a?.type === 'BEGIN_IF') {
    const beginIf = Object.values(state.actions).find((item) => item.type === 'BEGIN_IF');

    // items to delete
    // if
    if (!beginIf) return state;
    const openBlockActionUuid = beginIf.data.then.openBlockActionUuid;
    const closeBlockActionUuid = beginIf.data.then.closeBlockActionUuid;

    // else
    const openBlockActionUuidElse = beginIf.data.else.openBlockActionUuid;
    const closeBlockActionUuidElse = beginIf.data.else.closeBlockActionUuid;

    const newArrAux = [];
    const toDelete = [
      openBlockActionUuid,
      closeBlockActionUuid,
      openBlockActionUuidElse,
      closeBlockActionUuidElse
    ];
    for (const actionsId of stateActions) {
      if (!toDelete.includes(actionsId)) {
        // get only the ones that does not match
        newArrAux.push(actionsId);
      }
    }

    if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
      state.callerInfo.actions = newArrAux;
    } else {
      state.functions[functionUuid].actions = newArrAux;
    }

    // deleting if
    delete state.actions[openBlockActionUuid];
    delete state.actions[closeBlockActionUuid];

    // deleting else
    delete state.actions[openBlockActionUuidElse];
    delete state.actions[closeBlockActionUuidElse];

    return state;
  }

  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    const index = state.callerInfo.actions.indexOf(uuid);
    state.callerInfo.actions.splice(index, 1);
  } else {
    const index = state.functions[functionUuid].actions.indexOf(uuid);
    state.functions[functionUuid].actions.splice(index, 1);
  }

  delete state.actions[uuid];

  return state;
}
