import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import { ApiAppInfo } from 'modules/project/types';
import AppSettingsModal from '../app_settings_modal';
import { CodeDownloadDialog } from '../code_download_modal';
import ApplicationAvatar from 'web_ui/application_avatar';
import CreateVersionModal from 'modules/project/components/create_version_modal';
import Icon from 'web_ui/icon';
import styles from './style.module.css';
import { Authorization } from 'modules/auth/session/authorization';
import { ContextRole, RoleAuthorities } from 'modules/auth/types/auth_types';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { mixpanel } from 'exocode';

export type AppResumeProps = {
  loading: boolean;
  appInfo: ApiAppInfo;
  updateAppInfo: (updatedAppinfo: ApiAppInfo) => void;
  onAfterDeleteApp: () => void;
  reload: React.Dispatch<React.SetStateAction<boolean>>;
};

type RedirectState = {
  isManage: boolean;
};

function AppResume(props: AppResumeProps) {
  const [showModal, setShowModal] = useState(false);
  const [showModalVersion, setShowModalVersion] = useState(false);
  const [showModalForDownload, setShowModalForDownload] = useState<boolean>(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.state) {
      const { isManage } = location.state as RedirectState;
      if (isManage) setShowModal(true);
    }
  }, [location.state]);

  function handleCloseModal() {
    setShowModal(false);
  }

  const handleClickDownload = () => {
    mixpanel.track('start_download_code', {
      buttonName: 'downloadCodeButton',
      path: location.pathname,
      pageName: document.title
    });
    setShowModalForDownload(true);
  };

  return (
    <>
      <Row className={`${styles.firstRow} bg-body-secondary`}>
        <Col
          sm={12}
          className={`${styles.appWrapper}`}
          style={props.loading ? { justifyContent: 'center', alignItems: 'center' } : undefined}
        >
          {props.loading && (
            <div>
              <div className={`fa-3x`}>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          )}

          {!props.loading && (
            <Row className={styles.firstContainer}>
              <Col xs={12} sm={12} md={8} lg={9} xl={10} className={`${styles.resumeWrapper}`}>
                <div className="d-none d-sm-none d-md-block">
                  <ApplicationAvatar
                    name={props.appInfo.name}
                    width="5rem"
                    borderRadius="10px"
                    fontSize="48px"
                    className={`${styles.appIcon}`}
                  />
                </div>

                <div className={styles.appDetails}>
                  <div className={styles.appData}>
                    <div style={{ display: 'flex' }}>
                      <p
                        className={`text-body-primary ${styles.ownerName}`}
                        style={{ marginRight: 5 }}
                        id="projectOwner"
                      >
                        {props.appInfo.owner_name}
                      </p>
                      <p>/</p>
                    </div>
                    <h3 className={`text-body-emphasis ${styles.nameProject} `} id="projectName">
                      {props.appInfo.name}
                    </h3>
                  </div>
                  <div className={styles.appDescription}>
                    <p
                      id="projectDescription"
                      className={
                        props.appInfo.description.length > 88 ? `${styles.overFlowText}` : ''
                      }
                    >
                      {props.appInfo.description}
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={12} md={4} lg={3} xl={2} className={`${styles.appAction}`}>
                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.MANAGE_APP]}
                >
                  <>
                    {/*<HelpPopover
                      helpBoxProps={{
                        title: t('appResume.CreateVersion') ?? 'appResume.CreateVersion'
                      }}
                      placement="top"
                    >
                      <Button
                        style={{ width: '50px', height: '50px' }}
                        className="btn-secondary"
                        onClick={() => setShowModalVersion(true)}
                      >
                        <Icon iconName="clone"></Icon>
                      </Button>
                    </HelpPopover>*/}

                    <HelpPopover
                      helpBoxProps={{
                        title: t('appResume.Manage') ?? 'appResume.Manage'
                      }}
                      placement="top"
                    >
                      <Button
                        id={'settingsButton'}
                        className="btn-secondary"
                        style={{ width: '50px', height: '50px', marginLeft: '.5rem' }}
                        onClick={() => setShowModal(true)}
                      >
                        <Icon iconName="gear"></Icon>
                      </Button>
                    </HelpPopover>
                  </>
                </Authorization>

                <Authorization
                  context={ContextRole.APP}
                  allowedAuthorities={[RoleAuthorities.GENERATE_CODE]}
                >
                  <HelpPopover
                    helpBoxProps={{
                      title: t('appResume.DownloadCode') ?? 'appResume.DownloadCode'
                    }}
                    placement="top"
                  >
                    <Button
                      id={'downloadCodeButton'}
                      className={styles.downloadCodeButton}
                      onClick={handleClickDownload}
                      style={{ width: '50px', height: '50px' }}
                    >
                      <Icon iconName="laptop-code"></Icon>
                    </Button>
                  </HelpPopover>
                </Authorization>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <AppSettingsModal
        appinfo={props.appInfo}
        updateAppInfo={props.updateAppInfo}
        showModal={showModal}
        onCloseRequest={handleCloseModal}
        onAfterDeleteApp={props.onAfterDeleteApp}
        usingAuth={true}
        reload={props.reload}
      />
      <CodeDownloadDialog
        app={props.appInfo}
        withPR={props.appInfo.createPR}
        show={showModalForDownload}
        onClose={() => setShowModalForDownload(false)}
      />
      <CreateVersionModal
        appInfo={props.appInfo}
        show={showModalVersion}
        onClose={() => setShowModalVersion(false)}
      />
    </>
  );
}

export default AppResume;
