import React from 'react';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../../../store/types/function_editor_state';

function FunctionName() {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const name = useSelector(
    (state: FunctionEditorState) => state.functions[function_id ?? '']?.name
  );
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);

  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return <span className={styles.FunctionName}>{name}</span>;
}

export default FunctionName;
