import { t } from 'i18next';
import label from 'modules/designer/studio/exocode_components/label';
import React from 'react';
import HelpIcon from '../components/HelpIcon';
import HelpPopover from '../components/Popover';
import { DefaultLabel, DefaultInput } from '../styles';
import { ControlProps } from '..';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from 'modules/designer/studio/store';

const CarouselSectionsControl = ({
  value,
  onChange,
  id,
  label,
  maxLength,
  validation,
  tooltip,
  errorMessages
}: ControlProps) => {
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const components = useSelector((state: InterfaceStudioState) => state.components);

  const nextValueInRow = () => {
    if (
      components &&
      selectedComponent &&
      components[selectedComponent].data &&
      components[selectedComponent].data.qtdPages
    ) {
      if (value === components[selectedComponent].data.qtdPages) {
        return;
      }
      onChange && onChange(value + 1, id);
    }
  };

  const previousValueInRow = () => {
    if (value === 1) {
      return;
    }
    onChange && onChange(value - 1, id);
  };

  const mountingBallElements = (): JSX.Element => {
    const componentsToRender: JSX.Element[] = [];

    if (
      components &&
      selectedComponent &&
      components[selectedComponent].data &&
      components[selectedComponent].data.qtdPages
    ) {
      for (let i = 1; i <= components[selectedComponent].data.qtdPages; i++) {
        componentsToRender[i] = (
          <div
            key={i}
            style={{
              width: 50,
              height: 50,
              borderRadius: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#0A6EFD'
            }}
          >
            <span style={{ fontSize: 23, color: 'white' }}>{i}</span>
          </div>
        );
      }

      return value ? componentsToRender[value] : <></>;
    }

    return <></>;
  };

  return (
    <div
      className="mb-3"
      style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
    >
      <div style={{ marginBottom: '1rem' }}>
        <DefaultLabel
          style={{ display: 'flex', justifyContent: 'flex-start', whiteSpace: 'nowrap' }}
        >
          {label}
          {tooltip && (
            <HelpPopover
              placement="top"
              helpBoxProps={{
                description: t(`${tooltip}`) ?? ''
              }}
            >
              <HelpIcon />
            </HelpPopover>
          )}
        </DefaultLabel>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <i
          onClick={(ev) => previousValueInRow()}
          className="fa-solid fa-circle-arrow-left"
          style={{ cursor: 'pointer', fontSize: 25 }}
        ></i>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
          {value !== 1 && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 9999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'gray'
              }}
            />
          )}
          {mountingBallElements()}
          {components &&
            selectedComponent &&
            value !== components[selectedComponent].data.qtdPages && (
              <div
                style={{
                  width: 25,
                  height: 25,
                  borderRadius: 9999,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: 'gray'
                }}
              />
            )}
        </div>
        <i
          onClick={(ev) => nextValueInRow()}
          className="fa-solid fa-circle-arrow-right"
          style={{ cursor: 'pointer', fontSize: 25 }}
        ></i>
      </div>
    </div>
  );
};

export default CarouselSectionsControl;
