import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { FaGithub } from 'react-icons/all';
import produce from 'immer';
import { OrganizationContext } from '../..';
import SessionContext from 'modules/auth/store';
import ProjectRow from 'routes/homepage/components/project_row';
import CantCreateAppModal from 'routes/homepage/components/cant_create_app_modal';
import { Authorization } from 'modules/auth/session/authorization';
import { OrganizationService } from 'modules/organization/services';
import { AccountService } from 'modules/auth/services';
import { IntegrationsService } from '../../../../../modules/integrations/services';
import { ApiAppInfo } from 'modules/project/types';
import { FiltersOptions } from 'modules/organization/types';
import {
  ContextRole,
  RoleAuthorities,
  SystemRoleAuthorityName
} from 'modules/auth/types/auth_types';
import EmptyMessage from 'web_ui/empty';
import Confirmation from '../../../../../web_ui/confirmation';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

import styles from '../../../../settings/sub_routes/account/styles.module.css';
import { mixpanel } from 'exocode';
import { getFeatureLimit } from '../../../../../utils/utils';
import UpgradePlanModal from '../../../../../web_ui/upgrade_plan_modal';

export const API_URL = process.env.REACT_APP_API_URL;

function OrganizationDashboard() {
  const session = React.useContext(SessionContext);
  const orgContext = useContext(OrganizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [projects, setProjects] = useState<ApiAppInfo[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<FiltersOptions>(FiltersOptions.NAME);
  const [searchText, setSearchText] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = React.useState<boolean>(false);
  const [showCanCreateOrgModal, setShowCanCreateOrgModal] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const search_project: string = t('organizations.organization.SearchProjects');
  const filter: string = t('Filter');

  useEffect(() => {
    findProjects();
  }, []);

  async function uninstallGitHub() {
    if (!orgContext.organization) return;
    await IntegrationsService.uninstallFromOrg(orgContext.organization.id);
    orgContext.setOrganization({ ...orgContext.organization, gitHubIntegration: false });
    setShowConfirmationDialog(false);
  }

  /**
   * Fetch organizations apps from the current user
   */
  const findProjects = async () => {
    try {
      setLoading(true);
      if (orgContext.organization) {
        const projects = await OrganizationService.getProjectsOfOrg(orgContext.organization.id);
        setProjects(projects);
        changeFilterType(filterType);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  function changeFilterType(type: FiltersOptions) {
    setFilterType(type);
    if (type === FiltersOptions.NAME) {
      // filtertype does not exist
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
        });
        return [...nextState];
      });
    } else if (type === FiltersOptions.DATE) {
      setProjects((currentVal) => {
        const nextState = produce(currentVal, (draft) => {
          draft.sort((a, b) => (a.changed_at < b.changed_at ? 1 : -1));
        });
        return [...nextState];
      });
    }
  }

  function handleDeleteApp() {
    findProjects();
  }

  useEffect(() => {
    findProjects();
  }, [reload]);

  const verifyingOrgLimits = async () => {
    mixpanel.track('project_created', {
      buttonName: t('organizations.organization.NewProject'),
      path: location.pathname,
      pageName: document.title
    });
    if (orgContext.organization) {
      const userLimits = await AccountService.getLimits(orgContext.organization.owner.id);
      const limitOfApplications = getFeatureLimit(userLimits, SystemRoleAuthorityName.ADD_APP);
      if (!limitOfApplications) {
        return;
      }
      if (userLimits.totals.numberOfCreatedApplications < limitOfApplications) {
        navigate('/wizard', { state: { orgId: orgContext.organization?.id } });
      } else {
        orgContext.organization?.owner.id === session.user?.id
          ? setShowUpgradePlanModal(true)
          : setShowCanCreateOrgModal(true);
      }
    }
  };

  return (
    <>
      {/* Projects */}
      <Row className="pt-5">
        <Col className="d-flex align-items-center">
          <h4 style={{ fontWeight: '400', margin: 0 }}>
            {t('organizations.organization.Projects')}
          </h4>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Authorization
              context={ContextRole.ORGANIZATION}
              allowedAuthorities={[RoleAuthorities.MANAGE_APP]}
            >
              <Button id={'newProjectButton'} variant="primary" onClick={verifyingOrgLimits}>
                {t('organizations.organization.NewProject')}
              </Button>
            </Authorization>
          </div>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col sm={4}>
          <input
            id={'searchField'}
            type={'search'}
            className="form-control"
            placeholder={search_project}
            onChange={(ev) => setSearchText(ev.target.value)}
          />
        </Col>
        <Col sm={8}>
          <div className="d-flex align-items-center justify-content-end">
            <h6 className="ps-1 pe-1" style={{ margin: 0 }}>
              {t('OrderBy')}
            </h6>
            <div className="ps-1 ">
              <select
                id={'filterDropdown'}
                className={`form-select form-select-sm`}
                placeholder={filter}
                defaultValue={FiltersOptions.NAME}
                onChange={(ev) => changeFilterType(ev.target.value as FiltersOptions)}
              >
                {Object.values(FiltersOptions)
                  .filter((v) => isNaN(Number(v)))
                  .map((type) => {
                    return (
                      <option id={type} key={type} value={type}>
                        {t('order_options.' + type)}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </Col>
      </Row>
      <div className="pt-5 border rounded p-4 mt-4">
        <ListGroup style={{ maxHeight: '40vh', overflowY: 'auto' }}>
          {!isLoading &&
            projects &&
            projects
              .filter((item) => {
                return (
                  !searchText ||
                  item.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
                );
              })
              .map((item: ApiAppInfo, index: number) => {
                return (
                  <ProjectRow
                    key={index}
                    apiAppInfo={item}
                    setApiAppInfo={setProjects}
                    onAfterDeleteApp={handleDeleteApp}
                    isDisabled={item.disabled}
                    reload={setReload}
                  />
                );
              })}
          {!isLoading && projects.length === 0 && (
            <div className="w-100 h-100 pt-3 pb-3" id="bodyMessage">
              <EmptyMessage
                message={t('organizations.organization.NoProjects')}
                icon="exclamation"
              />
            </div>
          )}
        </ListGroup>
      </div>

      <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.GITHUB_SYNC]}>
        <Col className={styles.Section}>
          <Row>
            <Col className={`d-flex align-items-center mt-4`}>
              <h4 style={{ fontWeight: '400', margin: 0 }}>{t('appResume.Integrations')}</h4>
            </Col>
          </Row>
          <div className={styles.FormContent}>
            {orgContext.organization?.gitHubIntegration ? (
              <HelpPopover
                placement={'top'}
                helpBoxProps={{
                  title: t('account_settings.integrations.githubDisconnectTooltip')!
                }}
              >
                <Button
                  id={'disconnectGitHub'}
                  style={{ width: 'fit-content' }}
                  variant="primary"
                  type="submit"
                  onClick={() => setShowConfirmationDialog(true)}
                >
                  <FaGithub />
                  <span className="ps-2">
                    {t('account_settings.integrations.githubDisconnect')}
                  </span>
                </Button>
              </HelpPopover>
            ) : (
              <Button
                href={`${API_URL}/orgs/${orgContext.organization?.id}/integrations/github/install`}
                style={{ width: 'fit-content' }}
                id={'connectGitHub'}
              >
                <FaGithub />
                <span className="ps-2">{t('account_settings.integrations.githubConnect')}</span>
              </Button>
            )}
          </div>
        </Col>
      </Authorization>

      <Authorization
        context={ContextRole.ORGANIZATION}
        allowedAuthorities={[RoleAuthorities.BILLING]}
      >
        <Col className={styles.Section}>
          <Row>
            <Col className={`d-flex align-items-center mt-4`}>
              <h4 style={{ fontWeight: '400', margin: 0 }}>
                {t('organizations.organization.Billing')}
              </h4>
            </Col>
          </Row>
          <div className={styles.FormContent}>
            <form
              action={`${API_URL}/stripe/orgs/${orgContext.organization?.id}/create-portal-session`}
              method="POST"
            >
              <Button id="billingInformationButton" variant="primary" type="submit">
                {t('organizations.organization.BillingInfo')}
              </Button>
            </form>
          </div>
        </Col>
      </Authorization>

      <Confirmation
        show={showConfirmationDialog}
        message={t('account_settings.integrations.githubDisconnect')}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => uninstallGitHub()}
        onClose={() => setShowConfirmationDialog(false)}
      />
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
      {showCanCreateOrgModal && (
        <CantCreateAppModal setShow={setShowCanCreateOrgModal} show={showCanCreateOrgModal} />
      )}
    </>
  );
}

export default OrganizationDashboard;
