/* eslint-disable prettier/prettier */
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import PasswordFields, { validatePassword } from 'web_ui/password_fields';
import SocialButtons from 'modules/auth/components/social_buttons';

type SignUpUserProps = {
  handleEmailOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUsernameOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordConfirmationOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePromotionalCodeOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckValidity: (validity: boolean) => void;
  email: string;
  username: string;
  password: string;
  passwordConfirmation: string;
  promotionalCode: string;
  disabelEmail?: boolean;
  disabelPromotionalCode?: boolean;
  recaptchaToken: string | null;
  setRecaptchaToken: React.Dispatch<React.SetStateAction<string | null>>;
  recaptchaRef: React.MutableRefObject<ReCAPTCHA | null>;
};

/**
 * Request user information for Sign Up process.
 *
 * @component
 */
function SignUpUserForm(props: SignUpUserProps) {
  const { t } = useTranslation();
  const [passwordConfirmationValidity, setPasswordConfirmationValidity] = React.useState<boolean>();
  const [passwordValidity, setPasswordValidity] = React.useState<boolean>(false);
  const [userNameValidity, setUserNameValidity] = React.useState<boolean>(true);
  const [emailValidity, setEmailValidity] = React.useState<boolean>();
  const [inviteCodeValidity, setInviteCodeValidity] = React.useState<boolean>();
  const [termsSelect, setTermsSelect] = React.useState(false);
  const siteKey = process.env.REACT_APP_SITE_KEY;
  const inviteCodeRequired = process.env.REACT_APP_INVITE_CODE_REQUIRED?.toLowerCase() === 'true';
  const { handleEmailOnChange, handleUsernameOnChange, handlePasswordConfirmationOnChange } = props;

  function onRecaptchaChange(token: string | null) {
    props.setRecaptchaToken(token);
    props.handleCheckValidity(token !== null);
  }

  function handleCheckPasswordConfirmation(value: string, ignoreState?: boolean) {
    const validity = value === props.password && value.length > 0;

    if (!ignoreState) {
      setPasswordConfirmationValidity(validity);
    }
    return validity;
  }

  function handleCheckEmail(next: string, ignoreState?: boolean) {
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
    );
    const validity = emailRegex.test(next);

    if (!ignoreState) {
      setEmailValidity(validity);
    }
    return validity;
  }

  function handleCheckUserName(name: string, ignoreState?: boolean) {
    const nameRegex = new RegExp('^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$');
    const validity = nameRegex.test(name);

    if (!ignoreState) {
      setUserNameValidity(validity);
    }
    return validity;
  }

  useEffect(() => {
    setPasswordValidity(validatePassword(props.password));
    const validity =
      handleCheckEmail(props.email, true) &&
      handleCheckPasswordConfirmation(props.passwordConfirmation, true) &&
      handleCheckUserName(props.username, true) &&
      props.username.length > 0 &&
      props.password.length > 0 &&
      (!inviteCodeRequired || props.promotionalCode.length > 0) &&
      termsSelect &&
      props.recaptchaToken != null &&
      passwordValidity;
    props.handleCheckValidity(validity);
  }, [props, termsSelect]);

  function handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    props.handlePasswordOnChange(event);
    setPasswordValidity(validatePassword(event.target.value));
  }

  const handleEmailChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleCheckEmail(e.target.value);
      handleEmailOnChange(e);
    },
    [handleEmailOnChange]
  );

  const handleUsernameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleCheckUserName(e.target.value);
      handleUsernameOnChange(e);
    },
    [handleUsernameOnChange]
  );

  const handlePasswordConfirmationChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleCheckPasswordConfirmation(e.target.value);
    handlePasswordConfirmationOnChange(e);
  };

  const handleCheckInviteCode = (code: string) => {
    if (!inviteCodeRequired || code.length > 0) {
      setInviteCodeValidity(true);
    } else {
      setInviteCodeValidity(false);
    }
  };

  const handleInviteCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleCheckInviteCode(e.target.value);
    props.handlePromotionalCodeOnChange(e);
  };

  return (
    <>
      <div className={`text-white ${styles.LoginSignInText}`}>
        {t('sign_up.signup')}
      </div>
      <div className={`${styles.LoginExternalLink} ${styles.LoginExternalLinkCenter}`}>
        <SocialButtons promotionalCode={props.promotionalCode} />
        <div className='mb-3'>{t('ContinueWith')}</div>
      </div>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label className="text-secondary">{t('Email')}</Form.Label>
        <Form.Control
          className={`bg-dark border-secondary text-white-50 ${styles.placeholder}`}
          onChange={handleEmailChange}
          type="email"
          value={props.email}
          required
          disabled={props.disabelEmail}
          maxLength={50}
          onBlur={() => handleCheckEmail(props.email)}
          isInvalid={emailValidity != null && !emailValidity}
          autoComplete="email"
        />
        <Form.Control.Feedback type="invalid">
          {t('sign_up.EmailFormatError')}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formUsername">
        <Form.Label className="text-secondary">{t('sign_up.Username')}</Form.Label>
        <Form.Control
          className={`bg-dark border-secondary text-white-50 ${styles.placeholder}`}
          onChange={handleUsernameChange}
          type="text"
          value={props.username}
          required
          maxLength={64}
          isInvalid={userNameValidity != null && !userNameValidity}
          onBlur={() => handleCheckUserName(props.username)}
          autoComplete="username"
        />
        <Form.Control.Feedback type="invalid">
          {t('sign_up.UsernameFormatError')}
        </Form.Control.Feedback>
      </Form.Group>
      <PasswordFields
        newPassword={props.password}
        confirmPassword={props.passwordConfirmation}
        handleNewPassword={handlePassword}
        handleConfirmPassword={handlePasswordConfirmationChange}
        isLoading={false}
        isFromSignUp
      />
      <Form.Group className="mb-3" controlId="formPromotionalCode">
        <Form.Label className="text-secondary">{t('sign_up.PromotionalCode')}</Form.Label>
        <Form.Control
          className={`bg-dark border-secondary text-white-50 ${styles.placeholder}`}
          onChange={handleInviteCodeChange}
          type="text"
          value={props.promotionalCode}
          required={inviteCodeRequired}
          maxLength={50}
          isInvalid={inviteCodeValidity != null && !inviteCodeValidity}
          disabled={props.disabelPromotionalCode}
          onBlur={() => {
            handleCheckInviteCode(props.promotionalCode);
          }}
          autoComplete="off"
        />
      </Form.Group>
      <Form.Group className="text-secondary mb-3" controlId="formTermsAndConditions">
        <Form.Check
          required
          label={
            <Trans i18nKey="sign_up.formTermsAndConditions">
              {/* .prettierignore is applied to this file due to the line bellow. */}
              By creating an account, you agree to the <a href="https://exocoding.com/terms/" target="_blank" rel="noreferrer">Terms of Service</a>. For more information about Exocoding&#39s privacy practices, see the <a href="https://exocoding.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Statement</a>. We&#39ll occasionally send you account-related emails.
            </Trans>
          }
          name="formTermsAndConditions"
          type="checkbox"
          id="formCheck"
          checked={termsSelect}
          onChange={() => {
            setTermsSelect(!termsSelect);
          }}
          value={termsSelect ? 'agreed' : undefined}
        />
      </Form.Group>
      {siteKey && <ReCAPTCHA ref={props.recaptchaRef} sitekey={siteKey} onChange={onRecaptchaChange} />}
    </>
  );
}

export default SignUpUserForm;
