import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FunctionEditorState } from '../../../web_ui/function_editor/store/types/function_editor_state';
import { FunctionsState } from '../../../web_ui/function_editor/store/types/functions';
import { ParametersState } from '../../../web_ui/function_editor/store/types/parameters';
import { ActionsState } from '../../../web_ui/function_editor/store/types/actions';
import { FrontendFunctionsRepo } from '../../../modules/designer/function_editor/repo';
import DesignerFunctionEditor from '../../../modules/designer/function_editor';
import { ModuleInfo } from 'modules/dashboard/types';
import { ApiAppInfo } from 'modules/project/types';
import { ProjectsService } from 'modules/project/services';
import useTitle from 'hooks/useTitle';
import { useTranslation } from 'react-i18next';

type DesignerFunctionEditorPageProps = {
  toggleTheme: () => void;
};

function DesignerFunctionEditorPage({ toggleTheme }: DesignerFunctionEditorPageProps) {
  const { app_id, view_id, function_id, module_id } = useParams();
  const [state, setState] = useState<FunctionEditorState>();
  const [loading, setLoading] = useState(true);
  const [mod, setMod] = useState<ModuleInfo>();
  const [app, setApp] = useState<ApiAppInfo>();
  const { t } = useTranslation();

  const setCorrectTabName = React.useCallback(async () => {
    if (!app_id || !module_id) return;
    const projectData = await ProjectsService.getProjectById(app_id);
    const moduleData = await ProjectsService.getModuleById(module_id);
    setApp(projectData);
    setMod(moduleData);
  }, [app_id, module_id]);

  useEffect(() => {
    setCorrectTabName();
  }, []);

  useTitle(
    app?.name && mod?.name ? `${t('tab.functionEditor')}${mod.name} - ${app.name}` : 'Exocoding',
    mod
  );

  useEffect(() => {
    const fetchFunctionEditorState = async () => {
      if (!view_id || !function_id) return;
      const rawState = await FrontendFunctionsRepo.getInitialState(view_id, function_id);
      const functions = {} as FunctionsState;

      // Apparently this is a FunctionType.
      Object.values(rawState.functions).forEach((f: any) => {
        functions[f.id] = {
          ...f,
          uuid: f.id,
          returnType: {
            type: f.returnType,
            objectUuid: f.returnObjectUuid,
            enumUuid: f.returnEnumUuid,
            list: f.returnList
          }
        };
      });

      const parameters = {} as ParametersState;
      Object.values(rawState.parameters).forEach((p: any) => {
        parameters[p.uuid] = { ...p, objectUuid: p?.lgObject };
      });

      const actions = {} as ActionsState;
      Object.values(rawState.actions).forEach((a: any) => {
        actions[a.uuid] = { ...a };
      });

      const newState: FunctionEditorState = {
        ...rawState,
        actions,
        functions,
        parameters,
        editor: {
          moduleId: view_id,
          callerId: function_id,
          selectedAction: '',
          mode: 'DESIGNER'
        }
      };

      setState(newState);
      setLoading(false);
    };

    fetchFunctionEditorState();
  }, [app_id, view_id, function_id]);

  return (
    <>
      {app_id && view_id && function_id && state && !loading && (
        <DesignerFunctionEditor moduleId={view_id} functionId={function_id} state={state} />
      )}
    </>
  );
}

export default DesignerFunctionEditorPage;
