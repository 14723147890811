import React from 'react';
import { Sidebar } from '../../../web_ui/function_editor/components/sidebar';
import ActionsToolbar from '../../../web_ui/function_editor/components/actions_toolbar';
import { Editor } from '../../../web_ui/function_editor/components/editor';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES
} from './function_actions/types/actions';
import { BACKEND_FUNCTIONS_ACTIONS_MANIFEST } from './function_actions';
import { DATA_TYPES } from '../types';
import EditActionToolbar from '../../../web_ui/function_editor/components/edit_action_toolbar';
import { SidebarPosition } from '../../../web_ui/workboard/sidebar';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

function BackendFunctionEditor() {
  return (
    <div
      className={'d-flex bg-body-tertiary'}
      style={{ overflow: 'hidden', marginRight: '0.5rem' }}
      id={WALKTHROUGH_STEPS_ELEMENTS['function-editor-main']}
    >
      <Sidebar.Root>
        <Sidebar.Content>
          <ActionsToolbar
            actions={Object.values(BACKEND_FUNCTIONS_ACTIONS_MANIFEST)}
            categories={Object.values(BACKEND_ACTIONS_CATEGORIES)}
            categoriesIcons={BACKEND_ACTION_CATEGORY_ICONS}
          />
        </Sidebar.Content>
      </Sidebar.Root>
      <Editor.Root>
        <Editor.Header types={Object.values(DATA_TYPES)} />
        <Editor.Body manifests={BACKEND_FUNCTIONS_ACTIONS_MANIFEST} />
      </Editor.Root>
      <Sidebar.Root position={SidebarPosition.RIGHT}>
        <EditActionToolbar manifests={BACKEND_FUNCTIONS_ACTIONS_MANIFEST} />
      </Sidebar.Root>
    </div>
  );
}

export default BackendFunctionEditor;
