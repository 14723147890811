import React from 'react';
import { t } from 'i18next';
import { FunctionActionManifest } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';

function ApiResponseIcon() {
  return (
    <span className="fa-layers fa-fw" style={{ whiteSpace: 'nowrap' }}>
      <i className="fas fa-share-from-square fa-flip-vertical" data-fa-transform="grow-2"></i>
    </span>
  );
}

export const API_RESPONSE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.API_RESPONSE,
  name: t('Return'),
  description: t('ApiResponseActionDescription'),
  color: 'warning',
  category: BACKEND_ACTIONS_CATEGORIES.FUNCTION,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.FUNCTION,
  icon: <ApiResponseIcon />,
  inlineInputs: [
    {
      label: t('Return'),
      placeholder: t('toReturn') ?? 'toReturn',
      key: 'responseVariable',
      type: ACTION_INPUTS_TYPES.VARIABLES_INPUT
    },
    {
      label: t('HttpStatus'),
      placeholder: t('HttpStatusCode') ?? 'HttpStatusCode',
      key: 'statusCode',
      type: ACTION_INPUTS_TYPES.HTTP_STATUS_INPUT
    }
  ],
  inputs: []
};
