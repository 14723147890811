import React from 'react';

const useTitle = (title: string, ...dependency: any) => {
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = React.useRef(documentDefined ? document.title : '');

  React.useEffect(() => {
    if (!documentDefined) {
      return;
    }

    if (document.title !== title) {
      document.title = title;
    }

    return () => {
      document.title = originalTitle.current;
    };
  }, [title]);
};

export default useTitle;
