import { Action } from 'redux';
import { FunctionParameterType, ParametersState } from '../../types/parameters';

export const SET_FILTER = 'SET_FILTER';

export interface SetFilterAction extends Action {
  type: 'SET_FILTER';
  payload: {
    functionUuid: string;
    value: boolean;
    filterUuid: string;
  };
}

export const setFilter = (
  functionUuid: string,
  value: boolean,
  filterUuid: string
): SetFilterAction => ({
  type: SET_FILTER,
  payload: { functionUuid, value, filterUuid }
});

export function doSetFilter(state: ParametersState, action: SetFilterAction): ParametersState {
  const { value, filterUuid } = action.payload;
  if (value) {
    state[filterUuid] = createFilterParam(filterUuid);
    return state;
  }

  if (!state[filterUuid]) return state;
  delete state[filterUuid];
  return state;
}

const createFilterParam = (filterUuid: string): FunctionParameterType => {
  return {
    uuid: filterUuid,
    name: 'filter',
    type: 'STRING',
    desc: '',
    list: false,
    required: true,
    native: true
  };
};
