import React, { ComponentType, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import { changeComponentStyle } from '../../store/actions/components';
import { changeViewStyle } from '../../store/actions/views';
import { VIEWS_MANIFEST, VIEWS_TYPES } from '../../frames';
import { ComponentManifest, Editor, LayoutComponent, View, ViewManifest } from '../../../types';
import { ViewStyles } from '../../frames/page_view';
import { ControlProps, ControlsList } from 'web_ui/workboard/sidebar/controls';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import ComponentHeader from '../components/component_header';
import { CssClassesControl } from '../../../../../web_ui/workboard/sidebar/controls/CssClassesControl';
import { isLocalhostControl } from '../../designer_utils';
import { IS_LOCALHOST } from '../../../../../constants';

type StylesToolbarProps = {
  name?: string;
  icon?: string;
};

function StylesToolbar(props: StylesToolbarProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);

  // Only one of these should be null at a time (or maybe both, if nothing is selected).
  let componentInfo: LayoutComponent | null = null;
  let viewInfo: View | null = null;
  // Manifest.
  let manifest: ComponentManifest | ViewManifest | null = null;
  // Custom component editor.
  if (editor === Editor.CUSTOM_COMPONENT) {
    if (selectedView) {
      componentInfo = components[selectedView];
      manifest = VIEWS_MANIFEST[VIEWS_TYPES.CUSTOMCOMPONENTS];
    } else if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    }
  }
  // Regular editor.
  else {
    if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    } else if (selectedView) {
      viewInfo = views[selectedView];
      manifest = VIEWS_MANIFEST[viewInfo?.type];
    }
  }

  const handleChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent) {
        dispatch(changeComponentStyle(selectedComponent, key, value));
      } else if (selectedView) {
        dispatch(changeViewStyle(selectedView, key as keyof ViewStyles, value));
      }
    },
    [dispatch, selectedComponent, selectedView]
  );

  function getLabel(label: string) {
    return t('designer.right_side.' + label);
  }

  return (
    <>
      {(viewInfo || componentInfo) && (
        <>
          <ComponentHeader name={props.name} icon={props.icon} />
          <div className={`${styles.controlList} p-3 pt-0`}>
            <CssClassesControl
              elementId={selectedComponent || selectedView || ''}
              elementType={selectedComponent ? 'component' : 'view'}
            />
            {manifest?.styles.map((item, index) => {
              if (!IS_LOCALHOST && isLocalhostControl(item?.controlType)) return null;
              const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
              let value;
              if (item.key === 'themeColor' || item.key === 'staticColor') {
                value = {
                  staticColor: componentInfo?.styles['staticColor'],
                  themeColor: componentInfo?.styles['themeColor']
                };
              } else {
                value = item.key ? componentInfo?.styles[item.key] : componentInfo?.styles;
              }
              return selectedComponent ? (
                <Control
                  key={`${selectedComponent}-${index}`}
                  id={item.key}
                  label={getLabel(item.controlLabel)}
                  value={value}
                  options={item.controlOptions}
                  onChange={handleChange}
                  tooltip={item.tooltip}
                />
              ) : (
                <Control
                  key={`${selectedComponent}-${index}`}
                  id={item.key}
                  label={getLabel(item.controlLabel)}
                  value={
                    item.key ? viewInfo?.styles[item.key as keyof ViewStyles] : viewInfo?.styles
                  }
                  options={item.controlOptions}
                  onChange={handleChange}
                  tooltip={item.tooltip}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default memo(StylesToolbar);
