import React from 'react';
import useReturnToStudio from '../../../hooks/useReturnToStudio';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import styles from './styles.module.css';

function HeaderReturn() {
  const returnToStudio = useReturnToStudio();
  const { t } = useTranslation();

  return (
    <HelpPopover
      placement={'top'}
      helpBoxProps={{
        title: t('GoBack')!
      }}
    >
      <div
        className={`btn btn-md text-body-secondary bg-body-secondary border-0 ${styles.hoverButton}`}
        onClick={() => returnToStudio()}
        id={'backButton'}
      >
        <span className={'fa fa-arrow-left'} />
      </div>
    </HelpPopover>
  );
}

export default HeaderReturn;
