import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActualEndpointType, Method } from 'modules/logic_builder/types';

// Not being used at the moment.
export type UpdateEndpointPayload = ActualEndpointType;

export type UpdateEndpointNamePayload = {
  id: string;
  newName: string;
  isValid: boolean;
};

export type UpdateEndpointMethodPayload = {
  id: string;
  newMethod: Method;
};

export type UpdateEndpointDescriptionPayload = {
  id: string;
  newDescription: string;
  isValid: boolean;
};

export type UpdateEndpointBooleanProperties = {
  id: string;
  deprecated?: boolean;
  pageable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
};

export type UpdateEndpointPathPayload = {
  id: string;
  newPath: string;
  isValid: boolean;
};

export type UpdateEndpointSummaruPayload = {
  id: string;
  newSummary: string;
  isValid: boolean;
};

export type UpdateEndpointRolesPayload = {
  id: string;
  newRoles: number[];
};

export type UpdateEndpointTagsPayload = {
  id: string;
  newTags: number[];
};

const initialState: ActualEndpointType = {
  controllerUuid: '',
  name: '',
  path: '',
  method: 'GET',
  summary: '',
  uuid: '',
  description: '',
  deprecated: false,
  accessLevel: 'PROTECTED',
  pageable: false,
  sortable: false,
  filterable: false,
  allowAllRoles: false,
  native: false,
  roles: [],
  // This is not being used. Check endpointParametersReducer.
  parameters: [],
  tags: [],
  moduleId: '',
  actions: [],
  modificationTime: new Date(),
  creationTime: new Date(),
  modifiedByUser: '',
  createdByUser: ''
};

// Used for changing the endpoint information (state.endpoint).
export const endpointEditorEndpointReducer = createSlice({
  name: 'state.endpoint',
  initialState,
  reducers: {
    updateEndpointName: (state, action: PayloadAction<UpdateEndpointNamePayload>) => {
      state.name = action.payload.newName;
    },
    updateEndpointMethod: (state, action: PayloadAction<UpdateEndpointMethodPayload>) => {
      state.method = action.payload.newMethod;
    },
    updateEndpointDescription: (state, action: PayloadAction<UpdateEndpointDescriptionPayload>) => {
      state.description = action.payload.newDescription;
    },
    updateEndpointBooleanProperties: (
      state,
      action: PayloadAction<UpdateEndpointBooleanProperties>
    ) => {
      if (action.payload.deprecated != null) {
        state.deprecated = action.payload.deprecated;
      }
      if (action.payload.pageable != null) {
        state.pageable = action.payload.pageable;
      }
      if (action.payload.sortable != null) {
        state.sortable = action.payload.sortable;
      }
      if (action.payload.filterable != null) {
        state.filterable = action.payload.filterable;
      }
    },
    updateEndpointPath: (state, action: PayloadAction<UpdateEndpointPathPayload>) => {
      state.path = action.payload.newPath;
    },
    updateEndpointRoles: (state, action: PayloadAction<UpdateEndpointRolesPayload>) => {
      state.roles = action.payload.newRoles;
    },
    updateEndpointSummary: (state, action: PayloadAction<UpdateEndpointSummaruPayload>) => {
      state.summary = action.payload.newSummary;
    },
    updateEndpointTags: (state, action: PayloadAction<UpdateEndpointTagsPayload>) => {
      state.tags = action.payload.newTags;
    }
  }
});

export const {
  updateEndpointName,
  updateEndpointRoles,
  updateEndpointMethod,
  updateEndpointPath,
  updateEndpointDescription,
  updateEndpointBooleanProperties,
  updateEndpointSummary,
  updateEndpointTags
} = endpointEditorEndpointReducer.actions;
