import React from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { ReturnType } from 'web_ui/function_editor/store/types/functions';
import { FunctionParameterCrud } from 'modules/logic_builder/types';
import Icon from 'web_ui/icon';
import DataTypePicker from '../signature/data_picker';
import useFetchTypes from 'web_ui/function_editor/action_inputs/utils/useFetchTypes';
import { FRONTEND_VARIABLE_TYPES } from 'modules/designer/types';
import { useTranslation } from 'react-i18next';

type ParameterEditorProps = {
  uuid: string;
  types: ReturnType[];
  currParam: FunctionParameterCrud;
  parameters: FunctionParameterCrud[];
  handleChangeParameters: (parameters: FunctionParameterCrud[]) => void;
};

function ParameterEditor({
  uuid,
  types,
  currParam,
  parameters,
  handleChangeParameters
}: ParameterEditorProps) {
  const { t } = useTranslation();
  const { enums, objects } = useFetchTypes(Object.values(FRONTEND_VARIABLE_TYPES), 'react');

  const handleChangeName = (name: string) => {
    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);
    myParams[editingIndexParam].name = name;
    handleChangeParameters(myParams);
  };

  const handleChangeType = (newType: ReturnType) => {
    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);

    myParams[editingIndexParam].type = newType.type;
    myParams[editingIndexParam].objectUuid = newType.objectUuid;
    myParams[editingIndexParam].enumUuid = newType.enumUuid;
    myParams[editingIndexParam].name = newType.name!;

    handleChangeParameters(myParams);
  };

  const handleDelete = (uuid: string) => {
    const myParams = parameters;
    const paramsFound = myParams.find((item) => item.uuid === uuid);
    if (paramsFound) {
      const editingIndexParam = myParams.indexOf(paramsFound);
      myParams.splice(editingIndexParam, 1);
      handleChangeParameters(myParams);
    }
  };

  if (!currParam) return null;

  const handleChangeList = (val: boolean) => {
    const myParams = parameters;
    const editingIndexParam = myParams.indexOf(currParam);

    myParams[editingIndexParam].list = val;

    handleChangeParameters(myParams);
  };

  return (
    <div className={`border-body-tertiary ${styles.ParameterEditor}`}>
      <div className={'d-flex'}>
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
        <Icon iconName={'fa-solid fa-ellipsis-vertical'} />
      </div>
      <div className={styles.ReturnType} style={{ width: '250px' }}>
        <DataTypePicker
          handleChange={handleChangeType}
          type={{
            type: currParam.type,
            objectUuid: currParam.objectUuid,
            enumUuid: currParam.enumUuid,
            list: currParam.list
          }}
          types={types}
          disabled={currParam?.native}
          enums={enums}
          objects={objects}
        />
        <div className={styles.ReturnTypeListCheck} style={{ borderRadius: 0, marginRight: 0 }}>
          <Form.Label style={{ marginBottom: '0' }}>{t('automation.step3.list')}</Form.Label>
          <Form.Check
            disabled={currParam?.native}
            checked={currParam && currParam.list ? currParam.list : false}
            onChange={(e) => handleChangeList(e.target.checked)}
          />
        </div>
      </div>
      <Form.Control
        value={currParam.name}
        onChange={(e) => handleChangeName(e.target.value)}
        style={{ borderBottomLeftRadius: '0', borderTopLeftRadius: '0', marginLeft: -8 }}
        disabled={currParam?.native}
      />
      {currParam?.native ? (
        <div className={`btn btn-sm ${styles.deleteButtonContainerDisabled}`}>
          <Icon iconName={'trash'} extraProps={'text-white'} />
        </div>
      ) : (
        <div
          className={`btn btn-sm ${styles.deleteButtonContainer}`}
          onClick={() => handleDelete(currParam.uuid!)}
        >
          <Icon iconName={'trash'} extraProps={'text-white'} />
        </div>
      )}
    </div>
  );
}

export default ParameterEditor;
