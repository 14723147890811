import React from 'react';
import styles from './styles.module.css';
import ActionWrapper from './action_wrapper';
import ActionsSpacerRoot from './actions_spacer/actions_spacer';
import ActionRoot from './action/action_root';
import { ActionsManifests } from '../../../store/types/manifestsAndInputs';
import { useDispatch, useSelector } from 'react-redux';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import useHandleDropAction from './hooks/useHandleDropAction';
import { selectAction } from '../../../store/events/editor/select_action';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';
import { FunctionActionType } from 'web_ui/function_editor/store/types/actions';

type FunctionBodyProps = {
  manifests: ActionsManifests;
  className?: string;
};

function FunctionBody({ manifests, className }: FunctionBodyProps) {
  const state = useSelector((state: FunctionEditorState | EndpointEditorState) => state);
  const functionId = state.editor.callerId;
  const selected = state.editor.selectedAction;
  const actions = state.actions;

  let functionActionsIDs: string[];
  if (state.editor.mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    functionActionsIDs = state.callerInfo.actions;
  } else {
    functionActionsIDs = state?.functions[functionId]?.actions;
  }

  const handleDrop = useHandleDropAction(functionActionsIDs, manifests, state);
  const dispatch = useDispatch();

  let indentation = 0;

  const setSelected = (index: number) => {
    if (!functionActionsIDs || index > functionActionsIDs?.length) return;
    const actionUuid = functionActionsIDs[index];
    dispatch(selectAction(actionUuid));
  };

  const functionActions = (): FunctionActionType[] => {
    return functionActionsIDs.map((uuid) => actions[uuid]);
  };

  if (!functionId || !actions || !functionActionsIDs) {
    return null;
  }

  return (
    <div
      id={WALKTHROUGH_STEPS_ELEMENTS['function-editor-body']}
      className={`${styles.FunctionBody} ${className}`}
    >
      {functionActions().map((a, index) => {
        if (manifests[a?.type]?.closeBlock) indentation--;
        const action = (
          <React.Fragment key={index}>
            <ActionsSpacerRoot index={index} handleDrop={handleDrop} />
            <ActionWrapper
              key={index}
              index={index}
              indentation={indentation}
              selected={a && a.uuid ? selected === a.uuid : false}
              handleDrop={handleDrop}
              handleSelect={setSelected}
            >
              <ActionRoot
                uuid={a?.uuid}
                manifest={manifests[a?.type]}
                index={index}
                handleDrop={handleDrop}
              />
            </ActionWrapper>
          </React.Fragment>
        );
        if (manifests[a?.type]?.openBlock) indentation++;
        return action;
      })}
      {!state.editor.customCode && (
        <ActionsSpacerRoot index={Object.values(actions).length} handleDrop={handleDrop} />
      )}
    </div>
  );
}

export default FunctionBody;
