import { ControlsTypes, LocalhostControlsList } from 'web_ui/workboard/sidebar/controls';
import { LinksState } from './store';

export const getParentIdFromComponent = (component: string, links: LinksState): string => {
  for (const parent of Object.keys(links)) {
    const children = links[parent];
    if (!children) continue;
    for (const child of children) {
      if (child === component) {
        return parent;
      }
    }
  }
  return '';
};

/**
 * Get next component.
 *
 * @param component
 * @param links
 * @param view
 * @returns
 */
export const getNextComponent = (component: string, links: LinksState, view: string): string => {
  const queue = [view];
  let returnNext = false;
  while (queue.length) {
    const checkThis = queue.pop();
    if (!checkThis) return '';

    const children = links[checkThis];
    if (!children) continue;
    for (const child of children) {
      queue.push(child);
      if (returnNext) return child;
      if (child === component) {
        returnNext = true;
      }
    }
  }
  return links[view][0];
};

/**
 * Get previous component.
 *
 * @param component
 * @param links
 * @param view
 * @returns
 */
export const getPreviousComponent = (
  component: string,
  links: LinksState,
  view: string
): string => {
  const queue = [view];
  let lastChecked = '';
  while (queue.length) {
    const checkThis = queue.pop();
    if (!checkThis) return '';

    const children = links[checkThis];
    if (!children) continue;
    for (const child of children) {
      queue.push(child);
      if (child === component) {
        // If selected component is the first to be checked, 'lastChecked' is still ''.
        // When that happens we return the last element.
        if (lastChecked === '') continue;
        return lastChecked;
      }
      lastChecked = child;
    }
  }
  return lastChecked;
};

export function isLocalhostControl(type: string) {
  return LocalhostControlsList.includes(type as ControlsTypes);
}
