import React, { ComponentType } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { Item } from './item';
import { RenderItemProps } from '.';

interface Props {
  id: string;
  RenderItem: ComponentType<RenderItemProps>;
  item?: unknown;
}

export const SortableItem = (props: Props) => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  };

  return (
    <Item
      id={props.id}
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      RenderItem={props.RenderItem}
      item={props.item}
      {...attributes}
      {...listeners}
    />
  );
};
