import { Action } from 'redux';
import { ActionsState, FunctionActionType } from '../../types/actions';
import { EditorMode } from '../../types/function_editor_state';

export const ADD_BLOCK = 'ADD_BLOCK';

export interface AddBlockAction extends Action {
  type: 'ADD_BLOCK';
  payload: {
    functionUuid: string;
    openBlockAction: FunctionActionType;
    emptyAction: FunctionActionType;
    closeBlockAction: FunctionActionType;
    mode: EditorMode;
  };
}

export const addBlock = (
  functionUuid: string,
  openBlockAction: FunctionActionType,
  emptyAction: FunctionActionType,
  closeBlockAction: FunctionActionType,
  mode: EditorMode
): AddBlockAction => ({
  type: ADD_BLOCK,
  payload: { functionUuid, openBlockAction, emptyAction, closeBlockAction, mode }
});

export function doAddBlock(state: ActionsState, action: AddBlockAction): ActionsState {
  const { openBlockAction, emptyAction, closeBlockAction } = action.payload;
  state[openBlockAction.uuid] = openBlockAction;
  state[emptyAction.uuid] = emptyAction;
  state[closeBlockAction.uuid] = closeBlockAction;
  return state;
}
