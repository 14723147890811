import { FunctionEditorState } from '../../types/function_editor_state';
import { FunctionVariableType } from '../../types/variables';
import { AddBlockAction } from '../actions/add_block';
import { EndpointEditorState } from '../../../../../routes/studio/endpoint_editor/reducers';

export function doAddBlockAction(
  state: FunctionEditorState | EndpointEditorState,
  action: AddBlockAction
): FunctionEditorState {
  state.editor.selectedAction = action.payload.openBlockAction.uuid;
  const { functionUuid, openBlockAction, emptyAction, closeBlockAction, mode } = action.payload;

  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    state.callerInfo.actions.splice(
      openBlockAction?.order ?? 0,
      0,
      openBlockAction.uuid,
      emptyAction.uuid,
      closeBlockAction.uuid
    );
  } else {
    state.functions[functionUuid].actions.splice(
      openBlockAction?.order ?? 0,
      0,
      openBlockAction.uuid,
      emptyAction.uuid,
      closeBlockAction.uuid
    );
  }

  if (action.payload.openBlockAction.type !== 'BEGIN_CYCLE') return state;

  // Create control variable in local state
  const data = action.payload.openBlockAction.data;
  const variable: FunctionVariableType = {
    uuid: data.uuid,
    name: data.name,
    desc: '',
    type: data.dataType.type,
    objectUuid: data.dataType?.objectUuid ?? '',
    enumUuid: data.dataType?.enumUuid ?? '',
    list: false,
    required: false
  };

  state.variables[variable.uuid] = variable;
  return state;
}
