import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.css';
import { Button, ButtonGroup, Dropdown, Nav, ToggleButton } from 'react-bootstrap';
import {
  setDesignerMode,
  setEditMode,
  setSelectedView,
  setViewportMode
} from '../../store/actions/studio';
import { DesignerMode, InterfaceStudioState, ViewportMode } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { BehaviorTab } from '../../studio';
import Icon from 'web_ui/icon';
import SessionContext from 'modules/auth/store';
import { Preferences } from 'modules/auth/enum';
import { ContextRole, RoleAuthorities } from '../../../../auth/types/auth_types';
import { Authorization } from '../../../../auth/session/authorization';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LocalStorageManager } from 'utils/localstorage';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { changeEditorMode } from '../../store/actions/views';
import { Editor, EditorModeOptions } from 'modules/designer/types';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import HelpIcon from 'web_ui/workboard/sidebar/controls/components/HelpIcon';
import { IS_LOCALHOST } from '../../../../../constants';

type ActionBarProps = {
  handleSelectBehaviorTabs: (tab: BehaviorTab) => void;
  selectedBehaviorTab: BehaviorTab;
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
};

export function ActionBar({
  handleSelectBehaviorTabs,
  selectedBehaviorTab,
  isRightSidebarOpen,
  isLeftSidebarOpen
}: ActionBarProps) {
  const queryParameters = useQuery();
  const designerMode = useSelector((state: InterfaceStudioState) => state.studio.designerMode);
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);
  const editMode = useSelector((state: InterfaceStudioState) => state.studio.editMode);
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const viewId = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const session = useContext(SessionContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { module_id, app_id, view_id } = useParams();

  useEffect(() => {
    const currentValTabDesigner = LocalStorageManager.getValueLocalStorageState(
      app_id!,
      module_id!
    );

    if (
      currentValTabDesigner[module_id!] &&
      currentValTabDesigner[module_id!].interface.lastSelectedTab
    ) {
      dispatch(
        setDesignerMode(currentValTabDesigner[module_id!].interface.lastSelectedTab as DesignerMode)
      );
    }

    if (
      currentValTabDesigner[module_id!] &&
      currentValTabDesigner[module_id!].interface.lastSelectedTab === 'DESIGN' &&
      currentValTabDesigner[module_id!].interface.lastSelectedViewport
    ) {
      dispatch(
        setViewportMode(
          currentValTabDesigner[module_id!].interface.lastSelectedViewport as ViewportMode
        )
      );
    }
  }, []);

  const changeDesignerMode = useCallback(
    (mode: DesignerMode) => {
      dispatch(setDesignerMode(mode));
    },
    [dispatch]
  );

  const changeViewportMode = useCallback(
    (mode: ViewportMode) => {
      dispatch(setViewportMode(mode));
    },
    [dispatch]
  );

  const handleShowGrids = useCallback(() => {
    session.changePreferences(Preferences.SHOW_GRIDS, !session.preferences[Preferences.SHOW_GRIDS]);
  }, [session]);

  const getActionWrapperLeftPadding = useMemo((): string | number => {
    if (isLeftSidebarOpen) {
      return '240px';
    } else {
      return 0;
    }
  }, [isLeftSidebarOpen]);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const getActionWrapperRightPadding = useMemo((): string => {
    if (isRightSidebarOpen) {
      if (isFromVsCodeExt()) {
        return 240 + 'px';
      } else {
        return 240 + 65 + 'px';
      }
    } else {
      if (isFromVsCodeExt()) {
        return '0px';
      } else {
        return '65px';
      }
    }
  }, [isRightSidebarOpen]);

  const VIEWPORT_MODES = [
    {
      name: 'desktop',
      icon: 'fa-solid fa-desktop fa-xl',
      tooltip: 'Desktop'
    },
    {
      name: 'mobile',
      icon: 'fa-solid fa-mobile-screen-button fa-xl',
      tooltip: 'Mobile'
    },
    {
      name: 'tablet',
      icon: 'fa-solid fa-tablet-screen-button fa-xl',
      tooltip: 'Tablet'
    }
  ];

  const styleDeclaration = useMemo((): string => {
    if (!isLeftSidebarOpen && !isRightSidebarOpen) {
      return 'HideTabNameBothSidebarsClosed';
    } else if (isLeftSidebarOpen && isRightSidebarOpen) {
      return 'HideTabNameBothSidebarsOpen';
    } else if (!isLeftSidebarOpen && isRightSidebarOpen) {
      return 'HideTabNameOneSidebarOpen';
    } else if (isLeftSidebarOpen && !isRightSidebarOpen) {
      return 'HideTabNameOneSidebarOpen';
    } else {
      return '';
    }
  }, [isLeftSidebarOpen, isRightSidebarOpen]);

  const showPropertiesTab = (): boolean => {
    const editors = ['LAYOUT', 'PAGE', 'VIEW'];
    if (editor && editors.includes(editor)) {
      return false;
    } else if (editor === 'CUSTOM_COMPONENT') {
      const isHierarchy = components[viewId ?? '']
        ? components[viewId ?? ''].data.isHierarchy
        : false;
      if (!isHierarchy) {
        return false;
      }
    }
    return true;
  };
  const updatingTab = (val: string) => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    copying[module_id!] = {
      ...copying[module_id!],
      interface: {
        ...copying[module_id!].interface!,
        lastSelectedTab: val
      }
    };

    LocalStorageManager.setValueLocalStorageState(app_id!, copying);
  };

  const updatingTabBehavior = (val: string) => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    copying[module_id!] = {
      ...copying[module_id!],
      interface: {
        ...copying[module_id!].interface,
        lastSelectedTabBehavior: val
      }
    };

    LocalStorageManager.setValueLocalStorageState(app_id!, copying);
  };

  const updateViewPortTabDesign = (val: string) => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);

    copying[module_id!] = {
      ...copying[module_id!],
      interface: {
        ...copying[module_id!].interface,
        lastSelectedViewport: val
      }
    };

    LocalStorageManager.setValueLocalStorageState(app_id!, copying);
  };

  const handleChangeGridMode = useCallback(
    (editorModeOption: EditorModeOptions) => {
      if (!view_id) return;
      dispatch(changeEditorMode(view_id, editorModeOption));
    },
    [view_id, dispatch]
  );

  const activeGridButtonClassName = () => {
    return session.preferences[Preferences.SHOW_GRIDS] ? 'active' : '';
  };

  const handleChangeEditMode = () => {
    dispatch(setEditMode(!editMode));
    dispatch(setSelectedView(viewId));
  };

  return (
    <>
      <div
        className={styles.ActionBarWrapper}
        style={{
          width: '100%',
          paddingLeft: getActionWrapperLeftPadding,
          paddingRight: getActionWrapperRightPadding
        }}
      >
        <div className={styles.ActionsWrapper}>
          <div
            id={WALKTHROUGH_STEPS_ELEMENTS['designer-action-tabs']}
            className={`${styles.Section} ${styles.LeftSection}`}
          >
            <ButtonGroup>
              <ToggleButton
                id={`designTab`}
                type="radio"
                variant={'outline-primary'}
                name="radio"
                value={'DESIGN'}
                checked={designerMode === 'DESIGN'}
                className={`${designerMode !== 'DESIGN' && styles.UnselectedButton}`}
                onChange={(e) => {
                  updatingTab(e.currentTarget.value);
                  changeDesignerMode(e.currentTarget.value as DesignerMode);
                }}
              >
                {t('designer.behaviour.Design')}
              </ToggleButton>

              <Authorization
                context={ContextRole.APP}
                allowedAuthorities={[RoleAuthorities.MANAGE_ID_OPERATIONS]}
              >
                <ToggleButton
                  id={WALKTHROUGH_STEPS_ELEMENTS['behavior-tab']}
                  type="radio"
                  variant={'outline-primary'}
                  name="radio"
                  value={'BEHAVIOR'}
                  checked={designerMode === 'BEHAVIOR'}
                  className={`${designerMode !== 'BEHAVIOR' && styles.UnselectedButton}`}
                  onChange={(e) => {
                    updatingTab(e.currentTarget.value);
                    changeDesignerMode(e.currentTarget.value as DesignerMode);
                  }}
                  disabled={!views || Object.values(views).length === 0}
                >
                  {t('designer.behaviour.Behaviour')}
                </ToggleButton>
              </Authorization>
            </ButtonGroup>
            {editor === 'PAGE' && designerMode === 'DESIGN' && IS_LOCALHOST && (
              <HelpPopover
                key={'editMode'}
                placement={'right'}
                helpBoxProps={{
                  title: `${t('designer.Overview')}`
                }}
              >
                <Button
                  className="ms-1"
                  variant={editMode ? 'secondary' : 'primary'}
                  onClick={(e) => handleChangeEditMode()}
                  style={{ width: '48px' }}
                  disabled={!views || Object.values(views).length < 1}
                >
                  <Icon iconName={editMode ? 'eye-slash' : 'eye'}></Icon>
                </Button>
              </HelpPopover>
            )}
          </div>
          <div
            id={WALKTHROUGH_STEPS_ELEMENTS['designer-viewports']}
            className={`${styles.MiddleSection} ${styles.Section}`}
          >
            {designerMode === 'DESIGN' && views && Object.values(views).length > 0 && (
              <>
                {VIEWPORT_MODES.map((mode) => {
                  return (
                    <HelpPopover
                      key={mode.name}
                      placement={'top'}
                      helpBoxProps={{
                        title: mode.tooltip
                      }}
                    >
                      <div className="ms-2">
                        <i
                          className={`${mode.icon} ${
                            selectedViewport === mode.name ? 'text-primary' : ''
                          } ${selectedViewport !== mode.name ? styles.UnselectedIcon : ''}`}
                          onClick={() => {
                            changeViewportMode(mode.name as ViewportMode);
                            updateViewPortTabDesign(mode.name);
                          }}
                        ></i>
                      </div>
                    </HelpPopover>
                  );
                })}
              </>
            )}
            {designerMode === 'BEHAVIOR' && (
              <Nav
                className={styles.ActionNavItems}
                variant="underline"
                defaultActiveKey={selectedBehaviorTab}
              >
                <Nav.Item as="li">
                  <Nav.Link
                    id="functionTab"
                    eventKey={'FUNCTIONS'}
                    onClick={() => {
                      updatingTabBehavior('FUNCTIONS');
                      handleSelectBehaviorTabs('FUNCTIONS');
                    }}
                  >
                    <div className={styles.hidePopover}>
                      <span
                        className={`${styles.ActionBarTabs} ${
                          selectedBehaviorTab === 'FUNCTIONS' && styles.ActiveTab
                        }`}
                      >
                        <Icon iconName={'code'} extraProps="pe-1"></Icon>
                        <div className={styles[styleDeclaration]}>
                          {' '}
                          {t('designer.behaviour.Functions')}
                        </div>
                      </span>
                    </div>
                    <div className={styles.showPopover}>
                      <HelpPopover
                        placement="top"
                        helpBoxProps={{
                          title: t('designer.behaviour.Functions')!
                        }}
                      >
                        <span
                          className={`${styles.ActionBarTabs} ${
                            selectedBehaviorTab === 'FUNCTIONS' && styles.ActiveTab
                          }`}
                        >
                          <Icon iconName={'code'} extraProps="pe-1"></Icon>
                          <div className={styles[styleDeclaration]}>
                            {' '}
                            {t('designer.behaviour.Functions')}
                          </div>
                        </span>
                      </HelpPopover>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    id="objectTab"
                    eventKey={'OBJECTS'}
                    onClick={() => {
                      updatingTabBehavior('OBJECTS');
                      handleSelectBehaviorTabs('OBJECTS');
                    }}
                  >
                    <div className={styles.hidePopover}>
                      <span
                        className={`${styles.ActionBarTabs} ${
                          selectedBehaviorTab === 'OBJECTS' && styles.ActiveTab
                        }`}
                      >
                        <Icon iconName={'database'} extraProps="pe-1"></Icon>
                        <div className={styles[styleDeclaration]}>
                          {' '}
                          {t('designer.behaviour.Objects')}
                        </div>
                      </span>
                    </div>
                    <div className={styles.showPopover}>
                      <HelpPopover
                        placement="top"
                        helpBoxProps={{ title: t('designer.behaviour.Objects')! }}
                      >
                        <span
                          className={`${styles.ActionBarTabs} ${
                            selectedBehaviorTab === 'OBJECTS' && styles.ActiveTab
                          }`}
                        >
                          <Icon iconName={'database'} extraProps="pe-1"></Icon>
                          <span className={styles[styleDeclaration]}>
                            {t('designer.behaviour.Objects')}
                          </span>
                        </span>
                      </HelpPopover>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li">
                  <Nav.Link
                    id="variablesTab"
                    eventKey={'VARIABLES'}
                    onClick={() => {
                      updatingTabBehavior('VARIABLES');
                      handleSelectBehaviorTabs('VARIABLES');
                    }}
                  >
                    <div className={styles.hidePopover}>
                      <span
                        className={`${styles.ActionBarTabs} ${
                          selectedBehaviorTab === 'VARIABLES' && styles.ActiveTab
                        }`}
                      >
                        <Icon iconName={'subscript'} extraProps="pe-1"></Icon>
                        <span className={styles[styleDeclaration]}>
                          {' '}
                          {t('designer.behaviour.Variables')}
                        </span>
                      </span>
                    </div>
                    <div className={styles.showPopover}>
                      <HelpPopover
                        placement="top"
                        helpBoxProps={{ title: t('designer.behaviour.Variables')! }}
                      >
                        <span
                          className={`${styles.ActionBarTabs} ${
                            selectedBehaviorTab === 'VARIABLES' && styles.ActiveTab
                          }`}
                        >
                          <Icon iconName={'subscript'} extraProps="pe-1"></Icon>
                          <span className={styles[styleDeclaration]}>
                            {' '}
                            {t('designer.behaviour.Variables')}
                          </span>
                        </span>
                      </HelpPopover>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                {showPropertiesTab() && (
                  <Nav.Item as="li">
                    <Nav.Link
                      eventKey={'PROPERTIES'}
                      onClick={() => handleSelectBehaviorTabs('PROPERTIES')}
                    >
                      <div className={styles.hidePopover}>
                        <span
                          className={`${styles.ActionBarTabs} ${
                            selectedBehaviorTab === 'PROPERTIES' && styles.ActiveTab
                          }`}
                        >
                          <Icon iconName={'subscript'} extraProps="pe-1"></Icon>
                          <span className={styles[styleDeclaration]}>
                            {' '}
                            {t('designer.behaviour.Properties')}
                          </span>
                        </span>
                      </div>
                      <div className={styles.showPopover}>
                        <HelpPopover
                          placement="top"
                          helpBoxProps={{ title: t('designer.behaviour.Properties')! }}
                        >
                          <span
                            className={`${styles.ActionBarTabs} ${
                              selectedBehaviorTab === 'PROPERTIES' && styles.ActiveTab
                            }`}
                          >
                            <Icon iconName={'subscript'} extraProps="pe-1"></Icon>
                            <span className={styles[styleDeclaration]}>
                              {' '}
                              {t('designer.behaviour.Properties')}
                            </span>
                          </span>
                        </HelpPopover>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            )}
          </div>
          <div className={`${styles.RightSection} ${styles.Section}`}>
            {designerMode === 'DESIGN' && (
              <>
                <HelpPopover
                  helpBoxProps={{
                    title: `${t('CodePreview')}`
                  }}
                  placement="top"
                >
                  <Button
                    id="codePreview"
                    variant="secondary"
                    onClick={() => setShowCodePreviewDialog(true)}
                    style={{
                      marginRight: '10px',
                      textAlign: 'center'
                    }}
                    disabled={!views || Object.values(views).length < 1}
                  >
                    <Icon iconName="code"></Icon>
                  </Button>
                </HelpPopover>

                {editor === Editor.PAGE && IS_LOCALHOST && (
                  <Dropdown id={WALKTHROUGH_STEPS_ELEMENTS['designer-grid-mode']}>
                    <Dropdown.Toggle
                      className="me-0 me-2"
                      variant="secondary"
                      id="dropdown-basic"
                      disabled={!views || Object.values(views).length < 1}
                    >
                      <HelpPopover
                        helpBoxProps={{
                          title:
                            t('designer.EditorMode.GridModeTitle') ||
                            'designer.EditorMode.GridModeTitle',
                          description:
                            t('designer.EditorMode.GridModeDescription') ||
                            'designer.EditorMode.GridModeDescription',
                          note: [
                            t('designer.EditorMode.GridModeDescriptionNote01') ||
                              'designer.EditorMode.GridModeDescriptionNote01'
                          ]
                        }}
                        placement="left"
                      >
                        {t('designer.EditorMode.GridMode')}
                        <span className="me-2">
                          <HelpIcon />
                        </span>
                      </HelpPopover>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href="#/action-1"
                        active={
                          !view_id ||
                          !views?.[view_id]?.editorMode ||
                          views[view_id].editorMode === EditorModeOptions['NORMAL']
                        }
                        onClick={() => {
                          handleChangeGridMode(EditorModeOptions.NORMAL);
                        }}
                      >
                        <HelpPopover
                          helpBoxProps={{
                            title:
                              t('designer.EditorMode.GridModeNormalTitle') ||
                              'designer.EditorMode.GridModeNormalTitle',
                            description:
                              t('designer.EditorMode.GridModeNormalDescription') ||
                              'designer.EditorMode.GridModeNormalDescription'
                          }}
                          placement="left"
                        >
                          {t('designer.EditorMode.Normal')} <HelpIcon />
                        </HelpPopover>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="#/action-2"
                        active={
                          view_id && views?.[view_id]?.editorMode === EditorModeOptions['ADVANCED']
                            ? true
                            : false
                        }
                        onClick={() => {
                          handleChangeGridMode(EditorModeOptions.ADVANCED);
                        }}
                      >
                        <HelpPopover
                          helpBoxProps={{
                            title:
                              t('designer.EditorMode.GridModeAdvancedTitle') ||
                              'designer.EditorMode.GridModeAdvancedTitle',
                            description:
                              t('designer.EditorMode.GridModeAdvancedDescription') ||
                              'designer.EditorMode.GridModeAdvancedDescription'
                          }}
                          placement="left"
                        >
                          {t('designer.EditorMode.Advanced')} <HelpIcon />
                        </HelpPopover>
                      </Dropdown.Item>

                      {/* <Dropdown.Item
                      href="#/action-3"
                      active={
                        view_id &&
                        views?.[view_id]?.editorMode === EditorModeOptions['PIXEL_PERFECT']
                          ? true
                          : false
                      }
                      onClick={() => {
                        handleChangeGridMode(EditorModeOptions.PIXEL_PERFECT);
                      }}
                    >
                      Pixel Perfect
                    </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {IS_LOCALHOST && (
                  <HelpPopover
                    helpBoxProps={{
                      title: !session.preferences[Preferences.SHOW_GRIDS]
                        ? `${t('ShowGrid')}`
                        : `${t('HideGrid')}`
                    }}
                    placement="top"
                  >
                    <button
                      id={WALKTHROUGH_STEPS_ELEMENTS['designer-show-grid']}
                      type="button"
                      className={`btn btn-secondary ${activeGridButtonClassName()}`}
                      data-bs-toggle="button"
                      aria-pressed={true}
                      onClick={() => handleShowGrids()}
                      disabled={!views || Object.values(views).length < 1}
                    >
                      <i className={`fa-solid fa-border-none fa-lg`}></i>
                    </button>
                  </HelpPopover>
                )}
              </>
            )}
          </div>
          {designerMode === 'BEHAVIOR' && (
            <Button
              id="codePreview"
              className={styles.ControlButton}
              variant="secondary"
              onClick={() => setShowCodePreviewDialog(true)}
            >
              <Icon iconName="code" extraProps="pe-1"></Icon>
            </Button>
          )}
        </div>
      </div>
      {editor && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => setShowCodePreviewDialog(false)}
          id={view_id ?? ''}
          previewType={editor}
        />
      )}
    </>
  );
}
