import { ComponentUUID, ViewUUID } from 'modules/designer/types';
import React, { forwardRef, memo, useEffect, useState } from 'react';
import Icon from 'web_ui/icon';
import ComponentsRenderer from '../components_renderer';
import { COMPONENT_TYPES } from '..';
import SectionWrapper from '../section_wrapper';
import { useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';

export type CarouselData = {
  width?: number | string;
  widthUnit?: number | string;
  maxWidth?: number | string;
  maxWidthUnit?: string;
  minWidth?: number | string;
  minWidthUnit?: string;
  widthCalc?: number;
  height?: number;
  heightUnit?: number | string;
  maxHeight?: number | string;
  maxHeightUnit?: string;
  minHeight?: number | string;
  minHeightUnit?: string;
  optionSizeWidth?: string;
  optionSizeHeight?: string;
  qtdPages?: number;
  selectedPage?: number;
  timeToChange?: number;
};

export type CarouselStyle = any;

export type CarouselComponentProps = {
  uuid: ComponentUUID;
  data: CarouselData;
  styles: CarouselStyle;
  custom_uuid: ComponentUUID;
  new_custom_uuid: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
};

function Carousel(props: CarouselComponentProps, ref: React.Ref<any>) {
  const [circles, setCircles] = useState<number>(1);
  const links = useSelector((state: InterfaceStudioState) => state.links);

  useEffect(() => {
    setCircles(Number(props.data.qtdPages));
  }, [props.data.qtdPages]);

  const style = {
    ...(props.data.width &&
      props.data.widthUnit && {
        width: props.data.width + '' + props.data.widthUnit
      }),
    ...(props.data.height &&
      props.data.heightUnit && {
        height: props.data.height + '' + props.data.heightUnit
      }),
    ...(props.data.width &&
      !props.data.widthUnit && {
        width: props.data.width
      }),
    ...(props.data.height &&
      !props.data.heightUnit && {
        height: props.data.height
      }),
    ...(props.data.maxHeight &&
      props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight + '' + props.data.maxHeightUnit
      }),
    ...(props.data.maxHeight &&
      !props.data.maxHeightUnit && {
        maxHeight: props.data.maxHeight
      }),
    ...(props.data.minHeight &&
      props.data.minHeightUnit && {
        minHeight: props.data.minHeight + '' + props.data.minHeightUnit
      }),
    ...(props.data.minHeight &&
      !props.data.minHeightUnit && {
        minHeight: props.data.minHeight
      }),
    ...(props.data.maxWidth &&
      props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth + '' + props.data.maxWidthUnit
      }),
    ...(props.data.maxWidth &&
      !props.data.maxWidthUnit && {
        maxWidth: props.data.maxWidth
      }),
    ...(props.data.minWidth &&
      props.data.minWidthUnit && {
        minWidth: props.data.minWidth + '' + props.data.minWidthUnit
      }),
    ...(!props.data.minWidthUnit && {
      minWidth: props.data.minWidth
    })
  };

  const styleToInsert = (index: number) => {
    if (props.data.selectedPage == index + 1) {
      return { fontSize: 10, color: '#0A6EFD' };
    } else {
      return { fontSize: 10, color: 'gray', border: '1px solid white', borderRadius: 999 };
    }
  };

  const renderPoints = () => {
    return (
      <>
        {circles > 0 &&
          Array(circles)
            .fill(0)
            .map((item, index) => {
              return (
                <div key={index}>
                  <i className="fa-solid fa-circle" style={styleToInsert(index)}></i>
                </div>
              );
            })}
      </>
    );
  };

  const renderComponentsSections = () => {
    const values = [];
    for (let i = 1; i <= Number(props.data.qtdPages); i++) {
      const key = `${props.uuid}${String(i) ? '_' + String(i) : ''}`;
      values[i] = (
        <SectionWrapper
          key={String(i)}
          section={String(i)}
          uuid={props.uuid}
          type={COMPONENT_TYPES.CONTAINER}
          viewUUID={props.viewUUID}
          parentUUID={props.parentUUID}
          custom_uuid={props.custom_uuid}
        >
          {!links[key] && (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '100%',
                backgroundColor: '#d6cccb'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h1 style={{ color: '#545252' }}>Add your components here</h1>
                <i
                  className="fa-solid fa-triangle-exclamation"
                  style={{ fontSize: 70, marginRight: 10, color: '#545252' }}
                ></i>
              </div>
            </div>
          )}
          <ComponentsRenderer
            section={String(i)}
            viewUUID={props.viewUUID}
            parentUUID={props.uuid}
            custom_uuid={props.custom_uuid}
            new_custom_uuid={props.new_custom_uuid}
          />
        </SectionWrapper>
      );
    }

    if (props.data.selectedPage) {
      return values[Number(props.data.selectedPage)];
    } else {
      return false;
    }
  };

  return (
    <div ref={ref} style={{ ...style, position: 'relative' }}>
      {renderComponentsSections()}
      <div style={{ position: 'absolute', bottom: 10, left: 30 }}>
        <div style={{ display: 'flex', gap: 5 }}>{renderPoints()}</div>
      </div>
    </div>
  );
}

export * from './manifest';
export * from './template';
export default memo(forwardRef(Carousel));
