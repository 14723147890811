import {
  StructToSaveFunctionCrud,
  StructToSaveFunctionResponse,
  functionEditorCrud
} from 'web_ui/function_editor/store/types/functions';
import { FunctionsRepository } from '../repos';
import {
  FunctionSimple,
  FunctionType,
  FunctionExtended,
  FunctionParameter,
  FunctionVariable,
  Action,
  FunctionReturn
} from '../types';

export class FunctionsServ {
  async createFunction(
    moduleId: string,
    functionProperties: FunctionSimple
  ): Promise<FunctionType> {
    return await FunctionsRepository.createFunction(moduleId, functionProperties);
  }

  async createFunctionCrud(
    moduleId: string,
    entityId: string,
    data: StructToSaveFunctionCrud
  ): Promise<StructToSaveFunctionResponse> {
    return await FunctionsRepository.createFunctionCrud(moduleId, entityId, data);
  }

  async getFunctions(moduleId: string): Promise<FunctionType[]> {
    return await FunctionsRepository.getFunctionsByModule(moduleId);
  }

  async getFunctionsByService(serviceId: string, moduleId: string): Promise<functionEditorCrud[]> {
    return await FunctionsRepository.getFunctionByServiceId(serviceId, moduleId);
  }

  async getFunction(functionId: string): Promise<FunctionExtended> {
    return await FunctionsRepository.getFunction(functionId);
  }

  async getFunctionsParameters(moduleId: string): Promise<FunctionExtended[]> {
    return await FunctionsRepository.getFunctionsParameters(moduleId);
  }

  async updateFunction(
    functionId: string,
    functionProperties: FunctionType
  ): Promise<FunctionType> {
    return await FunctionsRepository.updateFunction(functionId, functionProperties);
  }

  async deleteFunction(functionId: string) {
    return await FunctionsRepository.deleteFunction(functionId);
  }

  async checkDuplicateSignature(functionId: string) {
    return await FunctionsRepository.checkDuplicateSignature(functionId);
  }

  async createParameter(
    functionId: string,
    parameter: FunctionParameter
  ): Promise<FunctionParameter> {
    return await FunctionsRepository.createParameter(functionId, parameter);
  }

  async getParameters(functionId: string): Promise<FunctionParameter[]> {
    return await FunctionsRepository.getParameters(functionId);
  }

  async updateParameter(
    functionId: string,
    parameterId: string,
    parameter: FunctionParameter
  ): Promise<FunctionParameter> {
    return await FunctionsRepository.updateParameter(functionId, parameterId, parameter);
  }

  async deleteParameter(functionId: string, parameterId: string) {
    return await FunctionsRepository.deleteParameter(functionId, parameterId);
  }

  async getFunctionVariables(functionId: string): Promise<FunctionVariable[]> {
    return await FunctionsRepository.getFunctionVariables(functionId);
  }

  async createFunctionVariable(
    functionId: string,
    variable: FunctionVariable
  ): Promise<FunctionVariable> {
    return await FunctionsRepository.createFunctionVariable(functionId, variable);
  }

  async updateFunctionVariable(
    functionId: string,
    variable: FunctionVariable
  ): Promise<FunctionVariable> {
    return await FunctionsRepository.updateFunctionVariable(functionId, variable);
  }

  async deleteFunctionVariable(functionId: string, variableId: string) {
    return await FunctionsRepository.deleteFunctionVariable(functionId, variableId);
  }

  async getFunctionActions(functionId: string): Promise<Action<any>[]> {
    return await FunctionsRepository.getFunctionActions(functionId);
  }

  async addFunctionAction(functionId: string, action: Action<any>): Promise<Action<any>> {
    return await FunctionsRepository.addFunctionAction(functionId, action);
  }

  async DeleteFunctionAction(functionId: string, actionId: string): Promise<boolean> {
    return await FunctionsRepository.deleteFunctionAction(functionId, actionId);
  }

  async getFunctionReturn(functionId: string): Promise<FunctionReturn[]> {
    return await FunctionsRepository.getFunctionReturn(functionId);
  }
}
