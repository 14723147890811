import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { AddInvite } from 'modules/invitation/types';
import { RoleOptions, SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { RoleType } from 'modules/organization/types';
import produce from 'immer';
import MembersList from '../members_list';
import { validateEmail } from 'utils/inputValidation';
import { getFeatureLimit } from '../../../../utils/utils';
import { AccountService } from '../../../auth/services';
import UpgradePlanModal from '../../../../web_ui/upgrade_plan_modal';
import { useParams } from 'react-router-dom';
import useSession from '../../../../hooks/useSession';
import { OrganizationService } from '../../services';

export type InvitesListProps = {
  invites: AddInvite[];
  setInvites: React.Dispatch<React.SetStateAction<AddInvite[]>>;
  listRoles: RoleType[];
  isUpdate?: boolean;
};

function InvitesList({ invites, setInvites, listRoles, isUpdate }: InvitesListProps) {
  const [emailInvite, setEmailInvite] = useState('');
  const [emailInviteError, setEmailInviteError] = useState('');
  const [roleInvite, setRoleInvite] = useState<string>(RoleOptions.DEV);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = React.useState<boolean>(false);
  const [session] = useSession();
  const { org_id } = useParams();
  const { t } = useTranslation();

  const email: string = t('Email');
  const role: string = t('invitation.Role');
  const not_valid_email: string = t('invitation.EmailNotValid');

  const canCreateInvite = async () => {
    let userLimits;
    let numberOfMembers = 0;
    if (org_id) {
      // Existing organization
      const organization = await OrganizationService.getOrganizationById(+org_id);
      userLimits = await AccountService.getLimits(organization.owner.id);
      numberOfMembers = userLimits.totals.numberOfInvitedUsers;
    } else {
      // New organization
      userLimits = session.user;
      numberOfMembers = 1; // owner
    }
    if (!userLimits) {
      return;
    }
    const limit = getFeatureLimit(userLimits, SystemRoleAuthorityName.ADD_USER);
    if (!limit) {
      setShowUpgradePlanModal(true);
      return;
    }
    if (numberOfMembers + invites.length < limit) {
      handleInvite();
    } else {
      setShowUpgradePlanModal(true);
    }
  };

  const handleInvite = () => {
    if (!emailInvite.length) return;

    if (!validateEmail(emailInvite)) {
      setEmailInviteError(not_valid_email);
      return;
    }

    const newInvite: AddInvite = {
      email: emailInvite,
      nameRole: roleInvite
    };

    const updatedInvites = invites.map((i) => (i.email === emailInvite ? newInvite : i));

    setInvites(
      updatedInvites.some((invite) => invite.email === emailInvite)
        ? updatedInvites
        : [...updatedInvites, newInvite]
    );

    setEmailInviteError('');
    setEmailInvite('');
    setRoleInvite(RoleOptions.DEV);
  };

  const changeRoleInvite = (role: string, member: AddInvite) => {
    const changeInvites = produce(invites, (draft) => {
      const index = draft.findIndex((invite) => invite.email === member.email);
      draft[index].nameRole = role;
    });

    setInvites(changeInvites);
  };

  const deleteInvite = (member: AddInvite) => {
    setInvites((current) => current.filter((value) => value.email !== member.email));
  };

  function translatingRole(role: string) {
    switch (role) {
      case 'OWNER':
        return t('OWNER');
      case 'ADMIN':
        return t('ADMIN');
      case 'MANAGER':
        return t('MANAGER');
      case 'DEV':
        return t('DEV');
      case 'BILLING':
        return t('BILLING');
      default:
        return role;
    }
  }

  return (
    <div>
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
      <Card>
        <Card.Body>
          <div className={`mb-4 text-body-primary`}>
            <h6 className="fs-5 py-3 ps-2 m-0">{t('invitation.InviteCollaborators')}</h6>
          </div>
          <Row className="d-flex align-items-center p-2">
            <Col className="py-1" lg={7}>
              <Form.Group>
                <Form.Control
                  type="text"
                  id="formEmail"
                  onChange={(e) => setEmailInvite(e.target.value)}
                  value={emailInvite}
                  placeholder={email}
                  maxLength={255}
                />
                <Form.Text style={{ color: 'var(--bs-danger)' }}>{emailInviteError}</Form.Text>
              </Form.Group>
            </Col>
            <Col className="py-1" lg={3}>
              <select
                id={`selectRole`}
                className={`form-select form-select-sm`}
                placeholder={role}
                value={roleInvite}
                onChange={(e) => {
                  setRoleInvite(e.target.value);
                }}
              >
                {listRoles.map((role) => {
                  return (
                    <option id={role.name} key={role.name} value={role.name}>
                      {translatingRole(role.name)}
                    </option>
                  );
                })}
              </select>
            </Col>
            <Col className="d-flex justify-content-center py-1" lg={2}>
              <Button id="inviteButton-1" variant="secondary" onClick={canCreateInvite}>
                {t('invitation.Invite')}
              </Button>
            </Col>
          </Row>
          <MembersList
            members={invites}
            showUsername={false}
            inModal={true}
            onDeleteMember={deleteInvite}
            onChangeRole={changeRoleInvite}
            listRoles={listRoles}
            isSmaller={isUpdate}
          />
        </Card.Body>
      </Card>
    </div>
  );
}

export default InvitesList;
