import { Middleware } from '@reduxjs/toolkit';
import { sendEndpointEditorAction } from '../actions';

interface ActionWithPayload {
  type: string;
  payload?: any;
}

const DEBOUNCE_DELAY = 350;

// Map action type to something that the backend understands.
// If the action doesn't map to anything, it will be ignored and not sent to the backend.
const ACTIONS_MAPPER: { [key: string]: string } = {
  // Endpoint actions.
  'state.endpoint/updateEndpointName': 'UPDATE_ENDPOINT_NAME',
  'state.endpoint/updateEndpointPath': 'UPDATE_ENDPOINT_PATH',
  'state.endpoint/updateEndpointMethod': 'UPDATE_ENDPOINT_METHOD',
  'state.endpoint/updateEndpointSummary': 'UPDATE_ENDPOINT_SUMMARY',
  'state.endpoint/updateEndpointBooleanProperties': 'UPDATE_ENDPOINT_BOOLEAN_PROPERTIES',
  'state.endpoint/updateEndpointRoles': 'UPDATE_ENDPOINT_ROLES',
  'state.endpoint/updateEndpointTags': 'UPDATE_ENDPOINT_TAGS',
  'state.endpoint/updateEndpointDescription': 'UPDATE_ENDPOINT_DESCRIPTION',
  // Endpoint parameters actions.
  'state.endpointParameters/updateEndpointParametersOrder': 'UPDATE_ENDPOINT_PARAMETERS_ORDER',
  'state.endpointParameters/addEndpointParameter': 'ADD_ENDPOINT_PARAMETER',
  'state.endpointParameters/updateEndpointParameterInputType':
    'UPDATE_ENDPOINT_PARAMETER_INPUT_TYPE',
  'state.endpointParameters/updateEndpointParameterName': 'UPDATE_ENDPOINT_PARAMETER_NAME',
  'state.endpointParameters/updateEndpointParameterBooleanProperties':
    'UPDATE_ENDPOINT_PARAMETER_BOOLEAN_PROPERTIES',
  'state.endpointParameters/updateEndpointParameterType': 'UPDATE_ENDPOINT_PARAMETER_TYPE',
  'state.endpointParameters/updateEndpointParameterInputName':
    'UPDATE_ENDPOINT_PARAMETER_INPUT_NAME',
  'state.endpointParameters/deleteEndpointParameter': 'DELETE_ENDPOINT_PARAMETER',
  'state.endpointParameters/updateEndpointParameterDataTypeItem':
    'UPDATE_ENDPOINT_PARAMETER_DATA_TYPE_ITEM',
  // Endpoint actions (the 'callerId', in this case, isn't a function. It's an endpoint).
  ADD_FUNCTION: 'ADD_FUNCTION',
  CHANGE_RETURN_TYPE: 'CHANGE_RETURN_TYPE',
  SET_FILTER: 'SET_FILTER',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_SORT: 'SET_SORT',
  UPDATE_FUNCTION: 'UPDATE_FUNCTION',
  DELETE_FUNCTION: 'DELETE_FUNCTION',
  ADD_PARAM: 'ADD_PARAM',
  DELETE_PARAM: 'DELETE_PARAM',
  UPDATE_PARAM: 'UPDATE_PARAM',
  CHANGE_PARAM_TYPE: 'CHANGE_PARAM_TYPE',
  CHANGE_PARAM_ORDER: 'CHANGE_PARAM_ORDER',
  ADD_VARIABLE: 'ADD_VARIABLE',
  DELETE_VARIABLE: 'DELETE_VARIABLE',
  UPDATE_VARIABLE: 'UPDATE_VARIABLE',
  CHANGE_VARIABLE_TYPE: 'CHANGE_VARIABLE_TYPE',
  ADD_ACTION: 'ADD_ACTION',
  UPDATE_ACTION: 'UPDATE_ACTION',
  DELETE_ACTION: 'DELETE_ACTION',
  CHANGE_ACTION_ORDER: 'CHANGE_ACTION_ORDER',
  ADD_BLOCK: 'ADD_BLOCK',
  DELETE_BLOCK: 'DELETE_BLOCK',
  CREATE_IF: 'CREATE_IF',
  CREATE_ELSE: 'CREATE_ELSE',
  REPLACE_EMPTY: 'REPLACE_EMPTY',
  CHANGE_RETURN: 'CHANGE_RETURN'
};

export const requestMiddleware: Middleware = (storeAPI) => {
  const timers: { [actionType: string]: NodeJS.Timeout } = {};
  // Used for batching payloads with the same action type.
  const buffers: { [actionType: string]: any[] } = {};
  // Always wait for the previous action to finish before sending the next one.
  let globalQueue: Promise<void> = Promise.resolve();

  return (next) => (action: ActionWithPayload) => {
    const { type, payload } = action;
    const { moduleId, endpointId } = storeAPI.getState().editorStatus;

    const backendAction = ACTIONS_MAPPER[type];
    if (!backendAction) {
      next(action);
      return;
    }

    // If the payload is not valid, do not dispatch the action.
    if (payload && payload.isValid === false) {
      next(action);
      return;
    }

    if (!buffers[backendAction]) {
      buffers[backendAction] = [];
    }
    buffers[backendAction].push(payload);

    const processQueue = async () => {
      const batchedPayload = [...buffers[backendAction]];
      buffers[backendAction] = [];
      try {
        await sendEndpointEditorAction(
          backendAction,
          payload,
          batchedPayload,
          moduleId,
          endpointId
        );
      } catch (error) {
        console.error(`Error sending ${backendAction} action:`, error);
      }
    };

    if (timers[backendAction]) {
      clearTimeout(timers[backendAction]);
    }

    timers[backendAction] = setTimeout(() => {
      globalQueue = globalQueue.then(() => processQueue());
      delete timers[backendAction];
    }, DEBOUNCE_DELAY);

    next(action);
  };
};
