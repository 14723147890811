import React, { useContext } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import { ActionsCategory, ActionsCategoriesIcons } from '../../store/types/manifestsAndInputs';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../../modules/project/store/app_context';
import { BACKEND_ACTIONS_CATEGORIES } from '../../../../modules/logic_builder/function_editor/function_actions/types/actions';

type CategoriesFilterProps = {
  categories: ActionsCategory[];
  categoriesIcons: ActionsCategoriesIcons;
  selectedCategory: string;
  setSelectedCategory: (name: string) => void;
};

function CategoriesFilter({
  categories,
  categoriesIcons,
  selectedCategory,
  setSelectedCategory
}: CategoriesFilterProps) {
  const hasDatabase = useContext(AppContext).projectInformation?.has_database;

  const shouldDisplay = (c: ActionsCategory) => {
    if (c === BACKEND_ACTIONS_CATEGORIES.ENTITY) {
      return hasDatabase;
    }
    return true;
  };

  const uppercaseFirstLetter = (text: string) => {
    return `${text[0].toUpperCase()}${text.substring(1).toLowerCase()}`;
  };
  const { t } = useTranslation();

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <h6 style={{ fontWeight: '400' }}>{t('automation.step3.Categories')}</h6>
        {selectedCategory && (
          <div className={styles.clearCategories} onClick={() => setSelectedCategory('')}>
            <Icon iconName={'x'} extraProps={'fa-xs'} />
          </div>
        )}
      </div>
      <div className={`${styles.categoriesFilter}`}>
        {categories.map(
          (c) =>
            shouldDisplay(c) && (
              <div
                id={`${t(c.toLowerCase())}Categories`}
                onClick={() => setSelectedCategory(c)}
                key={c}
                className={`${c === selectedCategory && 'text-primary-emphasis'}`}
              >
                {/*// @ts-ignore*/}
                <Icon iconName={categoriesIcons[c]} />
                <span className={`ms-1 ${styles.categoriesText}`}>
                  {t(uppercaseFirstLetter(c))}
                </span>
              </div>
            )
        )}
      </div>
      <hr />
    </>
  );
}

export default CategoriesFilter;
