import { FunctionsState } from '../types/functions';
import {
  doUpdateFunction,
  UPDATE_FUNCTION,
  UpdateFunctionAction
} from '../events/functions/update_function';
import {
  CHANGE_RETURN_TYPE,
  ChangeReturnTypeAction,
  doChangeReturnType
} from '../events/functions/change_return_type';
import produce from 'immer';
import {
  CHANGE_PARAM_ORDER,
  ChangeParamOrderAction,
  doChangeParamOrder
} from '../events/functions/change_param_order';
import { ADD_PARAM, AddParamAction } from '../events/parameters/add_param';
import { doAddParam } from '../events/functions/add_param';
import { SET_PAGINATION, SetPaginationAction } from '../events/parameters/set_pagination';
import { doSetPagination } from '../events/functions/set_pagination';
import { SET_SORT, SetSortAction } from '../events/parameters/set_sort';
import { doSetSort } from '../events/functions/set_sort';
import { SET_FILTER, SetFilterAction } from '../events/parameters/set_filter';
import { doSetFilter } from '../events/functions/set_filter';
import { initialState } from '../initial_empty_state';

type FunctionsActions =
  | UpdateFunctionAction
  | ChangeReturnTypeAction
  | AddParamAction
  | ChangeParamOrderAction
  | SetPaginationAction
  | SetSortAction
  | SetFilterAction;

export const functionsReducer = (
  state: FunctionsState = initialState.functions,
  action: FunctionsActions
): FunctionsState => {
  return produce(state, (draft: FunctionsState) => {
    switch (action.type) {
      case UPDATE_FUNCTION:
        return doUpdateFunction(draft, action);
      case CHANGE_RETURN_TYPE:
        return doChangeReturnType(draft, action);
      case ADD_PARAM:
        return doAddParam(draft, action);
      case CHANGE_PARAM_ORDER:
        return doChangeParamOrder(draft, action);
      case SET_PAGINATION:
        return doSetPagination(draft, action);
      case SET_SORT:
        return doSetSort(draft, action);
      case SET_FILTER:
        return doSetFilter(draft, action);
      default:
        return state;
    }
  });
};
