import React from 'react';
import { useTranslation } from 'react-i18next';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';

type ActionButtonProps = {
  onClick: () => void;
  className: string;
  icon: React.ReactNode;
};

export function ActionButton({ onClick, className, icon }: ActionButtonProps) {
  const { t } = useTranslation();

  function handleClick() {
    onClick();
  }

  return (
    <HelpPopover
      placement={'top'}
      helpBoxProps={{
        title: t('GoBack') ?? 'GoBack'
      }}
    >
      <div
        className={`btn btn-md text-body-secondary bg-body-secondary border-0 ${className}`}
        onClick={handleClick}
        id={'backButton'}
      >
        {icon}
      </div>
    </HelpPopover>
  );
}
