import React from 'react';
import {
  BACKEND_ACTION_CATEGORY_ICONS,
  BACKEND_ACTIONS_CATEGORIES,
  BACKEND_ACTIONS_TYPES
} from '../types/actions';
import SetVariableIcon from './set_variable_icon';
import { FunctionActionManifest } from '../../../../../web_ui/function_editor/store/types/manifestsAndInputs';
import { ACTION_INPUTS_TYPES } from '../../../../../web_ui/function_editor/action_inputs';
import { FunctionVariableType } from '../../../../../web_ui/function_editor/store/types/variables';
import { FunctionParameterType } from '../../../../../web_ui/function_editor/store/types/parameters';
import { TYPE_PICKER_TYPES } from '../../../../../web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';
import { FunctionEditorState } from '../../../../../web_ui/function_editor/store/types/function_editor_state';
import { DataType } from '../../../types';
import { t } from 'i18next';

function filterByVariableDataType(
  state: FunctionEditorState,
  actionUuid: string,
  dataType: DataType
): boolean {
  if (!dataType) return false;

  const variable = state.actions[actionUuid].data?.variable;
  if (!variable) return false;

  // get variable/parameter
  let variableParameter: FunctionVariableType | FunctionParameterType | undefined;
  switch (variable.type) {
    case TYPE_PICKER_TYPES.VAR:
      variableParameter = state.variables[variable.value];
      break;
    case TYPE_PICKER_TYPES.PARAM:
      variableParameter = state.parameters[variable.value];
      break;
    default:
      variableParameter = undefined;
      break;
  }
  if (!variableParameter) return false;

  // get the type of target
  let testType: string | undefined;
  if (variableParameter.type === 'OBJECT') {
    if (variable.objectNode) {
      // source is an item of an object
      testType = state.objects_items[variable.objectNode]?.dataType;
    } else if (dataType === 'OBJECT') {
      // both source/target are objects
      return true;
    }
  } else {
    // get type of target variable/parameter
    testType = variableParameter.type;
  }
  if (!testType) return false;

  return dataType?.toUpperCase() === testType.toUpperCase();
}

export const SET_VARIABLE_MANIFEST: FunctionActionManifest = {
  type: BACKEND_ACTIONS_TYPES.SET_VARIABLE,
  name: t('SetVariable'),
  description: 'Action to set a variable value',
  color: 'success',
  category: BACKEND_ACTIONS_CATEGORIES.VARIABLE,
  categoryIcon: BACKEND_ACTION_CATEGORY_ICONS.VARIABLE,
  icon: <SetVariableIcon />,
  inlineInputs: [
    {
      label: t('SetToVariable'),
      placeholder: '',
      key: 'variable',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { argumentTypes: [TYPE_PICKER_TYPES.VAR, TYPE_PICKER_TYPES.PARAM] }
    },
    {
      label: t('TheValue'),
      placeholder: '',
      key: 'value',
      type: ACTION_INPUTS_TYPES.ARGUMENT_PICKER,
      options: { filterByDataType: filterByVariableDataType }
    }
  ],
  inputs: []
};
