import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Service } from 'modules/logic_builder/types';
import { ServicesService } from 'modules/logic_builder/services';
import LogicBuilderContext from 'modules/logic_builder/store';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { useParams } from 'react-router-dom';
import { Entity } from 'routes/automation_wizard/types';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import { v4 } from 'uuid';
import { Table } from '../../../../modeler/types';
import { PopupAlert, PopupAlertVariant } from 'web_ui/popup_alert';

type ServiceCreatorDialogProps = {
  show: boolean;
  onClose: () => void;
  preSelectedEntity?: Entity;
  onChange?: (data: CrudData) => void;
  crudData?: CrudData;
  nameOfServiceCreated?: (val: string) => void;
  tables?: Table[];
  onCreate?: (createdService: string) => void;
  loading?: (val: boolean) => void;
};

export const validNameRegex = /^[a-zA-Z0-9]{1,64}$/;

export function validateName(name: string) {
  if (!name) return true;
  return !validNameRegex.test(name);
}

export function ServiceCreatorDialog(props: ServiceCreatorDialogProps) {
  const { module_id } = useParams();
  const { t } = useTranslation();
  const [newService, setNewService] = useState<Service>({
    name: '',
    description: '',
    native: false,
    disabled: false
  });
  const [isNameInvalid, setIsNameInvalid] = useState(false);
  const [isPathInvalid, setIsPathInvalid] = useState(false);
  const { fetchServices } = useContext(LogicBuilderContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<PopupAlertVariant>('success');

  const showErrorPopup = (message: string) => {
    setAlertVariant('danger');
    setAlertMessage(message);
  };

  const hideAlertPopup = () => {
    setAlertMessage('');
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!module_id || !newService) return;

    props.loading && props.loading(true);
    if (props.crudData && props.onChange) {
      // verifying the name
      const moduleServices = await ServicesService.getServices(module_id, newService.name);
      if (moduleServices.some((service) => service.name === newService.name)) {
        // setIsNameInvalid(true);
        showErrorPopup(t('automation.errorMessageService'));
        return;
      }

      // exclude other new services (since CRUD only creates one service)
      const copyData = props.crudData.services.filter((service) => service.new !== true);
      const newOne = {
        name: newService.name,
        description: newService.description,
        native: newService.native,
        entityUuid: newService.entityUuid,
        uuid: newService.uuid ? newService.uuid : v4(),
        new: true,
        disabled: false
      };
      copyData.push(newOne);
      const crud = {
        ...props.crudData,
        services: copyData,
        selectedService: newOne.uuid
      };
      props.onChange(crud);
      props.onClose();
      props.loading && props.loading(false);
    } else {
      setIsLoading(true);
      await ServicesService.createService(module_id, newService)
        .then((createdService) => {
          fetchServices(module_id);
          props.onCreate && props.onCreate(createdService.uuid ?? '');
          props.onClose();
        })
        .catch((err) => {
          showErrorPopup(t('automation.errorMessageService'));
        })
        .finally(() => {
          setIsLoading(false);
          props.loading && props.loading(false);
          if (props.nameOfServiceCreated) {
            props.nameOfServiceCreated(newService.name);
          }
        });
    }
  }

  useEffect(() => {
    setNewService({ name: '', description: '', native: false, disabled: false });
  }, [props.show]);

  function handleNameChange(e: any) {
    const name = e.target.value ?? '';
    setNewService({ ...newService, name: name } as Service);
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
      centered
      scrollable
    >
      <Form onSubmit={onSubmit} className={styles.formWrapper} id="formModal">
        <Modal.Header closeButton>
          <Modal.Title>{t('logicBuilder.service.Create')}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }} id="bodyModal">
          {/* {errorMessage && (
            <span className={styles.errorMsg}>{`*${t('logicBuilder.service.NameError')}`}</span>
          )} */}
          {alertMessage && (
            <Modal
              show={alertMessage ? true : false}
              className={styles.containerModal}
              centered={false}
            >
              <PopupAlert i18nKey={alertMessage} onClose={hideAlertPopup} variant={alertVariant} />
            </Modal>
          )}
          <Form.Group className="mb-3" controlId="formName">
            <Form.Label>{t('logicBuilder.service.Name')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('logicBuilder.service.NamePlaceholder') ?? ''}
              value={newService?.name ?? ''}
              // isInvalid={isNameInvalid}
              onChange={(e) => handleNameChange(e)}
              maxLength={64}
              autoFocus
              required
            />
            <Form.Control.Feedback type="invalid">Please inform a valid name</Form.Control.Feedback>
            <Form.Text className="text-muted">
              {t('logicBuilder.service.NameRequirements')}
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formDescription">
            <Form.Label>{t('logicBuilder.service.Description')}</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={t('logicBuilder.service.DescriptionPlaceholder') ?? ''}
              rows={2}
              maxLength={255}
              value={newService?.description ?? ''}
              onChange={(e) =>
                setNewService({
                  ...newService,
                  description: e.target.value
                } as Service)
              }
            />
          </Form.Group>
          {newService?.description && (
            <p>
              {128 - newService.description.length} {t('logicBuilder.service.CharactersLeft')}
            </p>
          )}

          <Form.Group controlId="selectEntity">
            <Form.Label style={{ marginTop: 10 }}>{t('modeler.Entity')}</Form.Label>
            <Form.Select
              style={{ marginTop: 10 }}
              disabled={!!props.preSelectedEntity}
              onChange={(e) =>
                setNewService({
                  ...newService,
                  entityUuid: e.target.value
                } as Service)
              }
            >
              {props.preSelectedEntity && (
                <option
                  key={props.preSelectedEntity.entityUuid}
                  value={props.preSelectedEntity.entityUuid}
                  selected
                >
                  {props.preSelectedEntity.entityName}
                </option>
              )}
              {props.tables && <option value="">---</option>}
              {props.tables?.map((entity) => (
                <option key={entity.uuid} value={entity.uuid}>
                  {entity.content.data.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancelButton" variant="secondary" onClick={() => props.onClose()}>
            {t('Cancel')}
          </Button>
          <Button
            id={'saveButton'}
            variant="primary"
            type="submit"
            disabled={isNameInvalid || isPathInvalid}
          >
            {t('logicBuilder.service.createOnly')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
