import React, { useState } from 'react';

import styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedComponent, setSelectedView } from '../../store/actions/studio';
import { ComponentUUID, ViewUUID } from '../../../types';
import Viewport from '../../components/viewport';
import { InterfaceStudioState } from '../../store';
import { changeViewProperty } from '../../store/actions/views';
import AnimatedLoading from 'web_ui/animated_loading';

export type ViewData = {
  title: string;
  description?: string;
  route?: string;
  rootPage?: boolean;
  listParams?: string[];
  url?: string;
};

export type ViewStyles = {
  backgroundColor: string;
  globalFont: string;
  globalFontSize: number;
  globalFontColor: string;
  classes: string[];
};

export type PageViewProps = {
  uuid: ViewUUID;
  name: string;
  data: ViewData;
  layout: ComponentUUID[];
  styles?: ViewStyles;
  layout_uuid?: ViewUUID;
};

export type MoldureProps = {
  unselectComponent: () => void;
  route?: string;
  children: React.ReactNode;
  rootpage?: boolean;
  updateUrlBar: (value: string) => void;
};

function PageView(props: PageViewProps) {
  const dispatch = useDispatch();
  const selectedView = useSelector((state: InterfaceStudioState) => state.studio.selectedView);
  const selectedViewport = useSelector((state: InterfaceStudioState) => state.studio.viewportMode);
  const [loading, setLoading] = useState(true);
  const themes = useSelector((state: InterfaceStudioState) => state.theme);
  const API_URL = process.env.REACT_APP_API_URL;

  const style = {
    backgroundColor: themes?.colors?.['BACKGROUND_COLOR'] ?? props.styles?.backgroundColor,
    fontFamily: themes?.text?.['GLOBAL_FONT'] ?? `${props.styles?.globalFont}`,
    fontSize: themes?.text?.['FONT_SIZE']
      ? Number(themes?.text?.['FONT_SIZE']) <= 0
        ? `10px`
        : `${themes?.text?.['FONT_SIZE']}px`
      : Number(props.styles?.globalFontSize) <= 0
      ? `10px`
      : `${props.styles?.globalFontSize}px`,
    color: themes?.text?.['GLOBAL_FONT_COLOR'] ?? props.styles?.globalFontColor,
    backgroundImage:
      props.data.url && props.data.url.includes('http')
        ? `url("${props.data.url}")`
        : `url("${API_URL! + props.data.url}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  };

  function handleSelectView() {
    dispatch(setSelectedView(props.uuid));
  }

  const updateUrlBar = (value: string): void => {
    if (!selectedView) return;
    dispatch(changeViewProperty(selectedView, 'route', value));
  };

  return (
    <>
      {loading && <AnimatedLoading />}

      <section
        className={`${styles[selectedViewport]} ${styles.browser} ${
          loading ? 'd-none' : 'd-block'
        }`}
        onClick={handleSelectView}
      >
        <Viewport uuid={props.uuid} styles={{ ...style }} onLoad={() => setLoading(false)} />
      </section>
    </>
  );
}

export * from './manifest';
export * from './template';
export default PageView;
