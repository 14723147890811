import React from 'react';
import styles from './styles.module.css';
import { Endpoint, Method } from 'modules/logic_builder/types';
import { EndpointInstance } from './endpoint_instance';
import EmptyMessage from 'web_ui/empty';
import { useTranslation } from 'react-i18next';

type EndpointsListProps = {
  search: string;
  endpoints: Endpoint[];
  onCreate: () => void;
  controllerPath: string;
};

export function EndpointsList(props: EndpointsListProps) {
  const { t } = useTranslation();
  const methodOrder: Method[] = ['POST', 'GET', 'PUT', 'PATCH', 'DELETE'];

  const applyFilter = (endpoint: Endpoint) => {
    return (
      !props.search ||
      endpoint.method.toLowerCase().includes(props.search) ||
      endpoint.path.toLowerCase().includes(props.search) ||
      endpoint.name.toLowerCase().includes(props.search) ||
      endpoint.summary.toLowerCase().includes(props.search)
    );
  };

  function handleCreate(event?: React.MouseEvent<HTMLButtonElement>) {
    event && event.stopPropagation();
    props.onCreate();
  }

  const sortFunction = (a: Endpoint, b: Endpoint) => {
    const methodComparison = methodOrder.indexOf(a.method) - methodOrder.indexOf(b.method);
    if (methodComparison !== 0) {
      return methodComparison;
    }

    return a.name.localeCompare(b.name);
  };

  return (
    <div id="list-group" className={styles.EndpointsListWrapper}>
      {props.endpoints
        .filter((e) => applyFilter(e))
        .sort(sortFunction)
        .map((endpoint) => {
          return (
            <EndpointInstance
              key={endpoint.uuid}
              cursor="pointer"
              endpoint={endpoint}
              controllerPath={props.controllerPath}
              showEditBtn={!endpoint.native}
              context="endpoints-list"
            />
          );
        })}
      {!props.endpoints.length && (
        <div className="w100 h100">
          <EmptyMessage
            message={t('logicBuilder.NoEndpoints')}
            icon="exclamation"
            actionMessage={t('logicBuilder.CreateEndpoint') ?? ''}
            linkAction={handleCreate}
          ></EmptyMessage>
        </div>
      )}
    </div>
  );
}
