import React from 'react';
import { Method, METHODS } from '../../../../../modules/logic_builder/types';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { updateEndpointMethod } from '../../reducers/endpointInfo';
import { RootState } from '../../reducers';
import { useTranslation } from 'react-i18next';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

type MethodsInputProps = {
  elementId?: string;
  className?: string;
};

export const MethodsInput = (props: MethodsInputProps) => {
  const { elementId, className } = props;
  const dispatch = useEndpointEditorDispatch();
  const { uuid, method } = useEndpointEditorSelector((state: RootState) => state.endpoint);
  const { t } = useTranslation();

  const onChangeMethod = (newMethod: Method) => {
    dispatch(updateEndpointMethod({ id: uuid, newMethod }));
  };

  return (
    <div className={`mb-3 ${className}`} id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-method']}>
      <div className="form-label">{t('logicBuilder.endepoint.Method')}</div>
      <select
        id={elementId ?? 'endpoint-method-input'}
        className={`form-select`}
        value={method}
        onChange={(e) => onChangeMethod(e.target.value as Method)}
      >
        {Object.keys(METHODS).map((method) => {
          return (
            <option key={method} value={method}>
              {METHODS[method as Method]}
            </option>
          );
        })}
      </select>
    </div>
  );
};
