import React, { useCallback, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { arrayMove, SortableContext, SortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './sortable_item';
import { Item } from './item';

export type DragNDropItem = {
  id: string;
  [key: string]: unknown;
};

export type ItemsWrapperProps = {
  children: React.ReactNode;
  [key: string]: unknown;
};

export type RenderItemProps = {
  id: string;
  isDragging?: boolean;
  item?: unknown;
  [key: string]: unknown;
};

interface DragNDropProps {
  // Must have properties: id.
  items: DragNDropItem[];
  // The filtered items (in case there is a search bar).
  filteredItems?: DragNDropItem[];
  // Update items order.
  updateOrder: (
    items: DragNDropItem[],
    movedId: string,
    oldIndex: number,
    newIndex: number
  ) => void;
  // Used to render the item.
  RenderItem: React.ComponentType<RenderItemProps>;
  // Used to wrap the items.
  ItemsWrapper: React.ComponentType<ItemsWrapperProps>;
  // Sorting strategy.
  sortingStrategy: SortingStrategy;
}

export const DragNDrop = ({
  items,
  updateOrder,
  RenderItem,
  ItemsWrapper,
  filteredItems,
  sortingStrategy
}: DragNDropProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (over && active.id !== over.id) {
        const oldIndex = items.findIndex((e) => e.id === active.id.toString());
        const newIndex = items.findIndex((e) => e.id === over.id.toString());
        const newItems = arrayMove(items, oldIndex, newIndex);
        updateOrder(newItems, active.id.toString(), oldIndex, newIndex);
      }
      setActiveId(null);
    },
    [items, updateOrder]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const getItemById = (id: string) => {
    return items.find((item) => item.id === id);
  };

  const listItems = filteredItems ? filteredItems : items;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={sortingStrategy}>
        <ItemsWrapper>
          {listItems.map((item) => (
            <SortableItem key={item.id} id={item.id} RenderItem={RenderItem} item={item} />
          ))}
        </ItemsWrapper>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? (
          <Item id={activeId} RenderItem={RenderItem} item={getItemById(activeId)} isDragging />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
