import React, {
  createContext,
  DragEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Background from './background';
import styles from './styles.module.css';
import { Transform, Zoom } from 'packages/zoom-pan';
import FrameRenderer from 'web_ui/workboard/frame/FrameRenderer';
import { Frame, FrameUUID } from './frame';
import { v4 } from 'uuid';
import { Widget, WidgetType } from './widgets/types';
import { useDispatch, useSelector } from 'react-redux';
import { addWidget } from 'modules/designer/studio/store/actions/widgets';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import SessionContext from 'modules/auth/store';
import ContextMenu from './ContextMenu';
import { WIDGETS_TEMPLATE } from './widgets';
import { useLocation } from 'react-router-dom';
import ContextMenuItem, { ContextMenuOption } from './ContextMenuItem';
import { Preferences } from '../../modules/auth/enum';
import { Button, DropdownButton, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { useClickOutsideEvent } from 'hooks/useClickOutside';

export type WorkboardContextType = {
  workboardRef: React.RefObject<HTMLDivElement> | null;
  scale: number;
  translate: {
    x: number;
    y: number;
  };
};

export const WorkboardContext = createContext<WorkboardContextType>({
  workboardRef: null,
  scale: 1,
  translate: {
    x: 0,
    y: 0
  }
});

export type WorkboardProps = {
  showZoom?: boolean;
  showFocus?: boolean;
  showSettings?: boolean;
  // toggleTheme: () => void;
  onDrop?: (
    ev: DragEvent<HTMLElement>,
    currentScale: number,
    currenPosition: { posX: number; posY: number }
  ) => void;
  children?: ReactNode;
  frames: { [key: FrameUUID]: Frame };
  contextMenuItems?: ContextMenuOption[];
  isRightSidebarOpen: boolean;
  handleWorkboardOnClick: () => void;
};

const Workboard = ({
  showZoom,
  showFocus,
  showSettings,
  onDrop,
  children,
  frames,
  contextMenuItems,
  isRightSidebarOpen,
  handleWorkboardOnClick
}: WorkboardProps) => {
  const workboardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const framesContainerRef = useRef<HTMLDivElement>(null);
  const [currentScale, setCurrentScale] = useState(0.7);
  const [currentPosition, setCurrentPosition] = useState({ posX: 0, posY: 0 });
  const [zoomInstance, setZoomInstance] = useState<Zoom>();
  const { module_id, view_id } = useSelector((state: InterfaceStudioState) => state.studio);
  const session = useContext(SessionContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'modeler';

  // used to prevent the browser zoom on press control key + mouse scroll, then we can use our zoom
  useEffect(() => {
    const workboarkElement = workboardRef.current;
    if (!workboarkElement) return;
    workboardRef.current.addEventListener(
      'wheel',
      (event) => {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      },
      true
    );
  }, []);

  const handleZoom = React.useCallback((t: Transform) => {
    if (framesContainerRef.current == null) return;
    framesContainerRef.current.style.transform =
      'translate(' + t.x + 'px,' + t.y + 'px) scale(' + t.k + ')';
    setCurrentScale(t.k);

    setCurrentPosition({ posX: t.x, posY: t.y });
  }, []);

  const checkMouseDownTarget = React.useCallback((e: MouseEvent): boolean => {
    return !framesContainerRef.current?.contains(e.target as Node);
  }, []);

  useEffect(() => {
    // TODO: this probably should be a singleton instead, not a state (setZoomInstance).
    let zoom: Zoom | null = null;
    if (zoomInstance != null) {
      zoom = zoomInstance;
      zoom.reset();
      zoom.setScaleExtent(0.2, 2);
      zoom.on('zoom', handleZoom);
      zoom.hook('mousedown', checkMouseDownTarget);
      setZoomInstance(zoom);
    } else {
      zoom = new Zoom(workboardRef.current as Element);
      zoom.setScaleExtent(0.2, 2);
      zoom.on('zoom', handleZoom);
      zoom.hook('mousedown', checkMouseDownTarget);
      setZoomInstance(zoom);
    }

    if (framesContainerRef.current) {
      framesContainerRef.current.addEventListener('mousedown', (e: any) => {
        e.stopPropagation();
      });

      const resizeObserver = new ResizeObserver((e: ResizeObserverEntry[]) => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(e) || !e.length) {
            return;
          }
          const initialX = window.innerWidth / 2 - e[0].contentRect.width / 2;
          const initialY = 37;
          zoom?.setPosition(initialX, initialY);
          zoom?.scaleBy(0.7);
          resizeObserver.disconnect();
        });
      });
      resizeObserver.observe(framesContainerRef.current);
    }
  }, [checkMouseDownTarget, handleZoom, zoomInstance]);

  function zoomIn(event: MouseEvent<HTMLButtonElement>) {
    zoomInstance?.scaleBy(1.3);
  }

  function zoomOut() {
    zoomInstance?.scaleBy(0.7);
  }

  function focus() {
    if (framesContainerRef.current) {
      const initialX = window.innerWidth / 2 - framesContainerRef.current.clientWidth / 2;
      const initialY = 36;
      zoomInstance?.setPosition(initialX, initialY);
      setCurrentScale(1);
    }
  }

  function handleDragOver(ev: DragEvent<HTMLDivElement>) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'move';
  }

  const handleShowGrids = () => {
    session.changePreferences(Preferences.SHOW_GRIDS, !session.preferences[Preferences.SHOW_GRIDS]);
  };

  const handleHideColumns = () => {
    session.changePreferences(
      Preferences.HIDE_COLUMNS,
      !session.preferences[Preferences.HIDE_COLUMNS]
    );
  };

  const handleAddWidget = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, widgetType: keyof typeof WidgetType) => {
      // Use module_id for Modeler studio.
      let widgetParent = module_id;
      // Use view_id for Designer studio.
      if (view_id) {
        widgetParent = view_id;
      }
      if (!widgetParent) return;

      let newWidget: Widget = WIDGETS_TEMPLATE[widgetType];
      if (widgetType === WidgetType.STICKY_NOTE) {
        newWidget = {
          ...newWidget,
          uuid: v4(),
          parent: widgetParent,
          // The '90's depends on the widget width and height.
          posX: (event.clientX - currentPosition.posX - 90) / currentScale,
          posY: (event.clientY - currentPosition.posY - 90) / currentScale,
          data: {
            ...newWidget.data,
            date: new Date().toISOString(),
            author: session.user?.username ?? '',
            height: 100,
            width: 250
          }
        };
      }

      dispatch(addWidget(newWidget));
    },
    [
      currentPosition.posX,
      currentPosition.posY,
      currentScale,
      dispatch,
      module_id,
      session.user?.username,
      view_id
    ]
  );

  function handleWorkboardOnMouseDown(event: React.MouseEvent<HTMLElement>) {
    if (!zoomInstance) return;
    zoomInstance.previousMousePosition = { x: event.clientX, y: event.clientY };
  }

  const handleWorkboardOnMouseUp = useCallback(
    (event: any) => {
      if (!zoomInstance) return;
      if (
        zoomInstance.previousMousePosition.x === event.clientX &&
        zoomInstance.previousMousePosition.y === event.clientY
      ) {
        handleWorkboardOnClick();
      }
      zoomInstance.previousMousePosition = { x: -1, y: -1 };
    },
    [handleWorkboardOnClick, zoomInstance]
  );

  useEffect(() => {
    if (!zoomInstance) return;
    document.addEventListener('mouseup', handleWorkboardOnMouseUp);

    return () => {
      document.removeEventListener('mouseup', handleWorkboardOnMouseUp);
    };
  }, [handleWorkboardOnMouseUp, zoomInstance]);

  function handleControlsMouseDown(event: MouseEvent) {
    event.stopPropagation();
  }

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    if (workboardRef.current) {
      driverInstance.current = startWalkthrough({
        context: currentContext,
        prevBtnText: 'Previous',
        nextBtnText: 'Next',
        doneBtnText: 'Done',
        onClose: () => {
          updatePageVisits(currentContext);
        }
      });
    }
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  return (
    <WorkboardContext.Provider
      value={{
        workboardRef: workboardRef,
        scale: currentScale,
        translate: {
          x: currentPosition.posX,
          y: currentPosition.posY
        }
      }}
    >
      <main
        ref={workboardRef}
        id={WALKTHROUGH_STEPS_ELEMENTS['dashboard-main']}
        className={styles.workboard}
        onDrop={(e) => {
          if (onDrop) onDrop(e, currentScale, currentPosition);
        }}
        onDragOver={handleDragOver}
        onMouseDown={handleWorkboardOnMouseDown}
      >
        <div
          id="workboard"
          className={`${styles.framesContainer} ${styles.activable}`}
          ref={framesContainerRef}
        >
          <FrameRenderer frames={frames} currentContext={currentContext} />
          {children}
        </div>
        <Background />
        <div
          style={isRightSidebarOpen ? { right: '270px' } : { right: '30px' }}
          className={`bg-body border p-2 ${styles.controlsContainer}`}
          onMouseDown={handleControlsMouseDown}
        >
          {showZoom && (
            <Button
              id="zoomOutButton"
              variant="body"
              size="sm"
              className={`${styles.controlButton} btn`}
              onClick={zoomOut}
            >
              <i className="fa-solid fa-magnifying-glass-minus" />
            </Button>
          )}

          {showZoom && <span className="text-body-emphasis">{Math.ceil(currentScale * 100)}%</span>}
          {showZoom && (
            <Button
              id="zoomInButton"
              variant="body"
              size="sm"
              className={`${styles.controlButton} btn`}
              onClick={zoomIn}
            >
              <i className="fa-solid fa-magnifying-glass-plus" />
            </Button>
          )}
          {showFocus && (
            <Button
              id="focusButton"
              variant="body"
              size="sm"
              className={`${styles.controlButton} btn`}
              onClick={focus}
            >
              <i className="fa-solid fa-arrows-to-eye" />
            </Button>
          )}
          {showSettings && (
            <DropdownButton
              id="settingsButton"
              drop={'up'}
              align={'end'}
              autoClose={'outside'}
              variant={'body'}
              className={`rounded ${styles.controlButton}`}
              title={<i className="fa-solid fa-sliders" />}
            >
              {location.pathname.split('/')[5] === 'ui' && (
                <DropdownItem onClick={() => handleShowGrids()}>
                  <Form.Check id="showGridsSettings">
                    <Form.Check
                      checked={session.preferences[Preferences.SHOW_GRIDS]}
                      label={`${t('studioSettings.designerSettings.ShowGrids')}`}
                    />
                  </Form.Check>
                </DropdownItem>
              )}
              {location.pathname.split('/')[5] !== 'ui' && (
                <DropdownItem onClick={() => handleHideColumns()}>
                  <Form.Check id="showNumberOfColumnsCheck">
                    <Form.Check
                      checked={session.preferences[Preferences.HIDE_COLUMNS]}
                      label={`${t('studioSettings.modelerSettings.HideColumns')}`}
                    />
                  </Form.Check>
                </DropdownItem>
              )}
            </DropdownButton>
          )}
        </div>

        <menu id="contextmenu" />
        <ContextMenu elementRef={workboardRef} offsetX={6} offsetY={9}>
          {contextMenuItems &&
            contextMenuItems.map((MenuOption: ContextMenuOption) => {
              return (
                <ContextMenuItem
                  key={MenuOption.label}
                  icon={MenuOption.icon}
                  label={MenuOption.label}
                  onClick={(e) => {
                    MenuOption.onClick(e, currentScale, currentPosition);
                  }}
                />
              );
            })}
          <ContextMenuItem
            icon="sticky-note"
            label="Add Note"
            onClick={(e) => handleAddWidget(e, WidgetType.STICKY_NOTE)}
          />
        </ContextMenu>
      </main>
    </WorkboardContext.Provider>
  );
};

export default Workboard;
