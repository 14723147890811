import React from 'react';
import { Tag } from 'modules/logic_builder/types';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

interface Props {
  tag: Tag;
  selectedTags: number[];
  updateTag: (tagId: number, checked: boolean) => void;
}

function TagItemRadio({ updateTag, tag, selectedTags }: Props) {
  return (
    <div className={styles.containerItemCheck}>
      <Form.Check
        type="checkbox"
        checked={tag.id != null && selectedTags.includes(tag.id)}
        onChange={(e) => updateTag(tag.id ?? 0, e.target.checked)}
      />
      <span>{tag.name}</span>
    </div>
  );
}

export default TagItemRadio;
