import produce from 'immer';
import { DatabaseStudioState, initialState, RootState } from '../index';
import {
  ADD_COLUMN,
  ADD_EXTERNAL_RELATIONSHIP_COLUMN,
  ADD_KEY_COLUMN,
  addColumnAction,
  addExternalRelationshipColumnAction,
  addKeyColumnAction,
  DELETE_RELATIONSHIP,
  DELETE_TABLE,
  deleteRelationshipAction,
  deleteTableAction,
  RootActions,
  SET_INITIAL_MODELER_STATE,
  SetInitialModelerStateAction
} from '../actions/root';
import {
  CHANGE_COLUMN_DESCRIPTION,
  CHANGE_COLUMN_NAME,
  CHANGE_COLUMN_TYPE,
  ChangeColumnDescriptionAction,
  ChangeColumnNameAction,
  ChangeColumnTypeAction,
  DELETE_COLUMN,
  DeleteColumnAction,
  MOVE_COLUMN,
  MoveColumnAction
} from '../actions/columns';
import { Column, externalColumn, Index, IndexType, sortOrder } from 'modules/modeler/types';
import { makeColumn } from 'routes/studio/data/elements/factory';
import {
  CHANGE_TABLE_NAME,
  changeTableNameAction,
  changeTableFolderAction,
  CHANGE_TABLE_FOLDER
} from '../actions/frames';
import { MOVE_ENUM_COLUMN, MoveEnumColumnAction } from '../actions/enum_column';

export const rootReducer = (
  state: RootState = initialState,
  action: RootActions
): DatabaseStudioState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case DELETE_RELATIONSHIP:
        return doDeleteRelationship(draft, action);
      case ADD_COLUMN:
        return doAddColumn(draft, action);
      case ADD_KEY_COLUMN:
        return doAddKeyColumn(draft, action);
      case DELETE_COLUMN:
        return doDeleteColumn(draft, action);
      case DELETE_TABLE:
        return doDeleteTable(draft, action);
      case CHANGE_COLUMN_NAME:
        return doChangeColumnName(draft, action);
      case CHANGE_COLUMN_TYPE:
        return doChangeColumnType(draft, action);
      case CHANGE_COLUMN_DESCRIPTION:
        return doChangeColumnDescription(draft, action);
      case CHANGE_TABLE_NAME:
        return doChangeTableName(draft, action);
      case CHANGE_TABLE_FOLDER:
        return doChangeTableFolder(draft, action);
      case ADD_EXTERNAL_RELATIONSHIP_COLUMN:
        return addExternalRelationshipColumn(draft, action);
      case MOVE_COLUMN:
        return doMoveColumn(draft, action);
      case MOVE_ENUM_COLUMN:
        return doMoveEnumColumn(draft, action);
      case SET_INITIAL_MODELER_STATE:
        return doSetInitialModelerState(draft, action);
      default:
        return state;
    }
  });
};

// 'content.data.columns' is what actually locally reorders the list of columns.
// Then we change the columnOrder property for DbColumn entity in the backend.
function doMoveColumn(state: DatabaseStudioState, action: MoveColumnAction): DatabaseStudioState {
  const columnsIds: string[] = [...state.tables[action.payload.table].content.data.columns];
  const columnIndex = columnsIds.findIndex((c) => c === action.payload.column);
  const swappedColumnIndex = columnsIds.findIndex((c) => c === action.payload.swappedColumn);

  if (columnIndex !== -1 && swappedColumnIndex !== -1) {
    columnsIds[swappedColumnIndex] = action.payload.column;
    columnsIds[columnIndex] = action.payload.swappedColumn;
    state.tables[action.payload.table].content.data.columns = columnsIds;
  }

  return state;
}

function doMoveEnumColumn(
  state: DatabaseStudioState,
  action: MoveEnumColumnAction
): DatabaseStudioState {
  const columnsIds: string[] = [...state.enums[action.payload.table].content.data.columns];
  const columnIndex = columnsIds.findIndex((c) => c === action.payload.column);
  const swappedColumnIndex = columnsIds.findIndex((c) => c === action.payload.swappedColumn);

  if (columnIndex !== -1 && swappedColumnIndex !== -1) {
    columnsIds[swappedColumnIndex] = action.payload.column;
    columnsIds[columnIndex] = action.payload.swappedColumn;
    state.enums[action.payload.table].content.data.columns = columnsIds;
  }

  return state;
}

function doDeleteRelationship(
  state: DatabaseStudioState,
  action: deleteRelationshipAction
): DatabaseStudioState {
  const relationshipID = action.payload.id;
  let tableID = '';
  let otherTableID = '';

  // Delete FK columns
  Object.keys(state.relationships[relationshipID].components).forEach((key: string) => {
    let columnID = '';
    if (state.relationships[relationshipID].type === 'ONE2MANY') {
      tableID = state.relationships[relationshipID].to;
      otherTableID = state.relationships[relationshipID].from;
      if (state.relationships[relationshipID].components[key]) {
        columnID = state.columns[state.relationships[relationshipID].components[key]].uuid;
        delete state.columns[state.relationships[relationshipID].components[key]];
      }
    } else {
      tableID = state.relationships[relationshipID].from;
      otherTableID = state.relationships[relationshipID].to;
      columnID = state.columns[key].uuid;
      delete state.columns[key];
    }
    // delete table reference to column
    state.tables[tableID].content.data.columns.splice(
      state.tables[tableID].content.data.columns.indexOf(columnID),
      1
    );
  });

  // delete table reference to relationship
  state.tables[tableID].content.data.relationships.splice(
    state.tables[tableID].content.data.relationships.indexOf(relationshipID),
    1
  );
  if (state.tables[otherTableID]) {
    state.tables[otherTableID].content.data.relationships.splice(
      state.tables[otherTableID].content.data.relationships.indexOf(relationshipID),
      1
    );
  }

  // Delete the relationship
  delete state.relationships[relationshipID];

  return state;
}

function doAddColumn(state: RootState, action: addColumnAction): RootState {
  const column: Column = Object.assign(
    makeColumn(
      action.payload.tableUUID,
      action.payload.name,
      action.payload.type,
      action.payload.columnOrder,
      action.payload.description,
      action.payload.nullable,
      action.payload.defaultData,
      false,
      false,
      action.payload.UUID,
      action.payload.enumUUID,
      undefined,
      action.payload.properties
    )
  );
  state.columns[action.payload.UUID] = column;
  return state;
}

function doAddKeyColumn(state: RootState, action: addKeyColumnAction): RootState {
  const column: Column = Object.assign(
    makeColumn(
      action.payload.column.tableUUID,
      action.payload.column.name,
      action.payload.column.type,
      action.payload.column.columnOrder,
      action.payload.column.description,
      false,
      action.payload.column.defaultData,
      true,
      false,
      action.payload.column.UUID,
      action.payload.column.enumUUID
    )
  );
  state.columns[action.payload.column.UUID] = column;
  state.tables[action.payload.column.tableUUID].content.data.columns.push(
    action.payload.column.UUID
  );

  if (action.payload.column.fkColumns) {
    Object.keys(action.payload.column.fkColumns).forEach((tableUUUID) => {
      const columnUUID = action.payload.column.fkColumns![tableUUUID];
      const fkColumn: Column = Object.assign(
        makeColumn(
          tableUUUID,
          action.payload.column.name + '_FK',
          action.payload.column.type,
          0,
          '',
          false,
          action.payload.column.defaultData,
          false,
          true,
          columnUUID,
          action.payload.column.enumUUID
        )
      );

      state.columns[columnUUID] = fkColumn;
      state.tables[tableUUUID].content.data.columns.push(columnUUID);
    });

    Object.keys(state.tables[action.payload.column.tableUUID].content.data.relationships).forEach(
      (relationshipKey) => {
        const relationshipID =
          state.tables[action.payload.column.tableUUID].content.data.relationships[relationshipKey];
        if (state.relationships[relationshipID] && action.payload.column.fkColumns) {
          Object.keys(action.payload.column.fkColumns).forEach((fkTableUUID) => {
            const fkColumns = action.payload.column.fkColumns;

            if (fkColumns !== undefined) {
              if (
                (state.relationships[relationshipID].to === action.payload.column.tableUUID &&
                  state.relationships[relationshipID].from === fkTableUUID) ||
                (state.relationships[relationshipID].from === action.payload.column.tableUUID &&
                  state.relationships[relationshipID].to === fkTableUUID)
              ) {
                state.relationships[relationshipID].type === 'ONE2MANY'
                  ? (state.relationships[relationshipID].components[action.payload.column.UUID] =
                      fkColumns[fkTableUUID])
                  : (state.relationships[relationshipID].components[fkColumns[fkTableUUID]] =
                      action.payload.column.UUID);
              }
            }
          });
        }
      }
    );
  }

  // Update the primary index or create one
  const indexColumn = {
    id: action.payload.pkIndexColumnUUID,
    columnId: action.payload.column.UUID,
    sortOrder: 'ASC' as sortOrder,
    columnOrder: 0
  };

  const tableIndexes: string[] = Object.values(
    state.tables[action.payload.column.tableUUID].content.data.indexes
  );
  if (tableIndexes.indexOf(action.payload.pkIndexUUID) !== -1) {
    state.indexes[action.payload.pkIndexUUID].columns[indexColumn.id] = indexColumn;
  } else {
    const index = {
      id: action.payload.pkIndexUUID,
      name: 'IDX_' + action.payload.column.name,
      type: IndexType['PRIMARY'],
      columns: {}
    } as Index;

    index.columns[indexColumn.id] = indexColumn;
    state.indexes[index.id] = index;
    state.tables[action.payload.column.tableUUID].content.data.indexes.push(index.id);
  }

  return state;
}

/**
 * Try to find and delete the list of columns.
 *
 * @param state The modeler studio state.
 * @param columnIds The list of columns to be deleted.
 */
function deleteFromColumns(state: RootState, columnsIds: string[]): void {
  const stateColumnsIds = Object.keys(state.columns);
  for (const columnId of stateColumnsIds) {
    if (columnsIds.includes(columnId)) {
      delete state.columns[columnId];
    }
  }
}

/**
 * Try to find and delete the list of columns.
 *
 * @param state The modeler studio state.
 * @param columnIds The list of columns to be deleted.
 */
function deleteFromIndexes(state: RootState, columnIds: string[], tableId: string): void {
  // Iterate over the indexes to remove the column from the column's list.
  Object.values(state.tables[tableId].content.data.indexes).forEach((indexId: any) => {
    Object.keys(state.indexes[indexId].columns).forEach((indexColumnId) => {
      if (columnIds.includes(state.indexes[indexId].columns[indexColumnId].columnId)) {
        delete state.indexes[indexId].columns[indexColumnId];
      }
    });
  });
}

/**
 * Find and delete the table relation and return some useful information.
 *
 * @return Returns the list of foreign keys (including columnId) that should
 * be deleted, the deleted relationId and the relation source table id.
 * @param state The modeler studio state.
 * @param columnId The id of the deleted foreign key column.
 */
function deleteRelation(
  state: RootState,
  columnId: string
): {
  // The deleted relationship id.
  relationId: string;
  // The list of foreign keys that should be deleted.
  foreignKeys: string[];
  // The source table.
  srcTableId: string;
  // The destination table.
  dstTableId: string;
} {
  const relationIds = Object.keys(state.relationships);
  const returnValue: {
    relationId: string;
    foreignKeys: string[];
    srcTableId: string;
    dstTableId: string;
  } = {
    relationId: '',
    foreignKeys: [],
    srcTableId: '',
    dstTableId: ''
  };
  for (const relationId of relationIds) {
    const foreignKeys: string[] = Object.values(state.relationships[relationId].components);
    if (foreignKeys.includes(columnId)) {
      returnValue.srcTableId = state.relationships[relationId].from;
      returnValue.dstTableId = state.relationships[relationId].to;
      returnValue.foreignKeys = foreignKeys;
      returnValue.relationId = relationId;
      delete state.relationships[relationId];
      return returnValue;
    }
  }
  return returnValue;
}

function doDeleteColumn(state: RootState, action: DeleteColumnAction): RootState {
  const columnId = action.payload.id;
  const tableId = state.columns[columnId].tableUUID;

  // If column is a foreign key then update relationships, columns and table data.
  if (state.columns[columnId].isFK) {
    const { relationId, foreignKeys, srcTableId, dstTableId } = deleteRelation(state, columnId);
    deleteFromColumns(state, foreignKeys);
    deleteFromIndexes(state, foreignKeys, tableId);

    // Update table data.columns and data.indexes references.
    for (const fkColumn of foreignKeys) {
      const columnIndex = state.tables[tableId].content.data.columns.indexOf(fkColumn);
      state.tables[tableId].content.data.columns.splice(columnIndex, 1);
      const indexIndex = state.tables[tableId].content.data.indexes.indexOf(fkColumn);
      state.tables[tableId].content.data.indexes.splice(indexIndex, 1);
    }
    // Update destination table data.relationships reference.
    const dstRelationIndex =
      state.tables[dstTableId].content.data.relationships.indexOf(relationId);
    state.tables[dstTableId].content.data.relationships.splice(dstRelationIndex, 1);
    // Update source table data.relationships reference.
    const srcRelationIndex =
      state.tables[srcTableId].content.data.relationships.indexOf(relationId);
    state.tables[srcTableId].content.data.relationships.splice(srcRelationIndex, 1);
    return state;
  }

  // if the column is a PK, it can be a key of a relationship
  if (state.columns[columnId].isPK) {
    // Iterate over all the relationships this table has
    Object.values(state.tables[tableId].content.data.relationships).forEach(
      (relationshipID: any) => {
        // Iterate over the columns that make the relationship, and check if the column
        //    to be deleted is contained in this list
        Object.keys(state.relationships[relationshipID].components).forEach((fromColumn) => {
          // Verify if this column is part of any relationship
          if (
            fromColumn === columnId ||
            state.relationships[relationshipID].components[fromColumn] === columnId
          ) {
            let fkColumn = '';
            if (state.relationships[relationshipID].type === 'ONE2MANY') {
              fkColumn = state.relationships[relationshipID].components[fromColumn];
            } else {
              fkColumn = fromColumn;
            }

            // Remove the reference to the column from the table...
            state.tables[state.columns[fkColumn].tableUUID].content.data.columns.splice(
              state.tables[state.columns[fkColumn].tableUUID].content.data.columns.indexOf(
                fkColumn
              ),
              1
            );
            // Delete columns
            delete state.columns[fkColumn];

            // If the column is the only one used to maintain the relationship, delete the relationship
            //     Obs.: The confirmation modal should inform that this action will delete the relationship.
            if (Object.keys(state.relationships[relationshipID].components).length === 1) {
              // Remove the references to the relationship from the tables...
              state.tables[
                state.relationships[relationshipID].from
              ].content.data.relationships.splice(
                state.tables[
                  state.relationships[relationshipID].from
                ].content.data.relationships.indexOf(relationshipID),
                1
              );

              state.tables[
                state.relationships[relationshipID].to
              ].content.data.relationships.splice(
                state.tables[
                  state.relationships[relationshipID].to
                ].content.data.relationships.indexOf(relationshipID),
                1
              );

              // Delete the relationship
              delete state.relationships[relationshipID];
            } else {
              // remove the item from associative array
              delete state.relationships[relationshipID].components[fromColumn];
            }
          }
        });
      }
    );

    // Iterate over the indexes to remove the column from the column's list
    Object.values(state.tables[tableId].content.data.indexes).forEach((indexID: any) => {
      Object.keys(state.indexes[indexID].columns).forEach((indexColumnID) => {
        if (state.indexes[indexID].columns[indexColumnID].columnId === action.payload.id) {
          delete state.indexes[indexID].columns[indexColumnID];
        }
      });
    });
  }

  state.tables[tableId].content.data.columns.splice(
    state.tables[tableId].content.data.columns.indexOf(columnId),
    1
  );
  delete state.columns[columnId];

  return state;
}

function doDeleteTable(state: RootState, action: deleteTableAction): RootState {
  const tableUUID = action.payload.uuid;

  Object.values(state.tables[tableUUID].content.data.relationships).forEach(
    (relationshipKey: any) => {
      const relationshipID = relationshipKey;
      let tableID: string;
      let otherTableID: string;
      // Delete FK columns
      Object.keys(state.relationships[relationshipID].components).forEach((key: string) => {
        let columnID = '';
        if (state.relationships[relationshipID].type === 'ONE2MANY') {
          tableID = state.relationships[relationshipID].to;
          otherTableID = state.relationships[relationshipID].from;
          if (state.relationships[relationshipID].components[key]) {
            columnID = state.columns[state.relationships[relationshipID].components[key]].uuid;
            delete state.columns[state.relationships[relationshipID].components[key]];
          }
        } else {
          tableID = state.relationships[relationshipID].from;
          otherTableID = state.relationships[relationshipID].to;
          columnID = state.columns[key].uuid;
          delete state.columns[key];
        }
        // delete table reference to column
        state.tables[tableID].content.data.columns.splice(
          state.tables[tableID].content.data.columns.indexOf(columnID),
          1
        );
        // delete table reference to relationship
        state.tables[tableID].content.data.relationships.splice(
          state.tables[tableID].content.data.relationships.indexOf(relationshipID),
          1
        );
        if (state.tables[otherTableID]) {
          state.tables[otherTableID].content.data.relationships.splice(
            state.tables[otherTableID].content.data.relationships.indexOf(relationshipID),
            1
          );
        }
      });
      // Delete the relationship
      delete state.relationships[relationshipID];
    }
  );
  delete state.tables[tableUUID];

  if (action.payload.limit != null) {
    // Update disabled field.
    const sortedTables = Object.values(state.tables).sort((m, n) => {
      if (!m.creationTime) return 1;
      if (!n.creationTime) return 0;
      return new Date(m.creationTime).getTime() - new Date(n.creationTime).getTime();
    });
    for (let i = 0; i < sortedTables.length; i++) {
      const tableId = sortedTables[i].uuid;
      state.tables[tableId].disabled = i >= action.payload.limit;
    }
  }
  return state;
}

function doChangeColumnDescription(
  state: RootState,
  action: ChangeColumnDescriptionAction
): RootState {
  state.columns[action.payload.id].description = action.payload.value;
  return state;
}

function doChangeColumnName(state: RootState, action: ChangeColumnNameAction): RootState {
  state.columns[action.payload.id].name = action.payload.value;
  // Iterate over all the relationships of the table containing the changed column
  if (state.columns[action.payload.id].isPK) {
    Object.keys(
      state.tables[state.columns[action.payload.id].tableUUID].content.data.relationships
    ).forEach((relationshipKey) => {
      const relationshipID =
        state.tables[state.columns[action.payload.id].tableUUID].content.data.relationships[
          relationshipKey
        ];
      // Iterate over each FK associated with the changed PK, and update the name according to the new name of the updated PK
      Object.keys(state.relationships[relationshipID].components).forEach((fromColumn) => {
        const toColumn = state.relationships[relationshipID].components[fromColumn];
        if (fromColumn === action.payload.id || toColumn === action.payload.id) {
          const fkColumnID =
            state.relationships[relationshipID].type === 'ONE2MANY' ? toColumn : fromColumn;
          if (fkColumnID && state.columns[fkColumnID]) {
            // Check whether the column exists in the current module
            state.columns[fkColumnID].name = action.payload.value + '_FK';
          }
        }
      });
    });
  }

  return state;
}

function doChangeColumnType(state: RootState, action: ChangeColumnTypeAction): RootState {
  state.columns[action.payload.id].type = action.payload.value;
  state.columns[action.payload.id].enumUUID = action.payload.enumUUID;

  // Iterate over all the relationships of the table containing the changed column
  if (state.columns[action.payload.id].isPK) {
    Object.keys(
      state.tables[state.columns[action.payload.id].tableUUID].content.data.relationships
    ).forEach((relationshipKey) => {
      const relationshipID =
        state.tables[state.columns[action.payload.id].tableUUID].content.data.relationships[
          relationshipKey
        ];
      // Iterate over each FK associated with the changed PK, and update the type according to the new type of the updated PK
      Object.keys(state.relationships[relationshipID].components).forEach((fromColumn) => {
        const toColumn = state.relationships[relationshipID].components[fromColumn];
        if (fromColumn === action.payload.id || toColumn === action.payload.id) {
          const fkColumnID =
            state.relationships[relationshipID].type === 'ONE2MANY' ? toColumn : fromColumn;
          state.columns[fkColumnID].type =
            action.payload.value === 'AUTOINCREMENT' ? 'BIGINT' : action.payload.value;
        }
      });
    });
  }
  return state;
}

function doChangeTableName(state: RootState, action: changeTableNameAction) {
  const oldTableName = state.tables[action.payload.uuid].content.data.name;
  const newTableName = action.payload.name;
  // Iterate over all the columns of the table, change the name that contains the old name of
  //    the table as a substring and update it to the new name
  Object.keys(state.tables[action.payload.uuid].content.data.columns).forEach((columnKey) => {
    const columnID = state.tables[action.payload.uuid].content.data.columns[columnKey];
    if (state.columns[columnID].name.split(oldTableName).length > 1) {
      const newName = state.columns[columnID].name.split(oldTableName).join(newTableName);
      if (!state.columns[columnID].isFK) {
        state.columns[columnID].name = newName;
      }

      // Check if this table have relationships, if the column is a PK, then update it the FK too
      if (state.columns[columnID].isPK) {
        Object.keys(state.tables[action.payload.uuid].content.data.relationships).forEach(
          (relationshipKey) => {
            const relationshipID =
              state.tables[action.payload.uuid].content.data.relationships[relationshipKey];
            // if it is one to many relationship, the FK will be in the value field
            //    of the of columns map array

            Object.keys(state.relationships[relationshipID].components).forEach((keyColumn) => {
              let fkColumnId = null;
              if (state.relationships[relationshipID].type === 'ONE2MANY') {
                if (keyColumn === columnID) {
                  fkColumnId = state.relationships[relationshipID].components[keyColumn];
                }
              } else {
                if (state.relationships[relationshipID].components[keyColumn] === columnID) {
                  fkColumnId = keyColumn;
                }
              }
              if (fkColumnId && state.columns[fkColumnId]) {
                // Check whether the column exists in the current module
                state.columns[fkColumnId].name = newName + '_FK';
              }
            });
          }
        );
      }
    }
  });
  state.tables[action.payload.uuid].content.data.name = action.payload.name;
  return state;
}

function doChangeTableFolder(state: RootState, action: changeTableFolderAction) {
  if (action.payload.folderId) {
    // if it has folder change it
    state.tables[action.payload.id].folder_id = action.payload.folderId;
  } else {
    // if it has not, delete
    state.tables[action.payload.id].folder_id = '';
  }
  return state;
}

function addExternalRelationshipColumn(
  state: RootState,
  action: addExternalRelationshipColumnAction
): RootState {
  action.payload.columns.forEach((column: externalColumn) => {
    const newColumn: Column = Object.assign(
      makeColumn(
        action.payload.tableID,
        column.name,
        column.type,
        0,
        '',
        false,
        '',
        false,
        true,
        column.id
      )
    );
    state.columns[newColumn.uuid] = newColumn;
  });
  return state;
}

function doSetInitialModelerState(
  _state: RootState,
  action: SetInitialModelerStateAction
): RootState {
  const newState = { ...action.state };
  const lastValidEntry = JSON.stringify(newState);
  newState.studio.history = {
    lastValidEntry: lastValidEntry,
    undoStack: [],
    redoStack: []
  };
  return newState;
}
