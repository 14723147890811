import React, { ChangeEvent, ComponentType, memo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InterfaceStudioState } from '../../store';
import { COMPONENTS_MANIFEST } from '../../exocode_components';
import {
  changeComponentProperty,
  changeCustomComponentViewProperty
} from '../../store/actions/components';
import { changeViewProperty, changeLayout } from '../../store/actions/views';
import {
  ComponentManifest,
  Editor,
  Layout,
  LayoutComponent,
  View,
  ViewManifest
} from '../../../types';
import { VIEWS_MANIFEST, VIEWS_TYPES } from '../../frames';
import { ViewData } from '../../frames/page_view';
import { ViewStateService } from 'modules/designer/services';
import { Form } from 'react-bootstrap';
import { ControlProps, ControlsList, ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { useTranslation } from 'react-i18next';
import ComponentHeader from '../components/component_header';
import TextControl from 'web_ui/workboard/sidebar/controls/TextControl';
import ListParams from './params';
import styles from './styles.module.css';
import { PropertyMapper } from './properties_mapper';
import RoleSelector from 'web_ui/role_selector';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { AppContext } from 'modules/project/store/app_context';
import { isLocalhostControl } from '../../designer_utils';
import { IS_LOCALHOST } from '../../../../../constants';

type PropertiesToolbarProps = {
  layouts: Layout[];
  name?: string;
  icon?: string;
};

export type ElementProperty = {
  value: string;
  key: string;
};

function PropertiesToolbar(props: PropertiesToolbarProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appInfo = useContext(AppContext).projectInformation;
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const views = useSelector((state: InterfaceStudioState) => state.views);
  const roles = useSelector((state: InterfaceStudioState) => state.roles);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const selectedView: string | null = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedView
  );

  // Only one of these should be null at a time (or maybe both, if nothing is selected).
  let componentInfo: LayoutComponent | null = null;
  let viewInfo: View | null = null;
  // Manifest.
  let manifest: ComponentManifest | ViewManifest | null = null;
  // Custom component editor.
  if (editor === Editor.CUSTOM_COMPONENT) {
    if (selectedView) {
      componentInfo = components[selectedView];
      manifest = VIEWS_MANIFEST[VIEWS_TYPES.CUSTOMCOMPONENTS];
    } else if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    }
  }
  // Regular editor.
  else {
    if (selectedComponent) {
      componentInfo = components[selectedComponent];
      manifest = COMPONENTS_MANIFEST[componentInfo?.type];
    } else if (selectedView) {
      viewInfo = views[selectedView];
      manifest = VIEWS_MANIFEST[viewInfo?.type];
    }
  }

  const handleChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent) {
        dispatch(changeComponentProperty(selectedComponent, key, value));
      } else if (selectedView && editor === Editor.CUSTOM_COMPONENT) {
        dispatch(changeCustomComponentViewProperty(selectedView, key, value));
      } else if (selectedView) {
        dispatch(changeViewProperty(selectedView, key as keyof ViewData, value));
      }
    },
    [dispatch, editor, selectedComponent, selectedView]
  );

  const handleNameChange = React.useCallback(
    (value: string, key: string) => {
      if (selectedComponent)
        dispatch(
          changeComponentProperty(selectedComponent, key, value.replace(/[^a-zA-Z0-9_]/g, ''))
        );
    },
    [dispatch, selectedComponent]
  );

  const handleLayoutSelect = async (e: ChangeEvent<HTMLSelectElement>) => {
    if (!viewInfo) return;

    // Fun thing: if you use e.target.value directly, the request payload will be wrong.
    // That's why we do this first, should probably move this async function call somewhere else.
    const id = e.target.value;

    if (id !== 'default') {
      const layout = await ViewStateService.getViewState(id);
      dispatch(changeLayout(viewInfo.uuid, id, layout));
    } else {
      dispatch(changeLayout(viewInfo.uuid, ''));
    }
  };

  const handleMainPageCheckbox = (e: ChangeEvent<HTMLInputElement>, viewId?: string) => {
    if (!viewId) return;

    dispatch(changeViewProperty(viewId, 'rootPage', e.target.checked));
  };

  const getViewLayoutId = (): string => {
    if (!viewInfo || viewInfo.type !== VIEWS_TYPES.PAGE) {
      return 'default';
    }

    return viewInfo.layout_uuid ?? 'default';
  };

  function getLabel(label: string) {
    return t('designer.right_side.' + label);
  }

  const getPageRoute = () => {
    if (!viewInfo) return '';

    if (viewInfo.data['rootPage']) {
      return '/';
    } else if (viewInfo.data['route'] != null) {
      return viewInfo.data['route'];
    }
    return '';
  };

  const getControlValue = (item: any) => {
    if (!viewInfo) return '';

    if (item.key) {
      return viewInfo.data[item.key as keyof ViewData];
    }
    return viewInfo.data;
  };

  return (
    <>
      {(viewInfo || componentInfo) && (
        <>
          <ComponentHeader name={props.name} icon={props.icon} />
          <div className={`${styles.controlList} p-3 pt-0`}>
            {componentInfo && (
              <>
                <TextControl
                  key={`${componentInfo.uuid}-name`}
                  id="name"
                  label={getLabel('Name')}
                  value={
                    componentInfo && componentInfo.data && componentInfo.data.name
                      ? componentInfo.data.name
                      : ''
                  }
                  onChange={handleNameChange}
                />
              </>
            )}
            {/* PAGE: Select Layouts input */}
            {editor !== Editor.CUSTOM_COMPONENT && viewInfo && viewInfo.type === 'PAGE' && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Layouts</Form.Label>
                  <Form.Select
                    id={'selectLayout'}
                    aria-label="Layout select"
                    onChange={handleLayoutSelect}
                    value={getViewLayoutId()}
                  >
                    <option value="default">{t('designer.right_side.ChangeLayout')}</option>
                    {props.layouts.map((layout) => {
                      return (
                        <option key={layout.uuid} value={layout.uuid}>
                          {layout.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
                {viewInfo && !viewInfo.data['rootPage'] && <ListParams />}
              </>
            )}
            {/* Custom component editor */}
            {editor === Editor.CUSTOM_COMPONENT &&
              manifest?.properties.map((item, index) => {
                const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
                return (
                  <Control
                    key={`${item.controlLabel + index}`}
                    id={item.key}
                    label={getLabel(item.controlLabel)}
                    value={
                      item.key && componentInfo && componentInfo.data
                        ? componentInfo.data[item.key]
                        : componentInfo?.data
                    }
                    options={item.controlOptions}
                    onChange={handleChange}
                    maxLength={item.maxLength}
                    validation={item.validation}
                    tooltip={item.tooltip}
                    errorMessages={item.errorMessages}
                  />
                );
              })}
            {/* VIEWS, excluding custom component editor */}
            {editor !== Editor.CUSTOM_COMPONENT &&
              manifest?.properties.map((item: any, index) => {
                if (!IS_LOCALHOST && isLocalhostControl(item?.controlType)) return null;
                const Control = ControlsList[item.controlType] as ComponentType<ControlProps>;
                return selectedComponent ? (
                  <Authorization
                    key={`${item.controlLabel + index}`}
                    allowedSystemAuthorities={
                      item.controlType === ControlsTypes.SELECT_TRANSLATION ||
                      item.key === 'hasTranslation'
                        ? [SystemRoleAuthorityName.MANAGE_LANGUAGES]
                        : []
                    }
                  >
                    <Control
                      id={item.key}
                      label={getLabel(item.controlLabel)}
                      value={
                        item.key && componentInfo && componentInfo.data
                          ? componentInfo.data[item.key]
                          : componentInfo?.data
                      }
                      options={item.controlOptions}
                      onChange={handleChange}
                      tooltip={item.tooltip}
                      validation={item.validation}
                      errorMessages={item.errorMessages}
                    />
                  </Authorization>
                ) : (
                  <Authorization
                    key={`${item.controlLabel + index}`}
                    allowedSystemAuthorities={
                      item.controlType === ControlsTypes.SELECT_TRANSLATION ||
                      item.key === 'hasTranslation'
                        ? [SystemRoleAuthorityName.MANAGE_LANGUAGES]
                        : []
                    }
                  >
                    <Control
                      id={item.key}
                      label={getLabel(item.controlLabel)}
                      value={getControlValue(item)}
                      options={item.controlOptions}
                      onChange={handleChange}
                      validation={item.validation}
                      errorMessages={item.errorMessages}
                      tooltip={item.tooltip}
                    />
                  </Authorization>
                );
              })}
            {/* PAGE: Route */}
            {editor !== Editor.CUSTOM_COMPONENT && viewInfo && viewInfo.type === 'PAGE' && (
              <div className="mb-3">
                <Form.Label>Route</Form.Label>
                <Form.Control
                  id="formRoute"
                  className={`form-control form-control-sm`}
                  value={getPageRoute()}
                  onChange={(e) => handleChange(e.target.value, 'route')}
                  disabled={viewInfo.data['rootPage']}
                />
              </div>
            )}
            {/* PAGE: Set as main page */}
            {editor !== Editor.CUSTOM_COMPONENT && viewInfo && viewInfo.type === 'PAGE' && (
              <div className="mb-3">
                <Form.Check
                  id="setAsMainPageCheck"
                  checked={viewInfo.data['rootPage'] != null ? viewInfo.data['rootPage'] : false}
                  type="checkbox"
                  label="Set as main page"
                  onChange={(e) => handleMainPageCheckbox(e, viewInfo?.uuid)}
                />
              </div>
            )}
            {/* PAGE: Select page Roles. */}
            {editor !== Editor.CUSTOM_COMPONENT &&
              viewInfo &&
              viewInfo.type === 'PAGE' &&
              appInfo?.has_authentication && (
                <Form.Group className="mb-2">
                  <Form.Label>{t('appResume.roles.Roles')}</Form.Label>
                  <RoleSelector roles={roles} viewInfo={viewInfo} />
                </Form.Group>
              )}
            {appInfo?.is_owner_org && viewInfo && (
              <>
                <hr />
                Group info
                <p style={{ fontSize: 'small' }}>{`Last edited by ${
                  viewInfo.modifiedByUser
                } at ${new Date(viewInfo.modificationTime)?.toLocaleDateString()}`}</p>
                <p style={{ fontSize: 'small' }}>{`Created by ${
                  viewInfo.createdByUser
                } at ${new Date(viewInfo.creationTime)?.toLocaleDateString()}`}</p>
              </>
            )}
            <PropertyMapper component={componentInfo} />
          </div>
        </>
      )}
    </>
  );
}

export default memo(PropertiesToolbar);
