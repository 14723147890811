import React, { useEffect, useState } from 'react';
import { ApiAppInfo } from '../../../../modules/project/types';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import AppDetail from '../app_details';
import { useParams } from 'react-router';
import AppTabNavItem from './app_tab_nav_item';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

export type SelectedTabs = 'MODULES' | 'LANGUAGES' | 'ASSETS' | 'ROLES';
const tabs: SelectedTabs[] = ['MODULES', 'LANGUAGES', 'ASSETS', 'ROLES'];

type AppTabsNavbarProps = {
  appInfo: ApiAppInfo;
  reload: React.Dispatch<React.SetStateAction<boolean>>;
};

function AppTabsNavbar({ appInfo, reload }: AppTabsNavbarProps) {
  const { app_id } = useParams();
  const [selectedTab, setSelectedTab] = useState<SelectedTabs>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const location_option = location.pathname.split('/').pop();

    if (location_option === 'modules') setSelectedTab('MODULES');
    else if (location_option === 'languages') setSelectedTab('LANGUAGES');
    else if (location_option === 'assets') setSelectedTab('ASSETS');
    else if (location_option === 'roles') setSelectedTab('ROLES');
    else {
      setSelectedTab('MODULES');
      navigate(`/app/${app_id}/dashboard/modules`);
    }
  }, [location]);

  const handleTabOnClick = (tab: SelectedTabs) => {
    navigate(`/app/${app_id}/dashboard/${tab.toLowerCase()}`);
    setSelectedTab(tab);
  };

  return (
    <Row
      id={WALKTHROUGH_STEPS_ELEMENTS['dashboard-modules-main']}
      className="flex-grow-1"
      style={{ marginTop: '24px' }}
    >
      <Col xs={12} sm={appInfo.is_owner_org ? 8 : 12} style={{ padding: 0, maxHeight: '100%' }}>
        <Card>
          <Card.Header className="bg-body-secondary">
            <Nav variant="tabs">
              {tabs.map((tab: SelectedTabs) => {
                if (
                  !appInfo.has_frontend &&
                  !appInfo.has_backend &&
                  appInfo.has_database &&
                  (tab === 'LANGUAGES' || tab === 'ASSETS' || tab === 'ROLES')
                )
                  return null;

                if (tab === 'ROLES') {
                  if (!appInfo.has_authentication) {
                    return null;
                  }
                  if (!appInfo.is_owner_org) {
                    return (
                      <Authorization
                        key={tab}
                        allowedSystemAuthorities={[SystemRoleAuthorityName.ADDITIONAL_MODULES]}
                      >
                        <AppTabNavItem
                          tab={tab}
                          selected={tab === selectedTab}
                          handleClick={handleTabOnClick}
                        />
                      </Authorization>
                    );
                  }
                }

                if (tab === 'LANGUAGES') {
                  return (
                    <Authorization
                      key={tab}
                      allowedSystemAuthorities={[SystemRoleAuthorityName.MANAGE_LANGUAGES]}
                    >
                      <AppTabNavItem
                        tab={tab}
                        selected={tab === selectedTab}
                        handleClick={handleTabOnClick}
                      />
                    </Authorization>
                  );
                }
                return (
                  <AppTabNavItem
                    key={tab}
                    tab={tab}
                    selected={tab === selectedTab}
                    handleClick={handleTabOnClick}
                  />
                );
              })}
            </Nav>
          </Card.Header>
          <Card.Body className="bg-body-tertiary">
            <Outlet context={{ appInfo, reload } as AppTabsNavbarProps} />
          </Card.Body>
        </Card>
      </Col>
      {appInfo.is_owner_org && (
        <Col xs={12} sm={4} style={{ maxHeight: '100%', paddingRight: 0 }}>
          <AppDetail app={appInfo} />
        </Col>
      )}
    </Row>
  );
}

export default AppTabsNavbar;

export function useAppInfo() {
  return useOutletContext<AppTabsNavbarProps>();
}
