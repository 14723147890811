import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

type TextTransformValue = 'none' | 'uppercase' | 'capitalize' | 'lowercase';

const TextTransformOptions: {
  value: TextTransformValue;
  label: string;
  text?: string;
  icon?: string;
}[] = [
  { value: 'none', label: 'Default', icon: 'fa-solid fa-xmark' },
  { value: 'uppercase', label: 'Uppercase', text: 'AA' },
  { value: 'capitalize', label: 'Capitalize', text: 'Aa' },
  { value: 'lowercase', label: 'Lowercase', text: 'aa' }
];

function TextTransform(props: ControlProps) {
  const { t } = useTranslation();
  const currentValue = props.value;

  function handleChange(value: TextTransformValue) {
    if (props.onChange) props.onChange(value, props.id);
  }

  const isSelected = (value: TextTransformValue): boolean => {
    return currentValue === value;
  };

  return (
    <div style={{ display: 'flex' }}>
      {props.tooltip && (
        <HelpPopover
          helpBoxProps={{
            description: `${t(props.tooltip)}`
          }}
          placement="top"
        >
          <HelpIcon />
        </HelpPopover>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          className={` form-control form-control-sm`}
          style={{
            width: '6.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '0'
          }}
        >
          {TextTransformOptions.map((option) => (
            <button
              className={` ${styles.buttonContent}  btn btn-secondary ${
                isSelected(option.value) ? 'active' : ''
              }`}
              onClick={() => handleChange(option.value)}
              key={option.label}
            >
              {option.icon && <i className={option.icon}></i>}
              {option.text && <span>{option.text}</span>}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TextTransform;
