import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Container, Row, Col, Spinner } from 'react-bootstrap';
import { OrganizationService } from 'modules/organization/services';
import {
  AddOrganization,
  AdminRole,
  ManagerRole,
  DevRole,
  BillingRole,
  OrganizationApiInfo
} from 'modules/organization/types';
import { InvitationService } from 'modules/invitation/service';
import { AddInvite } from 'modules/invitation/types';
import { useTranslation } from 'react-i18next';
import InvitesList from 'modules/organization/components/invites_list';

import styles from './style.module.css';
import Confirmation from '../../../../web_ui/confirmation';
import { useNavigate } from 'react-router-dom';
import useSession from '../../../../hooks/useSession';

export type UpdateOrganizationModalProps = {
  showModal: boolean;
  onCloseRequest: () => void;
  onUpdate: (org: AddOrganization) => void;
  preOrg: OrganizationApiInfo;
};

function UpdateOrganizationModal({
  showModal,
  onCloseRequest,
  onUpdate,
  preOrg
}: UpdateOrganizationModalProps) {
  const [name, setName] = useState(preOrg.name);
  const [description, setDescription] = useState(preOrg.description);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState<AddInvite[]>([]);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const navigate = useNavigate();
  const [session] = useSession();

  const { t } = useTranslation();

  const about_organization: string = t('organizations.update_organization.AboutOrganization');
  const organization: string = t('organizations.update_organization.organization');

  const buildQuery = (): AddOrganization => {
    const newOrg: AddOrganization = {
      name: name,
      description: description
    };
    return newOrg;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);
      if (preOrg) {
        const newOrg = buildQuery();
        const responseOrg = await OrganizationService.updateOrganizationById(preOrg.id, newOrg);
        if (invites.length > 0) await InvitationService.createInviteOrg(responseOrg.id, invites);
        onUpdate(newOrg);
        onClose();
      }
    } catch (error) {
      setShowError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteOrg = async (name: string) => {
    if (preOrg && preOrg.name === name) {
      try {
        setLoading(true);
        await OrganizationService.deleteOrganizationById(preOrg.id);
      } catch (error) {
        console.log(error);
      } finally {
        session.reloadUser();
        setLoading(false);
        navigate('/organizations');
      }
    } else {
      setShowError(true);
    }
    setShowConfirmationDialog(false);
    onCloseRequest();
  };

  const onClose = () => {
    setName('');
    setDescription('');
    setInvites([]);
    setShowConfirmationDialog(false);
    onCloseRequest();
  };

  useEffect(() => {
    setShowError(false);
    if (showModal) {
      setName(preOrg.name);
      setDescription(preOrg.description);
    }
  }, [showModal]);

  return (
    <>
      <Modal
        centered
        show={showModal}
        onHide={() => onClose()}
        backdrop={'static'}
        size={'lg'}
        style={{ height: '95vh' }}
      >
        <form onSubmit={onSubmit} id="formModal">
          <Modal.Header closeButton className={`bg-body ${styles.newOrgModalHeader}`}>
            <Modal.Title className={`text-body-emphasis ${styles.newOrgModalTitle}`}>
              <strong>{t('organizations.update_organization.updateOrganization')}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-body-tertiary" id="bodyModal">
            <Container>
              {showError && (
                <p className={styles.errorMessage} id="headerMessage">
                  {t('organizations.update_organization.errorMessage')}
                </p>
              )}
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>{t('Name')}</Form.Label>
                    <Form.Control
                      type="text"
                      id="formName"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={organization}
                      value={name}
                      disabled={isLoading}
                      maxLength={255}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>{t('Description')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  id="formDescription"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  placeholder={about_organization}
                  maxLength={255}
                  disabled={isLoading}
                />
              </Form.Group>
              {/* Invites */}
              <InvitesList
                invites={invites}
                setInvites={setInvites}
                listRoles={[AdminRole, ManagerRole, DevRole, BillingRole]}
                isUpdate={true}
              />
              <Form.Group className="mb-2">
                <Form.Label className={styles.dangerZoneTitle}>
                  {t('appResume.AttentionWarning')}
                </Form.Label>
                <div className="border border-2 border-danger rounded p-2">
                  <div className={styles.dangerZoneContainer}>
                    <div className={styles.dangerZoneLabels}>
                      <Form.Label id={'dangerZoneButton'} className={styles.dangerZoneTitle}>
                        {t('organizations.update_organization.DeleteOrganization')}
                      </Form.Label>
                      <Form.Label className={styles.dangerZoneDescription}>
                        {t('organizations.update_organization.DeleteOrganizationDescription')}
                      </Form.Label>
                    </div>
                    <Button
                      id={'deleteButton'}
                      variant="danger"
                      onClick={() => setShowConfirmationDialog(true)}
                    >
                      {t('Delete')}
                    </Button>
                  </div>
                </div>
              </Form.Group>
              <div className={styles.dialogSaveButton}>
                <Button id="saveButton" type="submit" disabled={isLoading}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className={
                      isLoading ? `${styles.ButtonSpinner}` : `${styles.DisabledButtonSpinner}`
                    }
                  />
                  {t('Save')}
                </Button>
              </div>
            </Container>
          </Modal.Body>
        </form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={t('organizations.update_organization.ConfirmDelete')}
        showItemNameInput={true}
        itemName={preOrg.name}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={(name) => deleteOrg(name)}
        onClose={onClose}
        confirmationLabel={t('Confirm') as string}
        cancelLabel={t('Cancel') as string}
        isLoading={isLoading}
      />
    </>
  );
}

export default UpdateOrganizationModal;
