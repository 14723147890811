import { InputNumberData, InputNumberStyles } from '.';
import { LayoutComponent } from 'modules/designer/types';

export const INPUT_NUMBER_TEMPLATE: LayoutComponent<InputNumberData, InputNumberStyles> = {
  uuid: 'empty',
  type: 'INPUTNUMBER',
  data: {
    label: 'Number',
    width: 'calc(100% - 20px)',
    widthUnit: '',
    maxWidth: 'none',
    minWidth: 'none',
    height: 'fit-content',
    heightUnit: '',
    maxHeight: 'none',
    minHeight: 'none',
    optionSizeWidth: 'full',
    optionSizeHeight: 'auto'
  },
  styles: {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
    borderTop: 0,
    borderRight: 0,
    borderBottom: 0,
    borderLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    css: ''
  }
};
