import React, { useContext, useState } from 'react';
import { Endpoint, METHODS } from '../../types';
import styles from './styles.module.css';
import { Badge } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { CrudData } from 'routes/automation_wizard/components/wizard_steps/crud';
import EditButton from '../edit_button/edit_button';
import { useTranslation } from 'react-i18next';
import LogicBuilderContext from 'modules/logic_builder/store';
import { TagItem } from '../dialogs/endpoint_editor_dialog/tag_item';
import useWindowDimensions from 'modules/designer/hooks/window';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { navigateToEndpointEditor } from 'routes/studio/endpoint_editor/actions';
import { useNavigate, useParams } from 'react-router-dom';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import Confirmation from 'web_ui/confirmation';
import { EndpointsService } from 'modules/logic_builder/services';
import { useQuery } from 'hooks/useQuery';

export type EndpointInstanceContext =
  | 'crud'
  | 'endpoint-editor'
  | 'endpoint-editor-dialog'
  | 'endpoints-list';

type EndpointInstanceProps = {
  className?: string;
  cursor?: string;
  endpoint: Endpoint;
  controllerPath?: string;
  crudData?: CrudData;
  showEditBtn: boolean;
  context: EndpointInstanceContext;
  editButtonOnClick?: () => void;
  getEndList?: (controllerId: string, endpIdDelete?: string) => void;
  onChange?: (crudData: CrudData) => void;
};

type EndpointStyles = {
  badge: string;
  border: string;
  background: string;
};

export const ENDPOINT_COLORS: { [key: string]: EndpointStyles } = {
  [METHODS.POST]: {
    badge: 'PostBackgroundColor', // css classes because using values did not work with bootstrap
    border: '1px solid #198754',
    background: 'rgba(25, 135, 84, 0.1)'
  },
  [METHODS.PUT]: {
    badge: 'PutBackgroundColor',
    border: '1px solid #FFC107',
    background: 'rgba(255, 193, 7, 0.1)'
  },
  [METHODS.GET]: {
    badge: 'GetBackgroundColor',
    border: '1px solid #0DCAF0',
    background: 'rgba(13, 202, 240, 0.1)'
  },
  [METHODS.PATCH]: {
    badge: 'PatchBackgroundColor',
    border: '1px solid #BA38E8',
    background: 'rgba(186, 56, 232, 0.1)'
  },
  [METHODS.DELETE]: {
    badge: 'DeleteBackgroundColor',
    border: '1px solid #DC3545',
    background: 'rgba(220, 53, 69, 0.1)'
  }
};

export function EndpointInstance(props: EndpointInstanceProps) {
  const queryParameters = useQuery();
  const { tags } = useContext(LogicBuilderContext);
  const { controller_id, module_id, app_id } = useParams();
  const [showCodePreviewDialog, setShowCodePreviewDialog] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { t } = useTranslation();
  const windowDimension = useWindowDimensions();
  const navigate = useNavigate();
  const { fetchEndpoints } = useContext(LogicBuilderContext);

  const renderPath = () => {
    const controller =
      props?.crudData?.controllers[
        props.crudData?.controllers.findIndex((item) => item.uuid === props.endpoint.controllerUuid)
      ]?.path;
    const controllerPath = props.controllerPath ?? controller ?? '';
    return `${controllerPath}${props.endpoint.path}`;
  };

  async function onDelete() {
    if (!module_id) return;
    if (await verifyEndpoint())
      await EndpointsService.deleteEndpoint(props.endpoint.uuid).then(() => {
        if (props.getEndList) {
          props.getEndList(props.endpoint.controllerUuid, props.endpoint.uuid);
        }
        fetchEndpoints(module_id);
        setShowConfirmationDialog(false);
      });
    //@ts-ignore
    if (window.fromCrud) {
      window.close();
    }
  }

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const verifyEndpoint = async (): Promise<boolean> => {
    if (!module_id) return false;
    let endpointList: Endpoint[];
    endpointList = await EndpointsService.getEndpointsByController(
      controller_id ?? props.endpoint.controllerUuid,
      module_id
    );

    endpointList.forEach((item) => {
      item.isNew = false;
    });

    const foundEndpoint = endpointList.find((item, index) => item.uuid === props.endpoint.uuid);

    if (foundEndpoint) {
      endpointList = [];
      return true;
    } else {
      const crud = {
        ...props.crudData,
        endpoints: endpointList
      } as CrudData;
      if (props.onChange) props.onChange(crud);
      return false;
    }
  };

  const openEditor = async () => {
    if (!props.showEditBtn) return;

    if (props.context === 'endpoints-list' && (await verifyEndpoint())) {
      if (!controller_id || !module_id || !app_id) return;
      navigateToEndpointEditor(
        props.endpoint.uuid,
        controller_id,
        module_id,
        app_id,
        navigate,
        props.context,
        isFromVsCodeExt
      );
    } else if (props.context === 'crud' && (await verifyEndpoint())) {
      if (!props.endpoint.controllerUuid || !module_id || !app_id) return;
      navigateToEndpointEditor(
        props.endpoint.uuid,
        props.endpoint.controllerUuid,
        module_id,
        app_id,
        navigate,
        props.context,
        isFromVsCodeExt
      );
    }
  };

  const hanldeDelete = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowConfirmationDialog(true);
  };

  const handleShowCodePreviewDialog = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setShowCodePreviewDialog(true);
  };

  return (
    <>
      <div
        id={props.endpoint.name}
        className={`${styles.EndpointWrapper} ${props.className}`}
        style={{
          background: ENDPOINT_COLORS[props.endpoint.method].background,
          border: ENDPOINT_COLORS[props.endpoint.method].border,
          cursor: props.cursor ?? 'default',
          position: 'relative'
        }}
        onClick={openEditor}
      >
        <div className={`${styles.wrapMethodAndPath}`} id={props.endpoint.path}>
          <div className={`${styles.EndpointDescription}`}>
            <Badge
              className={`${styles.Badge} ${styles[ENDPOINT_COLORS[props.endpoint.method].badge]}`}
            >
              {props.endpoint.method}
            </Badge>
          </div>
          <div>
            <div className={styles.UrlRequest}>
              <p className="mb-0">{renderPath()}</p>
            </div>
            {windowDimension.width > 775 && (
              <div
                className={styles.tagContainer}
                style={windowDimension.width <= 1175 ? { width: 300 } : {}}
              >
                {Object.values(props.endpoint.tags).map((tagId) => {
                  const tag = tags[tagId];
                  if (!tag) return null;
                  return <TagItem tag={tag} key={tag.id} viewOnly={true} />;
                })}
              </div>
            )}
          </div>
        </div>
        <div
          className={`${styles.Details}`}
          style={{ color: `${ENDPOINT_COLORS[props.endpoint.method].border.split(' ')[2]}` }}
        >
          <p className="mb-0 d-none d-md-block">
            {props.endpoint.summary && props.endpoint.summary.length > 36
              ? props.endpoint.summary.substring(0, 32) + '...'
              : props.endpoint.summary}
          </p>

          {props.endpoint.deprecated && (
            <div
              className={`badge text-bg-warning d-flex justify-content-center align-items-center ${styles.Badge}`}
            >
              {t('logicBuilder.Deprecated')}
            </div>
          )}

          {props.endpoint.accessLevel === 'PUBLIC' && (
            <span className="badge rounded-pill text-bg-light">
              <Icon iconName="lock-open"></Icon>
            </span>
          )}
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('CodePreview') ?? 'CodePreview'
            }}
          >
            <div
              id="codePreview"
              className={`bg-body-tertiary shadow-sm ${styles.CodePreviewBtn} text-secondary `}
              onClick={handleShowCodePreviewDialog}
            >
              <Icon iconName="fa-solid fa-code" extraProps="h7" />
            </div>
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('editButton') ?? 'editButton'
            }}
          >
            {props.showEditBtn && <EditButton execute={props.editButtonOnClick ?? openEditor} />}
          </HelpPopover>
          <HelpPopover
            placement={'top'}
            helpBoxProps={{
              title: t('Delete') ?? 'Delete'
            }}
          >
            <div
              id={`deleteButton-${props.endpoint.name}`}
              className={`bg-body-tertiary shadow-sm ${styles.CodePreviewBtn} text-secondary`}
              onClick={hanldeDelete}
            >
              <Icon iconName="trash" extraProps="h7" />
            </div>
          </HelpPopover>
        </div>
        <div className={styles.EndpointToggle} />
      </div>
      {props.endpoint.uuid && (
        <CodeEditorModal
          show={showCodePreviewDialog}
          handleClose={() => {
            setShowCodePreviewDialog(false);
          }}
          id={props.endpoint.uuid}
          previewType={CodePreviewType.ENDPOINT}
        />
      )}
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.endpoint')} (${props.endpoint.name})`}
        onConfirmation={onDelete}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
