import { RoleAuthorities, RoleOptions, UserProfile } from '../../auth/types/auth_types';

export type OrganizationApiInfo = {
  id: number;
  name: string;
  description: string;
  owner: UserProfile;
  members: UserProfile[];
  projects: number;
  modificationTime: string;
  gitHubIntegration: boolean;
  disabled: boolean;
};

export type AddOrganization = {
  name: string;
  description: string;
};

export type AddRole = {
  name: string;
  authorities?: RoleAuthorities[];
};

export type RoleType = {
  name: string;
  authorities: RoleAuthorities[];
  isCustom: boolean;
};

export const initalStateRole: RoleType = {
  name: '',
  isCustom: true,
  authorities: []
};

export const AdminRole: RoleType = {
  name: RoleOptions.ADMIN,
  isCustom: false,
  authorities: [
    RoleAuthorities.CREATE_TEAMS,
    RoleAuthorities.INVITE_USER,
    RoleAuthorities.ADD_ADMIN,
    RoleAuthorities.CREATE_CUSTOM_ROLE,
    RoleAuthorities.MANAGE_ORG,
    RoleAuthorities.MANAGE_APP,
    RoleAuthorities.DELETE_APP,
    RoleAuthorities.MANAGE_MODULE,
    RoleAuthorities.DELETE_MODULE,
    RoleAuthorities.GENERATE_CODE,
    RoleAuthorities.MANAGE_TEAM,
    RoleAuthorities.MANAGE_INTERFACE,
    RoleAuthorities.MANAGE_ID_OPERATIONS,
    RoleAuthorities.MANAGE_LOGIC_BUILDER,
    RoleAuthorities.MANAGE_DB_MODELER,
    RoleAuthorities.BILLING,
    RoleAuthorities.MANAGE_PAYMENT_METHODS
  ]
};
export const ManagerRole: RoleType = {
  name: RoleOptions.MANAGER,
  isCustom: false,
  authorities: [
    RoleAuthorities.CREATE_TEAMS,
    RoleAuthorities.INVITE_USER,
    RoleAuthorities.MANAGE_APP,
    RoleAuthorities.DELETE_APP,
    RoleAuthorities.MANAGE_MODULE,
    RoleAuthorities.DELETE_MODULE,
    RoleAuthorities.GENERATE_CODE,
    RoleAuthorities.MANAGE_TEAM,
    RoleAuthorities.MANAGE_INTERFACE,
    RoleAuthorities.MANAGE_ID_OPERATIONS,
    RoleAuthorities.MANAGE_LOGIC_BUILDER,
    RoleAuthorities.MANAGE_DB_MODELER,
    RoleAuthorities.BILLING,
    RoleAuthorities.AUTOMATIC_PURCHASE
  ]
};
export const DevRole: RoleType = {
  name: RoleOptions.DEV,
  isCustom: false,
  authorities: [
    RoleAuthorities.MANAGE_APP,
    RoleAuthorities.GENERATE_CODE,
    RoleAuthorities.MANAGE_INTERFACE,
    RoleAuthorities.MANAGE_ID_OPERATIONS,
    RoleAuthorities.MANAGE_LOGIC_BUILDER,
    RoleAuthorities.MANAGE_DB_MODELER
  ]
};
export const BillingRole: RoleType = {
  name: RoleOptions.BILLING,
  isCustom: false,
  authorities: [RoleAuthorities.BILLING, RoleAuthorities.MANAGE_PAYMENT_METHODS]
};

export enum FiltersOptions {
  NAME = 'Name',
  DATE = 'Modification Date'
}

export enum FiltersMembersOptions {
  NAME = 'Name',
  EMAIL = 'Email'
}

export enum OrgNavMenu {
  OVERVIEW = 'Overview',
  MEMBERS = 'Members',
  TEAMS = 'Teams',
  ROLES = 'Roles'
  // SETTINGS = 'Settings'
}
