import { useOutletContext } from 'react-router-dom';
import { Page, Modal, Layout, ILayoutDefault } from '../types';
import { CurrentPageLocation } from 'routes/layout';

type UseViewsContextType = {
  pages: Page[];
  modals: Modal[];
  layouts: Layout[];
  fetchViews: () => Promise<void>;
  layoutDefault: ILayoutDefault;
  // Sometimes the navbar needs to know whether the behavior tab is selected or not.
  setCurrentLocationSubContext: (subContext: string) => void;
  setWalkthroughStepsActions: (location: CurrentPageLocation, actions: Record<string, any>) => void;
};

export const useViews = () => {
  return useOutletContext<UseViewsContextType>();
};
