import React from 'react';
import {
  useEndpointEditorDispatch,
  useEndpointEditorSelector
} from '../../../../../hooks/reduxHooks';
import { updateEndpointPath } from '../../reducers/endpointInfo';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { validatePath } from '../../../../../modules/logic_builder/components/dialogs/controller_creator_dialog';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';

type TextInputProps = {
  elementId?: string;
  className?: string;
};

export function PathInput(props: TextInputProps) {
  const { t } = useTranslation();
  const { elementId, className } = props;
  const dispatch = useEndpointEditorDispatch();
  const { uuid, path } = useEndpointEditorSelector((state) => state.endpoint);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let valid = !validatePath(e.target.value);
    valid = e.target.value.length > 0 ? valid : false;
    dispatch(updateEndpointPath({ id: uuid, newPath: e.target.value, isValid: valid }));
  };

  let valid = !validatePath(path);
  valid = path.length > 0 ? valid : false;
  const errorMessage = 'inputValidationErrorMessages.EndpointPathInvalid';

  return (
    <div className={`mb-3 ${className}`} id={WALKTHROUGH_STEPS_ELEMENTS['endpoint-editor-path']}>
      <div className="form-label">{t('logicBuilder.endepoint.Path')}</div>
      <input
        type="text"
        className={`form-control ${valid ? '' : 'is-invalid'}`}
        id={elementId ?? 'endpoint-path-input'}
        value={path}
        onChange={handleChange}
        required
      />
      {!valid && (
        <div className={`${styles.InvalidFeedback} invalid-feedback`}>{t(errorMessage)}</div>
      )}
    </div>
  );
}
