import React, { useContext } from 'react';
import styles from './styles.module.css';
import './dropdown.css';
import { useTranslation } from 'react-i18next';
import { CrudData } from './index';
import Icon from '../../../../../web_ui/icon';
import { AppContext } from '../../../../../modules/project/store/app_context';

type Step6SummaryProps = {
  crudData: CrudData;
  onChange: (crudData: CrudData) => void;
  objectsCreatedIntoFlow: string[];
  pagesCreatedIntoFlow: string[];
};

export function Step6Summary(props: Step6SummaryProps) {
  const { t } = useTranslation();

  const appInfo = useContext(AppContext).projectInformation;

  const getControllerName = (controllerId: string) => {
    return props.crudData.controllers.find((c) => c.uuid === controllerId);
  };
  const getFolderName = (folderId: string) => {
    return props.crudData.folders.find((c) => c.uuid === folderId);
  };

  return (
    <div className={styles.StepWrapper}>
      <div className={styles.Title}>{t('automation.Step6Title')}</div>
      {/* Cards */}
      <div className={styles.CardsWrapperSummary}>
        <div className={styles.SummarySection}>
          <div className={styles.SummarySectionTitle}>
            <div className={styles.sectionIcon}>
              <Icon iconName="tags" />
            </div>
            {t('automation.Step6Objects')}
          </div>
          {props.crudData.objects.length > 0 &&
            Object.values(props.crudData.objects)
              //it needs to belongs to that state
              .filter((item) => props.objectsCreatedIntoFlow.includes(item.uuid))
              .map((object) => {
                return (
                  <div className={styles.containerSummaryItem} key={object.uuid}>
                    <div className={styles.checkIcon}>
                      <Icon iconName="circle-check" />
                    </div>
                    {object.name}
                  </div>
                );
              })}
        </div>
        <div id="functionTab" className={styles.SummarySection}>
          <div className={styles.SummarySectionTitle}>
            <div className={styles.sectionIcon}>
              <Icon iconName="gear" />
            </div>
            {t('automation.Step6Functions')}
          </div>
          {props.crudData.newFunctions.length > 0 &&
            Object.values(props.crudData.newFunctions).map((func) => {
              return (
                <div className={styles.containerSummaryItem} key={func.uuid}>
                  <div className={styles.checkIcon}>
                    <Icon iconName="circle-check" />
                  </div>
                  <div>
                    <div>{func.name}</div>
                    <div className={styles.parentName}>{func.serviceName}</div>
                  </div>
                </div>
              );
            })}
        </div>
        <div id="endpointTab" className={styles.SummarySection}>
          <div className={styles.SummarySectionTitle}>
            <div className={styles.sectionIcon}>
              <Icon iconName="file-code" />
            </div>
            {t('automation.Step6Endpoints')}
          </div>
          {props.crudData.endpoints.length > 0 &&
            Object.values(props.crudData.newEndpoints).map((endpoint) => {
              return (
                <div className={styles.containerSummaryItem} key={endpoint.uuid}>
                  <div className={styles.checkIcon}>
                    <Icon iconName="circle-check" />
                  </div>
                  <div>
                    <div>{endpoint.name}</div>
                    <div className={styles.parentName}>
                      {getControllerName(endpoint.controllerUuid)?.name}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {appInfo?.has_frontend && (
          <div id="pagesTab" className={styles.SummarySection}>
            <div className={styles.SummarySectionTitle}>
              <div className={styles.sectionIcon}>
                <Icon iconName="window-maximize" />
              </div>
              {t('automation.Step6Pages')}
            </div>
            {Object.values(props.crudData.pages).length > 0 &&
              Object.values(props.crudData.pages)
                .filter((item) => props.pagesCreatedIntoFlow.includes(item.name))
                .map((page) => {
                  return (
                    <div className={styles.containerSummaryItem} key={page.uuid}>
                      <div className={styles.checkIcon}>
                        <Icon iconName="circle-check" />
                      </div>
                      <div>
                        <div>{page.name}</div>
                        {page.folder_id && (
                          <div className={styles.parentName}>
                            {getFolderName(page.folder_id)?.name}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
          </div>
        )}
      </div>
    </div>
  );
}
