import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { ApiAppInfo } from '../../project/types';
import { ProjectsService } from 'modules/project/services';
import { useParams } from 'react-router-dom';
import { AppContext } from './app_context';
import { AccountService } from 'modules/auth/services';
import { UserLimits } from 'modules/auth/types/auth_types';

type SessionWrapperProps = {
  children: ReactNode;
};

/**
 * Store a few things:
 * - Project information.
 * - App owner user limits information.
 */
export function AppContextWrapper(props: SessionWrapperProps) {
  const { app_id } = useParams();
  const [appOwnerLimits, setAppOwnerLimits] = useState<UserLimits>();
  const [projectInformation, setProjectInformation] = useState<ApiAppInfo>();

  const updateProjectInformation = useCallback(
    async (appId: string): Promise<ApiAppInfo | null> => {
      const project = await ProjectsService.getProjectById(appId);
      setProjectInformation(project);
      return project;
    },
    []
  );

  const reloadAppOwnerLimits = async () => {
    if (app_id) {
      const ownerLimits = await AccountService.getLimitsByApp(app_id);
      setAppOwnerLimits(ownerLimits);
    }
  };

  const getUserLimitsByApp = useCallback(reloadAppOwnerLimits, [app_id]);

  useEffect(() => {
    if (!app_id) {
      setAppOwnerLimits(undefined);
      setProjectInformation(undefined);
    } else {
      getUserLimitsByApp();
      updateProjectInformation(app_id);
    }
  }, [app_id, getUserLimitsByApp, updateProjectInformation]);

  const setCurrentProjectInformation = useCallback((appInfo: ApiAppInfo) => {
    setProjectInformation(appInfo);
  }, []);

  return (
    <AppContext.Provider
      value={{
        projectInformation,
        updateProjectInformation,
        setCurrentProjectInformation,
        appOwnerLimits,
        reloadAppOwnerLimits
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
}
