import React from 'react';
import styles from '../styles.module.css';
import { ControlProps } from '../index';
import HelpPopover from '../components/Popover';
import HelpIcon from '../components/HelpIcon';
import { useTranslation } from 'react-i18next';

const AlignTextControlOptions = [
  { value: 'left', icon: 'fa-solid fa-align-left' },
  { value: 'center', icon: 'fa-solid fa-align-center' },
  { value: 'right', icon: 'fa-solid fa-align-right' },
  { value: 'justify', icon: 'fa-solid fa-align-justify' }
] as const;

function AlignTextControl(props: ControlProps) {
  const { t } = useTranslation();
  const currentValue = props.value;

  function handleChange(value: string) {
    if (props.onChange) props.onChange(value, props.id);
  }

  const isSelected = (value: string): boolean => value === currentValue;

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      {props.tooltip && (
        <div>
          <HelpPopover
            placement="top"
            helpBoxProps={{
              description: `${t(props.tooltip)}`
            }}
          >
            <HelpIcon />
          </HelpPopover>
        </div>
      )}
      <div
        className="mb-3"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <div className={`${styles.contentButtons} form-control form-control-sm`}>
          {AlignTextControlOptions.map((option) => (
            <button
              key={option.value}
              className={`${styles.buttonContent} btn btn-secondary ${
                isSelected(option.value) ? 'active' : ''
              }`}
              onClick={() => handleChange(option.value)}
            >
              <i className={option.icon}></i>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AlignTextControl;
