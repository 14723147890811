import { mixpanel } from 'exocode';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();
  const previousPath = useRef<string | null>(null);
  const startTime = useRef<number>(Date.now());

  useEffect(() => {
    const trackPageView = () => {
      const time = Date.now();
      const timeInPage = Math.round((time - startTime.current) / 1000);

      const path = window.location.pathname;
      const pageName = document.title;

      mixpanel.track('page_view_features', {
        path: path,
        pageName: pageName,
        initialReferrer: previousPath.current || '',
        duration: timeInPage
      });

      startTime.current = time;
      previousPath.current = path;
    };
    trackPageView();

    return () => {
      trackPageView();
    };
  }, [location]);
};
export default usePageTracking;
