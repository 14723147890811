import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';
import { FunctionEditorState } from '../../types/function_editor_state';
import { DeleteBlockAction } from '../actions/delete_block';

export function doDeleteBlockAction(
  state: FunctionEditorState | EndpointEditorState,
  action: DeleteBlockAction
): FunctionEditorState {
  const { functionUuid, uuid, closeBlockActionUuid, mode } = action.payload;

  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    const opepnBlockIndex = state.callerInfo.actions.indexOf(uuid);
    const closeBlockIndex = state.callerInfo.actions.indexOf(closeBlockActionUuid);
    state.callerInfo.actions.splice(closeBlockIndex, 1);
    state.callerInfo.actions.splice(opepnBlockIndex, 1);
  } else {
    const openBlockIndex = state.functions[functionUuid].actions.indexOf(uuid);
    const closeBlockIndex = state.functions[functionUuid].actions.indexOf(closeBlockActionUuid);
    state.functions[functionUuid].actions.splice(closeBlockIndex, 1);
    state.functions[functionUuid].actions.splice(openBlockIndex, 1);
  }

  const actionInfo = state.actions[uuid];
  if (actionInfo && state.actions[uuid]?.type === 'BEGIN_CYCLE') {
    delete state.variables[actionInfo.data.uuid];
  }

  return state;
}
