import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TagSelector from '../../tag_selector';
import { Tag } from 'modules/logic_builder/types';

interface TagManagerModalProps {
  open: boolean;
  onHide: () => void;
  updateTags: (newTags: number[]) => Promise<void>;
  allTags: Tag[];
  selectedTags: number[];
}

function SelectTagDialog({
  open,
  onHide,
  updateTags,
  allTags,
  selectedTags
}: TagManagerModalProps) {
  const [search, setSearch] = React.useState('');
  const { t } = useTranslation();

  return (
    <Modal show={open} onHide={onHide} centered>
      <Modal.Header closeButton>{t('logicBuilder.TagSelector')}</Modal.Header>
      <Modal.Body>
        <div>
          <label className="mb-2">{t('SearchItem')}</label>
          <Form.Control
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Ex. Exocoding"
          />
        </div>
        <div className="mt-4">
          <label>{t('logicBuilder.Tags')}</label>
          <TagSelector
            search={search}
            updateTags={updateTags}
            allTags={allTags}
            selectedTags={selectedTags}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onHide()}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectTagDialog;
