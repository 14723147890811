import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSession from 'hooks/useSession';
import { Form } from 'react-bootstrap';
import { CrudData } from '../../..';
import {
  AccessLevel,
  DATA_TYPES,
  Endpoint,
  INPUT_TYPES,
  Method,
  ParameterEndpointCrud
} from 'modules/logic_builder/types';
import { AppContext } from '../../../../../../../../modules/project/store/app_context';
import ParameterListEditorCrud from '../endpoint_edition/params';
import { v4 } from 'uuid';
import NameEditor from './params/signature/name_editor';
import PathEditor from './params/signature/path_editor';
import SecuritySelector from './params/signature/security_selector';
import MappingIntoEnpoint from './map_data_func_endpoint';
import useFetchTypes from 'web_ui/function_editor/action_inputs/utils/useFetchTypes';
import Icon from 'web_ui/icon';
import { Argument } from 'web_ui/function_editor/store/types/manifestsAndInputs';
import { TYPE_PICKER_TYPES } from 'web_ui/function_editor/action_inputs/inputs/argument_picker/type_picker';

import styles from './styles.module.css';

type Props = {
  crudData: CrudData;
  currentEndpoint: Endpoint;
  onChange: (crudData: CrudData) => void;
};

function EndpointEditor({ crudData, currentEndpoint, onChange }: Props) {
  const appInfo = useContext(AppContext).projectInformation;
  const { t } = useTranslation();
  const { fetchedTypes } = useFetchTypes(Object.values(DATA_TYPES), 'spring');
  const [accessLevel, setAccessLevel] = useState<AccessLevel>(currentEndpoint.accessLevel);
  const [allowAllRoles, setAllowAllRoles] = useState<boolean>(currentEndpoint.allowAllRoles);
  const [roles, setRoles] = useState<Array<number>>(currentEndpoint.roles || []);
  const [functionIdIntoAction, setFunctionIdIntoAction] = useState<string>(
    currentEndpoint.actions.find((ac) => ac.type === 'RUN_FUNCTION')?.data.function.functionId
  );
  const [session] = useSession();

  /* change type */
  const handleChangeMethod = (val: string) => {
    const allEnpoints = crudData.endpoints;
    const indexForEndpointToUpdate = allEnpoints.findIndex(
      (item) => item.uuid === currentEndpoint.uuid
    );

    // changing object data
    allEnpoints[indexForEndpointToUpdate].method = val as Method;

    const crud = {
      ...crudData,
      endpoints: allEnpoints
    };

    onChange(crud);
  };

  const handleChangeName = (val: string) => {
    const allEndpoints = crudData.endpoints;
    const indexForEndpointToUpdate = allEndpoints.findIndex(
      (item) => item.uuid === currentEndpoint.uuid
    );

    // updating
    allEndpoints[indexForEndpointToUpdate].name = val;

    const crud = {
      ...crudData,
      endpoints: allEndpoints
    };

    onChange(crud);
  };

  const handleChangePath = (val: string) => {
    const allEndpoints = crudData.endpoints;
    const indexForEndpointToUpdate = allEndpoints.findIndex(
      (item) => item.uuid === currentEndpoint.uuid
    );

    // updating
    allEndpoints[indexForEndpointToUpdate].path = val;

    const crud = {
      ...crudData,
      endpoints: allEndpoints
    };

    onChange(crud);
  };

  const handleChangeParameters = (parameters: ParameterEndpointCrud[]) => {
    const allEndpoint = crudData.endpoints;
    const indexForEndpointToUpdate = allEndpoint.findIndex(
      (item) => item.uuid === currentEndpoint.uuid
    );

    // updating
    allEndpoint[indexForEndpointToUpdate].parameters = parameters;

    const crud = {
      ...crudData,
      endpoints: allEndpoint
    };

    onChange(crud);
  };

  const handleChangeSecurity = (
    accessLevel: AccessLevel,
    allowAllRoles: boolean,
    roles: number[]
  ) => {
    const allEndpoint = crudData.endpoints;
    const indexForEndpointToUpdate = allEndpoint.findIndex(
      (item) => item.uuid === currentEndpoint.uuid
    );

    // updating
    allEndpoint[indexForEndpointToUpdate].accessLevel = accessLevel;
    allEndpoint[indexForEndpointToUpdate].allowAllRoles = allowAllRoles;
    allEndpoint[indexForEndpointToUpdate].roles = roles;

    const crud = {
      ...crudData,
      endpoints: allEndpoint
    };

    onChange(crud);
  };

  useEffect(() => {
    handleChangeSecurity(accessLevel, allowAllRoles, roles);
  }, [accessLevel, allowAllRoles, roles]);

  const handlePagination = (checked: boolean) => {
    if (checked) {
      const enpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      enpointUpdate.pageable = true;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      const newParamPaginationSize: ParameterEndpointCrud = {
        uuid: v4(),
        name: 'size',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'INTEGER',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined,
        inputType: INPUT_TYPES.QUERY, // query ?
        inputName: ''
      };

      const newParamPaginationPage: ParameterEndpointCrud = {
        uuid: v4(),
        name: 'page',
        description: '...',
        list: false,
        order: allParams.length + 1,
        required: true,
        type: 'INTEGER',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined,
        inputType: INPUT_TYPES.QUERY, // query ?
        inputName: ''
      };

      allParams.push(newParamPaginationSize);
      allParams.push(newParamPaginationPage);

      enpointUpdate.parameters = allParams;

      // updating the crudData
      const totalEndpoint = crudData.endpoints;
      const indexEndpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      );

      totalEndpoint[indexEndpoint] = enpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: totalEndpoint
      };

      onChange(crud);
    } else {
      const enpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      enpointUpdate.pageable = false;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'size'),
        1
      );
      allParams.splice(
        allParams.findIndex((item) => item.name === 'page'),
        1
      );

      enpointUpdate.parameters = allParams;

      // updating the crudData
      const totalEndpoint = crudData.endpoints;
      const indexEndpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      );

      totalEndpoint[indexEndpoint] = enpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: totalEndpoint
      };

      onChange(crud);
    }
  };

  const handleSort = (checked: boolean) => {
    if (checked) {
      const endpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      endpointUpdate.sortable = true;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      const newParamPaginationSort: ParameterEndpointCrud = {
        uuid: v4(),
        name: 'sort',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'STRING',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined,
        inputType: INPUT_TYPES.QUERY, // query ?
        inputName: ''
      };

      allParams.push(newParamPaginationSort);

      endpointUpdate.parameters = allParams;

      // updating the crudData
      const allEndpoint = crudData.endpoints;
      const indexendpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      ); // correct func
      allEndpoint[indexendpoint] = endpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: allEndpoint
      };
      onChange(crud);
    } else {
      const endpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      endpointUpdate.sortable = false;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'sort'),
        1
      );

      endpointUpdate.parameters = allParams;

      // updating the crudData
      const allEndpoints = crudData.endpoints;
      const indexEndpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      );

      allEndpoints[indexEndpoint] = endpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: allEndpoints
      };
      onChange(crud);
    }
  };

  const handleFilterable = (checked: boolean) => {
    if (checked) {
      const endpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      endpointUpdate.filterable = true;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      const newParamPaginationSort: ParameterEndpointCrud = {
        uuid: v4(),
        name: 'filter',
        description: '...',
        list: false,
        order: allParams.length,
        required: true,
        type: 'STRING',
        enumUuid: undefined,
        native: true,
        objectUuid: undefined,
        inputType: INPUT_TYPES.QUERY,
        inputName: ''
      };

      allParams.push(newParamPaginationSort);

      endpointUpdate.parameters = allParams;

      // updating the crudData
      const allEnpoints = crudData.endpoints;
      const indexEndpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      );

      allEnpoints[indexEndpoint] = endpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: allEnpoints
      };

      onChange(crud);
    } else {
      const endpointUpdate =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ];

      endpointUpdate.filterable = false;

      const allParams =
        crudData.endpoints[
          crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
        ].parameters;

      allParams.splice(
        allParams.findIndex((item) => item.name === 'filter'),
        1
      );

      endpointUpdate.parameters = allParams;

      // updating the crudData
      const allEndpoint = crudData.endpoints;
      const indexEndpoint = crudData.endpoints.findIndex(
        (item) => item.uuid === currentEndpoint.uuid
      );

      allEndpoint[indexEndpoint] = endpointUpdate;

      // update only the correct func
      const crud = {
        ...crudData,
        endpoints: allEndpoint
      };

      onChange(crud);
    }
  };

  const handleToggleSelectedRole = (roleId: number, checked: boolean) => {
    let nextRoles: number[];
    if (checked) {
      nextRoles = [...roles, roleId];
    } else {
      nextRoles = roles.filter((r) => r !== roleId);
    }
    setRoles(nextRoles);
  };

  const changingFunctionOfRunFun = (val: string) => {
    if (!val) return;
    const allEndpoints = crudData.endpoints;
    const correctIndex = allEndpoints.findIndex((item) => item.uuid === currentEndpoint.uuid);

    const correctAction = allEndpoints[correctIndex].actions.find(
      (ac) => ac.type === 'RUN_FUNCTION'
    );
    if (correctAction === undefined) {
      allEndpoints[correctIndex].actions = [
        {
          uuid: '',
          order: 0,
          type: 'RUN_FUNCTION',
          data: { function: { functionId: val, arguments: {} } },
          returnVariableUuid: ''
        }
      ];
    } else {
      const currentFunction = correctAction.data.function;
      // Changed function -> Change arguments
      if (val !== currentFunction.functionId) {
        // Clear current action arguments
        currentFunction.arguments = {};

        // Try and match parameters by name
        const actionArguments: { [key: string]: Argument } = {};
        const fxIdx = crudData.functions.findIndex((fx) => fx.uuid === val);
        const fxParams = crudData.functions[fxIdx].parameters;
        fxParams.forEach((fxParam) => {
          let endParam = allEndpoints[correctIndex].parameters.find(
            (p) => p.name.toLowerCase() === fxParam.name.toLowerCase()
          );
          if (!endParam) {
            endParam = allEndpoints[correctIndex].parameters[0];
          }
          if (fxParam.uuid && endParam.uuid) {
            actionArguments[fxParam.uuid] = {
              type: TYPE_PICKER_TYPES.PARAM,
              value: endParam.uuid
            };
          }
        });
        // Set matched arguments
        currentFunction.arguments = actionArguments;
      }
      currentFunction.functionId = val;
    }

    const crud = {
      ...crudData,
      endpoints: allEndpoints
    };

    onChange(crud);

    setFunctionIdIntoAction(val);
  };

  return (
    <div className={`border-primary ${styles.HeaderEditorWrapper}`}>
      {/* up */}
      <div className={styles.EditorAndOptions}>
        {/* left */}
        <div className={styles.ReturnTypeAndName}>
          <div className={styles.ReturnType}>
            <Form.Select
              value={
                crudData.endpoints[
                  crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                ].method
              }
              onChange={(val) => handleChangeMethod(val.target.value)}
              style={{ width: '9rem' }}
              disabled
            >
              <option value={'POST'}>POST</option>
              <option value={'GET'}>GET</option>
              <option value={'PUT'}>PUT</option>
              <option value={'DELETE'}>DELETE</option>
            </Form.Select>
            <PathEditor
              handleChangePath={handleChangePath}
              path={
                crudData.endpoints[
                  crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                ].path
              }
            />
          </div>
          <div className={styles.NameEditor}>
            <span style={{ marginLeft: '1rem', color: '#8E619A', marginRight: '1rem' }}>void</span>
            <NameEditor
              handleChangeName={handleChangeName}
              name={
                crudData.endpoints[
                  crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                ].name
              }
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '8px',
                marginLeft: '1rem'
              }}
            >
              <p style={{ color: '#3B6863', padding: 0, margin: 0 }}>{`(`}</p>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginRight: 10,
                  marginLeft: 10,
                  textDecoration: 'underline',
                  color: '#446089'
                }}
              >
                params
              </p>
              <p style={{ color: '#3B6863', padding: 0, margin: 0 }}>{`)`}</p>
            </div>
          </div>
          <ParameterListEditorCrud
            types={fetchedTypes}
            params={
              crudData.endpoints[
                crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
              ].parameters
            }
            endpointMethod={
              crudData.endpoints[
                crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
              ].method
            }
            handleChangeParameters={handleChangeParameters}
          />
        </div>
        {/* right */}
        <div className={styles.container}>
          {crudData.endpoints[
            crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
          ].crudType === 'READ_MANY' && (
            <div className={styles.optionsWrapper}>
              <div className={styles.checkOption}>
                <label style={{ marginRight: '.5rem' }}>{t('automation.step4.pagination')}: </label>
                <Form.Check
                  checked={
                    crudData.endpoints[
                      crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                    ].pageable
                  }
                  onChange={(val) => handlePagination(val.target.checked)}
                />
              </div>
              <div className={styles.checkOption}>
                <label style={{ marginRight: '.5rem' }}>{t('automation.step4.sort')}: </label>
                <Form.Check
                  checked={
                    crudData.endpoints[
                      crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                    ].sortable
                  }
                  onChange={(val) => handleSort(val.target.checked)}
                />
              </div>
              <div className={styles.checkOption}>
                <label style={{ marginRight: '.5rem' }}>{t('automation.step4.filter')}: </label>
                <Form.Check
                  checked={
                    crudData.endpoints[
                      crudData.endpoints.findIndex((item) => item.uuid === currentEndpoint.uuid)
                    ].filterable
                  }
                  onChange={(val) => handleFilterable(val.target.checked)}
                />
              </div>
            </div>
          )}
          {appInfo?.has_authentication && (
            <div className={styles.optionsWrapper}>
              <SecuritySelector
                currentAccessLevel={currentEndpoint.accessLevel}
                setAccessLevel={setAccessLevel}
                setAllowRoles={setAllowAllRoles}
                allowAllRoles={allowAllRoles}
                selectedRoles={roles}
                handleToggleSelectedRole={handleToggleSelectedRole}
                setRoles={setRoles}
              />
            </div>
          )}
        </div>
      </div>
      {/* down */}
      <div className={styles.functionIntoEnpointContainer}>
        <div
          style={{
            width: '8%',
            color: session.preferences['exocode-theme'] ? '#FFFFFF' : '#1c2025'
          }}
        >
          Function:
        </div>
        <div
          className={styles.containerIconMapping}
          style={{
            backgroundColor: session.preferences['exocode-theme'] ? '#1c2025' : '#FFFFFF'
          }}
        >
          <div
            style={{
              backgroundColor: '#1F9BF4',
              padding: '0.5rem',
              borderRadius: '8px',
              width: '40px',
              textAlign: 'center'
            }}
          >
            <Icon iconName={'fa-solid fa-florin-sign'} />
          </div>
          <div className={styles.containerFunctionAreaEdit}>
            <div className={styles.displayingMapping}>
              <div>
                <Form.Select
                  id="selectFunction"
                  onChange={(e) => changingFunctionOfRunFun(e.target.value)}
                  className={styles.select}
                  value={functionIdIntoAction}
                >
                  {crudData.newFunctions.length > 0 &&
                    crudData.newFunctions
                      .filter(
                        (item) =>
                          item.crudType === currentEndpoint.crudType &&
                          item.serviceUuid === currentEndpoint.crudServiceId
                      )
                      .map((item, index) => {
                        return (
                          <option key={index} value={item.uuid}>
                            {item.name}
                          </option>
                        );
                      })}
                </Form.Select>
              </div>
              <span
                style={{
                  color: session.preferences['exocode-theme'] ? '#FFFFFF' : '#1c2025'
                }}
              >
                (
              </span>
              {crudData.newFunctions.length > 0 && functionIdIntoAction
                ? crudData.newFunctions[
                    crudData.newFunctions.findIndex((item) => item.uuid === functionIdIntoAction)
                  ].parameters.map((itemIteratorParam, index) => {
                    return (
                      <MappingIntoEnpoint
                        key={index}
                        ParamFunc={itemIteratorParam}
                        crudData={crudData}
                        onChange={onChange}
                        currentEndpoint={currentEndpoint}
                        allParam={
                          crudData.newFunctions[
                            crudData.newFunctions.findIndex(
                              (item) => item.uuid === functionIdIntoAction
                            )
                          ].parameters
                        }
                      />
                    );
                  })
                : ''}
              <span
                style={{
                  color: session.preferences['exocode-theme'] ? '#FFFFFF' : '#1c2025'
                }}
              >
                )
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndpointEditor;
