import { Action } from 'redux';
import { EditorMode, FunctionEditorState } from '../../types/function_editor_state';
import { FunctionActionType } from '../../types/actions';
import { EndpointEditorState } from 'routes/studio/endpoint_editor/reducers';

export const CHANGE_ACTION_ORDER = 'CHANGE_ACTION_ORDER';

export interface ChangeActionOrderAction extends Action {
  type: 'CHANGE_ACTION_ORDER';
  payload: {
    functionUuid: string;
    originUuid: string;
    targetUuid: string;
    mode: EditorMode;
  };
}

export const changeActionOrder = (
  functionUuid: string,
  originUuid: string,
  targetUuid: string,
  mode: EditorMode
): ChangeActionOrderAction => ({
  type: CHANGE_ACTION_ORDER,
  payload: { functionUuid, originUuid, targetUuid, mode }
});

export function doChangeActionOrder(
  state: FunctionEditorState | EndpointEditorState,
  action: ChangeActionOrderAction
): FunctionEditorState {
  const { functionUuid, originUuid, targetUuid, mode } = action.payload;

  if (state.functions[functionUuid] && mode !== 'ENDPOINT_EDITOR') {
    const func = state.functions[functionUuid];

    const originIndex = func.actions.indexOf(originUuid);
    const targetIndex = func.actions.indexOf(targetUuid);

    func.actions.splice(originIndex, 1);
    if (originIndex < targetIndex) {
      func.actions.splice(targetIndex - 1, 0, originUuid);
    } else {
      func.actions.splice(targetIndex, 0, originUuid);
    }
  } else if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    const actions = state.callerInfo.actions;

    const originIndex = actions.indexOf(originUuid);
    const targetIndex = actions.indexOf(targetUuid);

    actions.splice(originIndex, 1);
    if (originIndex < targetIndex) {
      actions.splice(targetIndex - 1, 0, originUuid);
    } else {
      actions.splice(targetIndex, 0, originUuid);
    }
  }

  const originAction = state.actions[originUuid];
  const isOpenBlockAction = !!originAction?.data?.closeBlockActionUuid;
  if (isOpenBlockAction) return handleReorderOpenBlock(state, functionUuid, originAction, mode);
  const isCloseBlockAction = !!originAction?.data?.openBlockActionUuid;
  if (isCloseBlockAction) return handleReorderCloseBlock(state, functionUuid, originAction, mode);

  return state;
}

const handleReorderOpenBlock = (
  state: FunctionEditorState | EndpointEditorState,
  functionUuid: string,
  openBlockAction: FunctionActionType,
  mode: EditorMode
): FunctionEditorState => {
  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    const actions = state.callerInfo.actions;

    const openBlockActionIndex = actions.indexOf(openBlockAction.uuid);
    const closeBlockActionIndex = actions.indexOf(openBlockAction.data.closeBlockActionUuid);
    if (openBlockActionIndex < closeBlockActionIndex) return state;

    actions.splice(closeBlockActionIndex, 1);
    actions.splice(openBlockActionIndex, 0, openBlockAction.data.closeBlockActionUuid);
  } else {
    const func = state.functions[functionUuid];

    const openBlockActionIndex = func.actions.indexOf(openBlockAction.uuid);
    const closeBlockActionIndex = func.actions.indexOf(openBlockAction.data.closeBlockActionUuid);
    if (openBlockActionIndex < closeBlockActionIndex) return state;

    func.actions.splice(closeBlockActionIndex, 1);
    func.actions.splice(openBlockActionIndex, 0, openBlockAction.data.closeBlockActionUuid);
  }

  return state;
};

const handleReorderCloseBlock = (
  state: FunctionEditorState | EndpointEditorState,
  functionUuid: string,
  closeBlockAction: FunctionActionType,
  mode: EditorMode
): FunctionEditorState => {
  if (mode === 'ENDPOINT_EDITOR' && 'callerInfo' in state) {
    const actions = state.callerInfo.actions;

    const openBlockActionIndex = actions.indexOf(closeBlockAction.data.openBlockActionUuid);
    const closeBlockActionIndex = actions.indexOf(closeBlockAction.uuid);
    if (openBlockActionIndex < closeBlockActionIndex) return state;

    actions.splice(closeBlockActionIndex, 1);
    actions.splice(openBlockActionIndex, 0, closeBlockAction.uuid);
  } else {
    const func = state.functions[functionUuid];

    const openBlockActionIndex = func.actions.indexOf(closeBlockAction.data.openBlockActionUuid);
    const closeBlockActionIndex = func.actions.indexOf(closeBlockAction.uuid);
    if (openBlockActionIndex < closeBlockActionIndex) return state;

    func.actions.splice(closeBlockActionIndex, 1);
    func.actions.splice(openBlockActionIndex, 0, closeBlockAction.uuid);
  }

  return state;
};
