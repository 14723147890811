import React from 'react';
import { Editor } from '../../../../web_ui/function_editor/components/editor';
import { BACKEND_ACTIONS_MANIFEST } from '../../../../modules/logic_builder/function_editor/function_actions';
import styles from './styles.module.css';
import { useEndpointEditorSelector } from '../../../../hooks/reduxHooks';
import { EndpointEditorState } from '../reducers';

export function EditorActions() {
  const isEditorOpen = useEndpointEditorSelector(
    (state: EndpointEditorState) => state.editorStatus.isEndpointEditorOpen
  );

  const maxHeight = isEditorOpen ? 'calc(100% - 585px)' : 'calc(100% - 70px)';

  return (
    <div className={`pt-3`} style={{ maxHeight, overflow: 'auto', paddingInline: 20 }}>
      <Editor.Body manifests={BACKEND_ACTIONS_MANIFEST} className={styles.Wrapper} />
    </div>
  );
}
