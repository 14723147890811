import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateFunction } from 'web_ui/function_editor/store/events/functions/update_function';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { CrudType } from 'web_ui/function_editor/store/types/functions';

const options: CrudType[] = [
  CrudType.CREATE_ONE,
  CrudType.READ_ONE,
  CrudType.UPDATE_ONE,
  CrudType.DELETE_ONE,
  CrudType.CREATE_MANY,
  CrudType.READ_MANY,
  CrudType.UPDATE_MANY,
  CrudType.DELETE_MANY,
  CrudType.DELETE_MANY_ID
];

export default function CrudTypeEditor() {
  const state = useSelector((state: FunctionEditorState) => state);
  const dispatch = useDispatch();
  const funcId = state?.editor?.callerId;
  const currFunc = state.functions[funcId];
  const name = currFunc?.name ?? '';
  const desc = currFunc?.desc ?? '';

  const handleOnChange = (val: string) => {
    dispatch(updateFunction(funcId, name, desc, val));
  };

  if (state.editor.mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return (
    <Form.Group>
      <Form.Select
        value={currFunc?.crudType ?? ''}
        onChange={(ev) => handleOnChange(ev.target.value)}
      >
        <option value={'null'}>---</option>
        {options.map((itemIterator, index) => (
          <option key={index} value={itemIterator}>
            {itemIterator}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  );
}
