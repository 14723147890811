import React, { ComponentType, useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import HeaderOptions from './header_options';
import HeaderReturn from './header_return';
import { useSelector } from 'react-redux';
import { EditorPanel } from './editor_panel';
import useFetchTypes from '../../../action_inputs/utils/useFetchTypes';
import {
  AvailableFrameworks,
  FunctionSignature,
  FunctionSignatureProps
} from './function_signatures';
import HeaderSignatureCheck from './header_signature_check';
import { FunctionEditorState } from '../../../store/types/function_editor_state';
import SessionContext from 'modules/auth/store';
import { startWalkthrough } from 'web_ui/walkthrough/walkthrough';
import { getFunctionEditorActions } from 'web_ui/walkthrough/actions';
import { useClickOutsideEvent } from 'hooks/useClickOutside';

type FunctionHeaderProps = {
  types: string[];
  framework?: AvailableFrameworks;
};

function FunctionHeader({ types, framework = 'spring' }: FunctionHeaderProps) {
  const function_id = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const [edit, setEdit] = useState(false);
  const { fetchedTypes } = useFetchTypes(types, framework);
  const { pageVisits, updatePageVisits } = useContext(SessionContext);
  const driverInstance = useRef<any>();
  const currentContext = 'functionEditor';

  const Signature = FunctionSignature[framework] as ComponentType<FunctionSignatureProps>;

  useEffect(() => {
    if (!pageVisits) {
      return;
    }
    const alreadyShown = pageVisits[currentContext] != null;
    if (alreadyShown) return;

    driverInstance.current = startWalkthrough({
      context: currentContext,
      prevBtnText: 'Previous',
      nextBtnText: 'Next',
      doneBtnText: 'Done',
      onClose: () => {
        updatePageVisits(currentContext);
      },
      actions: getFunctionEditorActions(() => setEdit(true))
    });
  }, [pageVisits, updatePageVisits]);

  useClickOutsideEvent({
    id: 'driver-popover-content',
    action: () => {
      if (driverInstance.current) {
        if (pageVisits && !pageVisits[currentContext]) {
          updatePageVisits(currentContext);
        }
        driverInstance.current.destroy();
      }
    }
  });

  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return (
    <div id="functionHeader">
      <div className={`border-primary ${styles.FunctionSignatureWrapper}`}>
        <div style={{ display: 'flex', gap: '0.2 rem' }}>
          <HeaderReturn />
          {framework === 'spring' && <HeaderSignatureCheck functionId={function_id} />}
          <div style={{ paddingLeft: '8px', paddingTop: '6px' }}>
            <Signature types={fetchedTypes} />
          </div>
        </div>
        <HeaderOptions edit={edit} setEdit={setEdit} framework={framework} />
      </div>
      <div className={`border-primary ${styles.HeaderEditorWrapper}`}>
        {edit && (
          <EditorPanel.Root>
            <EditorPanel.ReturnTypeAndName types={fetchedTypes} framework={framework} />
            <EditorPanel.ParametersList types={fetchedTypes} />
            {framework === 'spring' && <EditorPanel.Options />}
          </EditorPanel.Root>
        )}
      </div>
      <hr />
    </div>
  );
}

export default FunctionHeader;
