import { UserLimits, UserProfile } from '../types/auth_types';
import { AccountRepo } from '../repos';

export default class Account {
  async getAccountInfo(): Promise<UserProfile> {
    return await AccountRepo.getAccountInfo();
  }

  async changeUserPreferences(key: string, value: any): Promise<void> {
    await AccountRepo.changeUserPreferences(key, value);
  }

  async getLimits(id: number): Promise<UserLimits> {
    return await AccountRepo.getLimits(id);
  }

  async getLimitsByApp(appId: string): Promise<UserLimits> {
    return await AccountRepo.getLimitsByApp(appId);
  }
}
