import { LayoutComponent } from 'modules/designer/types';
import { CarouselData, CarouselStyle } from '.';

export const CAROUSEL_TEMPLATE: LayoutComponent<CarouselData, CarouselStyle> = {
  uuid: 'empty',
  type: 'CAROUSEL',
  data: {
    selectedPage: 1,
    timeToChange: 5,
    qtdPages: 3,
    width: 700,
    widthUnit: 'px',
    maxWidth: 'none',
    minWidth: 'none',
    height: 300,
    heightUnit: 'px',
    maxHeight: 'none',
    minHeight: 'none',
    optionSizeWidth: 'Fixed',
    optionSizeHeight: 'Fixed'
  },
  styles: {},
  events: {}
};
