import React, { ReactNode, useState, useMemo, memo, CSSProperties, useEffect } from 'react';
import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import RecursiveLayer from 'modules/designer/studio/toolbars/components_toolbar/layers/recursiveLayer';
import { useParams } from 'react-router-dom';
import { LocalStorageManager } from 'utils/localstorage';
import { ErrorBoundary } from 'react-error-boundary';
import GenericFallback from 'error_boundaries/genericFallback';
import { globalReport } from 'error_boundaries';
import { useTranslation } from 'react-i18next';
import HelpPopover from './controls/components/Popover';
import { useQuery } from 'hooks/useQuery';
import DataElementsToolbar from 'modules/modeler/studio/toolbars/elements_toolbar';
import { WALKTHROUGH_STEPS_ELEMENTS } from 'web_ui/walkthrough/constants';
import { InterfaceStudioState } from 'modules/designer/studio/store';
import { useSelector } from 'react-redux';
import { LAYERS_ELEMENT_ID } from 'routes/studio/interface/constants';

export enum SidebarPosition {
  LEFT,
  RIGHT
}

export enum Studios {
  DB_MODELER = 'dbmodeler',
  DESIGNER = 'designer'
}

export type ToolbarItem = {
  id: string;
  name: string;
  icon: string;
  iconBrands?: string;
};

type SidebarProps = {
  position: SidebarPosition;
  toolbarItems?: ToolbarItem[];
  children?: ReactNode;
  isOpen?: (isOpen: boolean) => void;
  currentValueSaved?: number;
  from: Studios;
};

function Sidebar(props: SidebarProps) {
  const { t } = useTranslation();
  const { app_id, module_id, view_id, custom_component_id, schema_id } = useParams();
  const queryParameters = useQuery();
  const [selectedToolbar, setSelectedToolbar] = useState(
    props.currentValueSaved ? props.currentValueSaved : 0
  );
  const [colapseSidebar, setColapseSidebar] = useState<boolean>(false);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent
  );

  // When selected component changes, scroll to the new selected component.
  useEffect(() => {
    if (!selectedComponent) return;

    const wrapper = document.getElementById(LAYERS_ELEMENT_ID);

    if (!wrapper) return;

    const observer = new MutationObserver(() => {
      const targetElement = document.getElementById(`${selectedComponent.uuid}Component`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        observer.disconnect();
      }
    });

    observer.observe(wrapper, {
      attributes: true,
      subtree: true
    });

    return () => {
      observer.disconnect();
    };
  }, [selectedComponent]);

  function handleColapse() {
    setColapseSidebar(!colapseSidebar);
    if (props.isOpen) props.isOpen(colapseSidebar);
  }

  function renderLayers() {
    if (view_id) return <RecursiveLayer componentUUID={view_id} isFirstLevel={true} />;
    if (custom_component_id)
      return <RecursiveLayer componentUUID={custom_component_id} isFirstLevel={true} />;
  }

  const savingLastStatusLeftSideBar = (index: number) => {
    if (props.from === Studios.DESIGNER) {
      const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
      copying[module_id!] = {
        ...copying[module_id!],
        interface: {
          ...copying[module_id!].interface,
          lastSelectedLeftSidebarTab: index
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id!, copying);
    } else if (props.from === Studios.DB_MODELER) {
      const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
      copying[module_id!] = {
        ...copying[module_id!],
        dbmodeler: {
          ...copying[module_id!].dbmodeler,
          lastSelectedLeftSidebarTab: index
        }
      };
      LocalStorageManager.setValueLocalStorageState(app_id!, copying);
    }
  };

  const savingLastStatusRightSideBar = (index: number) => {
    const copying = LocalStorageManager.getValueLocalStorageState(app_id!, module_id!);
    copying[module_id!] = {
      ...copying[module_id!],
      interface: {
        ...copying[module_id!].interface,
        lastSelectedRightSidebarTab: index
      }
    };
    LocalStorageManager.setValueLocalStorageState(app_id!, copying);
  };

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  const styleForVsCode = (): CSSProperties => {
    return isFromVsCodeExt() ? { left: 0 } : {};
  };

  let resizingArea: string | null = null;
  let startHeight = 0;
  let startY = 0;

  function handleMouseDown(e: React.MouseEvent<HTMLDivElement>, area: string): void {
    e.preventDefault();
    resizingArea = area;

    document.body.style.cursor = 'ns-resize';

    const targetContent =
      area === 'view'
        ? (document.querySelector(`.${styles.toolbarContent}`) as HTMLElement)
        : (document.querySelector(`.${styles.layersWrapper}`) as HTMLElement);

    if (targetContent) {
      startHeight = targetContent.clientHeight;
      startY = e.clientY;
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }

  function handleMouseMove(e: MouseEvent): void {
    const targetContent =
      resizingArea === 'view'
        ? (document.querySelector(`.${styles.toolbarContent}`) as HTMLElement)
        : (document.querySelector(`.${styles.layersWrapper}`) as HTMLElement);

    if (targetContent) {
      const deltaY = e.clientY - startY;
      const newHeight = startHeight - deltaY;

      if (newHeight > 0) {
        targetContent.style.height = `${newHeight}px`;
      }
    }
  }

  function handleMouseUp(): void {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    resizingArea = null;

    document.body.style.cursor = '';
  }

  const toolbars = useMemo(() => React.Children.toArray(props.children), [props.children]);
  return (
    <>
      {props.position === SidebarPosition.LEFT ? (
        <div
          className={`border-end ${styles.leftToolbarContainer}`}
          style={colapseSidebar ? { width: '0px', ...styleForVsCode() } : { ...styleForVsCode() }}
        >
          <div
            id="collapseArrowLeft"
            className={styles.leftColapseArrow}
            style={colapseSidebar ? { transform: 'rotate(180deg)', right: '-30px' } : {}}
            onClick={handleColapse}
          >
            <Icon iconName="angles-left"></Icon>
          </div>
          <aside
            className={`bg-body ${styles.leftAside}`}
            style={colapseSidebar ? { width: '0px' } : {}}
          >
            {/* Remove this comment to allow components creation */}
            {/* 
            {props.from === Studios.DESIGNER && (
              <div className={`${styles.componentsContainer} mt-3`}>
                <ComponentsToolbar />
              </div>
            )} */}
            {schema_id && (
              <div className={`${styles.componentsContainer}`}>
                <div
                  id="layers-content-modeler"
                  className={`${styles.layersContent} p-1`}
                  tabIndex={0}
                >
                  <DataElementsToolbar />
                </div>
              </div>
            )}

            <div
              style={view_id || custom_component_id || schema_id ? { position: 'relative' } : {}}
              className={`${styles.toolbarContent}`}
            >
              <div
                className={`border-top`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: '10px',
                  cursor: 'ns-resize'
                }}
                onMouseDown={(e) => handleMouseDown(e, 'view')}
              ></div>

              <div className={`${styles.tabListContainer} ${view_id ? 'mt-3' : ''} `}>
                <ul
                  style={{ paddingBottom: '10px', overflowX: 'auto' }}
                  className={`${styles.tabList} nav nav-pills m-2`}
                >
                  {props.toolbarItems?.map((toolbarItem: ToolbarItem, index: number) => (
                    <li key={index} className={`${styles.liTabItem} nav-item`}>
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: toolbarItem.name
                        }}
                      >
                        <div
                          id={`tabButton-${index}`}
                          className={
                            selectedToolbar === index
                              ? `text-light bg-primary nav-link ${styles.button} ${styles.buttonActive}`
                              : `text-body-secondary bg-body-secondary nav-link ${styles.button} ${styles.buttonInactive}`
                          }
                          onClick={() => {
                            setSelectedToolbar(index);
                            savingLastStatusLeftSideBar(index);
                          }}
                        >
                          <Icon
                            brands={toolbarItem.iconBrands ? toolbarItem.iconBrands : undefined}
                            iconName={toolbarItem.icon}
                          ></Icon>
                        </div>
                      </HelpPopover>
                    </li>
                  ))}
                </ul>
              </div>
              {toolbars[selectedToolbar]}
            </div>
            {(view_id || custom_component_id) && (
              <div
                id={WALKTHROUGH_STEPS_ELEMENTS['designer-layers']}
                className={`${styles.layersWrapper} mt-3 pt-2 pe-0`}
                style={{ position: 'relative' }}
              >
                <div
                  className="border-top"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '10px',
                    cursor: 'ns-resize'
                  }}
                  onMouseDown={(e) => handleMouseDown(e, 'layers')}
                ></div>
                <label style={{ fontWeight: '600', marginLeft: '14px' }}>
                  {t('designer.layers.Layers')}
                </label>
                <div id={LAYERS_ELEMENT_ID} className={`${styles.layersContent} pe-2`} tabIndex={0}>
                  <ErrorBoundary
                    key={'LAYERS'}
                    FallbackComponent={({ error, resetErrorBoundary }) => (
                      <GenericFallback
                        error={error}
                        resetErrorBoundary={resetErrorBoundary}
                        title="designer.layers.Layers"
                        size="sm"
                      />
                    )}
                    onError={globalReport}
                  >
                    {renderLayers()}
                  </ErrorBoundary>
                </div>
              </div>
            )}
            {/* Icon to colapse the sidebar */}
          </aside>
        </div>
      ) : (
        <div
          className={`bg-body border-start ${styles.rightToolbarContainer}`}
          style={colapseSidebar ? { width: '0px' } : {}}
        >
          <div
            id="collapseArrowRight"
            className={styles.rightColapseArrow}
            style={colapseSidebar ? { transform: 'rotate(180deg)', left: '-30px' } : {}}
            onClick={handleColapse}
          >
            <Icon iconName="angles-right"></Icon>
          </div>
          <aside className={styles.rightAside} style={colapseSidebar ? { width: '0px' } : {}}>
            <div
              className={`${styles.rightToolbarContent}`}
              style={props.from === Studios.DB_MODELER ? { overflowY: 'auto' } : {}}
            >
              <div className={styles.tabListContainer}>
                <ul className="nav nav-pills m-2">
                  {props.toolbarItems?.map((toolbarItem: ToolbarItem, index: number) => (
                    <li key={index} className={`${styles.liTabItem} nav-item`}>
                      <HelpPopover
                        placement={'top'}
                        helpBoxProps={{
                          title: toolbarItem.name
                        }}
                      >
                        <div
                          id={`${toolbarItem.id.toLowerCase()}Button`}
                          className={
                            selectedToolbar === index
                              ? `text-light bg-primary nav-link ${styles.button} ${styles.buttonActive}`
                              : `text-body-secondary bg-body-secondary nav-link ${styles.button} ${styles.buttonInactive}`
                          }
                          onClick={() => {
                            setSelectedToolbar(index);
                            savingLastStatusRightSideBar(index);
                          }}
                        >
                          <Icon iconName={toolbarItem.icon}></Icon>
                        </div>
                      </HelpPopover>
                    </li>
                  ))}
                </ul>
              </div>

              {toolbars[selectedToolbar]}
            </div>
          </aside>
        </div>
      )}

      <div
        id={
          props.position === SidebarPosition.LEFT ? 'left-sidebar-popover' : 'right-sidebar-popover'
        }
      />
    </>
  );
}

export default memo(Sidebar);
