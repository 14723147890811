import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import Confirmation from 'web_ui/confirmation';
import { FunctionService } from 'modules/logic_builder/services';
import { useParams } from 'react-router-dom';
import useReturnToStudio from 'web_ui/function_editor/hooks/useReturnToStudio';
import LogicBuilderContext from 'modules/logic_builder/store';
import { PopupAlert } from 'web_ui/popup_alert';
import { useSelector } from 'react-redux';
import HelpPopover from 'web_ui/workboard/sidebar/controls/components/Popover';
import { FunctionEditorState } from 'web_ui/function_editor/store/types/function_editor_state';
import { FrontendFunctionsRepo } from 'modules/designer/function_editor/repo';
import CodeEditorModal from 'web_ui/code_editor_modal';
import { CodePreviewType } from 'web_ui/code_editor_modal/editor';
import { AvailableFrameworks } from './function_signatures';
import styles from './styles.module.css';

type HeaderOptionsProps = {
  edit: boolean;
  setEdit: (open: boolean) => void;
  framework?: AvailableFrameworks;
};

function HeaderOptions({ edit, setEdit, framework }: HeaderOptionsProps) {
  const returnToStudio = useReturnToStudio();
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const { fetchFunctions } = useContext(LogicBuilderContext);
  const { view_id, function_id, module_id } = useParams();
  const state = useSelector((state: FunctionEditorState) => state);
  const functionId = state?.editor?.callerId;
  const func = state.functions[functionId];
  const [alertMessage, setAlertMessage] = useState('');
  const [isCustom, setIsCustom] = useState(false);
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);

  async function handleDelete(fncId: string) {
    if (isLoading) return;

    setIsLoading(true);

    if (view_id) {
      //Front-End function
      await FrontendFunctionsRepo.deleteStateFunction(view_id, fncId)
        .then(() => {
          setShowConfirmationDialog(false);
          setIsLoading(false);
          returnToStudio();
        })
        .catch(() => {
          setAlertMessage(t('DelFunctionErr') ?? '');
        });
    } else {
      //Back-End Function
      await FunctionService.deleteFunction(fncId)
        .then(() => {
          fetchFunctions(module_id ?? '');
          returnToStudio();
        })
        .catch(() => {
          setAlertMessage(t('DelFunctionErr') ?? '');
        })
        .finally(() => {
          setIsLoading(false);
          setShowConfirmationDialog(false);
        });
    }
  }

  const closeCustomCode = () => {
    setIsCustom(false);
  };

  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return (
    <div style={{ display: 'flex', gap: '0.2rem' }}>
      {framework === 'spring' && (
        <HelpPopover
          placement={'top'}
          helpBoxProps={{
            title: t('CodePreview')!
          }}
        >
          <div
            id={`customCodeButton`}
            className={`btn btn-md text-body-secondary bg-body-secondary border-0 ${
              isCustom ? 'btn-outline-primary' : 'btn-outline-secondary'
            } ${styles.hoverButton}`}
            style={{ marginRight: '10px' }}
            onClick={() => setIsCustom(!isCustom)}
          >
            <span className={'fa-solid fa-code'} />
          </div>
        </HelpPopover>
      )}
      <HelpPopover
        placement={'top'}
        helpBoxProps={{
          title: t('Delete')!
        }}
      >
        <div
          id={`deleteFunctionButton`}
          className={`btn btn-md text-body-secondary bg-body-secondary border-0 ${styles.hoverButton}`}
          style={{ marginRight: '10px' }}
          onClick={() => setShowConfirmationDialog(true)}
        >
          <span className={'fa fa-solid fa-trash'} />
        </div>
      </HelpPopover>
      <HelpPopover
        placement={'top'}
        helpBoxProps={{
          title: t('Edit')!
        }}
      >
        <div
          id={`editFunctionButton`}
          className={`btn btn-md text-body-secondary bg-body-secondary border-0 ${styles.hoverButton}`}
          onClick={() => setEdit(!edit)}
        >
          <span className={`fa fa-solid fa-pen-to-square }`} />
        </div>
      </HelpPopover>
      <Confirmation
        show={showConfirmationDialog}
        message={`${t('deleteQuotes.function')} (${func?.name ?? ''})`}
        onCancel={() => setShowConfirmationDialog(false)}
        onConfirmation={() => {
          handleDelete(function_id ?? '');
        }}
        onClose={() => setShowConfirmationDialog(false)}
        isLoading={isLoading}
      />
      {alertMessage && (
        <PopupAlert i18nKey={alertMessage} onClose={() => setAlertMessage('')} variant={'danger'} />
      )}
      <CodeEditorModal
        show={isCustom}
        handleClose={closeCustomCode}
        previewType={CodePreviewType.FUNCTION}
        id={functionId}
      />
    </div>
  );
}

export default HeaderOptions;
