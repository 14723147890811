import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from '../../modules/designer/components/navbar/styles.module.css';
import Icon from '../../web_ui/icon';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuthService } from '../../modules/auth/services';
import SessionContext, { initialPreferences } from '../../modules/auth/store';
import { Authorization } from 'modules/auth/session/authorization';
import { SystemRoleAuthorityName } from 'modules/auth/types/auth_types';
import { JobInfo } from 'modules/project/types';
import CodeDownloadList from './code_download_list';
import useWindowDimensions from 'modules/designer/hooks/window';
import { mixpanel } from 'exocode';

type UserMenuProps = {
  fromDashBoard?: boolean;
  showShowWalkthrough?: () => void;
  showShowFeedback?: () => void;
  downloads?: Record<string, JobInfo>;
};

export default function UserProfileDropdown(props: UserMenuProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user, setUser, setLoggedIn, setPreferences, setPageVisits } = useContext(SessionContext);
  const { app_id } = useParams();
  const isMobile = useWindowDimensions().width <= 480;
  const location = useLocation();

  const signOut = async () => {
    await AuthService.logout();
    setUser(null);
    setPageVisits(undefined);
    setLoggedIn(false);
    navigate('/login');
    setPreferences({ ...initialPreferences });
  };

  const handleClickRoadmap = () => {
    mixpanel.track('roadmap', {
      buttonName: t('settings.Roadmap'),
      path: location.pathname,
      pageName: document.title
    });
  };

  return (
    <Dropdown className={`${styles.userDropdownContainer} d-inline`}>
      <Dropdown.Toggle id="usernameToolbar" className={styles.DropdownToggle}>
        <div
          id={'NavbarProfileUser'}
          className={`${props.fromDashBoard ? 'text-body' : 'text-white'} ${
            styles.ExocodeNavbarProfileImage
          }`}
        >
          {user?.username} <Icon iconName="user" padding="4px" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        <Link
          to={`/settings/account`}
          style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
        >
          <Dropdown.Item
            id={'profileButton'}
            style={{ display: 'flex', alignItems: 'center' }}
            as="li"
          >
            <div className="me-2">
              <Icon iconName="user"></Icon>
            </div>{' '}
            {t('settings.Profile')}
          </Dropdown.Item>
        </Link>
        <Link
          to={`/settings/general`}
          style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
        >
          <Dropdown.Item
            id={'settingsButton'}
            style={{ display: 'flex', alignItems: 'center' }}
            as="li"
          >
            <div className="me-2">
              <Icon iconName="gear"></Icon>
            </div>{' '}
            {t('settings.Settings')}
          </Dropdown.Item>
        </Link>
        {!isMobile && (
          <Link
            to={`/organizations`}
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          >
            <Dropdown.Item
              id={'organizationsButton'}
              style={{ display: 'flex', alignItems: 'center' }}
              as="li"
            >
              <div className="me-2">
                <Icon iconName="building"></Icon>
              </div>{' '}
              {t('settings.Organizations')}
            </Dropdown.Item>
          </Link>
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://roadmap.exocoding.com"
          style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          onClick={handleClickRoadmap}
        >
          <Dropdown.Item
            id={'ExocodeNavbarRoadmapButton'}
            style={{ display: 'flex', alignItems: 'center' }}
            as="li"
          >
            <div className="me-2">
              <Icon iconName="map"></Icon>
            </div>{' '}
            {t('settings.Roadmap')}
          </Dropdown.Item>
        </a>

        {props.downloads && (
          <Link
            to={`/app/${app_id}/jobs`}
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          >
            <Dropdown.Item
              id={'JobButton'}
              className="d-flex d-sm-none"
              style={{ display: 'flex', alignItems: 'center' }}
              as="li"
            >
              <div className="d-flex" style={{ position: 'relative' }}>
                <CodeDownloadList downloads={props.downloads} />
                Jobs
              </div>
            </Dropdown.Item>
          </Link>
        )}

        {props.showShowWalkthrough && (
          <Dropdown.Item
            id={'WaltroughButton'}
            onClick={props.showShowWalkthrough}
            className="d-flex d-sm-none"
            style={{ display: 'flex', alignItems: 'center' }}
            as="li"
          >
            <div className="me-2">
              <Icon iconName="question"></Icon>
            </div>{' '}
            Walkthrough
          </Dropdown.Item>
        )}

        {props.showShowFeedback && (
          <Dropdown.Item
            id={'FeedbackButton'}
            className="d-flex d-sm-none"
            onClick={props.showShowFeedback}
            style={{ display: 'flex', alignItems: 'center' }}
            as="li"
          >
            <div className="me-2">
              <Icon iconName="bullhorn"></Icon>
            </div>{' '}
            {t('Feedback')}
          </Dropdown.Item>
        )}

        <Authorization allowedSystemAuthorities={[SystemRoleAuthorityName.CONTACT_SUPPORT]}>
          <Link
            to={`/settings/support`}
            style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
          >
            <Dropdown.Item
              id={'supportButton'}
              style={{ display: 'flex', alignItems: 'center' }}
              as="li"
            >
              <div className="me-2">
                <Icon iconName="headset"></Icon>
              </div>{' '}
              {t('Support')}
            </Dropdown.Item>
          </Link>
        </Authorization>
        <Dropdown.Divider />
        <Dropdown.Item
          id={'signOutButton'}
          onClick={() => signOut()}
          style={{ display: 'flex', alignItems: 'center' }}
          as="li"
        >
          <div className="me-2">
            <Icon iconName="arrow-right-from-bracket"></Icon>
          </div>{' '}
          {t('session.SignOut')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
