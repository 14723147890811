import React from 'react';
import styles from './styles.module.css';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { changeReturnType } from '../../../../store/events/functions/change_return_type';
import { FunctionEditorState } from '../../../../store/types/function_editor_state';
import { ReturnType } from '../../../../store/types/functions';
import DataTypePicker from '../../data_type_picker';
import { useTranslation } from 'react-i18next';

type ReturnTypeEditorProps = {
  types: ReturnType[];
};

function ReturnTypeEditor({ types }: ReturnTypeEditorProps) {
  const functionId = useSelector((state: FunctionEditorState) => state.editor.callerId);
  const returnType = useSelector(
    (state: FunctionEditorState) => state.functions[functionId].returnType
  );
  const dispatch = useDispatch();
  const mode = useSelector((state: FunctionEditorState) => state.editor.mode);
  const { t } = useTranslation();

  const handleChangeType = (type: ReturnType) => {
    dispatch(
      changeReturnType(
        functionId,
        type.type,
        type.objectUuid ?? null,
        type.enumUuid ?? null,
        type?.list ?? false
      )
    );
  };

  const handleChangeList = (checked: boolean) => {
    dispatch(
      changeReturnType(
        functionId,
        returnType.type,
        returnType.objectUuid ?? '',
        returnType.enumUuid ?? '',
        checked
      )
    );
  };

  if (mode === 'ENDPOINT_EDITOR') {
    return <></>;
  }

  return (
    <div className={styles.ReturnType}>
      <DataTypePicker type={returnType} types={types} handleChange={handleChangeType} />
      <div className={styles.ReturnTypeListCheck}>
        <Form.Label style={{ marginBottom: '0' }}>{t('automation.step3.List')}</Form.Label>
        <Form.Check
          checked={returnType.list}
          onChange={(e) => handleChangeList(e.target.checked)}
        />
      </div>
    </div>
  );
}

export default ReturnTypeEditor;
