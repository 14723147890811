import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataType, InputType, ParameterEndpoint } from '../../../../modules/logic_builder/types';

const initialState: ParameterEndpoint[] = [];

export type UpdateEndpointParametersOrderPayload = {
  id: string;
  // Map of parameterId -> new order.
  parameters: Record<string, number>;
};

// This will be batched (multiple payloads can be sent to the backend at once).
export type AddEndpointParameterPayload = {
  id: string; // Endpoint id.
  parameter: ParameterEndpoint;
};

export type ParameterInputTypePayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
  newInputType: InputType;
  objectId?: string;
  enumId?: string;
};

export type UpdateParameterNamePayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
  newName: string;
  isValid: boolean;
};

export type UpdateParameterBooleanPropertiesPayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
  isList?: boolean;
};

export type UpdateParameterTypePayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
  newType: DataType;
  newEnumId?: string;
  newObjectId?: string;
};

export type UpdateParameterInputNamePayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
  newInputName: string;
  isValid: boolean;
};

export type DeleteParameterPayload = {
  id: string; // Endpoint id.
  endpointParameterId: string;
};

export type UpdateDataTypeItemPayload = {
  id: string; // Endpoint id.
  dataType: DataType;
  endpointParameterId: string;
  value: string;
};

export const endpointParametersReducer = createSlice({
  name: 'state.endpointParameters',
  initialState,
  reducers: {
    updateEndpointParametersOrder: (
      state,
      action: PayloadAction<UpdateEndpointParametersOrderPayload>
    ) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (action.payload.parameters[parameter.uuid] != null) {
          parameter.order = action.payload.parameters[parameter.uuid];
        }
      });
    },
    updateEndpointParameterInputType: (state, action: PayloadAction<ParameterInputTypePayload>) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          // If input type changes to BODY, then set the data type to OBJECT.
          // The ui disables the data type input if there are no objects.
          if (action.payload.newInputType === 'BODY') {
            parameter.type = 'OBJECT';
            parameter.enumId = undefined;
            parameter.objectUuid = action.payload.objectId;
          } else {
            // Check if previous input type was BODY, if it was then reset the data type input.
            if (parameter.inputType === 'BODY') {
              parameter.type = 'STRING';
              parameter.objectUuid = undefined;
              parameter.enumId = undefined;
            }
          }
          // Reset input name if new input type is BODY or NONE.
          if (action.payload.newInputType === 'BODY' || action.payload.newInputType === 'NONE') {
            parameter.inputName = '';
          }
          parameter.inputType = action.payload.newInputType;
        }
      });
    },
    updateEndpointParameterName: (state, action: PayloadAction<UpdateParameterNamePayload>) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          parameter.name = action.payload.newName;
        }
      });
    },
    addEndpointParameter: (state, action: PayloadAction<AddEndpointParameterPayload>) => {
      state.push(action.payload.parameter);
    },
    updateEndpointParameterBooleanProperties: (
      state,
      action: PayloadAction<UpdateParameterBooleanPropertiesPayload>
    ) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          if (action.payload.isList != null) {
            parameter.list = action.payload.isList;
          }
        }
      });
    },
    updateEndpointParameterType: (state, action: PayloadAction<UpdateParameterTypePayload>) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          // If new data type is an enum, then set the enum id.
          if (action.payload.newType === 'ENUM') {
            parameter.enumId = action.payload.newEnumId;
          } else {
            parameter.enumId = undefined;
          }
          // If new data type is an object, then set the object id.
          if (action.payload.newType === 'OBJECT') {
            parameter.objectUuid = action.payload.newObjectId;
          } else {
            parameter.objectUuid = undefined;
          }
          // Set the new data type.
          parameter.type = action.payload.newType;
        }
      });
    },
    updateEndpointParameterInputName: (
      state,
      action: PayloadAction<UpdateParameterInputNamePayload>
    ) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          parameter.inputName = action.payload.newInputName;
        }
      });
    },
    deleteEndpointParameter: (state, action: PayloadAction<DeleteParameterPayload>) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          state.splice(state.indexOf(parameter), 1);
        }
      });
    },
    updateEndpointParameterDataTypeItem: (
      state,
      action: PayloadAction<UpdateDataTypeItemPayload>
    ) => {
      state.forEach((parameter) => {
        if (!parameter.uuid) return;

        if (parameter.uuid === action.payload.endpointParameterId) {
          // If data type is an enum, then set the new enum id.
          if (action.payload.dataType === 'ENUM') {
            parameter.type = 'ENUM';
            parameter.enumId = action.payload.value;
          } else {
            parameter.enumId = undefined;
          }
          // If data type is an object, then set the new object id.
          if (action.payload.dataType === 'OBJECT') {
            parameter.type = 'OBJECT';
            parameter.objectUuid = action.payload.value;
          } else {
            parameter.objectUuid = undefined;
          }
        }
      });
    }
  }
});

export const {
  updateEndpointParametersOrder,
  addEndpointParameter,
  updateEndpointParameterInputType,
  updateEndpointParameterName,
  updateEndpointParameterBooleanProperties,
  updateEndpointParameterType,
  updateEndpointParameterInputName,
  deleteEndpointParameter,
  updateEndpointParameterDataTypeItem
} = endpointParametersReducer.actions;
