import { Action } from 'redux';
import { ActionsState, FunctionActionType } from '../../types/actions';
import { EditorMode } from '../../types/function_editor_state';

export const CREATE_ELSE = 'CREATE_ELSE';

export interface CreateElseAction extends Action {
  type: 'CREATE_ELSE';
  payload: {
    functionId: string;
    ifBeginAction: string;
    elseBeginAction: FunctionActionType;
    elseBodyAction: FunctionActionType;
    elseEndAction: FunctionActionType;
    mode: EditorMode;
  };
}

export const createElse = (
  functionId: string,
  ifBeginAction: string,
  elseBeginAction: FunctionActionType,
  elseBodyAction: FunctionActionType,
  elseEndAction: FunctionActionType,
  mode: EditorMode
): CreateElseAction => ({
  type: CREATE_ELSE,
  payload: {
    functionId,
    ifBeginAction,
    elseBeginAction,
    elseBodyAction,
    elseEndAction,
    mode
  }
});

export function doCreateElse(state: ActionsState, action: CreateElseAction): ActionsState {
  const { functionId, ifBeginAction, elseBeginAction, elseBodyAction, elseEndAction } =
    action.payload;

  // verifying if the begin if exist
  const beginIf = Object.values(state).find((item) => item.uuid === ifBeginAction);

  if (!beginIf) return state;

  // update begindata
  beginIf.data.else.openBlockActionUuid = elseBeginAction.uuid;
  beginIf.data.else.closeBlockActionUuid = elseEndAction.uuid;
  state[beginIf.uuid] = beginIf;

  // creating the new ones
  state[elseBeginAction.uuid] = elseBeginAction;
  state[elseBodyAction.uuid] = elseBodyAction;
  state[elseEndAction.uuid] = elseEndAction;

  return state;
}
