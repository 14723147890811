import * as React from 'react';
import style from './styles.module.css';

export default function AnimatedLoading() {
  return (
    <div className={style.mainContainer}>
      <div style={{ width: '200px' }}>
        <img src="/assets/cubeExocode.png" className={style.growing} />
        <p style={{ marginLeft: '1rem', fontSize: '19px' }}>
          Loading<span className={style.ellipsis}>...</span>
        </p>
      </div>
    </div>
  );
}
