import { CrudBodyType, FunctionParameterCrud } from 'modules/logic_builder/types';

export enum CrudType {
  CREATE_ONE = 'CREATE_ONE',
  READ_ONE = 'READ_ONE',
  UPDATE_ONE = 'UPDATE_ONE',
  DELETE_ONE = 'DELETE_ONE',
  CREATE_MANY = 'CREATE_MANY',
  READ_MANY = 'READ_MANY',
  UPDATE_MANY = 'UPDATE_MANY',
  DELETE_MANY = 'DELETE_MANY',
  DELETE_MANY_ID = 'DELETE_MANY_ID'
}

export type CallerInfo = {
  uuid: string;
  name: string;
  actions: string[];
  modificationTime: Date;
  creationTime: Date;
  modifiedByUser: string;
  createdByUser: string;
};

export interface FunctionType {
  uuid: string; // ?
  id?: string; // ?
  name: string;
  desc: string;
  returnType: ReturnType;
  actions: string[];
  parameters: string[];
  options: FunctionOptions;
  crudType: string;
  serviceName?: string;
  moduleName?: string;
  modificationTime: Date;
  modifiedByUser: string;
  creationTime: Date;
  createdByUser: string;
}

export interface FrontendFunctionType {
  customComponentId: string;
  description: string;
  name: string;
  object: string;
  parameters: FrontendFunctionParameters[];
  returnList: boolean;
  returnType: ReturnType;
  uuid: string;
  view: string;
}

export interface FrontendFunctionParameters {
  dbEnum: string;
  description: string;
  lgObject: string;
  list: boolean;
  name: string;
  order: number;
  required: boolean;
  type: string;
  uuid: string;
}

export interface ReturnType {
  type: string;
  objectUuid?: string;
  enumUuid?: string;
  name?: string;
  list?: boolean;
}

export interface FunctionsState {
  [key: string]: FunctionType;
}

export type FunctionOptions = BackendFunctionOptions;

export interface BackendFunctionOptions {
  pagination: boolean;
  sortable: boolean;
  filterable: boolean;
}

export const FORBIDDEN_FUNCTION_NAMES = ['function'];

export interface functionEditorCrud {
  name: string;
  description: string;
  returnList: boolean;
  returnType: string;
  functionDefinition: string;
  returnEnumUuid: string;
  crudType: string;
  pageable: boolean;
  sortable: boolean;
  filterable: boolean;
  parameters: FunctionParameterCrud[];
  crudObjectId: string;
  crudBodyType: CrudBodyType;
  uuid: string;
  serviceUuid: string;
  serviceName: string;
  returnObjectUuid: string;
  isNew: boolean;
}

interface Service {
  name?: string;
  description?: string;
  uuid: string;
  entityUuid?: string;
}

export interface StructToSaveFunctionCrud {
  service: Service | null;
  functions: functionEditorCrud[];
}

export interface StructToSaveFunctionResponse {
  service: Service | null;
  functions: functionEditorCrud[];
}
