export const HttpStatusCodes: Record<string, { code: number; message: string }> = {
  // 1xx: Informational Responses
  CONTINUE: { code: 100, message: 'Continue' },
  SWITCHING_PROTOCOLS: { code: 101, message: 'Switching Protocols' },
  PROCESSING: { code: 102, message: 'Processing' },

  // 2xx: Success
  OK: { code: 200, message: 'OK' },
  CREATED: { code: 201, message: 'Created' },
  ACCEPTED: { code: 202, message: 'Accepted' },
  NON_AUTHORITATIVE_INFORMATION: { code: 203, message: 'Non-Authoritative Information' },
  NO_CONTENT: { code: 204, message: 'No Content' },
  RESET_CONTENT: { code: 205, message: 'Reset Content' },
  PARTIAL_CONTENT: { code: 206, message: 'Partial Content' },

  // 3xx: Redirection
  MULTIPLE_CHOICES: { code: 300, message: 'Multiple Choices' },
  MOVED_PERMANENTLY: { code: 301, message: 'Moved Permanently' },
  FOUND: { code: 302, message: 'Found' },
  SEE_OTHER: { code: 303, message: 'See Other' },
  NOT_MODIFIED: { code: 304, message: 'Not Modified' },
  TEMPORARY_REDIRECT: { code: 307, message: 'Temporary Redirect' },
  PERMANENT_REDIRECT: { code: 308, message: 'Permanent Redirect' },

  // 4xx: Client Errors
  BAD_REQUEST: { code: 400, message: 'Bad Request' },
  UNAUTHORIZED: { code: 401, message: 'Unauthorized' },
  FORBIDDEN: { code: 403, message: 'Forbidden' },
  NOT_FOUND: { code: 404, message: 'Not Found' },
  METHOD_NOT_ALLOWED: { code: 405, message: 'Method Not Allowed' },
  NOT_ACCEPTABLE: { code: 406, message: 'Not Acceptable' },
  REQUEST_TIMEOUT: { code: 408, message: 'Request Timeout' },
  CONFLICT: { code: 409, message: 'Conflict' },
  GONE: { code: 410, message: 'Gone' },
  PAYLOAD_TOO_LARGE: { code: 413, message: 'Payload Too Large' },
  UNSUPPORTED_MEDIA_TYPE: { code: 415, message: 'Unsupported Media Type' },
  UNPROCESSABLE_ENTITY: { code: 422, message: 'Unprocessable Entity' },
  TOO_MANY_REQUESTS: { code: 429, message: 'Too Many Requests' },

  // 5xx: Server Errors
  INTERNAL_SERVER_ERROR: { code: 500, message: 'Internal Server Error' },
  NOT_IMPLEMENTED: { code: 501, message: 'Not Implemented' },
  BAD_GATEWAY: { code: 502, message: 'Bad Gateway' },
  SERVICE_UNAVAILABLE: { code: 503, message: 'Service Unavailable' },
  GATEWAY_TIMEOUT: { code: 504, message: 'Gateway Timeout' },
  HTTP_VERSION_NOT_SUPPORTED: { code: 505, message: 'HTTP Version Not Supported' }
} as const;

export type HttpStatusCode = keyof typeof HttpStatusCodes;
