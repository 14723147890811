import { BACKEND_ACTIONS_TYPES } from './actions';
import {
  AddListData,
  AddListDataInterface,
  ApiResponseData,
  ApiResponseDataInterface,
  BeginCycleData,
  BeginCycleDataInterface,
  BeginElseData,
  BeginElseDataInterface,
  BeginIfData,
  BeginIfDataInterface,
  ClearListData,
  ClearListDataInterface,
  CommentData,
  CommentDataInterface,
  CreateEntityData,
  CreateEntityDataInterface,
  CustomCodeData,
  CustomCodeDataInterface,
  CustomQueryData,
  CustomQueryDataInterface,
  DeclareVariableData,
  DeclareVariableDataInterface,
  DeleteEntityData,
  DeleteEntityDataInterface,
  EmptyData,
  EmptyDataInterface,
  EndCycleData,
  EndCycleDataInterface,
  EndElseData,
  EndElseDataInterface,
  EndIfData,
  EndIfDataInterface,
  ReadEntityData,
  ReadEntityDataInterface,
  RemoveListData,
  RemoveListDataInterface,
  ReturnTypeData,
  ReturnTypeDataInterface,
  RunFunctionData,
  RunFunctionDataInterface,
  SetVariableData,
  SetVariableDataInterface,
  UpdateEntityData,
  UpdateEntityDataInterface
} from './action_data';

type BackendActionsDataInterfaces =
  | CreateEntityDataInterface
  | ReadEntityDataInterface
  | UpdateEntityDataInterface
  | DeleteEntityDataInterface
  | CustomQueryDataInterface
  | AddListDataInterface
  | RemoveListDataInterface
  | ClearListDataInterface
  | DeclareVariableDataInterface
  | SetVariableDataInterface
  | BeginCycleDataInterface
  | BeginIfDataInterface
  | EndIfDataInterface
  | BeginElseDataInterface
  | EndElseDataInterface
  | EndCycleDataInterface
  | RunFunctionDataInterface
  | CustomCodeDataInterface
  | ReturnTypeDataInterface
  | EmptyDataInterface
  | CommentDataInterface
  | ApiResponseDataInterface;

export type BackendActionsDataType = {
  [key in BACKEND_ACTIONS_TYPES]: BackendActionsDataInterfaces;
};

export const BackendActionsData: BackendActionsDataType = {
  [BACKEND_ACTIONS_TYPES.CREATE_ENTITY]: CreateEntityData,
  [BACKEND_ACTIONS_TYPES.READ_ENTITY]: ReadEntityData,
  [BACKEND_ACTIONS_TYPES.UPDATE_ENTITY]: UpdateEntityData,
  [BACKEND_ACTIONS_TYPES.DELETE_ENTITY]: DeleteEntityData,
  [BACKEND_ACTIONS_TYPES.CUSTOM_QUERY]: CustomQueryData,
  [BACKEND_ACTIONS_TYPES.ADD_LIST]: AddListData,
  [BACKEND_ACTIONS_TYPES.CLEAR_LIST]: ClearListData,
  [BACKEND_ACTIONS_TYPES.REMOVE_LIST]: RemoveListData,
  [BACKEND_ACTIONS_TYPES.DECLARE_VARIABLE]: DeclareVariableData,
  [BACKEND_ACTIONS_TYPES.SET_VARIABLE]: SetVariableData,
  [BACKEND_ACTIONS_TYPES.BEGIN_CYCLE]: BeginCycleData,
  [BACKEND_ACTIONS_TYPES.BEGIN_IF]: BeginIfData,
  [BACKEND_ACTIONS_TYPES.END_IF]: EndIfData,
  [BACKEND_ACTIONS_TYPES.BEGIN_ELSE]: BeginElseData,
  [BACKEND_ACTIONS_TYPES.END_ELSE]: EndElseData,
  [BACKEND_ACTIONS_TYPES.END_CYCLE]: EndCycleData,
  [BACKEND_ACTIONS_TYPES.RUN_FUNCTION]: RunFunctionData,
  [BACKEND_ACTIONS_TYPES.CUSTOM_CODE]: CustomCodeData,
  [BACKEND_ACTIONS_TYPES.RETURN_TYPE]: ReturnTypeData,
  [BACKEND_ACTIONS_TYPES.EMPTY]: EmptyData,
  [BACKEND_ACTIONS_TYPES.COMMENT]: CommentData,
  [BACKEND_ACTIONS_TYPES.API_RESPONSE]: ApiResponseData
};
