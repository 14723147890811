import React, { useCallback, useContext, useEffect, useState } from 'react';

import InterfaceStudio from 'modules/designer/studio';
import { PageService, ViewStateService } from 'modules/designer/services';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from 'modules/designer/types';
import { DesignerMode, InterfaceStudioState, initialState } from 'modules/designer/studio/store';
import AnimatedLoading from 'web_ui/animated_loading';
import { LocalStorageManager } from 'utils/localstorage';
import { useQuery } from 'hooks/useQuery';
import { AppContext } from 'modules/project/store/app_context';

export type InterfaceStudioProps = {
  toggleTheme(): void;
};

export type PreviousView = {
  app: string;
  module: string;
  viewType: string;
  view: string;
};

function InterfaceStudioPage(props: InterfaceStudioProps) {
  const { app_id, module_id, view_id, custom_component_id } = useParams();
  const queryParameters = useQuery();
  const [state, setState] = useState<InterfaceStudioState>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const appInfo = useContext(AppContext).projectInformation;

  useEffect(() => {
    if (appInfo && !appInfo.has_frontend) navigate('/homepage');
  }, [appInfo]);

  const getValidPreviousView = useCallback((): PreviousView | null => {
    const previousViewRaw = localStorage.getItem('previousView') || '';

    if (previousViewRaw) {
      const previousView: PreviousView = JSON.parse(previousViewRaw);
      if (previousView.app === app_id && previousView.module === module_id) {
        return previousView;
      }
    }

    localStorage.removeItem('previousView');
    return null;
  }, [app_id, module_id]);

  /* Try fetch view state for 'viewId'.
     Navigate to root '.../ui' and clean localStorage if view doesn't exist anymore. */
  const tryFetchViewState = useCallback(
    async (viewId: string) => {
      await ViewStateService.getViewState(viewId)
        .then((viewState) => {
          setState(viewState as InterfaceStudioState);
          const pv: PreviousView = {
            app: app_id || '',
            module: module_id || '',
            viewType: 'VIEW',
            view: viewId
          };
          localStorage.setItem('previousView', JSON.stringify(pv));
        })
        .catch(() => {
          setState(initialState as InterfaceStudioState);
          localStorage.removeItem('previousView');
          navigate(
            `/app/${app_id}/module/${module_id}/ui${isFromVsCodeExt() ? '?vscode=true' : ''}`,
            {
              replace: true
            }
          );
        })
        .finally(() => setLoading(false));
    },
    [app_id, module_id, navigate]
  );

  const tryFetchCustomComponentState = useCallback(
    async (customComponentId: string) => {
      try {
        const customComponentState = await ViewStateService.getCustomComponentState(
          customComponentId
        );
        if (customComponentState.components && customComponentState.links) {
          const data = {
            name: customComponentState.name,
            description: customComponentState.description,
            isHierarchy: customComponentState.isHierarchy
          };

          customComponentState.components[customComponentId] = {
            uuid: customComponentId,
            custom_uuid: customComponentId,
            type: 'CUSTOM',
            data: data
          };

          const state = {
            widgets: customComponentState.widgets,
            functions: customComponentState.functions,
            variables: customComponentState.variables,
            theme: customComponentState.theme,
            properties: customComponentState.properties,
            links: customComponentState.links,
            components: customComponentState.components,
            objects: customComponentState.objects,
            globalCssClasses: customComponentState.globalCssClasses
          };

          const pv: PreviousView = {
            app: app_id || '',
            module: module_id || '',
            viewType: 'CUSTOM_COMPONENT',
            view: customComponentId
          };
          localStorage.setItem('previousView', JSON.stringify(pv));
          setState(state as InterfaceStudioState);
        }
      } catch {
        console.error('Tried to fetch a nonexistent custom component.');
        localStorage.removeItem('previousView');
        navigate(
          `/app/${app_id}/module/${module_id}/ui${isFromVsCodeExt() ? '?vscode=true' : ''}`,
          {
            replace: true
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [app_id, module_id, navigate]
  );

  const redirectToFirstPage = useCallback(async () => {
    if (!module_id) return;

    if (custom_component_id) {
      navigate(
        `/app/${app_id}/module/${module_id}/ui/custom_component/${custom_component_id}${
          isFromVsCodeExt() ? '?vscode=true' : ''
        }`,
        {
          replace: true
        }
      );
    } else {
      // const modals = await ModalService.getModalByModule(module_id);
      // const layouts = await LayoutService.getLayoutsByModule(module_id);
      // TODO: useViews does not contain the updated views yet. That is why we fetch again.
      //       Needs to be refactored in case there are no pages or...
      // This is a temporary fix.
      const pages = await PageService.getPagesByModule(module_id);
      if (pages.length > 0) {
        navigate(
          `/app/${app_id}/module/${module_id}/ui/${pages[0].uuid}${
            isFromVsCodeExt() ? '?vscode=true' : ''
          }`,
          { replace: true }
        );
      } else {
        setState(initialState);
        setLoading(false);
      }
    }
  }, [app_id, custom_component_id, module_id, navigate]);

  const isFromVsCodeExt = (): boolean => {
    const itemFound = queryParameters.get('vscode');
    if (itemFound) {
      return Boolean(itemFound);
    } else {
      return false;
    }
  };

  useEffect(() => {
    const previousView = getValidPreviousView();

    if (previousView && !view_id && !custom_component_id) {
      if (previousView.viewType === 'CUSTOM_COMPONENT') {
        navigate(
          `/app/${app_id}/module/${module_id}/ui/custom_component/${previousView.view}${
            isFromVsCodeExt() ? '?vscode=true' : ''
          }`,
          {
            replace: true
          }
        );
      } else if (previousView.viewType === 'VIEW') {
        navigate(
          `/app/${app_id}/module/${module_id}/ui/${previousView.view}${
            isFromVsCodeExt() ? '?vscode=true' : ''
          }`,
          { replace: true }
        );
      }
    } else {
      if (!view_id && !custom_component_id) {
        redirectToFirstPage();
      } else if (custom_component_id) {
        tryFetchCustomComponentState(custom_component_id);
      } else if (view_id) {
        tryFetchViewState(view_id);
      }
    }
  }, [
    module_id,
    view_id,
    custom_component_id,
    tryFetchViewState,
    getValidPreviousView,
    tryFetchCustomComponentState,
    navigate,
    app_id,
    redirectToFirstPage
  ]);

  const getEditor = useCallback((): keyof typeof Editor => {
    if (custom_component_id) {
      return Editor.CUSTOM_COMPONENT;
    } else if (view_id && state && state.views && state.views[view_id]) {
      return state.views[view_id].type as keyof typeof Editor;
    } else {
      return Editor.PAGE;
    }
  }, [custom_component_id, state, view_id]);

  const getLastDesignerTab = useCallback((appId: string, moduleId: string): DesignerMode => {
    const valueFromStorage = LocalStorageManager.getValueLocalStorageState(appId, moduleId);
    if (valueFromStorage[moduleId] && valueFromStorage[moduleId].interface.lastSelectedTab) {
      return valueFromStorage[moduleId].interface.lastSelectedTab as DesignerMode;
    } else {
      return 'DESIGN';
    }
  }, []);

  return (
    <>
      {loading && <AnimatedLoading />}
      {app_id && module_id && state && !loading && (
        <>
          <InterfaceStudio
            editor={getEditor()}
            module_id={module_id}
            view_id={view_id || custom_component_id || ''}
            toggleTheme={props.toggleTheme}
            state={state}
            initialTab={getLastDesignerTab(app_id, module_id)}
          />
        </>
      )}
    </>
  );
}

export default InterfaceStudioPage;
