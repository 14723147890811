import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { EndpointEditorContext } from '../..';
import { useEndpointEditorDispatch, useEndpointEditorSelector } from 'hooks/reduxHooks';
import { updateEndpointTags } from '../../reducers/endpointInfo';
import { EndpointEditorState } from '../../reducers';
import SelectTagDialog from 'modules/logic_builder/components/dialogs/endpoint_editor_dialog/components/select_tag_dialog';
import { TagItem } from 'modules/logic_builder/components/dialogs/endpoint_editor_dialog/tag_item';
import styles from './styles.module.css';

type EndpointTagsProps = {
  className?: string;
};

export function EndpointTags(props: EndpointTagsProps) {
  const { className } = props;
  const { t } = useTranslation();
  const [openSelectTagModal, setOpenSelectTagModal] = React.useState<boolean>(false);
  const tagsInfo = useContext(EndpointEditorContext).tags;
  const { uuid, tags } = useEndpointEditorSelector((state: EndpointEditorState) => state.endpoint);
  const dispatch = useEndpointEditorDispatch();

  const handleOpenSelectTag = () => {
    setOpenSelectTagModal(true);
  };

  const handleCloseSelectTag = () => {
    setOpenSelectTagModal(false);
  };

  const deleteTag = async (deleted: number) => {
    const nextTags: number[] = tags.filter((tId) => deleted !== tId);
    dispatch(updateEndpointTags({ id: uuid, newTags: nextTags }));
  };

  const updateTags = async (newTags: number[]) => {
    dispatch(updateEndpointTags({ id: uuid, newTags }));
  };

  return (
    <div className={`mb-3 ${className}`}>
      <div className={`d-flex align-items-center mb-1 justify-content-between`}>
        <div className="form-label">{t('logicBuilder.Tags')}</div>
        <button
          className={`btn btn-sm btn-outline-primary ${styles.addButton}`}
          onClick={handleOpenSelectTag}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
      <div className={`mb-3 d-flex flex-wrap border rounded p-2 gap-2`}>
        {tags.map((tagId) => {
          const tag = tagsInfo.find((t) => t.id === tagId);
          if (!tag) return null;
          return (
            <TagItem
              tag={tag}
              key={tag.id}
              onDelete={(id) => {
                deleteTag(id);
              }}
            />
          );
        })}
      </div>
      {openSelectTagModal && (
        <SelectTagDialog
          open={openSelectTagModal}
          onHide={handleCloseSelectTag}
          allTags={tagsInfo}
          updateTags={updateTags}
          selectedTags={tags}
        />
      )}
    </div>
  );
}
