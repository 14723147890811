import { InterfaceStudioState, LinksState } from 'modules/designer/studio/store';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './styles.module.css';
import {
  setHoveredComponent,
  setSelectedComponent
} from 'modules/designer/studio/store/actions/studio';
import Icon from 'web_ui/icon';
import { useParams } from 'react-router-dom';
import ComponentMenu from '../../../components/component_context_menu/ComponentMenu';
import { MoveSource, MoveTarget } from '../../../store/actions/links';
import { COMPONENTS_MANIFEST } from '../../../exocode_components';
import { useCheckShowGrid } from 'modules/designer/hooks/useCheckIsGridColumn';
import { moveComponent } from 'modules/designer/studio/store/actions/root';
import { IS_LOCALHOST } from '../../../../../../constants';

export type RecursiveLayerProps = {
  componentUUID: string;
  isFirstLevel: boolean;
};

function RecursiveLayer(props: RecursiveLayerProps) {
  const [showNested, setShowNested] = useState<any>({});
  const componentRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const { view_id, custom_component_id } = useParams();
  const componentLinks = useSelector((state: InterfaceStudioState) => state.links);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const selectedComponent = useSelector(
    (state: InterfaceStudioState) => state.studio.selectedComponent.uuid
  );
  const [newCompLinks, setNewCompLinks] = useState<LinksState>({});
  const dispatch = useDispatch();
  const checkShowGrid = useCheckShowGrid();

  useEffect(() => {
    setShowNested({ BODY: true });
  }, []);

  useEffect(() => {
    if (selectedComponent) {
      setShowNested({ BODY: true });

      const openParentComponents = (componentUUID: string) => {
        if (!components[componentUUID]) return;
        setShowNested((prev: Record<string, boolean>) => ({
          ...prev,
          [componentUUID]: true
        }));

        const parentComponents = Object.entries(componentLinks).filter(([, children]) => {
          return children.includes(componentUUID);
        });
        parentComponents.forEach(([parentUUID]) => {
          openParentComponents(parentUUID.split('_')[0]);
        });
      };

      openParentComponents(selectedComponent);
    }
  }, [componentLinks, components, selectedComponent]);

  function handleSelectedComponent(componentUUID: string) {
    if (!components[componentUUID]) return;
    dispatch(setSelectedComponent(componentUUID));
  }

  function handleDragStart(
    event: React.DragEvent<HTMLDivElement>,
    parentUUID: string,
    uuid: string,
    type: string
  ) {
    event.stopPropagation();
    if (event.dataTransfer == null) return;
    let deletePastParent = false;
    if (
      parentUUID &&
      components[parentUUID]?.data.autoGenerated &&
      (!componentLinks[parentUUID] || componentLinks[parentUUID]?.length === 1)
    )
      deletePastParent = true;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData(
      'exocode/move-source',
      JSON.stringify({
        parentUUID,
        uuid,
        type,
        deletePastParent
      } as MoveSource)
    );
  }

  const toggleNested = useCallback(
    (key: string) => {
      setShowNested({ ...showNested, [key]: !showNested[key] });
    },
    [showNested]
  );

  function handleDragOver(event: React.DragEvent<HTMLDivElement>, targetUUID: string) {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
    const elemRef = getOrCreateRef(targetUUID);
    if (!elemRef || !elemRef.current) return;

    elemRef.current.style.border = '1px solid #86b7fe';
    elemRef.current.style.boxShadow = '0 0 0 0.25rem rgba(13,110,253,.25)';
  }

  function handleDragLeave(targetUUID: string) {
    const elemRef = getOrCreateRef(targetUUID);
    if (!elemRef || !elemRef.current) return;

    elemRef.current.style.border = '';
    elemRef.current.style.boxShadow = '';
  }

  function handleDrop(
    event: React.DragEvent<HTMLDivElement>,
    targetUUID: string,
    targetParentUUID: string
  ) {
    if (event.dataTransfer == null) return;

    event.preventDefault();
    event.stopPropagation();

    const moveAction = event.dataTransfer.getData('exocode/move-source');
    const source: MoveSource = JSON.parse(moveAction);

    const targetType = components[targetUUID].type;
    const isContainer = COMPONENTS_MANIFEST[targetType].allowDrop;
    const target: MoveTarget = isContainer
      ? { parentUUID: targetUUID }
      : { parentUUID: targetParentUUID, uuid: targetUUID };
    if (targetUUID !== source.uuid) {
      dispatch(moveComponent(source, target));
    }

    const elemRef = getOrCreateRef(targetUUID);
    if (!elemRef || !elemRef.current) return;

    elemRef.current.style.border = '';
    elemRef.current.style.boxShadow = '';
  }

  function getOrCreateRef(componentUUID: string) {
    if (!componentRefs.current[componentUUID]) {
      componentRefs.current[componentUUID] = React.createRef<HTMLDivElement>();
    }
    return componentRefs.current[componentUUID];
  }

  function handleOnHoverOn(componentUUID: string) {
    dispatch(setHoveredComponent(componentUUID));
  }

  function handleOnHoverOff(componentUUID: string) {
    dispatch(setHoveredComponent(null));
  }

  useEffect(() => {
    let hasSections = false;
    const sectionLinks: { [key: string]: string[] } = {};

    Object.entries(componentLinks).forEach(([item, links]) => {
      if (item.includes('_')) {
        const linkIdWithoutSection = item.split('_')[0];
        sectionLinks[linkIdWithoutSection] = [
          ...(sectionLinks[linkIdWithoutSection] || []),
          ...links
        ];
        hasSections = true;
      }
    });

    const newLinks = hasSections ? { ...componentLinks, ...sectionLinks } : componentLinks;
    setNewCompLinks(newLinks);
  }, [components, componentLinks, view_id]);

  return (
    <div style={{ marginLeft: '0.9rem' }}>
      {/* body tag (root level) */}
      {props.isFirstLevel && (
        <div className={` d-flex`}>
          <div style={{ minWidth: '1rem' }} className="pe-1" onClick={() => toggleNested('BODY')}>
            {showNested['BODY'] ? (
              <Icon iconName="angle-down"></Icon>
            ) : (
              <Icon iconName="angle-right"></Icon>
            )}
          </div>
          <div className="pe-2">BODY</div>
        </div>
      )}

      {((props.isFirstLevel && showNested['BODY']) || !props.isFirstLevel) && (
        <div className={`${styles.childrenContainer}`}>
          {newCompLinks[props.componentUUID] &&
            newCompLinks[props.componentUUID].map((childUUID) => {
              const componentRef = getOrCreateRef(childUUID);
              const isGridColumn = checkShowGrid(childUUID);

              if (!components[childUUID]) return null;

              return (
                <div key={childUUID}>
                  <div className="ps-2" key={childUUID}>
                    <div
                      id={`${childUUID}Component`}
                      className={`${styles.layerRow} p-2`}
                      onClick={() => handleSelectedComponent(components[childUUID].uuid)}
                      ref={componentRef}
                      draggable={!isGridColumn && IS_LOCALHOST}
                      onDragStart={(e) =>
                        handleDragStart(
                          e,
                          props.componentUUID ?? view_id ?? custom_component_id,
                          childUUID,
                          components[childUUID].type
                        )
                      }
                      onMouseOver={(ev) => handleOnHoverOn(childUUID)}
                      onMouseLeave={(ev) => handleOnHoverOff(childUUID)}
                      onDragOver={(e) => handleDragOver(e, childUUID)}
                      onDragLeave={(e) => handleDragLeave(childUUID)}
                      onDrop={(e) => handleDrop(e, childUUID, props.componentUUID)}
                    >
                      {/* if it's a container, show arrow icon and make open/close visible */}

                      {newCompLinks &&
                        newCompLinks[childUUID] &&
                        newCompLinks[childUUID].length > 0 &&
                        components[childUUID] && (
                          <div
                            className={`${
                              components[childUUID].uuid === selectedComponent
                                ? 'text-body-emphasis'
                                : null
                            } d-flex justify-content-between`}
                          >
                            <div className="d-flex">
                              <div
                                id={`${components[childUUID].type.toLowerCase()}Collapse`}
                                style={{ minWidth: '1rem' }}
                                className="pe-1"
                                onClick={() => toggleNested(childUUID)}
                              >
                                {showNested[childUUID] ? (
                                  <Icon iconName="angle-down"></Icon>
                                ) : (
                                  <Icon iconName="angle-right"></Icon>
                                )}
                              </div>
                              {IS_LOCALHOST && (
                                <div
                                  className="border-end border-2 pe-1 me-1 text-body-tertiary"
                                  style={{ cursor: 'grab' }}
                                >
                                  {!isGridColumn && <Icon iconName="ellipsis-vertical" />}
                                </div>
                              )}

                              <div
                                id={`${components[childUUID]?.type.toLowerCase()}Component`}
                                className="pe-2"
                              >
                                {isGridColumn ? 'COLUMN' : components[childUUID].type}
                              </div>
                            </div>
                            {IS_LOCALHOST && (
                              <div>
                                <ComponentMenu
                                  elementRef={componentRef}
                                  componentUUID={childUUID}
                                  viewUUID={view_id ?? custom_component_id ?? ''}
                                  type={
                                    components[props.componentUUID] &&
                                    components[props.componentUUID].type
                                  }
                                  renderAsDropdown={true}
                                  parentUUID={props.componentUUID}
                                  offsetX={0}
                                  offsetY={-80}
                                />
                              </div>
                            )}
                          </div>
                        )}

                      {(!newCompLinks[childUUID] || newCompLinks[childUUID].length < 1) &&
                        components[childUUID] && (
                          <div
                            className={`${
                              components[childUUID].uuid === selectedComponent
                                ? 'text-body-emphasis fw-bold'
                                : null
                            } d-flex justify-content-between`}
                          >
                            <div className="d-flex ">
                              {IS_LOCALHOST && (
                                <div
                                  className="border-end border-2 pe-1 me-1 text-body-tertiary"
                                  style={{ cursor: 'grab' }}
                                >
                                  <Icon iconName="ellipsis-vertical" />
                                </div>
                              )}

                              <div className="pe-2">{components[childUUID].type}</div>
                            </div>
                            {IS_LOCALHOST && (
                              <div>
                                <ComponentMenu
                                  elementRef={componentRef}
                                  componentUUID={childUUID}
                                  viewUUID={view_id ?? custom_component_id ?? ''}
                                  type={
                                    components[props.componentUUID] &&
                                    components[props.componentUUID].type
                                  }
                                  renderAsDropdown={true}
                                  parentUUID={props.componentUUID}
                                  offsetX={0}
                                  offsetY={-80}
                                />
                              </div>
                            )}
                          </div>
                        )}

                      {/* if it isn't a container, show only the name */}
                    </div>

                    <div className={`${!showNested[childUUID] ? styles.hiddenChildren : null}`}>
                      <>
                        {newCompLinks[childUUID] && (
                          <RecursiveLayer componentUUID={childUUID} isFirstLevel={false} />
                        )}
                      </>
                    </div>
                  </div>
                  {IS_LOCALHOST && (
                    <ComponentMenu
                      elementRef={componentRef}
                      componentUUID={childUUID}
                      viewUUID={view_id ?? custom_component_id ?? ''}
                      type={components[props.componentUUID] && components[props.componentUUID].type}
                      offsetX={0}
                      offsetY={-80}
                      parentUUID={props.componentUUID}
                    />
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default RecursiveLayer;
