import React from 'react';
import { useEndpointEditorSelector } from '../../../../../hooks/reduxHooks';
import { RootState } from '../../reducers';
import { ParameterEndpoint } from '../../../../../modules/logic_builder/types';
import {
  addEndpointParameter,
  updateEndpointParametersOrder
} from '../../reducers/endpointParameters';
import { ParameterItem } from './parameter_item';
import { DragNDrop } from '../../../../../web_ui/drag_n_drop';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ParameterItemsWrapper } from './parameter_items_wrapper';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { getNewParameter } from '../../actions';
import { useDispatch } from 'react-redux';
import { generateUniqueName } from '../../../../../utils/utils';

export type EndpointParametersProps = {
  endpointId?: string;
};

export function EndpointParameters(props: EndpointParametersProps) {
  const dispatch = useDispatch();
  const parameters = useEndpointEditorSelector((state: RootState) => state.endpointParameters);
  const endpointId = useEndpointEditorSelector((state: RootState) => state.editorStatus.endpointId);
  const { t } = useTranslation();

  const updateOrders = (orderedItems: unknown[]) => {
    const orderedParameters = orderedItems as ParameterEndpoint[];
    // Map of parameterId -> new order.
    const newParametersOrder: Record<string, number> = {};
    orderedParameters.forEach((parameter, index) => {
      if (!parameter.uuid) return;
      newParametersOrder[parameter.uuid] = index;
    });

    dispatch(updateEndpointParametersOrder({ id: endpointId, parameters: newParametersOrder }));
  };

  // Add the property id to the endpoint parameters, and also sort the parameters by order.
  const formattedOrderedParameters = () => {
    const newParameters: (ParameterEndpoint & { id: string; order: number })[] = [];
    parameters.forEach((parameter) => {
      if (!parameter.uuid) return;
      newParameters.push({
        ...parameter,
        id: parameter.uuid,
        order: parameter.order ?? 0
      });
    });

    newParameters.sort((a, b) => {
      return a.order - b.order;
    });

    return newParameters;
  };

  const addNewEndpointParameter = () => {
    const newEndpointParameter = getNewParameter();
    const existingNames = parameters.map((p) => p.name);
    newEndpointParameter.name = generateUniqueName('endpointParameter', existingNames);
    newEndpointParameter.inputName = 'endpointParameter' + parameters.length;
    newEndpointParameter.order = parameters.length;
    dispatch(addEndpointParameter({ id: endpointId, parameter: newEndpointParameter }));
  };

  return (
    <>
      <div className={`form-label`}>{t('logicBuilder.endepoint.EndpointParameters')}</div>
      <DragNDrop
        items={formattedOrderedParameters()}
        updateOrder={updateOrders}
        RenderItem={ParameterItem}
        ItemsWrapper={ParameterItemsWrapper}
        sortingStrategy={verticalListSortingStrategy}
      />
      <div className={`${styles.addButtonWrapper}`}>
        <button
          className={`btn btn-sm btn-outline-primary ${styles.addButton}`}
          onClick={addNewEndpointParameter}
        >
          <i className="fa-solid fa-plus"></i>
        </button>
      </div>
    </>
  );
}
