import React, { forwardRef } from 'react';

import styles from './styles.module.css';
import { ComponentUUID, ViewUUID } from '../../../types';
import { useParams } from 'react-router-dom';
import Viewport from '../../components/viewport';
import { useDispatch } from 'react-redux';
import { setSelectedComponent, setSelectedView } from '../../store/actions/studio';

export type ModalData = {
  title: string;
  size: string;
};

export type ViewStyles = {
  backgroundColor: string;
};

export type ModalViewProps = {
  uuid: ViewUUID;
  data: ModalData;
  layout: ComponentUUID[];
  styles?: ViewStyles;
};

function ModalView(props: ModalViewProps, ref: React.Ref<any>) {
  const { view_id } = useParams();
  const dimensions: { [key: string]: string } = { sm: '30', lg: '60', xl: '70' };
  const dispatch = useDispatch();

  const style = {
    backgroundColor: props.styles?.backgroundColor
  };

  function handleSelectModal() {
    dispatch(setSelectedView(props.uuid));
  }

  return (
    <section
      className={`${styles.modal} ${styles.large} ${
        props.uuid !== view_id ? styles.disabled : ''
      } shadow`}
      style={{
        width: `${
          props.data.size && props.data.size !== '' ? dimensions[props.data.size] + 'vw' : '40vw'
        }`
      }}
    >
      <span onClick={handleSelectModal} style={{ cursor: 'pointer' }}>
        <b>modal:</b>
        <p className="text-body-secondary" style={{ display: 'inline' }}>
          {props.data.title}
        </p>
        <p className="text-body-tertiary" style={{ display: 'inline', fontSize: '0.875rem' }}>
          (click to select modal)
        </p>
      </span>
      <div style={{ height: '100%' }}>
        <Viewport uuid={props.uuid} styles={style} />
      </div>
    </section>
  );
}

export * from './manifest';
export default forwardRef(ModalView);
