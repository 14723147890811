import React, { CSSProperties } from 'react';
import { SortableCssClass } from '.';
import { DragNDrop, DragNDropItem, RenderItemProps } from 'web_ui/drag_n_drop';
import styles from './styles.module.css';
import { ItemsWrapper } from './items_wrapper';
import { rectSortingStrategy } from '@dnd-kit/sortable';

export const inlineFixedStyles: CSSProperties = {
  position: 'relative',
  transformOrigin: '50% 50%',
  height: 'auto',
  width: '155px',
  borderRadius: '20px'
};

export const inlineNameLabel: CSSProperties = {
  width: '140px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  fontSize: 'small',
  fontWeight: 400,
  margin: '2px'
};

interface CssClassesProps {
  classes: SortableCssClass[];
  updateClasses: (
    classes: DragNDropItem[],
    movedId: string,
    oldIndex: number,
    newIndex: number
  ) => void;
  globalClasses: string[];
  removeClass: (id: string) => void;
}

export const CssClasses = ({
  classes,
  updateClasses,
  removeClass,
  globalClasses
}: CssClassesProps) => {
  const RenderItem = ({ id, isDragging }: RenderItemProps): JSX.Element => {
    const className = classes.find((e) => e.id === id);

    if (!className) return <></>;

    const isClassNameExist = globalClasses.includes(className.className);

    const itemStyles = {
      ...inlineFixedStyles,
      display: 'flex',
      justifyContent: 'space-between',
      cursor: isDragging ? 'grabbing' : 'grab',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      color: isClassNameExist ? '#51cf66' : '#fa5252',
      border: isClassNameExist ? '1px solid #51cf66' : '1px solid #fa5252'
    };

    const iconStyles = {
      cursor: 'pointer',
      width: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };

    return (
      <div className={styles.DeleteButton} style={itemStyles}>
        <span style={inlineNameLabel}>{className.className}</span>
        <span
          style={iconStyles}
          onClick={() => removeClass(id)}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <i className="fa fa-times" aria-hidden="true"></i>
        </span>
      </div>
    );
  };

  return (
    <DragNDrop
      items={classes}
      updateOrder={updateClasses}
      RenderItem={RenderItem}
      ItemsWrapper={ItemsWrapper}
      sortingStrategy={rectSortingStrategy}
    />
  );
};
