import { createContext } from 'react';
import { ApiAppInfo } from '../../project/types';
import { UserLimits } from 'modules/auth/types/auth_types';

export type AppContextType = {
  projectInformation?: ApiAppInfo;
  updateProjectInformation: (projectId: string) => Promise<ApiAppInfo | null>;
  setCurrentProjectInformation: (app: ApiAppInfo) => void;
  appOwnerLimits?: UserLimits;
  reloadAppOwnerLimits: () => Promise<void>;
};

const initialState: AppContextType = {
  projectInformation: undefined,
  updateProjectInformation: async () => null,
  setCurrentProjectInformation: () => {},
  appOwnerLimits: undefined,
  reloadAppOwnerLimits: async () => {}
};

export const AppContext = createContext<AppContextType>(initialState);
