import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';

// link translate name property
export const INPUT_PHONE_MANIFEST: ComponentManifest = {
  type: 'INPUTPHONE',
  name: 'Inputphone',
  descriptionId: 'inputPhone',
  description: 'Input field to insert Phone numbers.',
  icon: 'fa-solid fa-phone',
  group: ComponentGroups['INPUT'],
  previewHeight: 70,
  previewWidth: 100,
  properties: [
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Label',
      key: 'label',
      tooltip: 'designer.right_side.controls.components.inputTranslate.label'
    },
    {
      controlType: ControlsTypes.TEXT,
      controlLabel: 'Placeholder',
      key: 'placeholder',
      tooltip: 'designer.right_side.controls.components.inputTranslate.placeholder'
    },
    {
      controlType: ControlsTypes.VISIBLEVARIABLE,
      controlLabel: 'Visible',
      key: 'visible',
      tooltip: 'designer.right_side.controls.components.inputTranslate.visible'
    },
    {
      controlType: ControlsTypes.VARIABLEREADONLY,
      controlLabel: 'ReadonlyVariable',
      key: 'readonlyVar',
      tooltip: 'designer.right_side.controls.components.inputTranslate.readonlyVar'
    },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'Variable',
      key: 'variable'
    },
    {
      controlType: ControlsTypes.TEXTAREA,
      controlLabel: 'Tooltip',
      key: 'tooltip',
      tooltip: 'designer.right_side.controls.components.inputTranslate.tooltip'
    },
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.ACTION_BUTTON,
      controlLabel: 'ActionButton',
      tooltip: 'designer.right_side.controls.components.inputTranslate.actionButton'
    },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Required',
    //   key: 'required'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Readonly',
    //   key: 'readonly'
    // },
    // {
    //   controlType: ControlsTypes.CHECKBOX,
    //   controlLabel: 'Disabled',
    //   key: 'disabled'
    // },
    {
      controlType: ControlsTypes.VARIABLE,
      controlLabel: 'DisabledVariable',
      key: 'disabledVar'
    },
    {
      controlType: ControlsTypes.SELECT_TRANSLATION,
      controlLabel: 'TranslateOption',
      key: 'translateKey',
      tooltip: 'designer.right_side.controls.components.inputTranslate.translation'
    },
    {
      controlType: ControlsTypes.CHECKBOX,
      controlLabel: 'SelectTranslate',
      key: 'hasTranslation',
      tooltip: 'designer.right_side.controls.components.inputTranslate.check'
    }
  ],
  styles: [
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Text Color'),
      tooltip: 'designer.right_side.controls.components.inputTranslate.textColor'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('Bg color'),
      key: 'bgColor',
      tooltip: 'designer.right_side.controls.components.input.themePickerBg'
    },
    {
      controlType: ControlsTypes.COLOR_PICKER,
      controlLabel: t('placeholderColor'),
      key: 'placeholderColor',
      tooltip: 'designer.right_side.controls.components.input.placeholder'
    },
    {
      controlType: ControlsTypes.BORDER_RADIUS,
      controlLabel: t('borderRadius')
    },
    {
      controlType: ControlsTypes.FONT,
      controlLabel: t('Font'),
      tooltip: 'designer.right_side.controls.components.inputTranslate.Font'
    },
    {
      controlType: ControlsTypes.SELECT,
      controlLabel: t('Spacing'),
      key: 'lineHeight',
      controlOptions: ['normal', 'inherit', 'initial', 'revert', 'revert-layer', 'unset'],
      tooltip: 'designer.right_side.controls.components.inputTranslate.lineHeight'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('Font Size'),
      key: 'fontSize',
      tooltip: 'designer.right_side.controls.components.inputTranslate.fontSize'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: 'LineHeight',
      key: 'lineHeight',
      tooltip: 'designer.right_side.controls.components.inputTranslate.lineHeight'
    },
    {
      controlType: ControlsTypes.TEXTALIGN,
      controlLabel: 'Text Align',
      key: 'textAlign',
      tooltip: 'designer.right_side.controls.components.inputTranslate.textAlign'

      // controlOptions: ['left', 'center', 'right']
    },
    {
      controlType: ControlsTypes.TEXTDECORATION,
      controlLabel: 'Text Decoration',
      key: 'textDecoration',
      tooltip: 'designer.right_side.controls.components.inputTranslate.textDecoration'
    },
    {
      controlType: ControlsTypes.TEXTTRANSFORM,
      controlLabel: 'Text Transform',
      key: 'textTransform',
      tooltip: 'designer.right_side.controls.components.inputTranslate.textTransform'
    },
    {
      controlType: ControlsTypes.FONTSTYLE,
      controlLabel: 'Font Style',
      key: 'fontStyle',
      tooltip: 'designer.right_side.controls.components.inputTranslate.fontStyle'
    },
    {
      controlType: ControlsTypes.SPACING,
      controlLabel: t('Spacing')
    },
    {
      controlType: ControlsTypes.BOXSHADOW,
      controlLabel: 'Box shadow',
      key: 'boxshadow',
      tooltip: 'boxshadowTooltip'
    },
    {
      controlType: ControlsTypes.CUSTOM_CSS,
      controlLabel: 'CustomCss',
      key: 'css'
    }
  ],
  events: ['CHANGE']
};
