import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import cronstrue from 'cronstrue';
import { ScheduledsService } from 'modules/logic_builder/services';
import Confirmation from 'web_ui/confirmation';
import { useTranslation } from 'react-i18next';
import { FunctionSimple, SchedulerJob } from '../../../types';
import { useParams } from 'react-router-dom';
import { AppContext } from 'modules/project/store/app_context';
import SessionContext from 'modules/auth/store';
import { fetchFunctionsWithParametersIncluded, validateCronExpression } from './utils';

type ScheduledDialogProps = {
  show: boolean;
  onClose: () => void;
  schedulerJob?: SchedulerJob;
  editMode: boolean;
  dialogTitle: string;
  onSuccess?: (newJob: SchedulerJob) => void;
  onDelete?: (id: string) => void;
};

export function ScheduledDialog({
  show,
  onClose,
  editMode,
  dialogTitle,
  schedulerJob,
  onSuccess,
  onDelete
}: ScheduledDialogProps) {
  const isNative = false;
  const { module_id } = useParams();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [cronExpression, setCronExpression] = useState('');
  const [scheduledName, setScheduledName] = useState('');
  const [scheduledDescription, setScheduledDescription] = useState('');
  const [functionId, setFunctionId] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [expressionFeedback, setExpressionFeedback] = useState<string>('');
  const [functionsList, setFunctionsList] = useState<FunctionSimple[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const appInfo = useContext(AppContext).projectInformation;
  const session = useContext(SessionContext);

  const fetchFunctions = useCallback(async () => {
    if (!module_id) return;
    const functionsWithParameters = await fetchFunctionsWithParametersIncluded(module_id);
    setFunctionsList(functionsWithParameters);
  }, [module_id]);

  useEffect(() => {
    if (!show) {
      return;
    }

    if (editMode && schedulerJob) {
      fetchFunctions();
      setScheduledName(schedulerJob.name);
      setScheduledDescription(schedulerJob.description);
      setFunctionId(schedulerJob.functionUuid);
      setCronExpression(schedulerJob.cronExpression);

      validateInputField(schedulerJob.cronExpression);
    } else {
      fetchFunctions();
      setScheduledName('');
      setScheduledDescription('');
      setFunctionId('');
      setCronExpression('* * * * *');

      const description = cronstrue.toString('* * * * *');
      setExpressionFeedback(description);

      setIsValid(true);
    }
  }, [editMode, fetchFunctions, schedulerJob, show]);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isLoading) return;
    if (!functionId || !module_id) return;

    if (!validateCronExpression(cronExpression)) {
      validateInputField(cronExpression);
      return;
    }

    setIsLoading(true);

    try {
      if (editMode && schedulerJob) {
        const newSchedulerJob: SchedulerJob = {
          ...schedulerJob,
          name: scheduledName,
          description: scheduledDescription,
          cronExpression: cronExpression,
          functionUuid: functionId
        };
        await ScheduledsService.updateScheduled(
          newSchedulerJob,
          module_id,
          newSchedulerJob.uuid ?? ''
        ).then((updated) => {
          onSuccess?.(updated);
          onClose();
        });
      } else {
        const newScheduled: SchedulerJob = {
          moduleUuid: module_id,
          functionUuid: functionId,
          cronExpression: cronExpression,
          name: scheduledName,
          description: scheduledDescription,
          modificationTime: new Date(),
          modifiedByUser: session.user?.name ?? '',
          createdByUser: session.user?.name ?? '',
          creationTime: new Date()
        };
        setIsLoading(true);
        await ScheduledsService.createScheduled(module_id, newScheduled).then((created) => {
          onSuccess?.(created);
          onClose();
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleCronChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCronExpression(event.target.value);
    validateInputField(event.target.value);
  };

  const validateInputField = (expression: string) => {
    const valid = validateCronExpression(expression);
    let expressionFeedback = '';

    if (valid) {
      expressionFeedback = cronstrue.toString(expression);
    }

    setIsValid(valid);
    setExpressionFeedback(expressionFeedback);
  };

  const handleDeleteSchedulerJob = useCallback(async () => {
    if (isLoading) return;
    if (!module_id) return;
    if (!editMode || !schedulerJob || !schedulerJob.uuid) return;

    setIsLoading(true);
    await ScheduledsService.deleteScheduled(schedulerJob.uuid, module_id)
      .then(() => {
        onDelete && onDelete(schedulerJob.uuid ?? '');
        setShowConfirmationDialog(false);
        onClose();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [editMode, isLoading, module_id, onClose, onDelete, schedulerJob]);

  return (
    <>
      <Modal show={show} onHide={onClose} centered>
        <Form onSubmit={onSubmit} id="formModal">
          <Modal.Header closeButton>
            <Modal.Title>{t('logicBuilder.scheduledTab.' + dialogTitle)}</Modal.Title>
          </Modal.Header>
          <Modal.Body id="bodyModal">
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type="text"
                maxLength={64}
                value={scheduledName}
                required
                disabled={isNative}
                onChange={(e) => setScheduledName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>{t('Description')}</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  maxLength={255}
                  value={scheduledDescription}
                  disabled={isNative}
                  onChange={(e) => setScheduledDescription(e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCron">
              <Form.Label>{t('logicBuilder.scheduledTab.CronExpression')}</Form.Label>
              <Form.Control
                type="text"
                maxLength={45}
                value={cronExpression}
                required
                disabled={isNative}
                onChange={handleCronChange}
              />
              {isValid && (
                <div style={{ width: '100%', marginTop: '0.25rem', fontSize: '0.875em' }}>
                  {expressionFeedback}
                </div>
              )}
              {!isValid && (
                <div className={`invalid-feedback d-block`}>
                  {t('logicBuilder.scheduledTab.Invalid')}
                </div>
              )}
            </Form.Group>
            <Form.Group controlId="formFunc">
              <Form.Label>{t('Function')}</Form.Label>
              <Form.Select
                id={'selectFunction'}
                aria-label="object"
                onChange={(e) => setFunctionId(e.target.value)}
                value={functionId}
                required
              >
                <option value={''}>Select function</option>
                {functionsList.map((fn) => {
                  return (
                    <option id={fn.uuid} key={fn.uuid} value={fn.uuid}>
                      {fn.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
            {appInfo?.is_owner_org && schedulerJob && (
              <>
                <hr />
                Group info
                <p style={{ fontSize: 'small' }}>{`Last edited by ${
                  schedulerJob.modifiedByUser
                } at ${new Date(schedulerJob.modificationTime).toLocaleDateString()}`}</p>
                <p style={{ fontSize: 'small' }}>{`Created by ${
                  schedulerJob.createdByUser
                } at ${new Date(schedulerJob.creationTime).toLocaleDateString()}`}</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {editMode && (
              <Button
                id="deleteButton"
                variant="danger"
                onClick={() => setShowConfirmationDialog(true)}
              >
                {t('Delete')}
              </Button>
            )}
            <Button id="cancelButton" variant="secondary" onClick={onClose}>
              {t('Cancel')}
            </Button>
            <Button id="saveButton" variant="primary" type="submit" disabled={isNative}>
              {t('Create')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Confirmation
        show={showConfirmationDialog}
        message={t('deleteQuotes.scheduled')}
        onConfirmation={handleDeleteSchedulerJob}
        onCancel={() => setShowConfirmationDialog(false)}
        onClose={() => setShowConfirmationDialog(false)}
      />
    </>
  );
}
