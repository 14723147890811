import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Nav, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { OrganizationContext } from '../../../organization_page';
import InviteOrganizationModal from 'routes/organizations/components/invite_organization_modal';
import {
  ContextRole,
  Role,
  RoleAuthorities,
  SystemRoleAuthorityName,
  UserProfile
} from 'modules/auth/types/auth_types';
import {
  AdminRole,
  ManagerRole,
  DevRole,
  BillingRole,
  FiltersMembersOptions
} from 'modules/organization/types';
import { OrganizationService } from 'modules/organization/services';
import MembersList from 'modules/organization/components/members_list';
import { AddInvite, ComplexMember } from 'modules/invitation/types';
import { InvitationService } from 'modules/invitation/service';
import { useTranslation } from 'react-i18next';
import { TeamServ } from 'modules/organization/services';
import { Teams } from 'modules/auth/types/auth_types';
import TeamList from './components/team_list';
import NewTeamModal from './components/new_team_modal';

import styles from './styles.module.css';
import Icon from 'web_ui/icon';
import EmptyMessage from 'web_ui/empty';
import UpgradePlanModal from '../../../../../web_ui/upgrade_plan_modal';
import { AccountService } from '../../../../../modules/auth/services';
import { getFeatureLimit } from '../../../../../utils/utils';

type options = 'Collaborators' | 'Pending';

function OrganizationTeams() {
  const [session] = useSession();
  const orgContext = useContext(OrganizationContext);
  const [simpleMembers, setSimpleMembers] = useState<AddInvite[]>([]);
  const [showInviteModal, setShowInviteModal] = React.useState<boolean>(false);
  const [complexMembers, setComplexMembers] = React.useState<ComplexMember[]>([]);
  const [searchText, setSearchText] = useState('');
  const [filterType, setFilterType] = useState<FiltersMembersOptions>(FiltersMembersOptions.NAME);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [allTeams, setAllTeams] = useState<Teams[]>([]);
  const [openNewTeamModal, setOpenNewTeamModal] = useState<boolean>(false);
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { org_id } = useParams();

  const [selectedItem, setSelectedItem] = React.useState<options>();

  const { t } = useTranslation();

  const search = t('Search');
  const filter = t('Filter');

  useEffect(() => {
    loadTeamList();
  }, [updateList]);

  const updatingList = () => {
    setUpdateList((val) => !val);
  };

  const loadTeamList = async () => {
    if (!orgContext.organization) return;
    const teams: Teams[] = await TeamServ.getAllTeams(orgContext.organization.id);
    setAllTeams(teams);
  };

  const closeNewTeamModal = () => {
    setOpenNewTeamModal(false);
  };

  async function handleAddTeam() {
    if (!orgContext.organization) {
      return;
    }
    const user = await AccountService.getLimits(orgContext.organization.owner.id);
    const limit = getFeatureLimit(user, SystemRoleAuthorityName.ADD_TEAM);
    if (!limit) {
      setShowUpgradePlanModal(true);
      return;
    }
    if (user.totals.numberOfTeams < limit) {
      setOpenNewTeamModal(true);
    } else {
      setShowUpgradePlanModal(true);
    }
  }

  return (
    <>
      <Row>
        <Col className={`d-flex align-items-center`}>
          <h4 style={{ fontWeight: '400', margin: 0 }}>{t('organizations.organization.Team')}</h4>
        </Col>
        <Col className={`d-flex justify-content-end`}>
          <Button onClick={handleAddTeam}>{t('organizations.organization.AddTeam')}</Button>
        </Col>
      </Row>
      <div className="pt-3">
        <Card>
          <Card.Body>
            <Row className="d-flex align-items-center p-2">
              <Col className="py-1" md={4}>
                <input
                  id={'searchField'}
                  type={'search'}
                  className="form-control"
                  placeholder={search}
                  onChange={(ev) => setSearchText(ev.target.value)}
                />
              </Col>
            </Row>
            <div className="pt-4">
              {isLoading || isLoadingRoles ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="secondary" />
                </div>
              ) : allTeams && allTeams.length > 0 ? (
                <TeamList
                  teams={allTeams}
                  orgId={orgContext.organization && orgContext.organization.id}
                  updateList={updatingList}
                />
              ) : (
                <div className="w-100 h-100" id="bodyMessage">
                  <EmptyMessage
                    message={t('organizations.organization.noTeams')}
                    icon="exclamation"
                  />
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <NewTeamModal
        show={openNewTeamModal}
        close={closeNewTeamModal}
        orgId={orgContext.organization && orgContext.organization.id}
        updateList={updatingList}
      />
      {showUpgradePlanModal && (
        <UpgradePlanModal setShow={setShowUpgradePlanModal} show={showUpgradePlanModal} />
      )}
    </>
  );
}

export default OrganizationTeams;
