import { ComponentGroups, ComponentManifest } from 'modules/designer/types';
import { ControlsTypes } from 'web_ui/workboard/sidebar/controls';
import { t } from 'i18next';
import { InputValidationOutput } from 'utils/inputValidation';

const validateNumbers = (val: string): InputValidationOutput => {
  const regex = /^\d+$/;

  if (regex.test(val) && val !== '') {
    return {
      code: 'STARTS_WITH',
      valid: true
    };
  }

  return {
    code: 'STARTS_WITH',
    valid: false
  };
};

export const CAROUSEL_MANIFEST: ComponentManifest = {
  type: 'CAROUSEL',
  name: 'Carousel',
  descriptionId: 'CarouselDescription',
  description: 'A carousel for show items in exocode.',
  icon: 'fa-solid fa-ellipsis',
  group: ComponentGroups['CONTAINER'],
  previewHeight: 30,
  previewWidth: 80,
  allowDrop: true,
  properties: [
    {
      controlType: ControlsTypes.SIZE,
      controlLabel: 'Size'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('qtdPages'),
      key: 'qtdPages',
      validation: (input: string) => validateNumbers(input),
      controlOptions: ['noUnit', 'full'],
      tooltip: 'designer.right_side.controls.components.carousel.qtdPages'
    },
    {
      controlType: ControlsTypes.CAROUSEL_SECTIONS,
      controlLabel: t('selectedPage'),
      key: 'selectedPage',
      validation: (input: string) => validateNumbers(input),
      tooltip: 'designer.right_side.controls.components.carousel.selecetedPage'
    },
    {
      controlType: ControlsTypes.NUMBER,
      controlLabel: t('timeToChange'),
      key: 'timeToChange',
      tooltip: 'designer.right_side.controls.components.carousel.timer',
      controlOptions: ['noUnit', 'full']
    }
  ],
  styles: []
};
