import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteComponent, extractForm, LinkInfo } from '../../store/actions/root';
import styles from './styles.module.css';
import {
  ComponentUUID,
  Editor,
  FRONTEND_EVENT_TYPES,
  LayoutComponent,
  Message,
  MessageTypes,
  ViewUUID
} from '../../../types';
import { setSelectedComponent } from '../../store/actions/studio';
import ContextMenu from 'web_ui/workboard/ContextMenu';
import ContextMenuItem from 'web_ui/workboard/ContextMenuItem';
import { useParams } from 'react-router-dom';
import { InterfaceStudioState } from '../../store';
import { ConfirmationInfo, ConvertCustomComponentInfo } from '../viewport';
import { FormInput } from '../../exocode_components/form';
import { COMPONENT_TYPES, COMPONENTS_TEMPLATE } from '../../exocode_components';
import { v4 as uuidv4 } from 'uuid';
import { faker } from '@faker-js/faker';
import produce from 'immer';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import Icon from 'web_ui/icon';
import { useCheckShowGrid } from 'modules/designer/hooks/useCheckIsGridColumn';
import { useDeleteGridColumn } from 'modules/designer/hooks/useDeleteGridColumn';

export type ComponentMenuProps = {
  elementRef?: React.RefObject<any>;
  componentUUID: ComponentUUID;
  viewUUID: ViewUUID;
  parentUUID?: ComponentUUID;
  column?: number;
  type?: string;
  new_custom_uuid?: string;
  custom_uuid?: string;
  offsetX?: number;
  offsetY?: number;
  renderAsDropdown?: boolean;
};

function ComponentMenu(props: ComponentMenuProps) {
  const dispatch = useDispatch();
  const { app_id, module_id } = useParams();
  const editor = useSelector((state: InterfaceStudioState) => state.studio.editor);
  const components = useSelector((state: InterfaceStudioState) => state.components);
  const links = useSelector((state: InterfaceStudioState) => state.links);
  const checkShowGrid = useCheckShowGrid();
  const isGridColumn = checkShowGrid(props?.componentUUID);
  const handleUpdateGridColumnsOnDelete = useDeleteGridColumn();
  const { t } = useTranslation();

  const waitForResult = useCallback(
    (event: MessageEvent<Message>) => {
      if (event.origin !== window.location.origin) return;
      if (event.data.type === MessageTypes.CONFIRMATION_RESULT) {
        window.removeEventListener('message', waitForResult);
        if (event.data.content.result === true) {
          dispatch(setSelectedComponent(null));
          if (props.new_custom_uuid) {
            dispatch(deleteComponent(props.new_custom_uuid));
          }
          if (!props.new_custom_uuid) {
            let isParentEmpty = undefined;
            if (
              props.parentUUID &&
              components[props.parentUUID] &&
              links[props.parentUUID]?.length <= 1 &&
              components[props.parentUUID].data?.autoGenerated
            ) {
              isParentEmpty = true;
            }

            dispatch(
              deleteComponent(
                props.componentUUID,
                isParentEmpty ? props.parentUUID : undefined,
                undefined,
                isParentEmpty
              )
            );
          }

          handleUpdateGridColumns(
            props.new_custom_uuid ? props.new_custom_uuid : props.componentUUID
          );
        }
        return;
      }
    },
    [dispatch, props.componentUUID, props.new_custom_uuid, links, props.parentUUID, components]
  );

  const requestConfirmation = React.useCallback(() => {
    if (editor === 'CUSTOM_COMPONENT') {
      dispatch(deleteComponent(props.componentUUID));
    } else {
      const content: ConfirmationInfo = {
        message: `${t('deleteSelectedComponent')} (${
          isGridColumn ? 'COLUMN' : components[props.componentUUID].type
        })`,
        confirmationLabel: t('Confirm'),
        cancelLabel: t('Cancel')
      };

      const navigateMessage: Message = {
        type: MessageTypes.CONFIRMATION,
        content: content
      };

      window.parent.postMessage(navigateMessage);

      window.addEventListener('message', waitForResult);
    }
  }, [waitForResult, props.parentUUID]);

  const requestConvertCustomModal = React.useCallback(() => {
    const content: ConvertCustomComponentInfo = {
      componentUUID: props.componentUUID
    };

    const navigateMessage: Message = {
      type: MessageTypes.CONVERT_CUSTOM_COMPONENT,
      content: content
    };

    window.parent.postMessage(navigateMessage);
  }, []);

  const goToCustomComponentEditor = React.useCallback(() => {
    const navigateMessage: Message = {
      type: MessageTypes.NAVIGATE,
      content: `/app/${app_id}/module/${module_id}/ui/custom_component/${props.custom_uuid}`
    };

    window.parent.postMessage(navigateMessage);
  }, [app_id, module_id, props.custom_uuid]);

  const extractFormInputs = React.useCallback(() => {
    const form = components[props.componentUUID];
    if (!form.data.content.variable) return;

    const inputs: LayoutComponent[] = [];

    form.data.content.inputs.forEach((input: FormInput) => {
      let component: LayoutComponent;

      switch (input.type) {
        case 'checkbox':
          component = COMPONENTS_TEMPLATE[COMPONENT_TYPES.CHECK];
          break;
        case 'number':
          component = COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTNUMBER];
          break;
        case 'date':
          component = COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUTDATE];
          break;
        case 'text':
          component = COMPONENTS_TEMPLATE[COMPONENT_TYPES.INPUT];
          break;
        case 'select':
          component = COMPONENTS_TEMPLATE[COMPONENT_TYPES.SELECT];
          component.data.options = [];
          break;
        default:
          throw new Error('Missing implementation for input type.');
      }

      component.uuid = uuidv4();
      component.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
      component.data.label = input.label;
      component.data.variable = {
        uuid: form.data.content.variable,
        objectItem: input.objectItemUuid
      };
      inputs.push(component);
    });

    if (form.data.extraText) {
      const extraButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
        draft.uuid = uuidv4();
        draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
        draft.data.text = form.data.extraText;
        draft.styles.themeColor = 'secondary';
        if (form.events && form.events[FRONTEND_EVENT_TYPES.CLICK]) {
          draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.CLICK];
        }
      });
      inputs.push(extraButton);
    }

    const submitButton = produce(COMPONENTS_TEMPLATE[COMPONENT_TYPES.BUTTON], (draft) => {
      draft.uuid = uuidv4();
      draft.data.name = faker.random.words(2).toLowerCase().replace(' ', '_');
      draft.data.text = form.data.submitText;
      draft.styles.themeColor = 'primary';
      if (form.events && form.events[FRONTEND_EVENT_TYPES.SUBMIT]) {
        draft.events[FRONTEND_EVENT_TYPES.CLICK] = form.events[FRONTEND_EVENT_TYPES.SUBMIT];
      }
    });
    inputs.push(submitButton);

    const link: LinkInfo = {
      viewUUID: props.viewUUID,
      parentUUID: props.parentUUID && props.parentUUID !== '' ? props.parentUUID : props.viewUUID
    };
    dispatch(extractForm(props.componentUUID, link, inputs));
  }, [components, dispatch, props.componentUUID, props.parentUUID, props.viewUUID]);

  const handleUpdateGridColumns = (deletedComponentUUID: string) => {
    handleUpdateGridColumnsOnDelete(deletedComponentUUID);
  };

  const checkIfCanDelete = useMemo(() => {
    if (editor === Editor.CUSTOM_COMPONENT) {
      if (props.viewUUID && links[props.viewUUID]?.includes(props.componentUUID)) {
        return false;
      }
    }
    return true;
  }, [editor, props.componentUUID, links, props.viewUUID]);

  return (
    <>
      {checkIfCanDelete &&
        (!props.renderAsDropdown ? (
          <ContextMenu
            elementRef={props.elementRef}
            offsetY={props.offsetY}
            offsetX={props.offsetX}
          >
            {props.new_custom_uuid == null &&
              props.componentUUID &&
              components[props.componentUUID]?.type === 'CONTAINER' && (
                <ContextMenuItem
                  icon="copy"
                  label={t('designer.layers.SaveAsBlock')}
                  onClick={() => requestConvertCustomModal()}
                />
              )}
            {props.new_custom_uuid == null && props.type === 'FORM' && (
              <ContextMenuItem
                icon="explosion"
                label={t('designer.layers.ExtractComponents')}
                onClick={() => extractFormInputs()}
              />
            )}
            {props.new_custom_uuid != null && editor !== Editor.CUSTOM_COMPONENT && (
              <ContextMenuItem
                icon=""
                label={t('designer.layers.EditCustomComponent')}
                onClick={() => goToCustomComponentEditor()}
              />
            )}
            <div className="text-danger pt-1 mt-1 border-top">
              <ContextMenuItem
                icon="trash"
                label={t('designer.layers.Delete')}
                onClick={() => requestConfirmation()}
                style={['text-danger']}
              />
            </div>
          </ContextMenu>
        ) : (
          <Dropdown drop={'up'}>
            <Dropdown.Toggle
              className={styles.dropdownButton}
              as={'span'}
              variant="success"
              id="contextMenuLayer"
            >
              <Icon iconName="ellipsis-h" />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ position: 'absolute', left: '-60px' }}>
              <Dropdown.Item onClick={() => requestConvertCustomModal()} id="saveAsBlockButton">
                <Icon iconName="copy me-2" />
                {t('designer.layers.SaveAsBlock')}
              </Dropdown.Item>

              {props.new_custom_uuid == null && props.type === 'FORM' && (
                <Dropdown.Item onClick={() => extractFormInputs()}>
                  <Icon iconName="explosion me-2" />
                  {t('designer.layers.ExtractComponents')}
                </Dropdown.Item>
              )}

              {props.new_custom_uuid != null && editor !== Editor.CUSTOM_COMPONENT && (
                <Dropdown.Item onClick={() => goToCustomComponentEditor()}>
                  <Icon iconName="explosion me-2" />
                  {t('designer.layers.ExtractComponents')}
                </Dropdown.Item>
              )}
              {checkIfCanDelete && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={() => requestConfirmation()}>
                    <div className="text-danger mt-1" id="deleteButton">
                      <Icon iconName="trash me-2" />
                      {t('designer.layers.Delete')}
                    </div>
                  </Dropdown.Item>
                </>
              )}
            </Dropdown.Menu>
          </Dropdown>
        ))}
    </>
  );
}

export default ComponentMenu;
